import { rolesFromUserGroups } from 'features/permissions/roleFromUserGroups';
import { Role, ROLE } from 'features/permissions/role.model';
import { USER_GROUPS } from './userGroups';
import { TokenManager } from './tokenManager';

interface AptrinsicUserBase {
  id: string;
  is_internal: boolean;
}

type AptrinsicUserRoles = {
  [key in ROLE]: boolean;
};

type AptrinsicUser = AptrinsicUserBase & AptrinsicUserRoles;

interface AptrinsicAccount {
  client: string;
}

declare const aptrinsic: {
  (method: 'identify', user: AptrinsicUser, account?: AptrinsicAccount): void;
};

const INTERNAL_ROLES: Role[] = [ROLE.SUPER_ADMIN, ROLE.VERITY_USER];

function setGainsightUserAttributes(client?: string) {
  const gainsightAppId = import.meta.env.VITE_GAINSIGHT_APP_ID;
  const tokenManager = TokenManager.getInstance();
  const userId = tokenManager.getUserAttributes()?.sub;
  if (!userId || !gainsightAppId) {
    return;
  }

  const userGroups: USER_GROUPS[] | undefined = tokenManager.userGroupsFromAccessToken();
  const roles: Role[] = rolesFromUserGroups(userGroups ?? []);

  const aptrinsicAccount: AptrinsicAccount = {
    client: client ?? 'undefined',
  };

  const roleAttributes: AptrinsicUserRoles = Object.values(ROLE).reduce(
    (acc, role) => {
      acc[role] = roles.includes(role);
      return acc;
    },
    {} as Record<ROLE, boolean>, // Define the type of the accumulator
  );

  const aptrinsicUser: AptrinsicUser = {
    id: userId,
    is_internal: roles.some((role) => INTERNAL_ROLES.includes(role)),
    ...roleAttributes,
  };

  console.debug('Setting Gainsight Attributes', aptrinsicUser, aptrinsicAccount);

  if (userId) {
    aptrinsic('identify', aptrinsicUser, aptrinsicAccount);
  }
}

export function setGainsightUser() {
  setGainsightUserAttributes();
}

export function setGainsightClient(client?: string) {
  setGainsightUserAttributes(client);
}
