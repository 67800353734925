import { Grid } from '@mui/material';
import { SettingsCard } from '../../SettingsCard';
import { SettingsItem } from '../../models/settingsCard.model';

export const MeasurementSystemCard = ({
  isLoading,
  items = [],
}: {
  isLoading: boolean;
  items?: SettingsItem[];
}) => (
  <Grid md={12} sm={12} xs={12} item>
    <SettingsCard
      title="Measurement System"
      isLoading={isLoading}
      items={items}
      testId="c-measurement-system-card"
    />
  </Grid>
);
