import { ILocationDataST, IVeritySlotStatus1ST } from 'codegen/report';
import { ILocationData } from 'store/Modals/facilityModals/IFacilityModalsStore';

export const getVerityState = (locationData: ILocationData | ILocationDataST) => {
  if (Object.hasOwn(locationData, 'verityState')) {
    return (locationData as ILocationData)?.verityState;
  }
  const verityStatus = (locationData as ILocationDataST)?.verity_status as IVeritySlotStatus1ST;
  return verityStatus?.state;
};
