import { IRuleActionSTIssueLogicEnum } from 'codegen/facility_settings';
import {
  IVeritySlotStatusStateST,
  IVeritySlotStatusUserOverrideST,
} from 'codegen/location_information';
import { ILocationDataST } from 'codegen/report';
import { DISPLAY_VERITY_STATES } from 'common/slotStates';
import { ILocationData } from 'store/Modals/facilityModals/IFacilityModalsStore';
import { getOverride } from './getOverride';
import { getContentFound } from './getContentFound';
import { getWmsState } from './getWmsState';
import { getVerityState } from './getVerityState';
import { locationHasIssues } from './locationHasIssues';
import { getVerityValuesAppliedByIssueLogicLocationWithIssue } from './getVerityValuesAppliedByIssueLogicLocationWithIssue';
import { getVerityValuesAppliedByIssueLogicLocationNoIssue } from './getVerityValuesAppliedByIssueLogicLocationNoIssue';
import { getVerityStatus } from './getVerityStatus';

const getVerityValuesAppliedByIssueLogicLocationWithOverride = (
  locationData: ILocationData | ILocationDataST,
  userOverride: IVeritySlotStatusUserOverrideST,
) => {
  const overridenContent: string[] = [];
  if (userOverride.state !== 'BARCODE') {
    return {
      verityState: userOverride.state,
      contentFound: [DISPLAY_VERITY_STATES[userOverride.state]],
    };
  }
  if (userOverride.barcodes.length) {
    let numOverride = 0;
    const contentFound = getContentFound(locationData);
    contentFound.forEach((found) => {
      if (found === 'No match') {
        overridenContent.push(found);
      } else {
        overridenContent.push(userOverride.barcodes[numOverride]);
        numOverride += 1;
      }
    });
  }
  return { verityState: userOverride.state, contentFound: overridenContent };
};

export const getVerityValuesAppliedByIssueLogic = (
  locationData: ILocationData | ILocationDataST,
  issueLogic: IRuleActionSTIssueLogicEnum | undefined,
): { verityState?: IVeritySlotStatusStateST; contentFound: string[] } => {
  const userOverride = getOverride(locationData);
  if (userOverride) {
    return getVerityValuesAppliedByIssueLogicLocationWithOverride(locationData, userOverride);
  }

  const hasIssue = locationHasIssues(locationData);
  if (hasIssue) {
    return getVerityValuesAppliedByIssueLogicLocationWithIssue({
      wmsState: getWmsState(locationData),
      verityState: getVerityState(locationData),
      contentFound: getContentFound(locationData),
    });
  }

  return getVerityValuesAppliedByIssueLogicLocationNoIssue({
    wmsState: getWmsState(locationData),
    verityStatus: getVerityStatus(locationData),
    contentFound: getContentFound(locationData),
    issueLogic,
  });
};
