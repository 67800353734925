import axios, { AxiosResponse } from 'axios';
import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack';

import { singleRequestHandler } from 'common/requestHelpers';

import {
  IWMSDataUploadPostRequestST,
  IWMSDataUploadPostResponsePresignedPostST,
  IWMSDataUploadPostResponseST,
  IWMSMovedLocationsDataUploadPostRequestST,
  IWMSMovedLocationsDataUploadPostResponseST,
  IWMSStockCheckDataUploadPostRequestST,
  IWMSStockCheckDataUploadPostResponseST,
} from 'codegen/wms_data';
import { IReportSpecificationPostRequestST } from 'codegen/report_specification';
import {
  getFacilityApiService,
  getReportSpecificationApiService,
  getWMSDataService,
} from './services';

const getFormDataForS3Upload = (
  presignedPostData: IWMSDataUploadPostResponsePresignedPostST,
  file: File,
) => {
  const formData = new FormData();

  if (presignedPostData.fields) {
    Object.entries(presignedPostData.fields).forEach(([key, val]) => {
      formData.append(key, val);
    });
  }

  formData.append('file', file);

  return formData;
};

const uploadWMSFile = (
  presignedPostService: (
    systemId: string,
    data:
      | IWMSDataUploadPostRequestST
      | IWMSStockCheckDataUploadPostRequestST
      | IWMSMovedLocationsDataUploadPostRequestST,
  ) => Promise<
    AxiosResponse<
      | IWMSDataUploadPostResponseST
      | IWMSStockCheckDataUploadPostResponseST
      | IWMSMovedLocationsDataUploadPostResponseST
    >
  >,
  file: File,
  setProgress: (percentage: number) => void,
  dispatcher?: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
) => {
  const uploadProgress = {
    onUploadProgress: (progressEvent: { loaded: number; total: number }) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setProgress(percentCompleted);
    },
  };

  return singleRequestHandler({
    request: presignedPostService,
    dispatcher,
    messageSuccess: 'Presigned post URL is get successfully.',
    messageErrorFallback: 'Error getting presigned post URL.',
  }).then(
    (
      response: AxiosResponse<
        | IWMSDataUploadPostResponseST
        | IWMSStockCheckDataUploadPostResponseST
        | IWMSMovedLocationsDataUploadPostResponseST
      >,
    ) => {
      const presignedPostData = response.data.presigned_post;
      const formData = getFormDataForS3Upload(presignedPostData, file);

      return singleRequestHandler({
        // we use axios directly here, because this url is loaded from the server
        // and it contains it's own keys
        request: axios.post,
        requestParams: [presignedPostData.url, formData, { ...uploadProgress }],
        dispatcher,
        messageSuccess: 'WMS data uploaded successfully.',
        messageErrorFallback: 'Error uploading WMS data.',
      });
    },
  );
};

interface IWMSDataParams {
  from?: string;
  until?: string;
  limit?: number;
  pageToken?: string;
  latest?: 'true' | 'false';
}

/**
 * Get uploaded snapshot file/s.
 * @param {string} systemId System ID (of the Facility)
 * @param {object} params <IWMSDataParams>
 * @returns {object} <IWMSDataFilesGetResponseST>
 */
const getUploadedWMSData = (systemId: string, params: IWMSDataParams, signal: AbortSignal) =>
  getWMSDataService().getUploadedWMSData(
    systemId,
    params.from,
    params.until,
    params?.limit,
    params?.pageToken,
    params?.latest,
    { signal },
  );

/**
 * Get uploaded stock check file/s.
 * @param {string} systemId System ID (of the Facility)
 * @param {object} params <IWMSDataParams>
 * @returns {object} <IWMSStockCheckDataUploadGetResponseST>
 */
const getUploadedStockCheckData = (systemId: string, params: IWMSDataParams, signal: AbortSignal) =>
  getWMSDataService().getUploadedStockCheckData(
    systemId,
    params.from,
    params.until,
    params?.limit,
    params?.pageToken,
    params?.latest,
    { signal },
  );

/**
 * Get uploaded moved locations file/s.
 * @param {string} systemId System ID (of the Facility)
 * @param {object} params <IWMSDataParams>
 * @returns {object} <IWMSMovedLocationsDataUploadGetResponseST>
 */
const getUploadedMovedLocationsData = (
  systemId: string,
  params: IWMSDataParams,
  signal: AbortSignal,
) =>
  getWMSDataService().getUploadedMovedLocationsData(
    systemId,
    params.from,
    params.until,
    params?.limit,
    params?.pageToken,
    params?.latest,
    { signal },
  );

const getMapUploadPresignedUrl = (systemId: string, signal: AbortSignal) =>
  getFacilityApiService().postFacilityPresignedUrl(systemId, { signal });

const getWMSDataPresignedUrl = (
  systemId: string,
  iWMSDataUploadPostRequestST: IWMSDataUploadPostRequestST,
) => getWMSDataService().getWMSDataPresignedUrl(systemId, iWMSDataUploadPostRequestST);

const getStockCheckDataPresignedUrl = (
  systemId: string,
  iWMSStockCheckDataUploadPostRequestST: IWMSStockCheckDataUploadPostRequestST,
) =>
  getWMSDataService().getStockCheckDataPresignedUrl(
    systemId,
    iWMSStockCheckDataUploadPostRequestST,
  );

const getMovedLocationsDataPresignedUrl = (
  systemId: string,
  iWMSMovedLocationsDataUploadPostRequestST: IWMSMovedLocationsDataUploadPostRequestST,
) =>
  getWMSDataService().getMovedLocationsDataPresignedUrl(
    systemId,
    iWMSMovedLocationsDataUploadPostRequestST,
  );

const uploadReportSpecification = (
  systemId: string,
  iReportSpecificationPostRequestST: IReportSpecificationPostRequestST,
) =>
  getReportSpecificationApiService().uploadReportSpecification(
    systemId,
    iReportSpecificationPostRequestST,
  );

/**
 * Get all report specifications.
 * @param {string} systemId System ID (of the Facility)
 * @returns {object} <IReportSpecificationGetAllResponseST>
 */
const getReportSpecifications = (systemId: string) =>
  getReportSpecificationApiService().getReportSpecifications(systemId);

/**
 * Remove specific report specification.
 * @param {string} systemId System ID (of the Facility)
 * @param {string} reportSpecId Report Specification ID
 * @returns {object} <IReportSpecificationDeleteResponseST>
 */
const deleteReportSpecification = (systemId: string, reportSpecId: string) =>
  getReportSpecificationApiService().deleteReportSpecification(systemId, reportSpecId);

export const uploadServices = {
  uploadWMSFile,
  getUploadedWMSData,
  getUploadedStockCheckData,
  getUploadedMovedLocationsData,
  getMapUploadPresignedUrl,
  getWMSDataPresignedUrl,
  getStockCheckDataPresignedUrl,
  getMovedLocationsDataPresignedUrl,
  uploadReportSpecification,
  getReportSpecifications,
  deleteReportSpecification,
};
