import { TextField, InputAdornment, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { ChangeEvent, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import type { SearchFieldProps } from './SearchField.model';
import { getFilterOnColumns } from './getFilterOnColumns';

export const SearchField = ({ columns, onChange, isDisabled = false }: SearchFieldProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [value, setValue] = useState('');

  const updateTerm = (term: string) => {
    debounce(() => {
      const items = getFilterOnColumns(term, columns);
      onChange(items);
    }, 200)();
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const term = event.target.value;
    setValue(term);
    updateTerm(term);
  };

  const handleClear = () => {
    setValue('');
    updateTerm('');
  };

  return (
    <TextField
      variant="outlined"
      size="small"
      color="primary"
      aria-label="filter"
      data-testid="c-search-filter"
      onChange={handleChange}
      value={value}
      inputRef={inputRef}
      disabled={isDisabled}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              data-testid="c-clear-search-filter"
              aria-label="clear filter"
              onClick={handleClear}
              size="small"
            >
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
