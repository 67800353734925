import { ILocationDataST, IVeritySlotStatus1ST } from 'codegen/report';
import { ILocationData } from 'store/Modals/facilityModals/IFacilityModalsStore';

export const getOverride = (locationData: ILocationData | ILocationDataST) => {
  if (Object.hasOwn(locationData, 'userOverride')) {
    return (locationData as ILocationData)?.userOverride;
  }
  const verityStatus = (locationData as ILocationDataST)?.verity_status as IVeritySlotStatus1ST;
  if (verityStatus && verityStatus.user_overrides?.length) {
    return verityStatus.user_overrides[verityStatus.user_overrides.length - 1];
  }

  return undefined;
};
