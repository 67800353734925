import { createContext, useContext, useReducer } from 'react';
import { initialFacilityModalsState } from './facilityModalInitialState';
import { modalsReducer } from './facilityModalsReducer';
import { IModalsStoreContext } from './IFacilityModalsStore';

const FacilityModalsStore = createContext<IModalsStoreContext | undefined>(undefined);

export const FacilityModalsStoreProvider = ({ children }: { children: React.ReactNode }) => {
  const [facilityModalsState, dispatchFacilityModals] = useReducer(
    modalsReducer,
    initialFacilityModalsState,
  );

  return (
    <FacilityModalsStore.Provider value={{ facilityModalsState, dispatchFacilityModals }}>
      {children}
    </FacilityModalsStore.Provider>
  );
};

export const useFacilityModalsStore = () => {
  const facilityModalsContext = useContext(FacilityModalsStore);

  if (facilityModalsContext === undefined) {
    throw new Error('useFacilityModalsStore has to be used within <FacilityModalsStoreProvider>');
  }

  return facilityModalsContext;
};
