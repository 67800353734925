import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { ILabelTypeST } from 'codegen/location_action';
import { useLabelsCardStyles } from './LabelsCard.style';
import { useLocationLabelApi } from './hooks/useLocationLabelApi';
import { LabelsCardContent } from './LabelsCardContent';

export const LabelsCard = ({
  locationId,
  wmsDateTime,
  verityDatetime,
}: {
  locationId: string;
  wmsDateTime: string;
  verityDatetime: string;
}) => {
  const { classes } = useLabelsCardStyles();
  const { isLoading, data, error, mutateAddLabel, mutateArchiveLabel } = useLocationLabelApi({
    locationId,
  });

  const [selectedLabelType, setSelectedLabelType] = useState<ILabelTypeST | undefined>();

  useEffect(() => {
    if (data) {
      setSelectedLabelType(data.data.label_type);
    }
  }, [data, error]);

  return (
    <Card className={classes.card} data-testid="labels-card">
      <CardHeader
        title="Labels"
        subheader="Add a label to the locations that require a follow up action."
        className={classes.cardHeader}
        classes={{
          title: classes.title,
          subheader: classes.subheader,
        }}
      />
      <CardContent>
        <Box display="flex" flexDirection="column" gap={2}>
          {isLoading ? (
            <Box display="flex" justifyContent="center">
              <CircularProgress
                color="secondary"
                disableShrink={true}
                size={40}
                data-testid="labels-card-spinner"
              />
            </Box>
          ) : (
            <LabelsCardContent
              locationLabel={data?.data}
              mutateAddLabel={mutateAddLabel}
              mutateArchiveLabel={mutateArchiveLabel}
              selectedLabelType={selectedLabelType}
              wmsDateTime={wmsDateTime}
              verityDateTime={verityDatetime}
            />
          )}
        </Box>
      </CardContent>
    </Card>
  );
};
