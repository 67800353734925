// libraries
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';

// mui core
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';

// mui icons
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

// store
import { useClientModalsStore } from '../../store/Modals';

// components
import { MenuLinks } from './MenuLinks';

interface IUserMenuProps {
  open: boolean;
  toggleSidebar: () => void;
  classes: { userOptionsDrawer: string; drawerPaper: string; drawerHeader: string; avatar: string };
  username: string;
  avatarInitials: string;
}

export const UserMenu = (props: IUserMenuProps) => {
  // props
  const { open, toggleSidebar, classes, username, avatarInitials } = props;

  // client level modals
  const { dispatchClientModals } = useClientModalsStore();

  // navigate and context
  const navigate = useNavigate();

  return (
    <Drawer
      className={clsx(classes.userOptionsDrawer, 'v-drawer')}
      variant="temporary"
      anchor="right"
      ModalProps={{ onBackdropClick: toggleSidebar }}
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <Tooltip title={username}>
          <Avatar className={classes.avatar}>{avatarInitials}</Avatar>
        </Tooltip>
        <IconButton onClick={toggleSidebar} size="large">
          <ChevronRightIcon />
        </IconButton>
      </div>
      <Divider />
      <MenuLinks
        toggleSidebar={toggleSidebar}
        navigate={navigate}
        dispatchClientModals={dispatchClientModals}
      />
    </Drawer>
  );
};
