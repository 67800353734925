import { IReportSpecificationST } from 'codegen/report_specification';
import moment from 'moment';
import { ScheduleFormAction } from '../reducers/ScheduleForm.model';

export const handleEndTimeChange = (
  dateUntil: moment.MomentInput,
  allowPastStartDate: boolean,
  reportSpec: IReportSpecificationST,
  dispatch: (value: ScheduleFormAction) => void,
): void => {
  moment(dateUntil).isValid() &&
    dispatch({
      type: 'SET_DATE_UNTIL',
      payload: { dateUntil, reportSpec, allowPastStartDate },
    });
};
