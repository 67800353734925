import { IRuleActionSTIssueLogicEnum } from 'codegen/facility_settings';
import { IIssueLogicST } from 'codegen/report';

export const getIssueLogicDescription = (issueLogic?: IIssueLogicST | null) => {
  switch (issueLogic) {
    case IRuleActionSTIssueLogicEnum.C001CMissingBc:
      return (
        <>
          This issue logic has been created to address the <b>UPS</b> use case. It treats locations
          with only missing barcodes as potential issues.
        </>
      );
    case IRuleActionSTIssueLogicEnum.DoubleDepth21:
      return (
        <>
          This issue logic should be <b>deprecated</b> in favor of <b>DOUBLE_DEPTH_MAP_BASED</b>.
          This is a custom issue logic created to address double depth slots in IKEA sites.
          Specifically, this applies to slots where the front labels start with 21, and the back
          slot start with 22.
        </>
      );
    case IRuleActionSTIssueLogicEnum.DoubleDepth22:
      return (
        <>
          This issue logic should be <b>deprecated</b> in favor of <b>DOUBLE_DEPTH_MAP_BASED</b>.
          This is a custom issue logic created to address double depth slots in IKEA sites.
          Specifically, this applies to slots where the front labels start with 22, and the back
          slot start with 21.
        </>
      );
    case IRuleActionSTIssueLogicEnum.DoubleDepthMapBased:
      return (
        <>
          This issue logic leverages the facility map to understand neighboring locations. It
          applies the double depth logic to all slots detected as double depth from the map.
        </>
      );
    case IRuleActionSTIssueLogicEnum.NaBarcode:
      return (
        <>
          This issue logic has been created for the <b>KEHE</b> use case to treat their ‘non empty,
          no barcode’ locations.
        </>
      );
    case IRuleActionSTIssueLogicEnum.PictureOnly:
      return (
        <>
          his issue logic ensures that no issues can be created, since we only care about taking
          pictures of locations.
        </>
      );
    case IRuleActionSTIssueLogicEnum.Default:
      return <>Default issue logic normally used by Verity systems.</>;
    default:
      return <>No issue logic could be detected.</>;
  }
};
