import { useState, useEffect, useCallback, useRef } from 'react';

const getScrollY = () =>
  window.scrollY ||
  (document.documentElement || document.body.parentNode || document.body).scrollTop;

const shouldHide = (
  scrollY: number,
  prevScrollY: number | undefined = 0,
  threshold: number | undefined,
) => {
  if (scrollY < prevScrollY) {
    return false;
  }
  if (scrollY > prevScrollY && scrollY > (threshold != null ? threshold : 100)) {
    return true;
  }
  return false;
};

export const useHideOnScroll = ({ threshold }: { threshold: number }) => {
  const scrollRef: React.MutableRefObject<number | undefined> = useRef();
  const [hide, setHide] = useState<boolean | undefined>(false);

  const handleScroll = useCallback(() => {
    const scrollY = getScrollY();
    const prevScrollY = scrollRef.current;
    scrollRef.current = scrollY;

    setHide(shouldHide(scrollY, prevScrollY, threshold));
  }, [threshold]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return hide;
};
