import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import * as React from 'react';
import { ChangeEvent } from 'react';

export const WarehouseStatusBetaTabs = ({
  tabs,
  activeTab,
  handleTabChange,
  tabCounts,
}: {
  tabs: {
    label: string;
    show: boolean;
  }[];
  activeTab: number;
  handleTabChange: (event: ChangeEvent<HTMLInputElement>, newValue: number) => void;
  tabCounts: {
    [key: string]: number;
  };
}) => (
  <Tabs
    aria-label="tabs"
    value={activeTab}
    scrollButtons
    allowScrollButtonsMobile
    variant="scrollable"
    onChange={handleTabChange}
  >
    {tabs
      .filter((tab) => tab.show)
      .map((tab) => {
        const tabCount =
          typeof tabCounts[tab.label] === 'number' ? ` (${tabCounts[tab.label]})` : '';
        return <Tab data-testid="c-table-tab" key={tab.label} label={`${tab.label}${tabCount}`} />;
      })}
  </Tabs>
);
