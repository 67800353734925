import { convertInputValue } from 'udb/ground-control/utils/convertInputValue';

export const getConvertedEvent = (
  name: string,
  event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  { isMetricSystem }: { isMetricSystem: boolean },
) => {
  const { value } = event.target;

  let convertedValue;

  if (isMetricSystem) {
    convertedValue = value || '0';
  } else {
    convertedValue = convertInputValue(value, { isMetricSystem });
  }

  return {
    ...event,
    target: {
      ...event.target,
      name,
      value: convertedValue,
    },
  };
};
