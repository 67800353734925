import { IFacilityInfoST, IFacilityMapInfoST } from 'codegen/facility';
import { REPORT_STATES } from 'common/reportStates';
import {
  IFacilitySettingsST,
  IFacilitySettingsSTMeasurementSystemEnum,
  IReportDeadlinesST,
} from 'codegen/facility_settings';
import { IFlightDomainData } from 'store/GroundControl/groundControlLevelStore.model';
import { IFacilityLevelState } from './facilityLevelStore.model';

export const initialFacilitySettings: IFacilitySettingsST = {
  allow_manual_wms_file_upload: false,
  auto_approve_verity_status: false,
  allow_user_amend: false,
  barcode_invalid_lengths: [],
  barcode_max_length: 99,
  barcode_min_length: 1,
  barcode_wms_substring_match: false,
  barcode_types: [],
  carefully_trust_reviewers_barcodes: false,
  enable_coexistence_wms_integration: false,
  enable_csfa_task_request_result: false,
  enable_diagnostic_view_logic: false,
  show_location_labels: false,
  enable_dual_check_on_unlock: false,
  enable_experimental_features: false,
  enable_mfa: false,
  enable_warehouse_status_reset: false,
  enable_superset_integration: false,
  enable_3d_location_view: false,
  external_review_batch_max_wait_time_s: 1,
  external_review_batch_size_desired: 1,
  external_review_enabled: false,
  external_review_num_assignments_per_review: 1,
  external_review_overrides_verity_data: false,
  fraction_of_correct_results_to_be_reviewed: 0,
  issue_logic_filter_names: [],
  measurement_system: IFacilitySettingsSTMeasurementSystemEnum.Metric,
  mturk_hit_layout_id: '',
  mturk_hit_lifetime_in_seconds: 1,
  mturk_hit_type_id: '',
  report_deadlines: {} as IReportDeadlinesST,
  show_customer: false,
  show_ground_control_app: false,
  show_coexistence_map: false,
  enable_counting: false,
  show_potential_issues: false,
  show_report_deadlines: false,
  show_scheduled_lock: false,
  support_email_recipients: '',
  trim_leading_digits: false,
  wms_email_senders: '',
  wms_slot_label_regex: '',
};

/**
 * Initial state of the facility level store
 */
export const initialFacilityLevelState: IFacilityLevelState = {
  appDataLoaded: false,
  beVersion: '',
  beLastUpdated: '',
  currentSystemId: undefined,
  facilityData: {} as IFacilityInfoST,
  facilityMapInfo: {} as IFacilityMapInfoST,
  facilityInfoLoaded: false,
  isFacilityInfoLoading: true,
  facilitySlotsLoaded: false,
  facilityMapInfoLoaded: false,
  facilityMapUpdate: {} as any,
  facilitySettings: initialFacilitySettings,
  facilitySettingsLoaded: false,
  flightDomains: [] as IFlightDomainData[],
  flightDomainsLoaded: false,
  isDataReady: false,
  isFacilityDataInitiated: false,
  inventory: {
    imageAvailable: undefined,
    reportExportAvailable: null,
    reportSpecifications: [],
    reportUpdate: {
      data: {
        report_id: '',
        report_state: REPORT_STATES.SCHEDULED,
        progress: 0,
        fullResponse: undefined,
        systemId: '',
        report_name: '',
        message: '',
        completion: 0,
      },
    },
    warehouseExportAvailable: null,
  },
  showSnackbar: false,
  webSocketError: false,
  slots: [],
  ui: {
    notFound: false,
  },
};
