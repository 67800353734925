/* tslint:disable */
/* eslint-disable */
/**
 * User Admin
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface IErrorResponseErrorsInnerST
 */
export interface IErrorResponseErrorsInnerST {
  /**
   * Error code
   * @type {string}
   * @memberof IErrorResponseErrorsInnerST
   */
  code: string;
}
/**
 *
 * @export
 * @interface IErrorResponseST
 */
export interface IErrorResponseST {
  /**
   * Error description
   * @type {string}
   * @memberof IErrorResponseST
   */
  message: string;
  /**
   * Error timestamp
   * @type {string}
   * @memberof IErrorResponseST
   */
  timestamp: string;
  /**
   * User triggering the request
   * @type {string}
   * @memberof IErrorResponseST
   */
  user_name: string;
  /**
   * The request trace id
   * @type {string}
   * @memberof IErrorResponseST
   */
  trace_id: string;
  /**
   * The URL that can be used to report the error
   * @type {string}
   * @memberof IErrorResponseST
   */
  report_url: string | null;
  /**
   * List of Error objects.
   * @type {Array<IErrorResponseErrorsInnerST>}
   * @memberof IErrorResponseST
   */
  errors: Array<IErrorResponseErrorsInnerST>;
}
/**
 *
 * @export
 * @interface IGroupGetAllResponseST
 */
export interface IGroupGetAllResponseST {
  /**
   * List of available group names
   * @type {Array<string>}
   * @memberof IGroupGetAllResponseST
   */
  groups: Array<IGroupGetAllResponseSTGroupsEnum>;
}

export const IGroupGetAllResponseSTGroupsEnum = {
  InventoryManager: 'InventoryManager',
  SuperAdmin: 'SuperAdmin',
  GroundControlOfficer: 'GroundControlOfficer',
  SafetyControls: 'SafetyControls',
  Analytics: 'Analytics',
  VerityUser: 'VerityUser',
  Reviewer: 'Reviewer',
  ClientAdmin: 'ClientAdmin',
  FacilityAdmin: 'FacilityAdmin',
} as const;

export type IGroupGetAllResponseSTGroupsEnum =
  typeof IGroupGetAllResponseSTGroupsEnum[keyof typeof IGroupGetAllResponseSTGroupsEnum];

/**
 * List of user\'s in pool
 * @export
 * @interface IUserGetAllResponseST
 */
export interface IUserGetAllResponseST {
  /**
   *
   * @type {Array<IUserResponseST>}
   * @memberof IUserGetAllResponseST
   */
  users: Array<IUserResponseST>;
}
/**
 *
 * @export
 * @interface IUserGetResponseST
 */
export interface IUserGetResponseST {
  /**
   *
   * @type {IUserResponseST}
   * @memberof IUserGetResponseST
   */
  user: IUserResponseST;
  /**
   * List of available group names
   * @type {Array<string>}
   * @memberof IUserGetResponseST
   */
  groups: Array<IUserGetResponseSTGroupsEnum>;
}

export const IUserGetResponseSTGroupsEnum = {
  InventoryManager: 'InventoryManager',
  SuperAdmin: 'SuperAdmin',
  GroundControlOfficer: 'GroundControlOfficer',
  SafetyControls: 'SafetyControls',
  Analytics: 'Analytics',
  VerityUser: 'VerityUser',
  Reviewer: 'Reviewer',
  ClientAdmin: 'ClientAdmin',
  FacilityAdmin: 'FacilityAdmin',
} as const;

export type IUserGetResponseSTGroupsEnum =
  typeof IUserGetResponseSTGroupsEnum[keyof typeof IUserGetResponseSTGroupsEnum];

/**
 *
 * @export
 * @interface IUserGroupPutRequestST
 */
export interface IUserGroupPutRequestST {
  /**
   * List of available group names
   * @type {Array<string>}
   * @memberof IUserGroupPutRequestST
   */
  new_user_groups: Array<IUserGroupPutRequestSTNewUserGroupsEnum>;
}

export const IUserGroupPutRequestSTNewUserGroupsEnum = {
  InventoryManager: 'InventoryManager',
  SuperAdmin: 'SuperAdmin',
  GroundControlOfficer: 'GroundControlOfficer',
  SafetyControls: 'SafetyControls',
  Analytics: 'Analytics',
  VerityUser: 'VerityUser',
  Reviewer: 'Reviewer',
  ClientAdmin: 'ClientAdmin',
  FacilityAdmin: 'FacilityAdmin',
} as const;

export type IUserGroupPutRequestSTNewUserGroupsEnum =
  typeof IUserGroupPutRequestSTNewUserGroupsEnum[keyof typeof IUserGroupPutRequestSTNewUserGroupsEnum];

/**
 *
 * @export
 * @interface IUserPostRequestST
 */
export interface IUserPostRequestST {
  /**
   * User family name
   * @type {string}
   * @memberof IUserPostRequestST
   */
  family_name: string;
  /**
   * User given name
   * @type {string}
   * @memberof IUserPostRequestST
   */
  given_name: string;
  /**
   * User phone number
   * @type {string}
   * @memberof IUserPostRequestST
   */
  phone_number: string;
  /**
   * List of user\'s system IDs as string
   * @type {string}
   * @memberof IUserPostRequestST
   */
  system_ids: string;
  /**
   * The email of the user
   * @type {string}
   * @memberof IUserPostRequestST
   */
  user_email: string;
  /**
   * List of available group names
   * @type {Array<string>}
   * @memberof IUserPostRequestST
   */
  user_groups: Array<IUserPostRequestSTUserGroupsEnum>;
  /**
   * Allow access to all client\'s facilities
   * @type {boolean}
   * @memberof IUserPostRequestST
   */
  access_to_all_facilities: boolean;
}

export const IUserPostRequestSTUserGroupsEnum = {
  InventoryManager: 'InventoryManager',
  SuperAdmin: 'SuperAdmin',
  GroundControlOfficer: 'GroundControlOfficer',
  SafetyControls: 'SafetyControls',
  Analytics: 'Analytics',
  VerityUser: 'VerityUser',
  Reviewer: 'Reviewer',
  ClientAdmin: 'ClientAdmin',
  FacilityAdmin: 'FacilityAdmin',
} as const;

export type IUserPostRequestSTUserGroupsEnum =
  typeof IUserPostRequestSTUserGroupsEnum[keyof typeof IUserPostRequestSTUserGroupsEnum];

/**
 *
 * @export
 * @interface IUserPostResponseST
 */
export interface IUserPostResponseST {
  /**
   *
   * @type {IUserResponseST}
   * @memberof IUserPostResponseST
   */
  user: IUserResponseST;
}
/**
 *
 * @export
 * @interface IUserPutRequestST
 */
export interface IUserPutRequestST {
  /**
   * User family name
   * @type {string}
   * @memberof IUserPutRequestST
   */
  family_name?: string;
  /**
   * User given name
   * @type {string}
   * @memberof IUserPutRequestST
   */
  given_name?: string;
  /**
   * User phone number
   * @type {string}
   * @memberof IUserPutRequestST
   */
  phone_number?: string;
  /**
   * List of user\'s system IDs as string
   * @type {string}
   * @memberof IUserPutRequestST
   */
  system_ids?: string;
  /**
   * Allow access to all client\'s facilities
   * @type {boolean}
   * @memberof IUserPutRequestST
   */
  access_to_all_facilities?: boolean;
}
/**
 * @type IUserResponseEmailST
 * The email of the user
 * @export
 */
export type IUserResponseEmailST = any;

/**
 *
 * @export
 * @interface IUserResponseST
 */
export interface IUserResponseST {
  /**
   * Timestamp of when the user was created
   * @type {string}
   * @memberof IUserResponseST
   */
  create_date: string;
  /**
   *
   * @type {IUserResponseEmailST}
   * @memberof IUserResponseST
   */
  email: IUserResponseEmailST;
  /**
   * Is user email verified
   * @type {string}
   * @memberof IUserResponseST
   */
  email_verified: IUserResponseSTEmailVerifiedEnum;
  /**
   * Is user enabled
   * @type {string}
   * @memberof IUserResponseST
   */
  enabled: IUserResponseSTEnabledEnum;
  /**
   * User family name
   * @type {string}
   * @memberof IUserResponseST
   */
  family_name: string;
  /**
   * User given name
   * @type {string}
   * @memberof IUserResponseST
   */
  given_name: string;
  /**
   * Allow access to all client\'s facilities
   * @type {boolean}
   * @memberof IUserResponseST
   */
  access_to_all_facilities?: boolean;
  /**
   * Timestamp of when the user was modified
   * @type {string}
   * @memberof IUserResponseST
   */
  last_modified_date: string;
  /**
   * User phone number
   * @type {string}
   * @memberof IUserResponseST
   */
  phone_number: string | null;
  /**
   * Is user phone number verified
   * @type {string}
   * @memberof IUserResponseST
   */
  phone_number_verified: IUserResponseSTPhoneNumberVerifiedEnum;
  /**
   * User status
   * @type {string}
   * @memberof IUserResponseST
   */
  status: IUserResponseSTStatusEnum;
  /**
   *
   * @type {string}
   * @memberof IUserResponseST
   */
  sub: string;
  /**
   * List of user\'s system IDs as integer
   * @type {Array<number>}
   * @memberof IUserResponseST
   */
  system_ids: Array<number>;
  /**
   * List of user\'s system IDs as string in short format (comma separated list)
   * @type {string}
   * @memberof IUserResponseST
   */
  user_system_ids: string;
  /**
   *
   * @type {string}
   * @memberof IUserResponseST
   */
  username: string;
  /**
   * List of available group names
   * @type {Array<string>}
   * @memberof IUserResponseST
   */
  user_groups?: Array<IUserResponseSTUserGroupsEnum>;
  /**
   * Indicating whether the current user can modify this user.
   * @type {boolean}
   * @memberof IUserResponseST
   */
  read_only: boolean;
}

export const IUserResponseSTEmailVerifiedEnum = {
  True: 'true',
  False: 'false',
} as const;

export type IUserResponseSTEmailVerifiedEnum =
  typeof IUserResponseSTEmailVerifiedEnum[keyof typeof IUserResponseSTEmailVerifiedEnum];
export const IUserResponseSTEnabledEnum = {
  True: 'True',
  False: 'False',
} as const;

export type IUserResponseSTEnabledEnum =
  typeof IUserResponseSTEnabledEnum[keyof typeof IUserResponseSTEnabledEnum];
export const IUserResponseSTPhoneNumberVerifiedEnum = {
  True: 'true',
  False: 'false',
} as const;

export type IUserResponseSTPhoneNumberVerifiedEnum =
  typeof IUserResponseSTPhoneNumberVerifiedEnum[keyof typeof IUserResponseSTPhoneNumberVerifiedEnum];
export const IUserResponseSTStatusEnum = {
  Unconfirmed: 'UNCONFIRMED',
  Confirmed: 'CONFIRMED',
  Archived: 'ARCHIVED',
  Compromised: 'COMPROMISED',
  Unknown: 'UNKNOWN',
  ResetRequired: 'RESET_REQUIRED',
  ForceChangePassword: 'FORCE_CHANGE_PASSWORD',
  ExternalProvider: 'EXTERNAL_PROVIDER',
} as const;

export type IUserResponseSTStatusEnum =
  typeof IUserResponseSTStatusEnum[keyof typeof IUserResponseSTStatusEnum];
export const IUserResponseSTUserGroupsEnum = {
  InventoryManager: 'InventoryManager',
  SuperAdmin: 'SuperAdmin',
  GroundControlOfficer: 'GroundControlOfficer',
  SafetyControls: 'SafetyControls',
  Analytics: 'Analytics',
  VerityUser: 'VerityUser',
  Reviewer: 'Reviewer',
  ClientAdmin: 'ClientAdmin',
  FacilityAdmin: 'FacilityAdmin',
} as const;

export type IUserResponseSTUserGroupsEnum =
  typeof IUserResponseSTUserGroupsEnum[keyof typeof IUserResponseSTUserGroupsEnum];

/**
 * UserAdminApi - axios parameter creator
 * @export
 */
export const UserAdminApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Create a new user.
     * @summary Create a new user.
     * @param {string} systemIdStr
     * @param {IUserPostRequestST} iUserPostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUser: async (
      systemIdStr: string,
      iUserPostRequestST: IUserPostRequestST,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('createUser', 'systemIdStr', systemIdStr);
      // verify required parameter 'iUserPostRequestST' is not null or undefined
      assertParamExists('createUser', 'iUserPostRequestST', iUserPostRequestST);
      const localVarPath = `/{system_id_str}/user`.replace(
        `{${'system_id_str'}}`,
        encodeURIComponent(String(systemIdStr)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iUserPostRequestST,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Remove specific user.
     * @summary Remove specific user.
     * @param {string} systemIdStr
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteUser: async (
      systemIdStr: string,
      userId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('deleteUser', 'systemIdStr', systemIdStr);
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('deleteUser', 'userId', userId);
      const localVarPath = `/{system_id_str}/user/{user_id}`
        .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
        .replace(`{${'user_id'}}`, encodeURIComponent(String(userId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Return available users.
     * @summary Get all users.
     * @param {string} systemIdStr
     * @param {string} [withUserGroups]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllUsers: async (
      systemIdStr: string,
      withUserGroups?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getAllUsers', 'systemIdStr', systemIdStr);
      const localVarPath = `/{system_id_str}/user`.replace(
        `{${'system_id_str'}}`,
        encodeURIComponent(String(systemIdStr)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (withUserGroups !== undefined) {
        localVarQueryParameter['with_user_groups'] = withUserGroups;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Return specific user.
     * @summary Get specific user.
     * @param {string} systemIdStr
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUser: async (
      systemIdStr: string,
      userId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getUser', 'systemIdStr', systemIdStr);
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('getUser', 'userId', userId);
      const localVarPath = `/{system_id_str}/user/{user_id}`
        .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
        .replace(`{${'user_id'}}`, encodeURIComponent(String(userId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Return available groups from cognito.
     * @summary Get available groups.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserGroups: async (
      systemIdStr: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getUserGroups', 'systemIdStr', systemIdStr);
      const localVarPath = `/{system_id_str}/group`.replace(
        `{${'system_id_str'}}`,
        encodeURIComponent(String(systemIdStr)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update specific user.
     * @summary Update specific user.
     * @param {string} systemIdStr
     * @param {string} userId
     * @param {IUserPutRequestST} iUserPutRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUser: async (
      systemIdStr: string,
      userId: string,
      iUserPutRequestST: IUserPutRequestST,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('updateUser', 'systemIdStr', systemIdStr);
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('updateUser', 'userId', userId);
      // verify required parameter 'iUserPutRequestST' is not null or undefined
      assertParamExists('updateUser', 'iUserPutRequestST', iUserPutRequestST);
      const localVarPath = `/{system_id_str}/user/{user_id}`
        .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
        .replace(`{${'user_id'}}`, encodeURIComponent(String(userId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iUserPutRequestST,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update group for specific user.
     * @summary Update group for specific user.
     * @param {string} systemIdStr
     * @param {string} userId
     * @param {IUserGroupPutRequestST} iUserGroupPutRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserGroups: async (
      systemIdStr: string,
      userId: string,
      iUserGroupPutRequestST: IUserGroupPutRequestST,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('updateUserGroups', 'systemIdStr', systemIdStr);
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('updateUserGroups', 'userId', userId);
      // verify required parameter 'iUserGroupPutRequestST' is not null or undefined
      assertParamExists('updateUserGroups', 'iUserGroupPutRequestST', iUserGroupPutRequestST);
      const localVarPath = `/{system_id_str}/user/{user_id}/group`
        .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
        .replace(`{${'user_id'}}`, encodeURIComponent(String(userId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iUserGroupPutRequestST,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UserAdminApi - functional programming interface
 * @export
 */
export const UserAdminApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UserAdminApiAxiosParamCreator(configuration);
  return {
    /**
     * Create a new user.
     * @summary Create a new user.
     * @param {string} systemIdStr
     * @param {IUserPostRequestST} iUserPostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createUser(
      systemIdStr: string,
      iUserPostRequestST: IUserPostRequestST,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IUserPostResponseST>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(
        systemIdStr,
        iUserPostRequestST,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Remove specific user.
     * @summary Remove specific user.
     * @param {string} systemIdStr
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteUser(
      systemIdStr: string,
      userId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(
        systemIdStr,
        userId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Return available users.
     * @summary Get all users.
     * @param {string} systemIdStr
     * @param {string} [withUserGroups]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllUsers(
      systemIdStr: string,
      withUserGroups?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IUserGetAllResponseST>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllUsers(
        systemIdStr,
        withUserGroups,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Return specific user.
     * @summary Get specific user.
     * @param {string} systemIdStr
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUser(
      systemIdStr: string,
      userId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IUserGetResponseST>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(
        systemIdStr,
        userId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Return available groups from cognito.
     * @summary Get available groups.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUserGroups(
      systemIdStr: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IGroupGetAllResponseST>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUserGroups(systemIdStr, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Update specific user.
     * @summary Update specific user.
     * @param {string} systemIdStr
     * @param {string} userId
     * @param {IUserPutRequestST} iUserPutRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateUser(
      systemIdStr: string,
      userId: string,
      iUserPutRequestST: IUserPutRequestST,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(
        systemIdStr,
        userId,
        iUserPutRequestST,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Update group for specific user.
     * @summary Update group for specific user.
     * @param {string} systemIdStr
     * @param {string} userId
     * @param {IUserGroupPutRequestST} iUserGroupPutRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateUserGroups(
      systemIdStr: string,
      userId: string,
      iUserGroupPutRequestST: IUserGroupPutRequestST,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserGroups(
        systemIdStr,
        userId,
        iUserGroupPutRequestST,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * UserAdminApi - factory interface
 * @export
 */
export const UserAdminApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = UserAdminApiFp(configuration);
  return {
    /**
     * Create a new user.
     * @summary Create a new user.
     * @param {string} systemIdStr
     * @param {IUserPostRequestST} iUserPostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUser(
      systemIdStr: string,
      iUserPostRequestST: IUserPostRequestST,
      options?: any,
    ): AxiosPromise<IUserPostResponseST> {
      return localVarFp
        .createUser(systemIdStr, iUserPostRequestST, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Remove specific user.
     * @summary Remove specific user.
     * @param {string} systemIdStr
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteUser(systemIdStr: string, userId: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .deleteUser(systemIdStr, userId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Return available users.
     * @summary Get all users.
     * @param {string} systemIdStr
     * @param {string} [withUserGroups]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllUsers(
      systemIdStr: string,
      withUserGroups?: string,
      options?: any,
    ): AxiosPromise<IUserGetAllResponseST> {
      return localVarFp
        .getAllUsers(systemIdStr, withUserGroups, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Return specific user.
     * @summary Get specific user.
     * @param {string} systemIdStr
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUser(systemIdStr: string, userId: string, options?: any): AxiosPromise<IUserGetResponseST> {
      return localVarFp
        .getUser(systemIdStr, userId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Return available groups from cognito.
     * @summary Get available groups.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserGroups(systemIdStr: string, options?: any): AxiosPromise<IGroupGetAllResponseST> {
      return localVarFp
        .getUserGroups(systemIdStr, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Update specific user.
     * @summary Update specific user.
     * @param {string} systemIdStr
     * @param {string} userId
     * @param {IUserPutRequestST} iUserPutRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUser(
      systemIdStr: string,
      userId: string,
      iUserPutRequestST: IUserPutRequestST,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .updateUser(systemIdStr, userId, iUserPutRequestST, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Update group for specific user.
     * @summary Update group for specific user.
     * @param {string} systemIdStr
     * @param {string} userId
     * @param {IUserGroupPutRequestST} iUserGroupPutRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserGroups(
      systemIdStr: string,
      userId: string,
      iUserGroupPutRequestST: IUserGroupPutRequestST,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .updateUserGroups(systemIdStr, userId, iUserGroupPutRequestST, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * UserAdminApi - object-oriented interface
 * @export
 * @class UserAdminApi
 * @extends {BaseAPI}
 */
export class UserAdminApi extends BaseAPI {
  /**
   * Create a new user.
   * @summary Create a new user.
   * @param {string} systemIdStr
   * @param {IUserPostRequestST} iUserPostRequestST
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserAdminApi
   */
  public createUser(
    systemIdStr: string,
    iUserPostRequestST: IUserPostRequestST,
    options?: AxiosRequestConfig,
  ) {
    return UserAdminApiFp(this.configuration)
      .createUser(systemIdStr, iUserPostRequestST, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Remove specific user.
   * @summary Remove specific user.
   * @param {string} systemIdStr
   * @param {string} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserAdminApi
   */
  public deleteUser(systemIdStr: string, userId: string, options?: AxiosRequestConfig) {
    return UserAdminApiFp(this.configuration)
      .deleteUser(systemIdStr, userId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Return available users.
   * @summary Get all users.
   * @param {string} systemIdStr
   * @param {string} [withUserGroups]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserAdminApi
   */
  public getAllUsers(systemIdStr: string, withUserGroups?: string, options?: AxiosRequestConfig) {
    return UserAdminApiFp(this.configuration)
      .getAllUsers(systemIdStr, withUserGroups, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Return specific user.
   * @summary Get specific user.
   * @param {string} systemIdStr
   * @param {string} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserAdminApi
   */
  public getUser(systemIdStr: string, userId: string, options?: AxiosRequestConfig) {
    return UserAdminApiFp(this.configuration)
      .getUser(systemIdStr, userId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Return available groups from cognito.
   * @summary Get available groups.
   * @param {string} systemIdStr
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserAdminApi
   */
  public getUserGroups(systemIdStr: string, options?: AxiosRequestConfig) {
    return UserAdminApiFp(this.configuration)
      .getUserGroups(systemIdStr, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update specific user.
   * @summary Update specific user.
   * @param {string} systemIdStr
   * @param {string} userId
   * @param {IUserPutRequestST} iUserPutRequestST
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserAdminApi
   */
  public updateUser(
    systemIdStr: string,
    userId: string,
    iUserPutRequestST: IUserPutRequestST,
    options?: AxiosRequestConfig,
  ) {
    return UserAdminApiFp(this.configuration)
      .updateUser(systemIdStr, userId, iUserPutRequestST, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update group for specific user.
   * @summary Update group for specific user.
   * @param {string} systemIdStr
   * @param {string} userId
   * @param {IUserGroupPutRequestST} iUserGroupPutRequestST
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserAdminApi
   */
  public updateUserGroups(
    systemIdStr: string,
    userId: string,
    iUserGroupPutRequestST: IUserGroupPutRequestST,
    options?: AxiosRequestConfig,
  ) {
    return UserAdminApiFp(this.configuration)
      .updateUserGroups(systemIdStr, userId, iUserGroupPutRequestST, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
