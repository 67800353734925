import { useState } from 'react';
import { useSnackbar } from 'notistack';

import { isFunction } from 'common/functions/otherFunctions';
import { IInventoryRequestST } from 'codegen/inventory_request';
import { useFacilityModalsStore } from '../../store/Modals';
import { FacilityModalsActionTypes } from '../../store/Modals/types';

import { EditInventoryRequestModal } from './EditInventoryRequestModal';
import { DeleteInventoryRequestModal } from './DeleteInventoryRequestModal';
import { EnableDisableModal } from './EnableDisableModal';
import { OverwriteDataModal } from './OverwriteDataModal/OverwriteDataModal';
import { LocationModal } from './LocationModal/LocationModal';
import { SnoozeIssueModal, SnoozeRequest } from './SnoozeIssueModal';
import { YesNoModal } from './YesNoModal';
import { WarehouseStatusReset } from './WarehouseStatusReset';

import {
  handleAbortReport,
  handleArchiveReport,
  handleDeleteReportSpec,
  handleRestoreReport,
  handleUpdateReport,
} from './SharedFunctions';
import { useRequestController } from '../../hooks';
import { useFacilityLevelStore } from '../../store/FacilityLevelStore/facilityLevelStore';

export const ModalsAndPopupsFacilityLevel = () => {
  // state
  const { facilityModalsState, dispatchFacilityModals } = useFacilityModalsStore();
  const [loading, setLoading] = useState(false);

  // NOTE: this component never gets unmounted and, hence, it doesn't matter
  // whether requests are set as cancellable or not.
  const { requestController } = useRequestController('ModalsAndPopups');

  // snackbar hook
  const { enqueueSnackbar } = useSnackbar();
  const { stateFacilityLevel } = useFacilityLevelStore();

  const { currentSystemId } = stateFacilityLevel;

  return (
    <>
      {facilityModalsState.editRequestModalOpened && (
        <EditInventoryRequestModal
          closeModal={() =>
            dispatchFacilityModals({ type: FacilityModalsActionTypes.EDIT_REQUEST, payload: {} })
          }
          opened={facilityModalsState.editRequestModalOpened}
          onConfirmUpdate={() => {
            dispatchFacilityModals({ type: FacilityModalsActionTypes.EDIT_REQUEST, payload: {} });
            Object.keys(facilityModalsState.refreshData).forEach((func) =>
              facilityModalsState.refreshData[func](),
            );
          }}
          requestItem={facilityModalsState.requestToEdit as IInventoryRequestST}
        />
      )}

      {facilityModalsState.deleteRequestModalOpened && (
        <DeleteInventoryRequestModal
          opened={facilityModalsState.deleteRequestModalOpened}
          requestItem={facilityModalsState.requestToEdit as IInventoryRequestST}
        />
      )}

      {facilityModalsState.toggleRequestStateModalOpened && (
        <EnableDisableModal
          opened={facilityModalsState.toggleRequestStateModalOpened}
          requestItem={facilityModalsState.requestToEdit as IInventoryRequestST}
        />
      )}

      {facilityModalsState.amendModalOpened && (
        <OverwriteDataModal
          closeModal={() =>
            dispatchFacilityModals({
              type: FacilityModalsActionTypes.AMEND_VERITY_VALUE,
              payload: {},
            })
          }
          opened={facilityModalsState.amendModalOpened}
          overwriteData={facilityModalsState.overwriteData}
        />
      )}

      {facilityModalsState.locationModalOpened && (
        <LocationModal
          opened={facilityModalsState.locationModalOpened}
          closeModal={() => {
            dispatchFacilityModals({
              type: FacilityModalsActionTypes.TOGGLE_LOCATION_MODAL,
              payload: {},
            });
          }}
          filteredLocationList={facilityModalsState.locations}
          allLocations={facilityModalsState.allLocations}
          parentPage={facilityModalsState.parentPage ?? 'WarehouseStatus'}
          reportId={facilityModalsState.reportId}
          refreshDataOnClose={facilityModalsState.refreshDataOnClose}
        />
      )}

      {facilityModalsState.toggleSnoozeStateModalOpened && (
        <SnoozeIssueModal
          opened={facilityModalsState.toggleSnoozeStateModalOpened}
          issueToSnooze={facilityModalsState.requestToEdit as SnoozeRequest}
        />
      )}

      {facilityModalsState.abortReportModalOpened && (
        <YesNoModal
          closeButton={false}
          opened={facilityModalsState.abortReportModalOpened}
          title={facilityModalsState.abortReportModalData.title}
          subtitle={facilityModalsState.abortReportModalData.subtitle}
          bodyText={facilityModalsState.abortReportModalData.bodyText}
          yesLabel={facilityModalsState.abortReportModalData.yesLabel}
          noLabel={facilityModalsState.abortReportModalData.noLabel}
          loading={loading}
          onYesFunction={() =>
            handleAbortReport(
              facilityModalsState,
              dispatchFacilityModals,
              requestController,
              currentSystemId,
              setLoading,
            )
          }
          onNoFunction={() => {
            isFunction(facilityModalsState.abortReportModalData.onNoFunction) &&
              facilityModalsState.abortReportModalData.onNoFunction();
            facilityModalsState.abortReportModalData.onCloseDispatch &&
              dispatchFacilityModals(facilityModalsState.abortReportModalData.onCloseDispatch);
          }}
          yesCTA={facilityModalsState.abortReportModalData.yesCTA}
        />
      )}

      {facilityModalsState.updateReportModalOpened && (
        <YesNoModal
          closeButton={false}
          opened={facilityModalsState.updateReportModalOpened}
          title={facilityModalsState.updateReportModalData.title}
          subtitle={facilityModalsState.updateReportModalData.subtitle}
          bodyText={facilityModalsState.updateReportModalData.bodyText}
          yesLabel={facilityModalsState.updateReportModalData.yesLabel}
          noLabel={facilityModalsState.updateReportModalData.noLabel}
          loading={loading}
          onYesFunction={() =>
            handleUpdateReport(
              facilityModalsState,
              dispatchFacilityModals,
              requestController,
              currentSystemId,
              setLoading,
            )
          }
          onNoFunction={() => {
            isFunction(facilityModalsState.updateReportModalData.onNoFunction) &&
              facilityModalsState.updateReportModalData.onNoFunction();
            facilityModalsState.updateReportModalData.onCloseDispatch &&
              dispatchFacilityModals(facilityModalsState.updateReportModalData.onCloseDispatch);
          }}
          yesCTA={facilityModalsState.updateReportModalData.yesCTA}
        />
      )}
      {facilityModalsState.archiveReportModalOpened && (
        <YesNoModal
          closeButton={false}
          opened={facilityModalsState.archiveReportModalOpened}
          title={facilityModalsState.archiveReportModalData.title}
          subtitle={facilityModalsState.archiveReportModalData.subtitle}
          bodyText={facilityModalsState.archiveReportModalData.bodyText}
          yesLabel={facilityModalsState.archiveReportModalData.yesLabel}
          noLabel={facilityModalsState.archiveReportModalData.noLabel}
          loading={loading}
          onYesFunction={() =>
            handleArchiveReport(
              facilityModalsState,
              dispatchFacilityModals,
              requestController,
              currentSystemId,
              enqueueSnackbar,
              setLoading,
            )
          }
          onNoFunction={() => {
            isFunction(facilityModalsState.archiveReportModalData.onNoFunction) &&
              facilityModalsState.archiveReportModalData.onNoFunction();
            facilityModalsState.archiveReportModalData.onCloseDispatch &&
              dispatchFacilityModals(facilityModalsState.archiveReportModalData.onCloseDispatch);
          }}
          yesCTA={facilityModalsState.archiveReportModalData.yesCTA}
        />
      )}

      {facilityModalsState.restoreReportModalOpened && (
        <YesNoModal
          closeButton={false}
          opened={facilityModalsState.restoreReportModalOpened}
          title={facilityModalsState.restoreReportModalData.title}
          subtitle={facilityModalsState.restoreReportModalData.subtitle}
          bodyText={facilityModalsState.restoreReportModalData.bodyText}
          yesLabel={facilityModalsState.restoreReportModalData.yesLabel}
          noLabel={facilityModalsState.restoreReportModalData.noLabel}
          loading={loading}
          onYesFunction={() =>
            handleRestoreReport(
              facilityModalsState,
              dispatchFacilityModals,
              requestController,
              currentSystemId,
              enqueueSnackbar,
              setLoading,
            )
          }
          onNoFunction={() => {
            isFunction(facilityModalsState.restoreReportModalData.onNoFunction) &&
              facilityModalsState.restoreReportModalData.onNoFunction();
            facilityModalsState.restoreReportModalData.onCloseDispatch &&
              dispatchFacilityModals(facilityModalsState.restoreReportModalData.onCloseDispatch);
          }}
          yesCTA={facilityModalsState.restoreReportModalData.yesCTA}
        />
      )}

      {facilityModalsState.deleteReportSpecModalOpened && (
        <YesNoModal
          closeButton={false}
          opened={facilityModalsState.deleteReportSpecModalOpened}
          title={facilityModalsState.deleteReportSpecData.title}
          bodyText={facilityModalsState.deleteReportSpecData.bodyText}
          yesLabel={facilityModalsState.deleteReportSpecData.yesLabel}
          noLabel={facilityModalsState.deleteReportSpecData.noLabel}
          loading={loading}
          onYesFunction={() =>
            handleDeleteReportSpec(
              facilityModalsState,
              dispatchFacilityModals,
              requestController,
              currentSystemId,
              setLoading,
            )
          }
          onNoFunction={() => {
            isFunction(facilityModalsState.deleteReportSpecData.onNoFunction) &&
              facilityModalsState.deleteReportSpecData.onNoFunction();
            facilityModalsState.deleteReportSpecData.onCloseDispatch &&
              dispatchFacilityModals(facilityModalsState.deleteReportSpecData.onCloseDispatch);
          }}
          yesCTA={facilityModalsState.deleteReportSpecData.yesCTA}
          subtitle={undefined}
        />
      )}

      {facilityModalsState.resetSystemModalOpened && (
        <WarehouseStatusReset
          closeButton={false}
          opened={facilityModalsState.resetSystemModalOpened}
          title={facilityModalsState.resetSystemData.title}
          subtitle={facilityModalsState.resetSystemData.subtitle}
          bodyText={facilityModalsState.resetSystemData.bodyText}
          confirmationWord={facilityModalsState.resetSystemData.confirmationWord}
          yesLabel={facilityModalsState.resetSystemData.yesLabel}
          noLabel={facilityModalsState.resetSystemData.noLabel}
          yesCTA={facilityModalsState.resetSystemData.yesCTA}
        />
      )}
    </>
  );
};
