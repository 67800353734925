// services
import { REPORT_STATES } from 'common/reportStates';
import {
  getReportSpecificationApiService,
  getReportService,
} from '../../../../../services/services';

// variables, configurations

/**
 * Get all report specifications.
 * @param {string} systemId System ID (of the Facility)
 * @returns {object} <IReportSpecificationGetAllResponseST>
 */
const getReportSpecifications = (systemId: string) =>
  getReportSpecificationApiService().getReportSpecifications(systemId);

/**
 * Get specific report specification.
 * @param {string} systemId System ID (of the Facility)
 * @param {string} id Report specification ID
 * @returns {object} <IReportSpecificationGetResponseST>
 */
const getReportSpecification = (systemId: string, id: string) =>
  getReportSpecificationApiService().getReportSpecification(systemId, id);

/**
 * Return specific report.
 * @param {string} systemId System ID (of the Facility)
 * @param {string} id Report ID
 * @returns {object} <IReportGetResponseST>
 */
const getReport = (
  systemId: string,
  id: string,
  from: string,
  until: string,
  signal: AbortSignal,
) => {
  if (from && until) {
    return getReportService().getReportData(systemId, id, from, until, {
      signal,
    });
  }
  return getReportService().getReport(systemId, id, { signal });
};

/**
 * Request Parameters for the getReportSummaries functions
 */
export interface IGetReportSummariesRequestParams {
  pageToken: string;
  from: string;
  until: string;
  limit: number;
  states: REPORT_STATES[];
}

/**
 * Return all report summaries.
 * @param {string} systemId System ID (of the Facility)
 * @param {object} requestParams <IGetReportSummariesRequestParams>
 * @returns {object} <IReportSummaryGetAllResponseST>
 */
const getReportSummaries = (
  systemId: string,
  requestParams: IGetReportSummariesRequestParams,
  signal: AbortSignal,
) =>
  getReportService().getReportSummaries(
    systemId,
    requestParams?.from,
    requestParams?.until,
    requestParams?.limit,
    requestParams.states || [],
    requestParams?.pageToken,
    { signal },
  );

/**
 * Return specific report summary.
 * @param {string} systemId System ID (of the Facility)
 * @param {string} id Report ID
 * @returns {object} <IReportSummaryGetResponseST>
 */
const getReportSummary = (systemId: string, id: string, signal: AbortSignal) =>
  getReportService().getReportSummary(systemId, id, { signal });

/**
 * Abort report.
 * @param {string} systemId System ID (of the Facility)
 * @param {string} id Report ID
 * @returns {object} <IReportAbortPutResponseST>
 */
const abortReport = (systemId: string, id: string) => getReportService().abortReport(systemId, id);

/**
 * Update report.
 * @param {string} systemId System ID (of the Facility)
 * @param {string} id Report ID
 * @returns {object} <IReportUpdatePutResponseST>
 */
const updateReport = (systemId: string, id: string) =>
  getReportService().updateReport(systemId, id);

/**
 * Send report export to email.
 * @param {string} systemId System ID (of the Facility)
 * @param {string} id Report ID
 * @returns {object} <IReportSendEmailPutResponseST>
 */
const sendReportEmail = (systemId: string, id: string, signal: AbortSignal) =>
  getReportService().sendEmailReport(systemId, id, { signal });

/**
 * Archive report.
 * @param {string} systemId System ID (of the Facility)
 * @param {array} ids Report IDs
 * @returns {object} <IReportArchivePutResponseST>
 */
const archiveReports = (systemId: string, ids: string[]) =>
  getReportService().archiveReport(systemId, { report_ids: ids });

/**
 * Restore archived reports.
 * @param {string} systemId System ID (of the Facility)
 * @param {array} ids Report IDs
 * @returns {object} <IReportArchivePutResponseST>
 */
const restoreReports = (systemId: string, ids: string[]) =>
  getReportService().restoreReport(systemId, { report_ids: ids });

export const reportServices = {
  getReportSpecifications,
  getReportSpecification,
  getReport,
  getReportSummary,
  getReportSummaries,
  abortReport,
  updateReport,
  sendReportEmail,
  archiveReports,
  restoreReports,
};
