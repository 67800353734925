import { VALUE_TYPES } from 'common/reportSpecifications';
import {
  IInventoryRequestParameterST,
  IReportParameterST,
  IReportParameterUniqueNameST,
} from 'codegen/inventory_request/api';
import { ReportParamInputError } from 'interfaces/reportsInterfaces';
import { TextParamField } from './TextParamField';
import { SingleChoiceParamField } from './SingleChoiceParamField';
import { MultiChoiceParamField } from './MultiChoiceParamField';
import { getTypeOfParam } from './utils/getTypeOfParam';
import { getValueOptions } from './utils/getValueOptions';
import { Errors } from '../../reducers/ScheduleForm.model';

export const ParamForm = ({
  classes,
  params,
  customParams,
  reportParamValues,
  errors,
  updateReportParamValues,
  clearReportParamValues,
  onBlur,
}: {
  classes: { formControl: string };
  params: IReportParameterST[];
  customParams: Record<IReportParameterUniqueNameST, string>;
  reportParamValues: IInventoryRequestParameterST[];
  errors: Errors;
  updateReportParamValues: (
    paramUniqueName: IReportParameterUniqueNameST,
    value: string | string[],
  ) => void;
  clearReportParamValues: (paramUniqueName: IReportParameterUniqueNameST) => void;
  onBlur: (paramName: IReportParameterUniqueNameST) => void;
}) => {
  const getMarkupForParam = (param: IReportParameterST, index: number) => {
    const isInvalidParamValue = !!(errors.validParams as ReportParamInputError[])?.find(
      (el) => el.unique_name === param.unique_name,
    );

    const helperText =
      (errors.validParams as ReportParamInputError[])?.find(
        (el) => el.unique_name === param.unique_name,
      )?.error || '';

    const key = `key-${param.unique_name}-param`;

    const typeOfParam = getTypeOfParam(param);

    if (typeOfParam === 'TEXT') {
      const paramType = [VALUE_TYPES.INTEGER, VALUE_TYPES.DECIMAL].includes(param.value_type)
        ? 'number'
        : 'text';
      return (
        <TextParamField
          key={key}
          param={param}
          index={index}
          paramValue={customParams[param.unique_name]}
          isInvalidParamValue={isInvalidParamValue}
          helperText={helperText}
          type={paramType}
          onChange={(e) => updateReportParamValues(param.unique_name, e.target.value)}
        />
      );
    }

    if (typeOfParam === 'SINGLE_CHOICE') {
      const valueOptions = getValueOptions(param);
      const paramValue =
        reportParamValues.find((el) => el.unique_name === param.unique_name)?.values.toString() ||
        '';
      return (
        <SingleChoiceParamField
          key={key}
          param={param}
          index={index}
          paramValue={paramValue}
          valueOptions={valueOptions}
          onChange={(e) => updateReportParamValues(param.unique_name, e.target.value)}
          isInvalidParamValue={isInvalidParamValue}
          helperText={helperText}
        />
      );
    }

    if (typeOfParam === 'MULTI_CHOICE') {
      const valueOptions = getValueOptions(param);
      const paramValues =
        reportParamValues.find((el) => el.unique_name === param.unique_name)?.values || [];
      return (
        <MultiChoiceParamField
          key={key}
          param={param}
          index={index}
          values={paramValues}
          valueOptions={valueOptions}
          onChange={(e) => updateReportParamValues(param.unique_name, e.target.value)}
          isInvalidParamValue={isInvalidParamValue}
          helperText={helperText}
          onBlur={() => onBlur(param.unique_name)}
          onClear={() => clearReportParamValues(param.unique_name)}
        />
      );
    }

    return null;
  };

  const paramFields = params.map(getMarkupForParam);

  return <div className={classes.formControl}>{paramFields}</div>;
};
