import { useTheme } from '@mui/material';
import moment from 'moment';
import { useEffect } from 'react';
import 'moment-timezone';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import { BaseCard } from 'components/BaseCard/BaseCard';
import { LocalStore } from 'common/functions/storageFunctions';
import { OperatingHoursEventST } from '../droneFlightHours/model/operatingHours.model';
import { createOperatingHoursByWeekDayFromSchedule } from './utils/createOperatingHoursByWeekDayFromSchedule';
import { useStyles } from './flightHoursCalendar.styles';
import { FlightHoursCalendarEvent } from './FlightHoursCalendarEvent';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const NOW = new Date();

export const FlightHoursCalendar = ({ schedule }: { schedule: OperatingHoursEventST[] }) => {
  const { classes, cx } = useStyles();
  const theme = useTheme();
  const timezone = LocalStore.getTimezone();

  useEffect(() => {
    moment.locale('en-GB');
    moment.tz.setDefault(timezone);

    return () => {
      moment.locale();
    };
  }, [timezone]);

  const localizer = momentLocalizer(moment);

  const events = createOperatingHoursByWeekDayFromSchedule(schedule).map((i, index) => ({
    id: index,
    title: i.weekDay,
    start: moment()
      .tz(timezone)
      .startOf('week')
      .set({ day: i.weekDay, hour: i.fromHours, minutes: i.fromMinutes })
      .toDate(),
    end: moment()
      .tz(timezone)
      .startOf('week')
      .set({ day: i.weekDay, hour: i.toHours, minutes: i.toMinutes })
      .toDate(),
  }));

  return (
    <BaseCard
      cardFor="drone-flight-hours-calendar"
      title="Drones flight hours calendar"
      subtitle="Weekly schedule of drone flying hours"
    >
      <div className={classes.cardContent}>
        <Calendar
          defaultView={Views.WEEK}
          views={{ week: true }}
          selectable={false}
          components={{
            toolbar: () => null,
            event: FlightHoursCalendarEvent,
          }}
          formats={{
            dayFormat: 'ddd',
            timeGutterFormat: 'HH:mm',
            eventTimeRangeFormat: () => '',
          }}
          eventPropGetter={() => ({
            style: {
              backgroundColor: theme.palette.warning.main,
              borderColor: theme.palette.warning.main,
            },
          })}
          slotPropGetter={(date) => {
            const isPast = date.getHours() < NOW.getHours();
            return {
              className: cx(classes.timeSlot, isPast && classes.timePassed),
            };
          }}
          localizer={localizer}
          events={events}
        />
      </div>
    </BaseCard>
  );
};
