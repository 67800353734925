import { IVeritySlotStatusStateST, IWMSSlotStatusSTStateEnum } from 'codegen/location_information';

export const OVERWRITE_MODES = {
  MANUAL: 'manually',
  USE_VERITY: 'original',
  USE_WMS: 'expected',
};

export interface IOverwritingItem {
  verity_correct: boolean | string;
  set_to_expected: boolean;
  barcodes: string[];
  state?: IVeritySlotStatusStateST | IWMSSlotStatusSTStateEnum;
  comment: string;
}
