export const verifyCustomGridStateAvailable = ({
  gridName,
  activeTab,
}: {
  gridName: 'report' | 'warehouse-status';
  activeTab: number;
}) => {
  const savedStateJson = localStorage.getItem(`savedGridState-${gridName}`);
  if (!savedStateJson) {
    return false;
  }
  const savedState = JSON.parse(savedStateJson);
  if (Object.keys(savedState).includes(activeTab.toString())) {
    return true;
  }
  return false;
};
