import { Typography, IconButton, Tooltip, useTheme, Popover } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Badge } from 'components/common/Badge';
import ClearIcon from '@mui/icons-material/Clear';
import { ISSUE_BADGE_COLORS } from 'common/colors';
import { ILocationData } from 'store/Modals/facilityModals/IFacilityModalsStore';
import { DISPLAY_ISSUE_TYPES } from 'common/issueTypesAndStates';
import {
  getIssueType,
  MuiColor,
  muiColorToTheme,
} from 'common/functions/issues/issueColorFunctions';
import { Dispatch, SetStateAction, useState } from 'react';
import { Box } from 'components/common/Box';
import { IFacilitySettingsST, IRuleActionSTIssueLogicEnum } from 'codegen/facility_settings';
import { userHasPermission } from 'features/permissions/userHasPermission';
import { PERMISSION } from 'features/permissions/permissions.model';
import { NavigationButtons } from './navigation/NavigationButtons';
import { locationStyles } from '../../styles';
import { ThreeDotsMenu } from './ThreeDotsMenu';
import { LocationImagesState } from '../../hooks/useLocationImagesState';
import { LocationDiagnostics } from './diagnostics/LocationDiagnostics';

export const LocationModalHeader = ({
  locationData,
  filteredLocationList,
  refreshLocationData,
  currentLocationIndex,
  setCurrentLocationIndex,
  enableSnooze,
  facilitySettings,
  locationImagesState,
  issueLogic,
}: {
  locationData: ILocationData;
  filteredLocationList: ILocationData[];
  refreshLocationData: (locationLabel: string) => void;
  currentLocationIndex: number;
  setCurrentLocationIndex: Dispatch<SetStateAction<number>>;
  enableSnooze: boolean;
  facilitySettings: IFacilitySettingsST;
  locationImagesState: LocationImagesState;
  issueLogic?: IRuleActionSTIssueLogicEnum;
}) => {
  const { classes } = locationStyles();
  const theme = useTheme();

  const showNavigation = filteredLocationList.length > 1;

  const title = locationData.location;
  const issue =
    locationData.issueType && DISPLAY_ISSUE_TYPES[locationData.issueType]
      ? DISPLAY_ISSUE_TYPES[locationData.issueType]
      : '';
  const issueType = locationData.issueType
    ? getIssueType(locationData.issueType)
    : {
        label: 'None',
        color: 'default' as MuiColor,
      };

  const [diagAnchorEl, setDiagAnchorEl] = useState<HTMLButtonElement | null>(null);

  const canSeeDiag = userHasPermission(PERMISSION.VIEW_LOCATION_DIAGNOSTICS);

  const showDiag = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!canSeeDiag) {
      return;
    }
    setDiagAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Box textAlign="left" p={1} paddingTop="6px" paddingBottom="6px">
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" alignItems="center" gap={2}>
            <Tooltip
              title="Location number"
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -4],
                      },
                    },
                  ],
                },
              }}
            >
              <Typography
                className={classes.modalTitle}
                color="secondary"
                variant="h5"
                data-testid="title"
                minWidth="90px"
              >
                {title}
              </Typography>
            </Tooltip>
            {issue && (
              <Badge
                bgcolor={muiColorToTheme(issueType.color, theme).main}
                color={muiColorToTheme(issueType.color, theme).text}
                bigBadge={false}
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  cursor: canSeeDiag ? 'pointer' : 'default',
                }}
              >
                <Tooltip
                  title={canSeeDiag ? 'Click to see the diagnostics information' : 'Location issue'}
                >
                  <Typography variant="body1" onClick={showDiag}>
                    {issue}
                  </Typography>
                </Tooltip>
              </Badge>
            )}
            <Badge
              bgcolor={ISSUE_BADGE_COLORS[locationData?.state ?? 'NONE']?.background}
              color={ISSUE_BADGE_COLORS[locationData?.state ?? 'NONE']?.text}
              bigBadge={false}
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                cursor: canSeeDiag ? 'pointer' : 'default',
              }}
            >
              <Tooltip
                title={canSeeDiag ? 'Click to see the diagnostics information' : 'Issue status'}
              >
                <Typography variant="body1" onClick={showDiag}>
                  {(locationData?.state && `${locationData.state} ISSUE`) || 'NO ISSUE'}
                </Typography>
              </Tooltip>
            </Badge>

            <ThreeDotsMenu
              locationData={locationData}
              enableSnooze={enableSnooze}
              refreshLocationData={refreshLocationData}
              locationImagesState={locationImagesState}
              facilitySettings={facilitySettings}
            />
          </Box>
          <Box display="flex" paddingRight="6em" marginTop="-3px" alignItems="center" gap={3}>
            <IconButton
              aria-label="refresh"
              onClick={() => refreshLocationData(locationData?.location)}
              size="large"
            >
              <Tooltip title="Refresh">
                <RefreshIcon />
              </Tooltip>
            </IconButton>
            {showNavigation && (
              <NavigationButtons
                locationsData={filteredLocationList}
                currentLocationIndex={currentLocationIndex}
                setCurrentLocationIndex={setCurrentLocationIndex}
              />
            )}
          </Box>
        </Box>
      </Box>
      <Popover onClose={() => setDiagAnchorEl(null)} anchorEl={diagAnchorEl} open={!!diagAnchorEl}>
        <Box>
          <Tooltip title="Click to close the diagnostics">
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={() => setDiagAnchorEl(null)}
              size="large"
            >
              <ClearIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <LocationDiagnostics locationData={locationData} issueLogic={issueLogic} />
      </Popover>
    </>
  );
};
