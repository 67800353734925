import { IBarcodeST } from '../../../../../../codegen/location_information';

export const calculateBarcodeFrameDimensions = (barcode: IBarcodeST) => {
  // TODO: This img size assumption only holds for PUP and SPUP, not sure if it should be hardcoded here.
  const imageHeightPx = 2160;
  const imageWidthPx = 2880;

  // list of x-coordinates of barcode
  const x: number[] = [
    barcode.location.topLeft.coordinate[0],
    barcode.location.topRight.coordinate[0],
    barcode.location.bottomLeft.coordinate[0],
    barcode.location.bottomRight.coordinate[0],
  ];

  // list of y-coordinates of barcode
  const y: number[] = [
    barcode.location.topLeft.coordinate[1],
    barcode.location.topRight.coordinate[1],
    barcode.location.bottomLeft.coordinate[1],
    barcode.location.bottomRight.coordinate[1],
  ];

  const topPx = Math.min(...y);
  const rightPx = Math.max(...x);
  const bottomPx = Math.max(...y);
  const leftPx = Math.min(...x);

  // Compute width/height in px
  const heightPx = bottomPx - topPx;
  const widthPx = rightPx - leftPx;

  // Convert everything to percentage
  const leftPc = (leftPx / imageWidthPx) * 100.0;
  const topPc = (topPx / imageHeightPx) * 100.0;
  const widthPc = (widthPx / imageWidthPx) * 100.0;
  const heightPc = (heightPx / imageHeightPx) * 100.0;

  return {
    leftPc,
    topPc,
    widthPc,
    heightPc,
  };
};
