import { ILocationData1ST } from 'codegen/report';

export const getIssueStateValueOptionsFromData = (
  locations: ILocationData1ST[],
  activeTab: number,
) => {
  const distinctIssueStates = [
    ...new Set(locations.filter((l) => l.issues?.length).map((l) => l.issues[0].state)),
  ];

  const values = activeTab === 0 ? [{ label: 'NONE', value: 'NONE' }] : [];

  return [
    ...values,
    ...[...new Set(distinctIssueStates)].map((val) => ({
      label: val,
      value: val,
    })),
  ];
};
