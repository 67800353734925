import { ILocationDataST, IVeritySlotStatus1ST } from 'codegen/report';
import { ILocationData } from 'store/Modals/facilityModals/IFacilityModalsStore';

export const getVerityStatus = (locationData: ILocationData | ILocationDataST) => {
  if (Object.hasOwn(locationData, 'rowData')) {
    return (locationData as ILocationData)?.rowData?.slotStatus?.verity_status;
  }
  const verityStatus = (locationData as ILocationDataST)?.verity_status as IVeritySlotStatus1ST;
  return verityStatus;
};
