import { INTERVAL_OPTIONS } from 'common/datetimeFormats';
import moment from 'moment';
import { IntervalLabel } from '../features/Recurrence/recurrence.model';
import { ScheduleFormAction } from '../reducers/ScheduleForm.model';

export const getAllowedOccurrenceIntervals = (
  isRecurring: boolean,
  dateFrom: moment.MomentInput,
  dateUntil: moment.MomentInput,
  interval: IntervalLabel,
  dispatch: (value: ScheduleFormAction) => void,
) => {
  if (isRecurring && dateFrom && dateUntil) {
    const difference = moment(dateFrom).diff(
      dateUntil,
      INTERVAL_OPTIONS[interval as IntervalLabel] as moment.unitOfTime.Diff,
    );
    dispatch({ type: 'SET_ALLOWED_OCCURRENCE_INTERVALS', payload: Math.abs(difference) });
  }
};
