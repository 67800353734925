import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  cardContent: {
    '& .rbc-header': {
      minHeight: 40,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    '& .rbc-timeslot-group': {
      minHeight: 30,
    },

    '& .rbc-event': {
      outline: 'none !important',
      cursor: 'default',
    },

    '& .rbc-today': {
      backgroundColor: 'transparent',
    },

    '& .rbc-current-time-indicator': {
      display: 'none',
    },

    '& .rbc-time-slot': {
      borderTop: '1px solid #e6e6e6',
    },

    '& .rbc-time-header-content .rbc-time-header-cell': {
      textAlign: 'center',
      fontWeight: 'bold',
    },

    '& .rbc-event-content': {
      textAlign: 'center',
      padding: theme.spacing(0.5),
    },
  },
  timePassed: {
    color: '#999',
    backgroundColor: '#f5f5f5',
    opacity: 0.7,
  },
  timeSlot: {
    fontSize: 12,
    borderTop: 'none !important',
  },
}));
