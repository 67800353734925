import { useEffect, useState } from 'react';
import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Box } from '../Box';
import { Spinner } from '../Spinner';
import { ImageCarousel } from './ImageCarousel';
import { PictureCounter } from './PictureCounter';
import { KeyboardNavigation } from './ImageCarousel.model';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    width: '100%',
    background: '#fff',
    boxSizing: 'border-box',
  },
  hidden: {
    visibility: 'hidden',
  },
  imageTextBox: {
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    background: theme.palette.shades.darker,
    marginBottom: 0,
    paddingLeft: theme.spacing(1),
    '& > h6': {
      color: theme.palette.shades.lightest,
      fontSize: '14px',
    },
  },
  downloadButton: {
    display: 'flex',
    color: '#EBEBEB',
    cursor: 'pointer',
  },
  spinner: {
    position: 'relative',
    minHeight: '400px',
  },
}));

export const ImageCarouselContainer = (props: {
  systemId: string;
  location: string;
  imageURLs: string[];
  isLoading: boolean;
  slotStatusVersion: number;
  isReview: boolean;
  imageNavigationOnTop: boolean;
  keyboardNavigation: KeyboardNavigation;
}) => {
  const {
    systemId,
    location,
    imageURLs,
    isLoading,
    slotStatusVersion,
    isReview,
    imageNavigationOnTop,
    keyboardNavigation,
  } = props;

  const [activeStep, setActiveStep] = useState(0);
  const [imagesDownloading, setImagesDownloading] = useState(false);

  useEffect(() => {
    const lastImageIndex = imageURLs.length - 1;
    if (activeStep > lastImageIndex) {
      setActiveStep(lastImageIndex);
    }
  }, [activeStep, imageURLs.length]);

  const { classes } = useStyles();

  const hasMultipleImages = imageURLs.length > 1;

  return !isLoading ? (
    <>
      <PictureCounter
        systemId={systemId}
        imageUrls={imageURLs}
        classes={classes}
        activeStep={activeStep}
        location={location}
        imagesDownloading={imagesDownloading}
        setImagesDownloading={setImagesDownloading}
        slotStatusVersion={slotStatusVersion}
        isReview={isReview}
      />

      <ImageCarousel
        imageUrls={imageURLs}
        activeStep={activeStep}
        onStepChange={setActiveStep}
        renderTopControls={imageNavigationOnTop && hasMultipleImages}
        renderBottomControls={!imageNavigationOnTop && hasMultipleImages}
        keyboardNavigation={keyboardNavigation}
      />
    </>
  ) : (
    <Box className={classes.spinner}>
      <Spinner />
    </Box>
  );
};
