import { IRequestController } from 'hooks';
import { IGetReportSummariesRequestParams, reportServices } from '../../../api/ReportServices';
import { processReportSummariesData } from './processReportSummariesData';
import { IProcessReportSummariesData } from './processReportSummariesData.model';

/**
 * Recursively fetch report summaries
 */
export const asyncGetReportSummariesRecursively = async (
  systemId: string,
  params: IGetReportSummariesRequestParams,
  /**
   * Request Controller
   */
  requestController: IRequestController,
  /**
   * Request ID for the request inside the passed request controller
   */
  requestId: string,
  /**
   * Axios Abort Signal
   */
  signal: AbortSignal,
  /**
   * if true it loads only the first batch of reports (e.g. in the home page).
   * if false it recursively keeps on loading all the reports (batch by batch).
   */
  getJustFirstPage: boolean,
  /**
   * Call-back invoked after each page is fetched. Used e.g. to partially populate reports list
   * as the list is loaded.
   */
  callBackPerPage: (r: IProcessReportSummariesData) => void,
) => {
  // Control variable to indicate that all report summaries have been fetched
  let allReportSummariesFetched = false;

  // Store the results to return to the invoking function
  let results: IProcessReportSummariesData | undefined;

  // Used on the first request with all params
  // replaced by page token on subsequent requests
  let reqParams: Partial<IGetReportSummariesRequestParams> = { ...params };

  // Get report summaries in a paginated fashion
  // loop while there is a pageToken is in the response
  // and the cancel token promise isn't resolved
  while (!allReportSummariesFetched && !requestController.isRequestCancelled(requestId)) {
    // We don't use a try/catch here because, errors are dealt with
    // on the files where this function is called
    const resp = await requestController.doRequest({
      request: reportServices.getReportSummaries,
      requestParams: [systemId, reqParams, signal],
      messageErrorFallback: 'The Report Summaries could not be fetched.',
    });

    // We only process the response, in case the cancel token promise is not resolved.
    // If it is resolved, it was meanwhile we waited for a response, and it means that
    // the response will be undefined.
    if (!requestController.isRequestCancelled(requestId)) {
      const reports = resp.data.items;
      const pageToken = resp.data.page_token;

      results = processReportSummariesData(reports, results);
      if (callBackPerPage) {
        callBackPerPage(results);
      }

      if (!pageToken || getJustFirstPage) {
        allReportSummariesFetched = true;
      } else {
        reqParams = { pageToken };
        continue;
      }
    }
  }

  return results;
};
