import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

const borderColor = '#C7C7C7';

export const useStyles = makeStyles()((theme: Theme) => ({
  areaGraph: {
    position: 'relative',
    display: 'flex',
    aspectRatio: '16 / 9',
    paddingBlock: '6px',
    fontSize: theme.typography.fontSize,
  },
  areaGraphLegend: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    textAlign: 'end',
    flex: '0 0 auto',
    fontVariantNumeric: 'tabular-nums',
    width: `calc(2.5ch + ${theme.spacing(2)})`,
  },
  areaGraphLegendItem: {
    position: 'absolute',
    insetInlineEnd: 0,
    display: 'block',
    lineHeight: 1,
    paddingInlineEnd: theme.spacing(1),
    transform: 'translateY(50%)',

    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      insetInlineEnd: 0,
      insetBlockStart: '50%',
      width: theme.spacing(0.5),
      height: '1px',
      borderBlockEnd: `1px solid ${borderColor}`,
    },

    '&:last-of-type': {
      '&::after': {
        transform: 'translateY(-1px)',
      },
    },
  },
  areaGraphData: {
    position: 'relative',
    flex: '1 1 auto',
    minWidth: 0,
    borderInlineStart: `1px solid ${borderColor}`,
    borderBlockEnd: `1px solid ${borderColor}`,
  },
}));
