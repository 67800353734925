import { ControlledZone } from 'shared/map/model/controlled-zone.model';

export const transformZoneSizeAndPosition = (droneZone: ControlledZone) => {
  const sizeAndPosition = Object.keys(droneZone.sizeAndPosition).reduce(
    (acc, key) => ({
      ...acc,
      [key]: Number(droneZone.sizeAndPosition[key as keyof ControlledZone['sizeAndPosition']]),
    }),
    {} as ControlledZone['sizeAndPosition'],
  );

  return { ...droneZone, sizeAndPosition };
};
