import { GridFilterInputValue, GridFilterItem, GridFilterOperator } from '@mui/x-data-grid';
import { ILocationDataST } from 'codegen/report';

export const notContainsOperator: GridFilterOperator<ILocationDataST, string> = {
  label: 'not contains',
  headerLabel: 'not contains',
  value: 'notContains',
  getApplyFilterFn: (filterItem: GridFilterItem) => {
    if (!filterItem.field || !filterItem.value || !filterItem.operator) {
      return null;
    }

    const filterItemValueWithEscapedRegexSpecialCharacters = filterItem.value.replace(
      /[.*+?^${}()|[\]\\]/g,
      '\\$&',
    );
    try {
      const filterRegex = new RegExp(filterItemValueWithEscapedRegexSpecialCharacters, 'i');
      return (value) => !filterRegex.test(value?.toString() ?? '');
    } catch (e) {
      console.error('Error => ', e);
    }
    return null;
  },
  InputComponent: GridFilterInputValue,
  InputComponentProps: { type: 'string' },
};
