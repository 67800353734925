import BlockIcon from '@mui/icons-material/Block';
import { IWMSSlotStatusST, IWMSSlotStatusSTSlotUsageEnum } from 'codegen/report';
import { IWMSSlotStatusSTStateEnum } from 'codegen/warehouse_status';
import { replaceArrayItems } from 'common/functions/arrayFunctions';
import { isArray, isEmpty } from 'lodash';

/**
 * Get the wms value from location data (to render on the screen)
 */
export const getWMSValueFromSlotStatus = (wms_status: IWMSSlotStatusST | string | null) => {
  let contentExpectedValues: string[] = [];
  let contentExpectedState: IWMSSlotStatusSTStateEnum | undefined;

  if (wms_status && typeof wms_status !== 'string') {
    contentExpectedState = wms_status.state;

    if (wms_status.state === IWMSSlotStatusSTStateEnum.Barcode) {
      contentExpectedValues = wms_status.barcodes;
    }
  }

  return { contentExpectedValues, contentExpectedState };
};

/**
 * Returns the WMS slot status version from location data
 * @param slotStatus Slot Status
 * @returns WMS Status or 'null'
 */
export const getWMSSlotStatusVersionFromSlotStatus = (
  wms_status: IWMSSlotStatusST | string | null,
) => (!wms_status || typeof wms_status === 'string' ? null : wms_status.version);

export const getCustomersFromSlotStatus = (
  wms_status: IWMSSlotStatusST | string | null,
): string[] => {
  if (!wms_status || typeof wms_status === 'string') {
    return ['-'];
  }

  return isArray(wms_status?.customers) && !isEmpty(wms_status?.customers)
    ? (replaceArrayItems(wms_status?.customers, null, '-') as string[])
    : ['-'];
};

/**
 * Returns the WMSQty from wms_status
 * @param wms_status
 * @returns WMSQty or "-"
 */
export const getWMSQtyFromSlotStatus = (wms_status: IWMSSlotStatusST | string | null): string[] => {
  if (!wms_status || typeof wms_status === 'string') {
    return ['-'];
  }

  return isArray(wms_status?.qtys) && !isEmpty(wms_status.qtys)
    ? (replaceArrayItems(wms_status.qtys as (number | string | null)[], null, '-') as string[])
    : ['-'];
};

/**
 * Returns the WMSArticleNos from location data
 * @param slotStatus Slot Status
 * @returns WMSArticleNos or "-"
 */

export const getWMSArticleNosFromSlotStatus = (
  wms_status: IWMSSlotStatusST | string | null,
): string[] => {
  if (!wms_status || typeof wms_status === 'string') {
    return ['-'];
  }

  return isArray(wms_status?.article_nos) && !isEmpty(wms_status.article_nos)
    ? (replaceArrayItems(wms_status.article_nos, null, '-') as string[])
    : ['-'];
};
/**
 * Returns the WMSDate from location data
 * @param slotStatus Slot Status
 * @returns WMSDate or "-"
 */

export const getWMSDateFromSlotStatus = (wms_status: IWMSSlotStatusST | string | null) =>
  !wms_status || typeof wms_status === 'string' ? '-' : wms_status.changed_at;

/**
 * Returns wms state from location data
 * @param slotStatus Slot Status
 * @returns wms state or "-"
 */
export const getWMSStateFromSlotStatus = (wms_status: IWMSSlotStatusST | string | null) =>
  !wms_status || typeof wms_status === 'string' ? undefined : wms_status.state;

/**
 * Returns the wms slot_usage status from location data
 * @param slotStatus Slot Status
 * @returns JSX Element
 */
export const getSlotUsageBlockedStatus = (wms_status: IWMSSlotStatusST | string | null) => {
  if (!wms_status || typeof wms_status === 'string') {
    return '-';
  }

  return wms_status.slot_usage === IWMSSlotStatusSTSlotUsageEnum.Blocked ? (
    <BlockIcon fontSize="small" />
  ) : (
    '-'
  );
};
