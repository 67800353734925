// libraries
import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';

// material-ui core
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Card from '@mui/material/Card';

// material-ui icons
import LockIcon from '@mui/icons-material/Lock';

// helpers, services & store

// variables, functions, configurations
import { LocalStore } from 'common/functions/storageFunctions';
import { singleRequestHandler } from 'common/requestHelpers';
import { AUTH_PAGES_URLS } from 'common/pages';

// styles

// components
import { Box } from 'components/common/Box';
import { Spinner } from 'components/common/Spinner';
import { Copyright } from 'components/common/Copyright';
import { EnhancedBox } from 'components/common/EnhancedBox';
import { Paper } from '@mui/material';
import { sharedStyles } from './styles';
import { useClientLevelStore } from '../../store/ClientLevelStore/clientLevelStore';
import { useFacilityLevelStore } from '../../store/FacilityLevelStore/facilityLevelStore';
import { useUserLevelStore } from '../../store/UserLevelStore/userLevelStore';
import { authStore } from '../../store/AuthStore';
import { UserLevelActionNames } from '../../store/UserLevelStore/userLevelActions';

export const MultiFactorAuth = () => {
  const { classes } = sharedStyles();
  // state variables
  const [spinner, setSpinner] = useState(0);
  const [code, setCode] = useState('');

  // location and navigate
  const navigate = useNavigate();
  const location = useLocation();

  // context
  const { dispatchUserLevelStore } = useUserLevelStore();
  const { dispatchFacilityLevel } = useFacilityLevelStore();
  const { dispatchClientLevel } = useClientLevelStore();

  // snackbar hook
  const { enqueueSnackbar } = useSnackbar();

  const session = localStorage.getItem('session');

  // component variables
  const authData = location.state === undefined ? {} : location.state;

  const handleChange = (event) => {
    setCode(event.target.value);
  };

  const mfaInfo = {
    icon: (
      <Box className={clsx(classes.cardIcon)}>
        <LockIcon fontSize="large" color="primary" />
      </Box>
    ),
    description:
      authData?.challenge_name === 'SOFTWARE_TOKEN_MFA'
        ? 'Enter the code via Authenticator app'
        : `Enter the code your received via ${
            authData?.challenge_parameters &&
            authData?.challenge_parameters.CODE_DELIVERY_DELIVERY_MEDIUM
          } on ${
            authData?.challenge_parameters &&
            authData?.challenge_parameters.CODE_DELIVERY_DESTINATION
          }`,
    actionButtons: [
      {
        label: 'Cancel',
        variant: 'outlined',
        action: () => {
          LocalStore.cleanStateAndLocalStorage({
            dispatchFacilityLevel,
            dispatchClientLevel,
            dispatchUserLevelStore,
          });
          navigate(AUTH_PAGES_URLS.SIGNIN);
        },
      },
      {
        label: 'Submit',
        variant: 'contained',
        disabled: !code || code.length < 6,
        action: () => handleSubmit(),
        class: classes.submit,
      },
    ],
  };

  const handleSubmit = () => {
    singleRequestHandler({
      request: authStore.authChallenge,
      requestParams: [
        {
          challenge_name: authData.challenge_name,
          session,
          username: authData.challenge_parameters.USER_ID_FOR_SRP,
          code,
        },
      ],
      dispatcher: enqueueSnackbar,
      callbackBeforeSend: () => setSpinner((repliesPending) => repliesPending + 1),
      callbackSuccess: ({ redirectRoute }) => {
        localStorage.removeItem('session');
        dispatchUserLevelStore({ type: UserLevelActionNames.CLEAR_AUTH_PROCESS_DETAILS });
        navigate(redirectRoute);
      },
      callbackFinally: () => setSpinner((repliesPending) => repliesPending - 1),
    });
  };

  return (
    <Container className={classes.container}>
      <Paper elevation={3} className={classes.paper}>
        <EnhancedBox
          classes={classes}
          icon={mfaInfo.icon}
          description={mfaInfo.description}
          actionButtons={mfaInfo.actionButtons}
        >
          <FormControl className={classes.formControl}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              autoComplete="off"
              fullWidth
              id="code"
              label="Authentication Code"
              autoFocus
              onKeyDown={(e) => e.key === 'Enter' && handleSubmit()}
              onChange={(e) => handleChange(e)}
            />
          </FormControl>
        </EnhancedBox>
      </Paper>
      <Box mt={8}>
        <Copyright />
      </Box>

      {spinner ? <Spinner /> : null}
    </Container>
  );
};
