import { IGroupGetAllResponseST } from 'codegen/user_admin';
import { USER_GROUPS } from 'common/userGroups';
import { IOption } from 'interfaces/inputs';

export const transformUserGroupsToSelectOptions: (
  userGroups: IGroupGetAllResponseST,
) => IOption[] = (userGroups) =>
  userGroups.groups
    .filter(
      (group) =>
        ![USER_GROUPS.FACILITY_ADMIN, USER_GROUPS.CLIENT_ADMIN].includes(group as USER_GROUPS),
    )
    .map((group: string) => ({
      value: group,
      label: group.replace(/([A-Z])/g, ' $1').trim(),
    }));
