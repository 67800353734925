import { ILocationData1ST } from 'codegen/report';
import { issueTypeEnumToStringMap } from 'common/functions/issues/issueColorFunctions';

export const getIssueValueOptionsFromData = (locations: ILocationData1ST[], activeTab: number) => {
  const distinctIssues = [
    ...new Set(locations.filter((l) => l.issues?.length).map((l) => l.issues[0].type)),
  ];

  const values = activeTab === 0 ? [{ label: 'No Issue', value: 'No Issue' }] : [];

  return [
    ...values,
    ...[...new Set(distinctIssues.map((issue) => issueTypeEnumToStringMap[issue]))].map((val) => ({
      label: val,
      value: val,
    })),
  ];
};
