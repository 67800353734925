import { ISSUE_TYPES } from 'common/issueTypesAndStates';
import { IIssueCountTotalsST } from 'codegen/report';

const getIssuesSummaryTotalCounts = (totals: IIssueCountTotalsST) => ({
  emptyInsteadOfBarcode: totals[ISSUE_TYPES.WMS_BARCODE_VERITY_EMPTY],
  foundDifferentBarcode: totals[ISSUE_TYPES.WMS_BARCODE_NOT_EQUAL_VERITY_BARCODE],
  barcodeInsteadOfEmpty: totals[ISSUE_TYPES.WMS_EMPTY_VERITY_BARCODE],
  nonEmptyNoBarcodeInsteadOfEmpty: totals[ISSUE_TYPES.WMS_EMPTY_VERITY_NOTEMPTY],
});

const getExpectedBarcodesSummary = (
  foundDifferentBarcode: number,
  emptyInsteadOfBarcode: number,
) => ({
  id: 'expected-barcode',
  title: 'Expected to find barcode and:',
  sectionItems: [
    {
      id: 'expected-barcode-found-different',
      label: 'found a different barcode',
      value: foundDifferentBarcode.toString(),
    },
    {
      id: 'expected-barcode-found-empty',
      label: 'found an empty location',
      value: emptyInsteadOfBarcode.toString(),
    },
  ],
});

const getExpectedEmptySummary = (
  barcodeInsteadOfEmpty: number,
  nonEmptyNoBarcodeInsteadOfEmpty: number,
) => ({
  id: 'expected-empty',
  title: 'Expected to find empty location and:',
  sectionItems: [
    {
      id: 'expected-empty-found-barcode',
      label: 'found a barcode',
      value: barcodeInsteadOfEmpty.toString(),
    },
    {
      id: 'expected-empty-found-no-barcode',
      label: 'found non-empty location, with no barcode',
      value: nonEmptyNoBarcodeInsteadOfEmpty.toString(),
    },
  ],
});

export const getIssuesSummary = (
  issueCountTotals: IIssueCountTotalsST,
  reportSpectName: string,
) => {
  const {
    emptyInsteadOfBarcode,
    foundDifferentBarcode,
    barcodeInsteadOfEmpty,
    nonEmptyNoBarcodeInsteadOfEmpty,
  } = getIssuesSummaryTotalCounts(issueCountTotals);

  const totalIssues =
    emptyInsteadOfBarcode +
    foundDifferentBarcode +
    barcodeInsteadOfEmpty +
    nonEmptyNoBarcodeInsteadOfEmpty;

  const issuesSections: {
    id: string;
    title: string;
    sectionItems: {
      id: string;
      label: string;
      value: string;
    }[];
  }[] = [];

  if (reportSpectName !== 'Empty location report, per area') {
    issuesSections.push(getExpectedBarcodesSummary(foundDifferentBarcode, emptyInsteadOfBarcode));
  }

  issuesSections.push(
    getExpectedEmptySummary(barcodeInsteadOfEmpty, nonEmptyNoBarcodeInsteadOfEmpty),
  );

  let subtitle = '';
  subtitle = 'Number of WMS mismatches';

  return {
    title: 'Issues',
    counter: totalIssues.toString(),
    counterSubject: 'issues',
    subtitle,
    sections: issuesSections,
  };
};
