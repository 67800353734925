import { Box } from '@mui/material';
import React from 'react';
import { ILabelTypeST, ILocationLabelST } from 'codegen/location_action';
import { LabelsRadioButton } from './LabelsRadioButton';
import { LabelsStatus } from './LabelsStatus';
import { labelTypeEnumToText } from './models/labelTypeEnumToText.model';

export const LabelsCardContent = ({
  locationLabel,
  selectedLabelType,
  mutateArchiveLabel,
  mutateAddLabel,
  wmsDateTime,
  verityDateTime,
}: {
  locationLabel: ILocationLabelST | undefined;
  selectedLabelType: ILabelTypeST | undefined;
  mutateArchiveLabel: () => void;
  mutateAddLabel: (labelType: ILabelTypeST) => void;
  wmsDateTime: string;
  verityDateTime: string;
}) => {
  const handleButtonClick = (labelType: ILabelTypeST) => {
    if (selectedLabelType === labelType && locationLabel?.status === 'ACTIVE') {
      mutateArchiveLabel();
    } else {
      mutateAddLabel(labelType);
    }
  };

  const isSelected = (buttonLabelType: ILabelTypeST) => {
    if (selectedLabelType && locationLabel?.status === 'ACTIVE') {
      return selectedLabelType === buttonLabelType;
    }

    return false;
  };

  return (
    <>
      <LabelsStatus
        selectedLabelType={selectedLabelType}
        createdDateTime={locationLabel?.created_at || undefined}
        wmsDateTime={wmsDateTime}
        verityDateTime={verityDateTime}
        locationLabelStatus={locationLabel?.status}
        onUnselect={mutateArchiveLabel}
      />

      <Box display="flex" gap={2}>
        <Box display="flex" flexDirection="column" gap={2} flex={1}>
          <LabelsRadioButton
            onClick={() => handleButtonClick(ILabelTypeST.WmsUpdateRequired)}
            selected={isSelected(ILabelTypeST.WmsUpdateRequired)}
          >
            {labelTypeEnumToText[ILabelTypeST.WmsUpdateRequired]}
          </LabelsRadioButton>

          <LabelsRadioButton
            onClick={() => handleButtonClick(ILabelTypeST.OnPremiseCheck)}
            selected={isSelected(ILabelTypeST.OnPremiseCheck)}
          >
            {labelTypeEnumToText[ILabelTypeST.OnPremiseCheck]}
          </LabelsRadioButton>
        </Box>

        <Box display="flex" flexDirection="column" gap={2} flex={1}>
          <LabelsRadioButton
            onClick={() => handleButtonClick(ILabelTypeST.AddToDoList)}
            selected={isSelected(ILabelTypeST.AddToDoList)}
          >
            {labelTypeEnumToText[ILabelTypeST.AddToDoList]}
          </LabelsRadioButton>

          <LabelsRadioButton
            onClick={() => handleButtonClick(ILabelTypeST.AddToRescanList)}
            selected={isSelected(ILabelTypeST.AddToRescanList)}
          >
            {labelTypeEnumToText[ILabelTypeST.AddToRescanList]}
          </LabelsRadioButton>
        </Box>
      </Box>
    </>
  );
};
