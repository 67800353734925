import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

export const useLabelsCardStyles = makeStyles()((theme: Theme) => ({
  card: {
    border: '1px solid',
    borderColor: theme.palette.grey[200],
  },
  cardHeader: {
    position: 'relative',
    '::before': {
      content: "''",
      position: 'absolute',
      bottom: 0,
      insetInlineStart: '24px',
      insetInlineEnd: '24px',
      borderBottomWidth: '1px',
      borderBottomStyle: 'solid',
      borderBottomColor: theme.palette.divider,
    },
  },
  title: {
    fontSize: '1.7rem',
    fontWeight: 'bold',
    color: theme.palette.textSecondary?.main,
    cursor: 'default',
  },
  subheader: {
    fontSize: '1rem',
    wordBreak: 'break-all',
    marginRight: theme.spacing(4),
  },
}));
