import { IVeritySlotStatusStateST } from 'codegen/location_information';

export const getImageText = ({
  verityState,
  isLoadingImages,
  imageUrlsLength,
  numberOfImagesRequested,
}: {
  verityState?: IVeritySlotStatusStateST;
  isLoadingImages: boolean;
  imageUrlsLength: number;
  numberOfImagesRequested: number;
}): string => {
  const numberOfCurrentlyAvailableImages = imageUrlsLength;
  const numberOfPendingImages = numberOfImagesRequested;

  if (!verityState) {
    return 'Images are not available because Verity has no data for this location.';
  }

  if (isLoadingImages) {
    return 'Loading images';
  }

  if (numberOfCurrentlyAvailableImages === 0 && numberOfPendingImages === 0) {
    return 'There are no images available for this location.';
  }

  if (numberOfPendingImages > 0) {
    return 'Waiting for images to be downloaded.';
  }

  return '';
};
