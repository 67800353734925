import { IInventoryRequestParameterST } from 'codegen/inventory_request';
import { IReportSpecificationST } from 'codegen/report_specification';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { ScheduleFormAction } from '../reducers/ScheduleForm.model';

/**
 * Remove param values from internal state (for ONE given param name)
 */
export const clearReportParamValues = (
  paramUniqueName: string,
  reportParamValues: IInventoryRequestParameterST[],
  reportSpec: IReportSpecificationST,
  dispatch: (value: ScheduleFormAction) => void,
) => {
  const lp = getLogPrefixForType('FUNCTION', 'clearReportParamValues');
  console.debug(lp, 'paramUniqueName', paramUniqueName);
  const paramAux = reportSpec?.params?.find((el) => el.unique_name === paramUniqueName);
  if (paramAux) {
    // Filter out the affected parameter, removing it form the internal state
    const auxReportParams = reportParamValues.filter((el) => el.unique_name !== paramUniqueName);

    dispatch({ type: 'SET_REPORT_PARAM_VALUES', payload: auxReportParams });
  } else {
    console.error(
      lp,
      `param ${paramUniqueName} does not exist on reportSpec: ${reportSpec.report_spec_name}`,
    );
  }
};
