/* tslint:disable */
/* eslint-disable */
/**
 * Location Information
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @enum {string}
 */

export const IBarcodeMatchLogicST = {
  IntersectionNotEmpty: 'INTERSECTION_NOT_EMPTY',
  ExactMatch: 'EXACT_MATCH',
  VeritySuperset: 'VERITY_SUPERSET',
  WmsSuperset: 'WMS_SUPERSET',
} as const;

export type IBarcodeMatchLogicST = typeof IBarcodeMatchLogicST[keyof typeof IBarcodeMatchLogicST];

/**
 *
 * @export
 * @interface IErrorResponseErrorsInnerST
 */
export interface IErrorResponseErrorsInnerST {
  /**
   * Error code
   * @type {string}
   * @memberof IErrorResponseErrorsInnerST
   */
  code: string;
}
/**
 *
 * @export
 * @interface IErrorResponseST
 */
export interface IErrorResponseST {
  /**
   * Error description
   * @type {string}
   * @memberof IErrorResponseST
   */
  message: string;
  /**
   * Error timestamp
   * @type {string}
   * @memberof IErrorResponseST
   */
  timestamp: string;
  /**
   * User triggering the request
   * @type {string}
   * @memberof IErrorResponseST
   */
  user_name: string;
  /**
   * The request trace id
   * @type {string}
   * @memberof IErrorResponseST
   */
  trace_id: string;
  /**
   * The URL that can be used to report the error
   * @type {string}
   * @memberof IErrorResponseST
   */
  report_url: string | null;
  /**
   * List of Error objects.
   * @type {Array<IErrorResponseErrorsInnerST>}
   * @memberof IErrorResponseST
   */
  errors: Array<IErrorResponseErrorsInnerST>;
}
/**
 *
 * @export
 * @interface IGetLocationLabel200ResponseST
 */
export interface IGetLocationLabel200ResponseST {
  /**
   * The location identifier.
   * @type {string}
   * @memberof IGetLocationLabel200ResponseST
   */
  location?: string | null;
  /**
   *
   * @type {ILabelTypeST}
   * @memberof IGetLocationLabel200ResponseST
   */
  label_type?: ILabelTypeST;
  /**
   *
   * @type {ILabelStatusST}
   * @memberof IGetLocationLabel200ResponseST
   */
  status?: ILabelStatusST;
  /**
   * Location Label version
   * @type {number}
   * @memberof IGetLocationLabel200ResponseST
   */
  version?: number | null;
  /**
   * The email associated with the label.
   * @type {string}
   * @memberof IGetLocationLabel200ResponseST
   */
  created_by?: string | null;
  /**
   * The timestamp when the label was created.
   * @type {string}
   * @memberof IGetLocationLabel200ResponseST
   */
  created_at?: string | null;
  /**
   *
   * @type {ILocationLabelVeritySlotStatusST}
   * @memberof IGetLocationLabel200ResponseST
   */
  verity_slot_status?: ILocationLabelVeritySlotStatusST | null;
  /**
   *
   * @type {ILocationLabelWmsSlotStatusST}
   * @memberof IGetLocationLabel200ResponseST
   */
  wms_slot_status?: ILocationLabelWmsSlotStatusST | null;
  /**
   * The email associated with the user who solved the label.
   * @type {string}
   * @memberof IGetLocationLabel200ResponseST
   */
  updated_by?: string | null;
  /**
   * The timestamp when the label was solved.
   * @type {string}
   * @memberof IGetLocationLabel200ResponseST
   */
  updated_at?: string | null;
}

/**
 *
 * @export
 * @enum {string}
 */

export const IIssueLogicST = {
  Default: 'DEFAULT',
  DoubleDepth21: 'DOUBLE_DEPTH_21',
  DoubleDepth22: 'DOUBLE_DEPTH_22',
  DoubleDepthMapBased: 'DOUBLE_DEPTH_MAP_BASED',
  NaBarcode: 'NA_BARCODE',
  PictureOnly: 'PICTURE_ONLY',
  C001CMissingBc: 'C001C_MISSING_BC',
} as const;

export type IIssueLogicST = typeof IIssueLogicST[keyof typeof IIssueLogicST];

/**
 *
 * @export
 * @enum {string}
 */

export const ILabelStatusST = {
  Active: 'ACTIVE',
  Archived: 'ARCHIVED',
} as const;

export type ILabelStatusST = typeof ILabelStatusST[keyof typeof ILabelStatusST];

/**
 *
 * @export
 * @enum {string}
 */

export const ILabelTypeST = {
  WmsUpdateRequired: 'WMS_UPDATE_REQUIRED',
  OnPremiseCheck: 'ON_PREMISE_CHECK',
  AddToDoList: 'ADD_TO_DO_LIST',
  AddToRescanList: 'ADD_TO_RESCAN_LIST',
} as const;

export type ILabelTypeST = typeof ILabelTypeST[keyof typeof ILabelTypeST];

/**
 *
 * @export
 * @interface ILocationLabelRequestPayloadST
 */
export interface ILocationLabelRequestPayloadST {
  /**
   *
   * @type {ILabelTypeST}
   * @memberof ILocationLabelRequestPayloadST
   */
  label_type: ILabelTypeST;
  /**
   *
   * @type {number}
   * @memberof ILocationLabelRequestPayloadST
   */
  verity_version?: number | null;
  /**
   *
   * @type {number}
   * @memberof ILocationLabelRequestPayloadST
   */
  wms_version?: number | null;
  /**
   *
   * @type {boolean}
   * @memberof ILocationLabelRequestPayloadST
   */
  verity_staging?: boolean | null;
}

/**
 *
 * @export
 * @interface ILocationLabelST
 */
export interface ILocationLabelST {
  /**
   * The location identifier.
   * @type {string}
   * @memberof ILocationLabelST
   */
  location?: string | null;
  /**
   *
   * @type {ILabelTypeST}
   * @memberof ILocationLabelST
   */
  label_type?: ILabelTypeST;
  /**
   *
   * @type {ILabelStatusST}
   * @memberof ILocationLabelST
   */
  status?: ILabelStatusST;
  /**
   * Location Label version
   * @type {number}
   * @memberof ILocationLabelST
   */
  version?: number | null;
  /**
   * The email associated with the label.
   * @type {string}
   * @memberof ILocationLabelST
   */
  created_by?: string | null;
  /**
   * The timestamp when the label was created.
   * @type {string}
   * @memberof ILocationLabelST
   */
  created_at?: string | null;
  /**
   *
   * @type {ILocationLabelVeritySlotStatusST}
   * @memberof ILocationLabelST
   */
  verity_slot_status?: ILocationLabelVeritySlotStatusST | null;
  /**
   *
   * @type {ILocationLabelWmsSlotStatusST}
   * @memberof ILocationLabelST
   */
  wms_slot_status?: ILocationLabelWmsSlotStatusST | null;
  /**
   * The email associated with the user who solved the label.
   * @type {string}
   * @memberof ILocationLabelST
   */
  updated_by?: string | null;
  /**
   * The timestamp when the label was solved.
   * @type {string}
   * @memberof ILocationLabelST
   */
  updated_at?: string | null;
}

/**
 *
 * @export
 * @interface ILocationLabelUpdateRequestPayloadST
 */
export interface ILocationLabelUpdateRequestPayloadST {
  /**
   *
   * @type {ILabelStatusST}
   * @memberof ILocationLabelUpdateRequestPayloadST
   */
  label_status: ILabelStatusST;
}

/**
 *
 * @export
 * @interface ILocationLabelVeritySlotStatusST
 */
export interface ILocationLabelVeritySlotStatusST {
  /**
   * Label of the slot
   * @type {string}
   * @memberof ILocationLabelVeritySlotStatusST
   */
  slot_label: string;
  /**
   *
   * @type {IVeritySlotStatusStateST}
   * @memberof ILocationLabelVeritySlotStatusST
   */
  state: IVeritySlotStatusStateST;
  /**
   * Indicates wether the slot information needs to be refined with a backup flight
   * @type {boolean}
   * @memberof ILocationLabelVeritySlotStatusST
   */
  pending_backup: boolean;
  /**
   * Time when Verity collected the data
   * @type {string}
   * @memberof ILocationLabelVeritySlotStatusST
   */
  collected_at: string;
  /**
   * List of barcodes that were detected
   * @type {Array<string>}
   * @memberof ILocationLabelVeritySlotStatusST
   */
  barcodes: Array<string>;
  /**
   * Ids of images collected
   * @type {Array<string>}
   * @memberof ILocationLabelVeritySlotStatusST
   */
  image_ids: Array<string>;
  /**
   * Result ID for this Verity slot status
   * @type {string}
   * @memberof ILocationLabelVeritySlotStatusST
   */
  result_id: string | null;
  /**
   * Time when Verity will next start collecting data for this slot
   * @type {string}
   * @memberof ILocationLabelVeritySlotStatusST
   */
  next_scheduled_at: string | null;
  /**
   * List of overrides to Verity data
   * @type {Array<IVeritySlotStatusUserOverrideST>}
   * @memberof ILocationLabelVeritySlotStatusST
   */
  user_overrides: Array<IVeritySlotStatusUserOverrideST> | null;
  /**
   * Version of the slot status
   * @type {number}
   * @memberof ILocationLabelVeritySlotStatusST
   */
  version: number;
  /**
   * Version of the slot status in the staging table
   * @type {number}
   * @memberof ILocationLabelVeritySlotStatusST
   */
  staging_version: number | null;
  /**
   * Time when this information was created/last updated
   * @type {string}
   * @memberof ILocationLabelVeritySlotStatusST
   */
  updated_at: string;
  /**
   * Indicates if the slot status was approved as part of a review
   * @type {boolean}
   * @memberof ILocationLabelVeritySlotStatusST
   */
  approved: boolean;
  /**
   * Indicates the username of the person that approved the slot status
   * @type {string}
   * @memberof ILocationLabelVeritySlotStatusST
   */
  approved_by: string | null;
  /**
   *
   * @type {IVeritySlotStatusIssueLogicUsedST}
   * @memberof ILocationLabelVeritySlotStatusST
   */
  issue_logic_used?: IVeritySlotStatusIssueLogicUsedST | null;
  /**
   *
   * @type {IVeritySlotStatusBarcodeMatchLogicUsedST}
   * @memberof ILocationLabelVeritySlotStatusST
   */
  barcode_match_logic_used?: IVeritySlotStatusBarcodeMatchLogicUsedST | null;
}

/**
 *
 * @export
 * @interface ILocationLabelWmsSlotStatusST
 */
export interface ILocationLabelWmsSlotStatusST {
  /**
   * Label of the slot
   * @type {string}
   * @memberof ILocationLabelWmsSlotStatusST
   */
  slot_label: string;
  /**
   * State of the slot
   * @type {string}
   * @memberof ILocationLabelWmsSlotStatusST
   */
  state: ILocationLabelWmsSlotStatusSTStateEnum;
  /**
   * Timestamp of when the content of this slot changed
   * @type {string}
   * @memberof ILocationLabelWmsSlotStatusST
   */
  changed_at: string;
  /**
   * ID of the WMS snapshot providing these data
   * @type {string}
   * @memberof ILocationLabelWmsSlotStatusST
   */
  snapshot_id: string;
  /**
   * List of barcodes expected at location
   * @type {Array<string>}
   * @memberof ILocationLabelWmsSlotStatusST
   */
  barcodes: Array<string>;
  /**
   * List of article numbers expected at location
   * @type {Array<string>}
   * @memberof ILocationLabelWmsSlotStatusST
   */
  article_nos: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ILocationLabelWmsSlotStatusST
   */
  slot_usage: ILocationLabelWmsSlotStatusSTSlotUsageEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof ILocationLabelWmsSlotStatusST
   */
  customers: Array<string>;
  /**
   *
   * @type {Array<number>}
   * @memberof ILocationLabelWmsSlotStatusST
   */
  unit_load_dimensions: Array<number> | null;
  /**
   * Version of the slot status
   * @type {number}
   * @memberof ILocationLabelWmsSlotStatusST
   */
  version: number;
  /**
   * Time when this information was created/last updated
   * @type {string}
   * @memberof ILocationLabelWmsSlotStatusST
   */
  updated_at: string;
  /**
   * List of quantity of each article
   * @type {Array<number>}
   * @memberof ILocationLabelWmsSlotStatusST
   */
  qtys: Array<number>;
  /**
   *
   * @type {string}
   * @memberof ILocationLabelWmsSlotStatusST
   */
  query_time: string;
  /**
   * RAW data
   * @type {string}
   * @memberof ILocationLabelWmsSlotStatusST
   */
  raw_data: string | null;
  /**
   * An identifier for the given location check in the client system (Optional)
   * @type {string}
   * @memberof ILocationLabelWmsSlotStatusST
   */
  client_location_check_id?: string | null;
  /**
   * Exclusion Status of this slot defined in WMS data.
   * @type {string}
   * @memberof ILocationLabelWmsSlotStatusST
   */
  exclusion_status: ILocationLabelWmsSlotStatusSTExclusionStatusEnum;
}

export const ILocationLabelWmsSlotStatusSTStateEnum = {
  Empty: 'EMPTY',
  Barcode: 'BARCODE',
  Invalid: 'INVALID',
} as const;

export type ILocationLabelWmsSlotStatusSTStateEnum =
  typeof ILocationLabelWmsSlotStatusSTStateEnum[keyof typeof ILocationLabelWmsSlotStatusSTStateEnum];
export const ILocationLabelWmsSlotStatusSTSlotUsageEnum = {
  Blocked: 'BLOCKED',
  Normal: 'NORMAL',
  Null: 'null',
} as const;

export type ILocationLabelWmsSlotStatusSTSlotUsageEnum =
  typeof ILocationLabelWmsSlotStatusSTSlotUsageEnum[keyof typeof ILocationLabelWmsSlotStatusSTSlotUsageEnum];
export const ILocationLabelWmsSlotStatusSTExclusionStatusEnum = {
  Include: 'INCLUDE',
  Exclude: 'EXCLUDE',
} as const;

export type ILocationLabelWmsSlotStatusSTExclusionStatusEnum =
  typeof ILocationLabelWmsSlotStatusSTExclusionStatusEnum[keyof typeof ILocationLabelWmsSlotStatusSTExclusionStatusEnum];

/**
 *
 * @export
 * @enum {string}
 */

export const INullableFieldST = {
  Null: 'null',
  None: 'None',
} as const;

export type INullableFieldST = typeof INullableFieldST[keyof typeof INullableFieldST];

/**
 * @type IVeritySlotStatusBarcodeMatchLogicUsedST
 * @export
 */
export type IVeritySlotStatusBarcodeMatchLogicUsedST = IBarcodeMatchLogicST | INullableFieldST;

/**
 * @type IVeritySlotStatusIssueLogicUsedST
 * @export
 */
export type IVeritySlotStatusIssueLogicUsedST = IIssueLogicST | INullableFieldST;

/**
 *
 * @export
 * @interface IVeritySlotStatusST
 */
export interface IVeritySlotStatusST {
  /**
   * Label of the slot
   * @type {string}
   * @memberof IVeritySlotStatusST
   */
  slot_label: string;
  /**
   *
   * @type {IVeritySlotStatusStateST}
   * @memberof IVeritySlotStatusST
   */
  state: IVeritySlotStatusStateST;
  /**
   * Indicates wether the slot information needs to be refined with a backup flight
   * @type {boolean}
   * @memberof IVeritySlotStatusST
   */
  pending_backup: boolean;
  /**
   * Time when Verity collected the data
   * @type {string}
   * @memberof IVeritySlotStatusST
   */
  collected_at: string;
  /**
   * List of barcodes that were detected
   * @type {Array<string>}
   * @memberof IVeritySlotStatusST
   */
  barcodes: Array<string>;
  /**
   * Ids of images collected
   * @type {Array<string>}
   * @memberof IVeritySlotStatusST
   */
  image_ids: Array<string>;
  /**
   * Result ID for this Verity slot status
   * @type {string}
   * @memberof IVeritySlotStatusST
   */
  result_id: string | null;
  /**
   * Time when Verity will next start collecting data for this slot
   * @type {string}
   * @memberof IVeritySlotStatusST
   */
  next_scheduled_at: string | null;
  /**
   * List of overrides to Verity data
   * @type {Array<IVeritySlotStatusUserOverrideST>}
   * @memberof IVeritySlotStatusST
   */
  user_overrides: Array<IVeritySlotStatusUserOverrideST> | null;
  /**
   * Version of the slot status
   * @type {number}
   * @memberof IVeritySlotStatusST
   */
  version: number;
  /**
   * Version of the slot status in the staging table
   * @type {number}
   * @memberof IVeritySlotStatusST
   */
  staging_version: number | null;
  /**
   * Time when this information was created/last updated
   * @type {string}
   * @memberof IVeritySlotStatusST
   */
  updated_at: string;
  /**
   * Indicates if the slot status was approved as part of a review
   * @type {boolean}
   * @memberof IVeritySlotStatusST
   */
  approved: boolean;
  /**
   * Indicates the username of the person that approved the slot status
   * @type {string}
   * @memberof IVeritySlotStatusST
   */
  approved_by: string | null;
  /**
   *
   * @type {IVeritySlotStatusIssueLogicUsedST}
   * @memberof IVeritySlotStatusST
   */
  issue_logic_used?: IVeritySlotStatusIssueLogicUsedST | null;
  /**
   *
   * @type {IVeritySlotStatusBarcodeMatchLogicUsedST}
   * @memberof IVeritySlotStatusST
   */
  barcode_match_logic_used?: IVeritySlotStatusBarcodeMatchLogicUsedST | null;
}

/**
 * State of the slot
 * @export
 * @enum {string}
 */

export const IVeritySlotStatusStateST = {
  Empty: 'EMPTY',
  Barcode: 'BARCODE',
  NotEmpty: 'NOT_EMPTY',
  Unreachable: 'UNREACHABLE',
  NoResult: 'NO_RESULT',
} as const;

export type IVeritySlotStatusStateST =
  typeof IVeritySlotStatusStateST[keyof typeof IVeritySlotStatusStateST];

/**
 *
 * @export
 * @interface IVeritySlotStatusUserOverrideST
 */
export interface IVeritySlotStatusUserOverrideST {
  /**
   * Id of user making the override
   * @type {string}
   * @memberof IVeritySlotStatusUserOverrideST
   */
  user_id: string;
  /**
   * Name of user making the override
   * @type {string}
   * @memberof IVeritySlotStatusUserOverrideST
   */
  user_name: string;
  /**
   * Timestamp of when the user submitted the override
   * @type {string}
   * @memberof IVeritySlotStatusUserOverrideST
   */
  timestamp: string;
  /**
   * User-provided flag indicating if original Verity data were correct
   * @type {boolean}
   * @memberof IVeritySlotStatusUserOverrideST
   */
  verity_correct: boolean;
  /**
   * True, if override corresponds to data provided by WMS
   * @type {boolean}
   * @memberof IVeritySlotStatusUserOverrideST
   */
  set_to_expected: boolean;
  /**
   *
   * @type {IVeritySlotStatusStateST}
   * @memberof IVeritySlotStatusUserOverrideST
   */
  state: IVeritySlotStatusStateST;
  /**
   * List of barcodes that should override those found by Verity
   * @type {Array<string>}
   * @memberof IVeritySlotStatusUserOverrideST
   */
  barcodes: Array<string>;
  /**
   * Free-text comment by user
   * @type {string}
   * @memberof IVeritySlotStatusUserOverrideST
   */
  comment: string | null;
  /**
   * Indicates if the update is part of a review
   * @type {boolean}
   * @memberof IVeritySlotStatusUserOverrideST
   */
  review: boolean;
}

/**
 *
 * @export
 * @interface IWMSSlotStatusST
 */
export interface IWMSSlotStatusST {
  /**
   * Label of the slot
   * @type {string}
   * @memberof IWMSSlotStatusST
   */
  slot_label: string;
  /**
   * State of the slot
   * @type {string}
   * @memberof IWMSSlotStatusST
   */
  state: IWMSSlotStatusSTStateEnum;
  /**
   * Timestamp of when the content of this slot changed
   * @type {string}
   * @memberof IWMSSlotStatusST
   */
  changed_at: string;
  /**
   * ID of the WMS snapshot providing these data
   * @type {string}
   * @memberof IWMSSlotStatusST
   */
  snapshot_id: string;
  /**
   * List of barcodes expected at location
   * @type {Array<string>}
   * @memberof IWMSSlotStatusST
   */
  barcodes: Array<string>;
  /**
   * List of article numbers expected at location
   * @type {Array<string>}
   * @memberof IWMSSlotStatusST
   */
  article_nos: Array<string>;
  /**
   *
   * @type {string}
   * @memberof IWMSSlotStatusST
   */
  slot_usage: IWMSSlotStatusSTSlotUsageEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof IWMSSlotStatusST
   */
  customers: Array<string>;
  /**
   *
   * @type {Array<number>}
   * @memberof IWMSSlotStatusST
   */
  unit_load_dimensions: Array<number> | null;
  /**
   * Version of the slot status
   * @type {number}
   * @memberof IWMSSlotStatusST
   */
  version: number;
  /**
   * Time when this information was created/last updated
   * @type {string}
   * @memberof IWMSSlotStatusST
   */
  updated_at: string;
  /**
   * List of quantity of each article
   * @type {Array<number>}
   * @memberof IWMSSlotStatusST
   */
  qtys: Array<number>;
  /**
   *
   * @type {string}
   * @memberof IWMSSlotStatusST
   */
  query_time: string;
  /**
   * RAW data
   * @type {string}
   * @memberof IWMSSlotStatusST
   */
  raw_data: string | null;
  /**
   * An identifier for the given location check in the client system (Optional)
   * @type {string}
   * @memberof IWMSSlotStatusST
   */
  client_location_check_id?: string | null;
  /**
   * Exclusion Status of this slot defined in WMS data.
   * @type {string}
   * @memberof IWMSSlotStatusST
   */
  exclusion_status: IWMSSlotStatusSTExclusionStatusEnum;
}

export const IWMSSlotStatusSTStateEnum = {
  Empty: 'EMPTY',
  Barcode: 'BARCODE',
  Invalid: 'INVALID',
} as const;

export type IWMSSlotStatusSTStateEnum =
  typeof IWMSSlotStatusSTStateEnum[keyof typeof IWMSSlotStatusSTStateEnum];
export const IWMSSlotStatusSTSlotUsageEnum = {
  Blocked: 'BLOCKED',
  Normal: 'NORMAL',
  Null: 'null',
} as const;

export type IWMSSlotStatusSTSlotUsageEnum =
  typeof IWMSSlotStatusSTSlotUsageEnum[keyof typeof IWMSSlotStatusSTSlotUsageEnum];
export const IWMSSlotStatusSTExclusionStatusEnum = {
  Include: 'INCLUDE',
  Exclude: 'EXCLUDE',
} as const;

export type IWMSSlotStatusSTExclusionStatusEnum =
  typeof IWMSSlotStatusSTExclusionStatusEnum[keyof typeof IWMSSlotStatusSTExclusionStatusEnum];

/**
 * LocationActionApi - axios parameter creator
 * @export
 */
export const LocationActionApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Returns the label for a given location.
     * @summary Return label for a given location.
     * @param {string} systemIdStr
     * @param {string} location
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLocationLabel: async (
      systemIdStr: string,
      location: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getLocationLabel', 'systemIdStr', systemIdStr);
      // verify required parameter 'location' is not null or undefined
      assertParamExists('getLocationLabel', 'location', location);
      const localVarPath = `/{system_id_str}/location/{location}/label`
        .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
        .replace(`{${'location'}}`, encodeURIComponent(String(location)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Create a new label for a given location.
     * @summary Create a new label for a given location.
     * @param {string} systemIdStr
     * @param {string} location
     * @param {ILocationLabelRequestPayloadST} iLocationLabelRequestPayloadST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postLocationLabel: async (
      systemIdStr: string,
      location: string,
      iLocationLabelRequestPayloadST: ILocationLabelRequestPayloadST,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('postLocationLabel', 'systemIdStr', systemIdStr);
      // verify required parameter 'location' is not null or undefined
      assertParamExists('postLocationLabel', 'location', location);
      // verify required parameter 'iLocationLabelRequestPayloadST' is not null or undefined
      assertParamExists(
        'postLocationLabel',
        'iLocationLabelRequestPayloadST',
        iLocationLabelRequestPayloadST,
      );
      const localVarPath = `/{system_id_str}/location/{location}/label`
        .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
        .replace(`{${'location'}}`, encodeURIComponent(String(location)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iLocationLabelRequestPayloadST,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update label status for a given location.
     * @summary Update label status for a given location.
     * @param {string} systemIdStr
     * @param {string} location
     * @param {ILocationLabelUpdateRequestPayloadST} iLocationLabelUpdateRequestPayloadST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putLocationLabel: async (
      systemIdStr: string,
      location: string,
      iLocationLabelUpdateRequestPayloadST: ILocationLabelUpdateRequestPayloadST,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('putLocationLabel', 'systemIdStr', systemIdStr);
      // verify required parameter 'location' is not null or undefined
      assertParamExists('putLocationLabel', 'location', location);
      // verify required parameter 'iLocationLabelUpdateRequestPayloadST' is not null or undefined
      assertParamExists(
        'putLocationLabel',
        'iLocationLabelUpdateRequestPayloadST',
        iLocationLabelUpdateRequestPayloadST,
      );
      const localVarPath = `/{system_id_str}/location/{location}/label`
        .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
        .replace(`{${'location'}}`, encodeURIComponent(String(location)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iLocationLabelUpdateRequestPayloadST,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * LocationActionApi - functional programming interface
 * @export
 */
export const LocationActionApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = LocationActionApiAxiosParamCreator(configuration);
  return {
    /**
     * Returns the label for a given location.
     * @summary Return label for a given location.
     * @param {string} systemIdStr
     * @param {string} location
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLocationLabel(
      systemIdStr: string,
      location: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IGetLocationLabel200ResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getLocationLabel(
        systemIdStr,
        location,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Create a new label for a given location.
     * @summary Create a new label for a given location.
     * @param {string} systemIdStr
     * @param {string} location
     * @param {ILocationLabelRequestPayloadST} iLocationLabelRequestPayloadST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postLocationLabel(
      systemIdStr: string,
      location: string,
      iLocationLabelRequestPayloadST: ILocationLabelRequestPayloadST,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ILocationLabelST>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postLocationLabel(
        systemIdStr,
        location,
        iLocationLabelRequestPayloadST,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Update label status for a given location.
     * @summary Update label status for a given location.
     * @param {string} systemIdStr
     * @param {string} location
     * @param {ILocationLabelUpdateRequestPayloadST} iLocationLabelUpdateRequestPayloadST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async putLocationLabel(
      systemIdStr: string,
      location: string,
      iLocationLabelUpdateRequestPayloadST: ILocationLabelUpdateRequestPayloadST,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ILocationLabelST>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.putLocationLabel(
        systemIdStr,
        location,
        iLocationLabelUpdateRequestPayloadST,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * LocationActionApi - factory interface
 * @export
 */
export const LocationActionApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = LocationActionApiFp(configuration);
  return {
    /**
     * Returns the label for a given location.
     * @summary Return label for a given location.
     * @param {string} systemIdStr
     * @param {string} location
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLocationLabel(
      systemIdStr: string,
      location: string,
      options?: any,
    ): AxiosPromise<IGetLocationLabel200ResponseST> {
      return localVarFp
        .getLocationLabel(systemIdStr, location, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Create a new label for a given location.
     * @summary Create a new label for a given location.
     * @param {string} systemIdStr
     * @param {string} location
     * @param {ILocationLabelRequestPayloadST} iLocationLabelRequestPayloadST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postLocationLabel(
      systemIdStr: string,
      location: string,
      iLocationLabelRequestPayloadST: ILocationLabelRequestPayloadST,
      options?: any,
    ): AxiosPromise<ILocationLabelST> {
      return localVarFp
        .postLocationLabel(systemIdStr, location, iLocationLabelRequestPayloadST, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Update label status for a given location.
     * @summary Update label status for a given location.
     * @param {string} systemIdStr
     * @param {string} location
     * @param {ILocationLabelUpdateRequestPayloadST} iLocationLabelUpdateRequestPayloadST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putLocationLabel(
      systemIdStr: string,
      location: string,
      iLocationLabelUpdateRequestPayloadST: ILocationLabelUpdateRequestPayloadST,
      options?: any,
    ): AxiosPromise<ILocationLabelST> {
      return localVarFp
        .putLocationLabel(systemIdStr, location, iLocationLabelUpdateRequestPayloadST, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * LocationActionApi - object-oriented interface
 * @export
 * @class LocationActionApi
 * @extends {BaseAPI}
 */
export class LocationActionApi extends BaseAPI {
  /**
   * Returns the label for a given location.
   * @summary Return label for a given location.
   * @param {string} systemIdStr
   * @param {string} location
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LocationActionApi
   */
  public getLocationLabel(systemIdStr: string, location: string, options?: AxiosRequestConfig) {
    return LocationActionApiFp(this.configuration)
      .getLocationLabel(systemIdStr, location, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Create a new label for a given location.
   * @summary Create a new label for a given location.
   * @param {string} systemIdStr
   * @param {string} location
   * @param {ILocationLabelRequestPayloadST} iLocationLabelRequestPayloadST
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LocationActionApi
   */
  public postLocationLabel(
    systemIdStr: string,
    location: string,
    iLocationLabelRequestPayloadST: ILocationLabelRequestPayloadST,
    options?: AxiosRequestConfig,
  ) {
    return LocationActionApiFp(this.configuration)
      .postLocationLabel(systemIdStr, location, iLocationLabelRequestPayloadST, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update label status for a given location.
   * @summary Update label status for a given location.
   * @param {string} systemIdStr
   * @param {string} location
   * @param {ILocationLabelUpdateRequestPayloadST} iLocationLabelUpdateRequestPayloadST
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LocationActionApi
   */
  public putLocationLabel(
    systemIdStr: string,
    location: string,
    iLocationLabelUpdateRequestPayloadST: ILocationLabelUpdateRequestPayloadST,
    options?: AxiosRequestConfig,
  ) {
    return LocationActionApiFp(this.configuration)
      .putLocationLabel(systemIdStr, location, iLocationLabelUpdateRequestPayloadST, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
