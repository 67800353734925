import { useEffect, RefObject } from 'react';
import { useThree } from '@react-three/fiber';
import { Box3, Vector3, Object3D, OrthographicCamera } from 'three';
import type CameraControlsImpl from 'camera-controls';
import { getLogPrefixForType } from 'common/functions/logFunctions';

const FIT_PADDING = 1;

const logPrefix = getLogPrefixForType('HOOK', 'uzeZoomClamp');

export function useZoomClamp(objectRef: RefObject<Object3D>): void {
  const controls = useThree((state) => state.controls);
  const camera = useThree((state) => state.camera);
  useEffect(() => {
    if (!objectRef.current || !controls || !(camera instanceof OrthographicCamera)) {
      return;
    }

    const cameraControls = controls as unknown as CameraControlsImpl;

    const box = new Box3().setFromObject(objectRef.current);
    box.expandByScalar(FIT_PADDING);
    const size = box.getSize(new Vector3());
    const width = camera.right - camera.left;
    const height = camera.top - camera.bottom;

    const aisleWidth = Math.max(size.x, size.y);
    const minZoom = width / aisleWidth;
    const maxZoom = height / size.z;

    console.debug(logPrefix, `clamping zoom minZoom: ${maxZoom}, maxZoom: ${minZoom} `);

    // in some cases aisle width is smaller or similar to the height,
    // when that happens and this is rendered in wide viewport it causes
    // issue where min and max are mixed up. hence the flip with min/max here [TC]
    cameraControls.minZoom = Math.min(maxZoom, minZoom);
    cameraControls.maxZoom = Math.max(maxZoom, minZoom);
  }, [camera, controls, objectRef]);
}
