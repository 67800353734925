export const onRemoveCustomGrid = ({
  gridName,
  activeTab,
}: {
  gridName: 'report' | 'warehouse-status';
  activeTab: number;
}) => {
  const savedGridState = localStorage.getItem(`savedGridState-${gridName}`);

  if (!savedGridState) {
    return;
  }
  const gridState = JSON.parse(savedGridState);

  delete gridState[activeTab];

  const serializedNewState = JSON.stringify(gridState);
  localStorage.setItem(`savedGridState-${gridName}`, serializedNewState);
};
