import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import { useLabelsStatusStyles } from './LabelsStatus.style';

export interface LabelsStatusAlertProps {
  onClick: () => void;
  actionButtonLabel: string;
  message: JSX.Element | string;
  severity: 'info' | 'warning';
}

export const LabelsStatusAlert = ({
  onClick,
  actionButtonLabel,
  message,
  severity,
}: LabelsStatusAlertProps) => {
  const { classes } = useLabelsStatusStyles();

  return (
    <Alert
      severity={severity}
      className={classes.labelsStatus}
      action={
        <Button onClick={onClick} variant="text" color="secondary">
          {actionButtonLabel}
        </Button>
      }
    >
      {message}
    </Alert>
  );
};
