import { createSvgIcon } from '@mui/material/utils';

export const FacilitySettingsIcon = () => {
  const Icon = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M13.228 15.333a1.91 1.91 0 0 1 1.905-1.904 1.91 1.91 0 0 1 1.905 1.904 1.91 1.91 0 0 1-1.905 1.905 1.91 1.91 0 0 1-1.905-1.905Zm6.857.648c.029-.21.048-.429.048-.648 0-.219-.02-.438-.048-.657l1.41-1.105a.337.337 0 0 0 .076-.428l-1.334-2.305a.337.337 0 0 0-.409-.143l-1.657.667a4.895 4.895 0 0 0-1.124-.657l-.248-1.762a.346.346 0 0 0-.333-.276H13.8a.346.346 0 0 0-.333.285l-.247 1.762c-.4.172-.781.39-1.124.657l-1.658-.666a.337.337 0 0 0-.409.143l-1.333 2.304a.336.336 0 0 0 .076.429l1.41 1.105c-.03.21-.048.428-.048.647 0 .22.019.438.047.658l-1.41 1.104a.337.337 0 0 0-.075.429l1.333 2.305c.086.142.257.2.41.142l1.657-.666c.342.266.714.486 1.123.657l.248 1.762A.347.347 0 0 0 13.8 22h2.667a.346.346 0 0 0 .333-.286l.248-1.762c.4-.171.781-.39 1.124-.657l1.657.667c.152.057.324 0 .41-.143l1.333-2.305a.337.337 0 0 0-.076-.428l-1.41-1.105Z"
      />
      <path
        fill="currentColor"
        d="M9.576 2 1.03 5.65v12.54h4.213v-8.095h3.022l.124-.214.008-.013a2.242 2.242 0 0 1 2.712-.947l.021.008.396.16.061-.435.006-.033a2.25 2.25 0 0 1 2.209-1.86h2.667a2.24 2.24 0 0 1 1.704.789v-1.9L9.576 2Z"
      />
    </svg>,
    'FacilitySettingIcon',
  );

  return <Icon />;
};
