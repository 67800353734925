import React, { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AlertColor, Theme, useTheme } from '@mui/material';
import isNil from 'lodash/isNil';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import CheckIcon from '@mui/icons-material/Check';
import CloudOffIcon from '@mui/icons-material/CloudOff';

import { Box } from 'components/common/Box';
import { PageHeaderSection } from 'components/Page/PageHeaderSection';
import { AlertComponent } from 'components/common/Alert';
import { GroundControlActionNames } from 'store/GroundControl/groundControlLevelStore.model';
import { useGroundControlStore } from '../../../../store/GroundControl/groundControlLevelStore';

import { FleetSummaryCard } from './FleetSummaryCard';
import { NoFlyZonesCard } from './NoFlyZonesCard';
import { DroneShiftsCard } from './DroneShiftsCard';
import { FlightDomainStatusCard } from './FlightDomainStatusCard';

import {
  getDroneShiftsCardData,
  getFleetCardData,
  getFlightDomainStatusCardData,
} from './FlightDomainOverviewFunctions';
import { userHasPermission } from '../../../../features/permissions/userHasPermission';
import { PERMISSION } from '../../../../features/permissions/permissions.model';

export const Overview = () => {
  const { stateGroundControl, dispatchGroundControlLevel } = useGroundControlStore();

  const { systemId = '', flightDomainId = '' } = useParams();
  const navigate = useNavigate();
  const theme = useTheme() as Theme;
  const { flightDomain, isExecutingEmergencyLanding, isExecutingReturnHome, dronesFlying } =
    stateGroundControl;

  const isNoFlyZoneCardVisible = userHasPermission(PERMISSION.NO_FLY_ZONE_MANAGEMENT);
  const isDroneShiftsCardVisible = userHasPermission(PERMISSION.VIEW_DRONE_FLIGHT_HOURS);
  const isFlightDomainStatusCardVisible = userHasPermission(PERMISSION.VIEW_LOCK_STATUS);
  const isFleetInfoCardVisible = userHasPermission(PERMISSION.VIEW_FLEET_INFO);
  const isFleetGridVisible = isFlightDomainStatusCardVisible || isFleetInfoCardVisible;
  const isDroneGridVisible = isDroneShiftsCardVisible || isNoFlyZoneCardVisible;

  const flightDomainStatusData = getFlightDomainStatusCardData(flightDomain);
  const fleetData = getFleetCardData({
    systemId,
    nDronesLanded: flightDomain.fleet_status_summary?.num_drones_landed_in_error || -1,
    flightDomain,
    isExecutingEmergencyLanding,
    isExecutingReturnHome,
    dronesFlying,
    navigate,
    theme,
  });

  const droneShiftsData = flightDomain.flight_domain_status
    ? getDroneShiftsCardData(
        systemId,
        flightDomain.flight_domain_id,
        flightDomain.flight_domain_status,
        navigate,
      )
    : null;
  const dataIsAvailable =
    !!flightDomain.fleet_status_summary && !!flightDomain.flight_domain_status;

  const setSafetyRibbon = useCallback(
    (message: string) => {
      dispatchGroundControlLevel({
        type: GroundControlActionNames.SET_ALERT_INFO,
        payload: {
          key: 'main',
          variant: stateGroundControl.activeAlertVariant,
          message: `${stateGroundControl.flightDomain.flight_domain_name} - ${message}`,
        },
      });
    },
    [
      dispatchGroundControlLevel,
      stateGroundControl.activeAlertVariant,
      stateGroundControl.flightDomain.flight_domain_name,
    ],
  );

  const handleSafetyRibbon = useCallback(() => {
    if (isExecutingEmergencyLanding) {
      setSafetyRibbon(
        'Drones are still flying. Wait for them to land in place. Proceed with caution, find the drones and put them back to their chargers.',
      );
      return;
    }

    if (isExecutingReturnHome) {
      setSafetyRibbon('Drones are still flying. Wait for them to reach their chargers.');
      return;
    }

    if (dronesFlying) {
      setSafetyRibbon('Drones are flying. Proceed with caution.');
      return;
    }

    if (
      flightDomain.fleet_status_summary &&
      flightDomain.fleet_status_summary?.num_drones_landed_in_error > 0
    ) {
      setSafetyRibbon('Drones have landed in place or are in error state.');
      return;
    }

    if (!flightDomain.flight_domain_status?.locked) {
      setSafetyRibbon('Drones are enabled and allowed to fly. Proceed with caution.');
      return;
    }

    setSafetyRibbon('Drones are on their chargers.');
  }, [
    dronesFlying,
    flightDomain.fleet_status_summary,
    flightDomain.flight_domain_status?.locked,
    isExecutingEmergencyLanding,
    isExecutingReturnHome,
    setSafetyRibbon,
  ]);

  useEffect(() => {
    handleSafetyRibbon();
  }, [handleSafetyRibbon]);

  return (
    <div>
      <PageHeaderSection
        title="Ground control overview"
        subtitle={`Here is the overview for ${stateGroundControl.flightDomain.flight_domain_name}.`}
      />

      <Container maxWidth="xl" sx={{ paddingTop: '32px' }}>
        <Box mb={3}>
          {dataIsAvailable ? (
            <AlertComponent
              isActive={stateGroundControl.alertInfo.main.isOpened}
              iconMapping={{
                info: <CheckIcon fontSize="inherit" />,
                warning: <DoNotDisturbIcon fontSize="inherit" />,
                error: <DoNotDisturbIcon fontSize="inherit" />,
              }}
              status={stateGroundControl.alertInfo.main.variant as AlertColor}
              message={stateGroundControl.alertInfo.main.message ?? ''}
              handleClose={() =>
                dispatchGroundControlLevel({
                  type: GroundControlActionNames.SET_ALERT_INFO,
                  payload: {
                    key: 'main',
                  },
                })
              }
              preventClose={true}
              testId="c-flight-domain-overview-card-info-box"
            />
          ) : (
            <AlertComponent
              isActive={true}
              iconMapping={{
                info: <CheckIcon fontSize="inherit" />,
                warning: <DoNotDisturbIcon fontSize="inherit" />,
                error: <CloudOffIcon fontSize="inherit" />,
              }}
              status="error"
              message="No data available. Proceed with caution."
              preventClose={true}
              testId="c-flight-domain-overview-card-info-box"
            />
          )}
        </Box>

        <Grid spacing={3} container>
          {isFleetGridVisible && (
            <Grid flexDirection="column" display="flex" md={6} item>
              {isFlightDomainStatusCardVisible && (
                <Grid mb={3} item>
                  <FlightDomainStatusCard
                    dronesFlying={stateGroundControl.dronesFlying}
                    flightDomain={flightDomain}
                    flightDomainId={flightDomainId || ''}
                    isExecutingEmergencyLanding={isExecutingEmergencyLanding}
                    isExecutingReturnHome={isExecutingReturnHome}
                    flightDomainStatusData={flightDomainStatusData}
                    noData={isNil(stateGroundControl.flightDomain.flight_domain_status)}
                  />
                </Grid>
              )}

              {isFleetInfoCardVisible && (
                <Grid item>
                  <FleetSummaryCard
                    nDronesLanded={
                      flightDomain.fleet_status_summary?.num_drones_landed_in_error || 0
                    }
                    isExecutingEmergencyLanding={isExecutingEmergencyLanding}
                    isExecutingReturnHome={isExecutingReturnHome}
                    dronesFlying={dronesFlying}
                    fleetData={fleetData}
                    noData={isNil(flightDomain.fleet_status_summary)}
                  />
                </Grid>
              )}
            </Grid>
          )}

          {isDroneGridVisible && (
            <Grid display="flex" flexDirection="column" md={6} item>
              {isDroneShiftsCardVisible && (
                <Grid mb={3} item>
                  <DroneShiftsCard
                    droneShiftsData={droneShiftsData}
                    noData={isNil(droneShiftsData)}
                  />
                </Grid>
              )}

              {isNoFlyZoneCardVisible && (
                <Grid item>
                  <NoFlyZonesCard systemId={systemId} flightDomainId={flightDomainId} />
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      </Container>
    </div>
  );
};
