import { IWMSSlotStatusSTStateEnum, IVeritySlotStatusStateST } from 'codegen/location_information';
import { DISPLAY_VERITY_STATES } from 'common/slotStates';

export const getVerityValuesAppliedByIssueLogicLocationWithIssue = ({
  wmsState,
  verityState,
  contentFound,
}: {
  wmsState?: IWMSSlotStatusSTStateEnum;
  verityState?: IVeritySlotStatusStateST;
  contentFound: string[];
}) => {
  if (wmsState === 'BARCODE' && verityState === 'EMPTY') {
    return {
      verityState,
      contentFound: [DISPLAY_VERITY_STATES.EMPTY],
    };
  }
  return {
    verityState,
    contentFound,
  };
};
