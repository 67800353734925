import { IWMSSlotStatusST } from 'codegen/location_information';
import { ILocationDataST } from 'codegen/report';
import { ILocationData } from 'store/Modals/facilityModals/IFacilityModalsStore';

export const getWmsState = (locationData: ILocationData | ILocationDataST) => {
  if (Object.hasOwn(locationData, 'wmsState')) {
    return (locationData as ILocationData)?.wmsState;
  }
  const wmsStatus = (locationData as ILocationDataST)?.wms_status as IWMSSlotStatusST;
  return wmsStatus?.state;
};
