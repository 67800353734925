export enum PERMISSION {
  // Warehouse status permissions
  VIEW_WAREHOUSE_STATUS = 'VIEW_WAREHOUSE_STATUS',
  VIEW_LOCATION_DETAILS = 'VIEW_LOCATION_DETAILS',
  SHARE_FEEDBACK_ON_LOCATION = 'SHARE_FEEDBACK_ON_LOCATION',
  ADVANCED_SHARE_FEEDBACK_ON_LOCATION = 'ADVANCED_SHARE_FEEDBACK_ON_LOCATION',
  EXCLUDE_INCLUDE_LOCATIONS = 'EXCLUDE_INCLUDE_LOCATIONS',
  VIEW_LOCATION_STATUS = 'VIEW_LOCATION_STATUS',
  VIEW_LOCATION_DIAGNOSTICS = 'VIEW_LOCATION_DIAGNOSTICS',
  VIEW_ANALYTICS = 'VIEW_ANALYTICS',
  EXPORT_WAREHOUSE_STATUS_VIA_EMAIL = 'EXPORT_WAREHOUSE_STATUS_VIA_EMAIL',
  SNOOZE_LOCATION = 'SNOOZE_LOCATION',
  AMEND_LOCATION = 'AMEND_LOCATION',

  // Reports permissions
  SCHEDULED_REPORTS_MANAGEMENT = 'SCHEDULED_REPORTS_MANAGEMENT',
  SCHEDULE_HOURLY_REPORTS = 'SCHEDULE_HOURLY_REPORTS',
  VIEW_ONGOING_REPORTS = 'VIEW_ONGOING_REPORTS',
  ABORT_ONGOING_REPORTS = 'ABORT_ONGOING_REPORTS',
  VIEW_FINISHED_REPORTS = 'VIEW_FINISHED_REPORTS',
  EXPORT_FINISHED_REPORTS = 'EXPORT_FINISHED_REPORTS',
  REPORTS_FOR_REVIEW_MANAGEMENT = 'REPORTS_FOR_REVIEW_MANAGEMENT',
  ARCHIVED_REPORTS_MANAGEMENT = 'ARCHIVED_REPORTS_MANAGEMENT',
  UPDATE_REPORTS = 'UPDATE_REPORTS',
  SHOW_POTENTIAL_REPORT_ISSUES = 'SHOW_POTENTIAL_REPORT_ISSUES',
  AMEND_REPORT = 'AMEND_REPORT',
  PRIORITY_PICKER_INTERNAL = 'PRIORITY_PICKER_INTERNAL',

  WMS_MANAGEMENT = 'WMS_MANAGEMENT',

  // Safety permissions
  VIEW_LOCK_STATUS = 'VIEW_LOCK_STATUS',
  LOCK_SYSTEM = 'LOCK_SYSTEM',
  UNLOCK_SYSTEM = 'UNLOCK_SYSTEM',
  SCHEDULE_LOCK = 'SCHEDULE_LOCK',
  GENERATE_QR_CODE = 'GENERATE_QR_CODE',
  NO_FLY_ZONE_MANAGEMENT = 'NO_FLY_ZONE_MANAGEMENT',
  DRONE_ZONES_MANAGEMENT = 'DRONE_ZONES_MANAGEMENT',
  VIEW_FLEET_INFO = 'VIEW_FLEET_INFO',
  VIEW_DRONE_FLIGHT_HOURS = 'VIEW_DRONE_FLIGHT_HOURS',
  EDIT_DRONE_FLIGHT_HOURS = 'EDIT_DRONE_FLIGHT_HOURS',
  INITIATE_SAFETY_LANDING = 'INITIATE_SAFETY_LANDING',

  FACILITY_MANAGEMENT = 'FACILITY_MANAGEMENT',

  // User management
  USER_MANAGEMENT = 'USER_MANAGEMENT',
  GRANT_ALL_FACILITIES = 'GRANT_ALL_FACILITIES',
  SEE_ALL_FACILITIES = 'SEE_ALL_FACILITIES',

  DELIVERY_MANAGEMENT = 'DELIVERY_MANAGEMENT',
}

export type Permission = keyof typeof PERMISSION;

export const reportsPermissions: Permission[] = [
  PERMISSION.SCHEDULED_REPORTS_MANAGEMENT,
  PERMISSION.VIEW_ONGOING_REPORTS,
  PERMISSION.ABORT_ONGOING_REPORTS,
  PERMISSION.VIEW_FINISHED_REPORTS,
  PERMISSION.EXPORT_FINISHED_REPORTS,
  PERMISSION.REPORTS_FOR_REVIEW_MANAGEMENT,
  PERMISSION.ARCHIVED_REPORTS_MANAGEMENT,
  PERMISSION.UPDATE_REPORTS,
  PERMISSION.SHOW_POTENTIAL_REPORT_ISSUES,
  PERMISSION.AMEND_REPORT,
];

export const overviewPagePermissions: Permission[] = [
  PERMISSION.REPORTS_FOR_REVIEW_MANAGEMENT,
  PERMISSION.SCHEDULED_REPORTS_MANAGEMENT,
  PERMISSION.VIEW_ONGOING_REPORTS,
  PERMISSION.VIEW_FINISHED_REPORTS,
  PERMISSION.WMS_MANAGEMENT,
  PERMISSION.VIEW_WAREHOUSE_STATUS,
];

export const safetyPermissions: Permission[] = [
  PERMISSION.VIEW_LOCK_STATUS,
  PERMISSION.LOCK_SYSTEM,
  PERMISSION.UNLOCK_SYSTEM,
  PERMISSION.SCHEDULE_LOCK,
  PERMISSION.GENERATE_QR_CODE,
  PERMISSION.NO_FLY_ZONE_MANAGEMENT,
  PERMISSION.DRONE_ZONES_MANAGEMENT,
  PERMISSION.VIEW_FLEET_INFO,
  PERMISSION.VIEW_DRONE_FLIGHT_HOURS,
  PERMISSION.EDIT_DRONE_FLIGHT_HOURS,
  PERMISSION.INITIATE_SAFETY_LANDING,
];
