import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

// this is the padding offset to for the components, 18 is because
// baseCard already has 6 which we need to account for [TC]
const PADDING_OFFSET = 18;

export const useMap3DCard = makeStyles()((theme: Theme) => ({
  canvasWrapper: {
    display: 'flex',
    minHeight: '30vh',
    height: '100%',
    position: 'relative',
    containerType: 'size',
    paddingBottom: PADDING_OFFSET,
    transform: 'translateZ(0)', // bump to new stacking context for fixed position elements
  },
  canvas: {
    minHeight: '30vh',
    borderRadius: 2 * theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
  },
  aisleSummary: {
    position: 'absolute',
    left: theme.spacing(1.5),
    maxWidth: '80%',
    [theme.breakpoints.down('lg')]: {
      flexWrap: 'wrap',
    },
  },
  zoomControls: {
    position: 'absolute',
    right: theme.spacing(1.5),
  },
  bottomDistance: {
    bottom: `calc(${theme.spacing(1.5)} + ${PADDING_OFFSET}px)`,
  },
  drawerPaper: {
    height: `calc(100% - ${PADDING_OFFSET}px)`,
  },
}));
