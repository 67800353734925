import { PageHeaderSection } from 'components/Page/PageHeaderSection';
import { useParams } from 'react-router-dom';
import { useFacilityMap } from 'shared/map-3d/aisle-view/api/useFacilityMap';
import { Spinner } from 'components/common/Spinner';
import { Map3DCanvas } from 'shared/map-3d/map-3d-canvas/Map3DCanvas';
import { useAisleView } from 'shared/map-3d/aisle-view/hooks/useAisleView';
import { AisleView } from 'shared/map-3d/aisle-view/AisleView';
import MuiBox from '@mui/material/Box';
import { Card, Typography } from '@mui/material';
import { AisleSummary } from 'shared/map-3d/aisle-summary/AisleSummary';
import { ErrorBoundary } from 'react-error-boundary';
import { useLocationData } from '../warehouse-status-beta/hooks/useLocationData';
import { useWarehouseStatus3DStyle } from './styles/warehouseStatus3D.style';

export const WarehouseStatus3D = () => {
  const { classes } = useWarehouseStatus3DStyle();
  const { systemId = '' } = useParams<{ systemId: string }>();
  const { isLoadingLocations, locationData } = useLocationData(systemId);
  const { data: facilityMap, isLoading } = useFacilityMap(systemId ?? '');
  const { aisleBins, summary = [] } = useAisleView({
    facilityMap,
    allLocations: locationData,
    currentLocationName: locationData[0] ? locationData[0].slot_label : '',
  });
  return (
    <>
      <PageHeaderSection
        title="Warehouse map"
        subtitle="See surrounding issues and navigate to other locations"
      />
      <div className={classes.pageWrapper}>
        {isLoading || isLoadingLocations ? (
          <Spinner />
        ) : (
          <Card className={classes.card}>
            <ErrorBoundary
              fallback={
                <Typography
                  color="textSecondary"
                  textAlign="center"
                  variant="h6"
                  component="p"
                  gutterBottom
                >
                  Sorry an error occured loading the map
                </Typography>
              }
            >
              <MuiBox className={classes.wrapper}>
                <Map3DCanvas className={classes.canvas}>
                  <AisleView bins={aisleBins} fit={{ type: 'height' }} />
                </Map3DCanvas>
                <AisleSummary className={classes.aisleSummary} issues={summary} />
              </MuiBox>
            </ErrorBoundary>
          </Card>
        )}
      </div>
    </>
  );
};
