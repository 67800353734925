import Button from '@mui/material/Button';
import { ReactNode } from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { useLabelsRadioButtonStyles } from './LabelsRadioButton.style';

export const LabelsRadioButton = ({
  children,
  onClick,
  selected = false,
}: {
  children: ReactNode;
  onClick?: () => void;
  selected?: boolean;
}) => {
  const { classes } = useLabelsRadioButtonStyles();

  const icon = selected ? (
    <CheckBoxIcon titleAccess="Radio button checked" />
  ) : (
    <CheckBoxOutlineBlankIcon titleAccess="Radio button unchecked" />
  );

  return (
    <Button className={classes.labelsRadioButton} startIcon={icon} size="medium" onClick={onClick}>
      {children}
    </Button>
  );
};
