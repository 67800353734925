import { ILocationDataST, ILocationLabelST } from 'codegen/warehouse_status';
import { getLatestOverwriteFromSlotStatus } from 'common/functions/slot/utils/getLatestOverwriteFromSlotStatus';

export const calculateTabCounts = ({
  locationDataList,
  countAmended = false,
  countLabeled = false,
}: {
  locationDataList: ILocationDataST[];
  countAmended?: boolean;
  countLabeled?: boolean;
}) => {
  const counters = {
    'FULL REPORT': locationDataList.length,
    ISSUES: 0,
    POTENTIAL: 0,
    INCONCLUSIVE: 0,
    EXCLUDED: 0,
    SNOOZED: 0,
    INVALID: 0,
    AMENDED: 0,
    LABELED: 0,
  };

  locationDataList.forEach((locationData) => {
    if (countAmended && getLatestOverwriteFromSlotStatus(locationData.verity_status, 'client')) {
      counters.AMENDED += 1;
    }

    if (locationData.slot_settings?.exclusion_status === 'EXCLUDE') {
      counters.EXCLUDED += 1;
    }

    const issueState = locationData.issues?.[0]?.state;
    const issueType = locationData.issues?.[0]?.type;

    const snoozedCondition = issueState === 'SNOOZED';
    const potentialCondition =
      issueType === 'INCONCLUSIVE1_W_BC_V_NOTEMPTY' || issueType === 'C001C_MISSING_BC';
    const inconclusiveCondition =
      issueType === 'INCONCLUSIVE2_W_BC_V_NORESULT' ||
      issueType === 'INCONCLUSIVE3_W_EMPTY_V_NORESULT' ||
      issueType === 'INCONCLUSIVE5_V_INVALID';
    const invalidCondition = issueType === 'INCONCLUSIVE4_W_INVALID';

    const countIssues = () => {
      if (snoozedCondition) {
        counters.SNOOZED += 1;
      } else if (potentialCondition) {
        counters.POTENTIAL += 1;
      } else if (inconclusiveCondition) {
        counters.INCONCLUSIVE += 1;
      } else if (invalidCondition) {
        counters.INVALID += 1;
      } else {
        counters.ISSUES += 1;
      }
    };

    if (locationData.issues?.length > 0 && issueState !== 'SOLVED') {
      countIssues();
    }

    if (countLabeled && (locationData.location_label as ILocationLabelST)?.status === 'ACTIVE') {
      counters.LABELED += 1;
    }
  });

  return counters;
};
