import { TextField } from '@mui/material';
import { Field, FieldProps } from 'formik';
import { useEffect, useState } from 'react';
import { useMeasurementSystem } from 'udb/ground-control/hooks/useMeasurementSystem';
import { formatDisplayValue } from 'udb/ground-control/utils/formatDisplayValue';
import { FormInputDistancesProps } from './FormInputDistances.model';
import { validateInput } from './utils/validateInput';
import { shouldIgnoreInput } from './utils/shouldIgnoreInput';
import { getLabel } from './utils/getLabel';
import { getConvertedEvent } from './utils/getConvertedEvent';
import { DEFAULT_DECIMAL_PLACES } from '../defaults/FormInput.defaults';

export const FormInputDistances = ({
  name,
  placeholder = '',
  label = '',
  size = 'medium',
  decimalPlaces = DEFAULT_DECIMAL_PLACES,
  onChange,
  onBlur,
  isMaxVisible = false,
  isMinVisible = false,
  initialValue,
  value: controlledValue,
  setFieldError,
  ...rest
}: FormInputDistancesProps) => {
  const { unit, isMetricSystem } = useMeasurementSystem();
  const [displayedValue, setDisplayedValue] = useState(
    formatDisplayValue(initialValue, { decimalPlaces, isMetricSystem }),
  );
  const [error, setError] = useState<string | undefined>();

  useEffect(() => {
    if (!controlledValue) {
      return;
    }

    setDisplayedValue(formatDisplayValue(controlledValue, { decimalPlaces, isMetricSystem }));
  }, [controlledValue, isMetricSystem, decimalPlaces]);

  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    field: FieldProps['field'],
  ) => {
    const { value } = event.target;

    if (shouldIgnoreInput(value, { isMetricSystem })) {
      return;
    }

    const currError = validateInput({ value, isMetricSystem, decimalPlaces });
    setError(currError);
    setFieldError(currError);

    setDisplayedValue(value);

    if (currError) {
      return;
    }

    const convertedEvent = getConvertedEvent(name, event, { isMetricSystem });
    field.onChange(convertedEvent);
    onChange?.(convertedEvent);
  };

  const handleBlur = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    field: FieldProps['field'],
  ) => {
    field.onBlur(event);

    if (onBlur) {
      onBlur(event);
    }
  };

  return (
    <Field name={name}>
      {({ field, meta }: FieldProps) => (
        <TextField
          name={name}
          value={displayedValue}
          placeholder={placeholder}
          label={getLabel({
            baseLabel: label,
            unit,
            decimalPlaces,
            isMetricSystem,
            extra: {
              max: rest.inputProps?.max,
              isMaxVisible,
              min: rest.inputProps?.min,
              isMinVisible,
            },
          })}
          onChange={(event) => handleChange(event, field)}
          onBlur={(event) => handleBlur(event, field)}
          type={isMetricSystem ? 'number' : 'text'}
          size={size}
          error={!!meta.touched && !!(error || meta.error)}
          helperText={error || meta.error}
          {...rest}
        />
      )}
    </Field>
  );
};
