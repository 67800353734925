import { sort } from 'common/functions/otherFunctions';
import { IReportSpecification1ST } from 'codegen/report_specification';
import { reportServices } from '../../../api/ReportServices';

/**
 * GET ALL REPORT SPECIFICATIONS
 * @param systemId ID of the facility the specification are read for
 * @returns fullResponse - route raw response
 * reportSpecifications - all specifications sorted in ascending order by request_default_name
 */
export const getReportSpecifications = (systemId: string) =>
  reportServices.getReportSpecifications(systemId).then((r) => {
    const reportSpecifications = sort({
      array: r.data.items,
      sortingOrder: 'asc',
      sortBy: 'request_default_name',
    }) as unknown as {
      array: Array<IReportSpecification1ST>;
      sortingOrder: string;
      sortBy: string;
    };

    return {
      fullResponse: r,
      reportSpecifications,
    };
  });
