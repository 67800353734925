import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

export const useImageGridStyles = makeStyles()((theme: Theme) => ({
  paper: {
    width: '100%',
    borderRadius: theme.spacing(2),
  },
  spinner: {
    position: 'relative',
    minHeight: '400px',
  },
  imageContainer: {
    minHeight: '200px',
    display: 'flex',
    alignItems: 'center',
  },
  imageContainerText: {
    width: '80%',
    marginLeft: '10%',
  },
}));
