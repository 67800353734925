import { geometryToBottomLeftDimensions } from 'shared/map-container/utils/creation.util';

import { NoFlyZone } from 'shared/map/model/no-fly-zone.model';
import { DroneZoneTypes } from 'shared/map/model/drone-zones.model';
import { INoFlyZoneStatus1ST } from 'codegen/no_fly_zone';

export const transformIntoNoFlyZone = (nfz: INoFlyZoneStatus1ST & { id: string }): NoFlyZone => {
  const { w, l, h, minX, minY, minZ, maxZ } = geometryToBottomLeftDimensions(nfz.geometry);

  return {
    id: nfz.id,
    type: DroneZoneTypes.noFlyZone,
    description: nfz.description,
    name: nfz.name,
    isActive: !nfz.drone_allowed_in_zone,
    lastModifiedAt: nfz.last_zone_permission_change_time,
    sizeAndPosition: { w, l, h, minX, minY, minZ, maxZ },
    isSelected: false,
  };
};
