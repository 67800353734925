import { Vec6 } from 'shared/map-container/MapContainer.model';
import { useMeasurementSystem } from 'udb/ground-control/hooks/useMeasurementSystem';
import { NoFlyZone } from 'shared/map/model/no-fly-zone.model';
import { RecOfStrings } from 'ts-types/Utility';
import { BottomLeftDimensions } from '../../../model/NoFlyZoneGeometry.model';
import { validateSizeAndPositionFitsWithinWorldBox } from '../NoFlyZoneForm/validateSizeAndPositionFitsWithinWorldBox';

export const useValidateDroneZoneForm = (
  zoneName: string,
): {
  validate: (worldBox: Vec6) => (values: NoFlyZone) => RecOfStrings<NoFlyZone>;
} => {
  const { isMetricSystem } = useMeasurementSystem();

  const validate = (worldBox: Vec6) => (values: NoFlyZone) => {
    let errors: RecOfStrings<NoFlyZone> = {};

    if (!values.name.trim().length) {
      errors = {
        ...errors,
        name: `A name is required to create a ${zoneName} zone.`,
      };
    }

    Object.keys(values.sizeAndPosition).forEach((key) => {
      if (zoneName === 'controlled' && key === 'h') return;
      if (zoneName === 'no-fly' && (key === 'maxZ' || key === 'minZ')) return;

      const validationMessage: string | null = validateSizeAndPositionFitsWithinWorldBox({
        zoneVectors: values.sizeAndPosition,
        facilityVectors: worldBox,
        fieldKey: key as keyof BottomLeftDimensions,
        isMetricSystem,
      });

      if (validationMessage) {
        errors = {
          ...errors,
          sizeAndPosition: {
            ...errors.sizeAndPosition,
            [key]: validationMessage,
          },
        };
      }
    });

    return errors;
  };

  return { validate };
};
