import { styled } from '@mui/material/styles';

export const DrawerList = styled('ul')<{ enableOffset: boolean; hasMultipleFacilities: boolean }>(
  ({ theme, enableOffset, hasMultipleFacilities }) => ({
    marginTop: !hasMultipleFacilities && !enableOffset ? 60 : 0,
    padding: '0px 0px 60px',
    overflowX: 'hidden',
    position: 'relative',
    visibility: 'visible',
    paddingTop: 0,
    transition: theme.transitions.create(['margin', 'visibility'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  }),
);
