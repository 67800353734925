import LockIcon from '@mui/icons-material/Lock';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import { TooltipedIcon } from 'components/common/TooltipedIcon';
import { NoAccessIcon } from '../Icons/NoAccessIcon';

type GroundControlSideIconProps = {
  isLocked?: boolean;
  isInCoexistence?: boolean;
  isDroneOnly?: boolean | null;
};

export const GroundControlSideIcon = ({
  isLocked,
  isInCoexistence,
  isDroneOnly,
}: GroundControlSideIconProps) => {
  if (isLocked) {
    return <LockIcon sx={{ color: 'inherit', fontSize: 'inherit' }} />;
  }

  if (!isInCoexistence || isDroneOnly) {
    return (
      <TooltipedIcon
        data-testid="c-icon-drone-only"
        icon={<NoAccessIcon />}
        tooltip="Do not enter"
      />
    );
  }

  return (
    <TooltipedIcon
      data-testid="c-icon-coexistence"
      icon={<DirectionsWalkIcon sx={{ color: 'inherit', fontSize: 'inherit' }} />}
      tooltip="Drones are mixed with people"
    />
  );
};
