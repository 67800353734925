import { IReportSpecificationST } from 'codegen/report_specification';
import moment from 'moment';
import { ScheduleFormAction } from '../reducers/ScheduleForm.model';

export const handleStartTimeChange = (
  dateFrom: moment.MomentInput,
  allowPastStartDate: boolean,
  reportSpec: IReportSpecificationST,
  dispatch: (value: ScheduleFormAction) => void,
): void => {
  moment(dateFrom).isValid() &&
    dispatch({
      type: 'SET_DATE_FROM',
      payload: { dateFrom, reportSpec, allowPastStartDate },
    });
};
