import {
  getGridBooleanOperators,
  getGridDateOperators,
  getGridStringOperators,
  GridColDef,
  GridTreeNode,
} from '@mui/x-data-grid-premium';
import { IFacilitySettingsST } from 'codegen/facility_settings';
import { ILocationDataST } from 'codegen/warehouse_status';
import { AllIssueTypes } from 'common/functions/issues/issueColorFunctions';
import {
  ContentExpectedCell,
  ContentFoundCell,
  ISSUE_STATE,
  IssueCell,
  IssueStateCell,
} from 'udb/inventory/features/premium-grid/features/cell';
import { ContentShouldBeAtCell } from 'udb/inventory/features/premium-grid/features/cell/ContentShouldBeAtCell';
import { ContentWasFoundAtCell } from 'udb/inventory/features/premium-grid/features/cell/ContentWasFoundAtCell';
import { TooltipedCell } from 'udb/inventory/features/premium-grid/features/cell/TooltipedCell';
import { WmsQuantityCell } from 'udb/inventory/features/premium-grid/features/cell/WmsQuantityCell';
import { dateTimeValueFormatter } from 'udb/inventory/features/premium-grid/utils/cell-value-getters/dateTimeValueFormatter';
import { firstFoundOnGroupingValueGetter } from 'udb/inventory/features/premium-grid/utils/cell-value-getters/firstFoundOnGroupingValueGetter';
import { firstFoundOnValueGetter } from 'udb/inventory/features/premium-grid/utils/cell-value-getters/firstFoundOnValueGetter';
import { foundDateGroupingValueGetter } from 'udb/inventory/features/premium-grid/utils/cell-value-getters/foundDateGroupingValueGetter';
import { foundDateValueGetter } from 'udb/inventory/features/premium-grid/utils/cell-value-getters/foundDateValueGetter';
import { issueStatusValueGetter } from 'udb/inventory/features/premium-grid/utils/cell-value-getters/issueStatusValueGetter';
import { issueValueGetter } from 'udb/inventory/features/premium-grid/utils/cell-value-getters/issueValueGetter';
import { unknownBarcodesPercentageValueGetter } from 'udb/inventory/features/premium-grid/utils/cell-value-getters/unknownBarcodesPercentageValueGetter';
import { unknownBarcodesValueGetter } from 'udb/inventory/features/premium-grid/utils/cell-value-getters/unknownBarcodesValueGetter';
import { matchingBarcodesPercentageValueGetter } from 'udb/inventory/features/premium-grid/utils/cell-value-getters/matchingBarcodesPercentageValueGetter';
import { matchingBarcodesValueGetter } from 'udb/inventory/features/premium-grid/utils/cell-value-getters/matchingBarcodesValueGetter';
import { missingBarcodesPercentageValueGetter } from 'udb/inventory/features/premium-grid/utils/cell-value-getters/missingBarcodesPercentageValueGetter';
import { missingBarcodesValueGetter } from 'udb/inventory/features/premium-grid/utils/cell-value-getters/missingBarcodesValueGetter';
import { foundBarcodesValueGetter } from 'udb/inventory/features/premium-grid/utils/cell-value-getters/foundBarcodesValueGetter';
import { expectedBarcodesValueGetter } from 'udb/inventory/features/premium-grid/utils/cell-value-getters/expectedBarcodesValueGetter';
import { shouldBeAtValueGetter } from 'udb/inventory/features/premium-grid/utils/cell-value-getters/shouldBeAtValueGetter';
import { contentFoundValueGetter } from 'udb/inventory/features/premium-grid/utils/cell-value-getters/contentFoundValueGetter';
import { wmsDateGroupingValueGetter } from 'udb/inventory/features/premium-grid/utils/cell-value-getters/wmsDateGroupingValueGetter';
import { wmsDateValueGetter } from 'udb/inventory/features/premium-grid/utils/cell-value-getters/wmsDateValueGetter';
import { customerValueGetter } from 'udb/inventory/features/premium-grid/utils/cell-value-getters/customerValueGetter';
import { wmsQuantityValueGetter } from 'udb/inventory/features/premium-grid/utils/cell-value-getters/wmsQuantityValueGetter';
import { wmsArticleNumberValueGetter } from 'udb/inventory/features/premium-grid/utils/cell-value-getters/wmsArticleNumberValueGetter';
import { wasFoundAtValueGetter } from 'udb/inventory/features/premium-grid/utils/cell-value-getters/wasFoundAtValueGetter';
import { expectedContentValueGetter } from 'udb/inventory/features/premium-grid/utils/cell-value-getters/expectedContentValueGetter';
import { notContainsOperator } from 'udb/inventory/features/premium-grid/utils/custom-filters/notContainsOperator';
import { userHasPermission } from 'features/permissions/userHasPermission';
import { PERMISSION } from 'features/permissions/permissions.model';
import { amendedValueGetter } from 'udb/inventory/features/premium-grid/utils/cell-value-getters/amendedValueGetter';
import { IsSelectedFilterOperator } from 'udb/inventory/features/premium-grid/utils/custom-filters/IsSelectedFilterOperator';
import { IsNotSelectedFilterOperator } from 'udb/inventory/features/premium-grid/utils/custom-filters/IsNotSelectedFilterOperator';
import { getIssueValueOptionsFromData } from 'udb/inventory/features/premium-grid/utils/getIssueValueOptionsFromData';
import { getIssueStateValueOptionsFromData } from 'udb/inventory/features/premium-grid/utils/getIssueStateValueOptionsFromData';
import { labelValueGetter } from 'udb/inventory/features/premium-grid/utils/cell-value-getters/labelValueGetter';
import { getLabelOptionsFromData } from 'udb/inventory/features/premium-grid/utils/getLabelOptionsFromData';

// The render cell function should only return true when its a leaf node and not a group node
// or when its the autogenerated expandable group column
const isNotGroupCell = (field: string, rowNode: GridTreeNode): boolean =>
  !(rowNode.type === 'group' && !field.startsWith('__row_group'));

const customGridStringOperators = getGridStringOperators().filter(
  ({ value }) => !['isEmpty', 'isNotEmpty'].includes(value),
);

const customGridDateOperators = getGridDateOperators().filter(
  ({ value }) => !['isEmpty', 'isNotEmpty'].includes(value),
);

const specColumns = (
  facilitySettings: IFacilitySettingsST,
  rows: ILocationDataST[],
  activeTab: number,
): GridColDef<ILocationDataST>[] => [
  {
    headerName: 'Location',
    field: 'slot_label',
    minWidth: 100,
    groupable: false,
    valueGetter: (_value, row) => row?.slot_label || '-',
    renderCell: (params) =>
      isNotGroupCell(params.field, params.rowNode) && <TooltipedCell value={params.value} />,
  },
  ...(facilitySettings.show_location_labels
    ? ([
        {
          headerName: 'Label',
          field: 'location_label',
          type: 'singleSelect',
          valueOptions: getLabelOptionsFromData(rows),
          valueGetter: (_value, row) => labelValueGetter(row),
          filterOperators: [
            IsSelectedFilterOperator,
            IsNotSelectedFilterOperator,
            ...customGridStringOperators,
            notContainsOperator,
          ],
        },
      ] as GridColDef<ILocationDataST>[])
    : []),
  {
    headerName: 'Content expected',
    field: 'wms_status.state',
    groupable: false,
    minWidth: 170,
    valueGetter: (_value, row) => expectedContentValueGetter(row),
    renderCell: (params) => (
      <ContentExpectedCell facilitySettings={facilitySettings} locationData={params.row} />
    ),
  },
  ...(facilitySettings.disable_found_and_should_be_at
    ? []
    : ([
        {
          headerName: 'Was found at',
          field: 'wasFoundAt',
          groupable: false,
          valueGetter: (_value, row) => wasFoundAtValueGetter(row),
          renderCell: (params) => <ContentWasFoundAtCell row={params.row} />,
        },
      ] as GridColDef<ILocationDataST>[])),
  {
    headerName: 'WMS article nr.',
    field: 'wms_status.article_nos.0',
    minWidth: 60,
    groupable: false,
    valueGetter: (_value, row) => wmsArticleNumberValueGetter(row),
    groupingValueGetter: (_value, row) => wmsArticleNumberValueGetter(row),
    renderCell: (params) =>
      isNotGroupCell(params.field, params.rowNode) && (
        <TooltipedCell value={params.formattedValue} />
      ),
  },
  {
    headerName: 'WMS quantity',
    field: 'wms_status.qtys.0',
    groupable: false,
    minWidth: 60,
    valueGetter: (_value, row) => wmsQuantityValueGetter(row),
    renderCell: (params) =>
      isNotGroupCell(params.field, params.rowNode) && <WmsQuantityCell row={params.row} />,
  },
  ...(facilitySettings.show_customer
    ? ([
        {
          headerName: 'Customer',
          field: 'wms_status.customers.0',
          groupable: true,
          width: 50,
          valueGetter: (_value, row) => customerValueGetter(row),
          groupingValueGetter: (_value, row) => customerValueGetter(row),
          renderCell: (params) =>
            isNotGroupCell(params.field, params.rowNode) && (
              <TooltipedCell value={params.formattedValue} />
            ),
        },
      ] as GridColDef<ILocationDataST>[])
    : []),
  {
    headerName: 'WMS date',
    field: 'wms_status.changed_at',
    minWidth: 130,
    type: 'dateTime',
    groupable: false,
    valueGetter: (_value, row) => wmsDateValueGetter(row),
    valueFormatter: dateTimeValueFormatter,
    groupingValueGetter: (_value, row) => wmsDateGroupingValueGetter(row),
    renderCell: (params) =>
      isNotGroupCell(params.field, params.rowNode) && (
        <TooltipedCell value={params.formattedValue} />
      ),
    filterOperators: customGridDateOperators,
  },
  {
    headerName: 'Content found',
    field: 'verity_status.state',
    groupable: false,
    minWidth: 170,
    valueGetter: (_value, row) => contentFoundValueGetter(row),
    renderCell: (params) => (
      <ContentFoundCell locationData={params.row} facilitySettings={facilitySettings} />
    ),
  },
  ...(facilitySettings.disable_found_and_should_be_at
    ? []
    : ([
        {
          headerName: 'Should be at',
          field: 'shouldBeAt',
          groupable: false,
          valueGetter: (_value, row) => shouldBeAtValueGetter(row),
          renderCell: (params) => <ContentShouldBeAtCell row={params.row} />,
        },
      ] as GridColDef<ILocationDataST>[])),
  {
    headerName: 'Expected Barcodes',
    field: 'expected_barcodes',
    groupable: false,
    minWidth: 60,
    valueGetter: (_value, row) => expectedBarcodesValueGetter(row),
  },
  {
    headerName: 'Found Barcodes',
    field: 'found_barcodes',
    groupable: false,
    minWidth: 60,
    valueGetter: (_value, row) => foundBarcodesValueGetter(row),
  },
  {
    headerName: 'Missing Barcodes',
    field: 'missing_barcodes',
    groupable: false,
    minWidth: 60,
    valueGetter: (_value, row) => missingBarcodesValueGetter(row),
  },
  {
    headerName: 'Missing Barcodes rate',
    field: 'missing_barcodes_rate',
    groupable: false,
    minWidth: 60,
    valueGetter: (_value, row) => missingBarcodesPercentageValueGetter(row),
  },
  {
    headerName: 'Unexpected Barcodes',
    field: 'unexpected_barcodes',
    groupable: false,
    minWidth: 60,
    valueGetter: (_value, row) => unknownBarcodesValueGetter(row),
  },
  {
    headerName: 'Unexpected Barcodes rate',
    field: 'unexpected_barcodes_rate',
    groupable: false,
    minWidth: 60,
    valueGetter: (_value, row) => unknownBarcodesPercentageValueGetter(row),
  },
  {
    headerName: 'Matching Barcodes',
    field: 'matching_barcodes',
    groupable: false,
    minWidth: 60,
    valueGetter: (_value, row) => matchingBarcodesValueGetter(row),
  },
  {
    headerName: 'Matching Barcodes rate',
    field: 'matching_barcodes_rate',
    groupable: false,
    minWidth: 60,
    valueGetter: (_value, row) => matchingBarcodesPercentageValueGetter(row),
  },
  {
    headerName: 'Found date',
    field: 'contentFoundDate',
    minWidth: 140,
    type: 'dateTime',
    groupable: false,
    valueGetter: (_value, row) => foundDateValueGetter(row),
    valueFormatter: dateTimeValueFormatter,
    groupingValueGetter: (_value, row) => foundDateGroupingValueGetter(row),
    renderCell: (params) =>
      isNotGroupCell(params.field, params.rowNode) && (
        <TooltipedCell value={params.formattedValue} />
      ),
    filterOperators: customGridDateOperators,
  },
  {
    headerName: 'First found on',
    field: 'issues.0.first_found_on',
    minWidth: 130,
    type: 'dateTime',
    groupable: false,
    valueGetter: (_value, row) => firstFoundOnValueGetter(row),
    valueFormatter: dateTimeValueFormatter,
    groupingValueGetter: (_value, row) => firstFoundOnGroupingValueGetter(row),
    renderCell: (params) =>
      isNotGroupCell(params.field, params.rowNode) && (
        <TooltipedCell value={params.formattedValue} />
      ),
    filterOperators: customGridDateOperators,
  },
  {
    headerName: 'Issue',
    field: 'issues.0.type',
    minWidth: 180,
    align: 'left',
    type: 'singleSelect',
    valueOptions: getIssueValueOptionsFromData(rows, activeTab),
    valueGetter: (value, row) => issueValueGetter(value, row),
    groupingValueGetter: (value, row) => issueValueGetter(value, row),
    filterOperators: [
      IsSelectedFilterOperator,
      IsNotSelectedFilterOperator,
      ...customGridStringOperators,
      notContainsOperator,
    ],
    renderCell: (params) =>
      isNotGroupCell(params.field, params.rowNode) && (
        <IssueCell issueType={(params.value as AllIssueTypes) || '-'} />
      ),
  },
  {
    headerName: 'Issue status',
    field: 'issues.0.state',
    minWidth: 90,
    type: 'singleSelect',
    valueOptions: getIssueStateValueOptionsFromData(rows, activeTab),
    align: 'left',
    valueGetter: (_value, row) => issueStatusValueGetter(row),
    groupingValueGetter: (_value, row) => issueStatusValueGetter(row),
    renderCell: (params) =>
      isNotGroupCell(params.field, params.rowNode) && (
        <IssueStateCell state={params.value as ISSUE_STATE} />
      ),
    filterOperators: [
      IsSelectedFilterOperator,
      IsNotSelectedFilterOperator,
      ...customGridStringOperators,
      notContainsOperator,
    ],
  },
  ...(userHasPermission(PERMISSION.AMEND_REPORT) && facilitySettings?.allow_user_amend
    ? ([
        {
          headerName: 'Amended',
          field: 'content_found_amended',
          groupable: true,
          minWidth: 60,
          valueGetter: (_value, row) => amendedValueGetter(row),
          filterOperators: getGridBooleanOperators(),
          renderCell: (params) => (params.value ? 'yes' : ''),
        },
      ] as GridColDef<ILocationDataST>[])
    : []),
];

export const getColumns = (
  facilitySettings: IFacilitySettingsST,
  rows: ILocationDataST[],
  activeTab: number,
): GridColDef[] =>
  specColumns(facilitySettings, rows, activeTab).map((col) => ({
    align: 'left',
    ...col,
    aggregable: false,
    headerClassName: 'datagrid-header',
    flex: 1,
    filterOperators: col.filterOperators ?? customGridStringOperators,
  }));
