import { Button, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import { Form, Formik, FormikProps } from 'formik';
import { useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import { DroneZoneTypes } from 'shared/map/model/drone-zones.model';
import { useValidateDroneZoneForm } from 'udb/ground-control/drone-zones/features/DroneZoneControls/hooks/useValidadeDroneZoneForm';
import { transformZoneSizeAndPosition } from 'udb/ground-control/utils/transformZoneSizeAndPosition';
import { FormInput } from 'components/FormFields/FormInput';
import { FormInputDistances } from 'components/FormFields/FormInputDistances/FormInputDistances';
import { NEW_DRONE_ZONE_ID } from 'shared/map/defaults/new-drone-zone-id';
import { mapFacilityVectors } from 'shared/map-container/utils/mapFacilityVectors.util';
import type { ControlledZone } from 'shared/map/model/controlled-zone.model';
import { DroneZoneFormProps } from './DroneZoneForm.model';
import { useStyles } from './DroneZoneForm.styles';

import { createControlledZone } from './utils/createControlledZone';
import { updateControlledZone } from './utils/updateControlledZone';
import { getHeight } from './utils/getHeight';

const DRONE_ZONE_TITLE_FROM_TYPE = {
  [DroneZoneTypes.controlledZone]: 'Controlled zone',
};

export const DroneZoneForm = ({
  flightDomainId,
  systemId,
  droneZone,
  worldBox,
  onCancel,
  onUpdate,
  onSubmit,
}: DroneZoneFormProps) => {
  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const formValues = useRef<FormikProps<ControlledZone>>(null);
  const originalDroneZone = useRef(droneZone);

  const [height, setHeight] = useState(
    droneZone ? droneZone.sizeAndPosition.maxZ - droneZone.sizeAndPosition.minZ : 0,
  );

  const { validate } = useValidateDroneZoneForm('controlled');

  const inputProps = { step: 0.5, min: 1 };
  // @ts-expect-error zone.type can include NFZ (as we use the same base for types), do we separate this for delivery and nondelivery types?
  const title = DRONE_ZONE_TITLE_FROM_TYPE[droneZone.type];

  const {
    minX: facilityMinX,
    maxX: facilityMaxX,
    minY: facilityMinY,
    maxY: facilityMaxY,
    maxZ: facilityMaxZ,
  } = mapFacilityVectors(worldBox);

  const defaultValues = {
    ...droneZone,
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let fieldName = e.target.name as keyof ControlledZone;
    let fieldValue: string | number | object = e.target.value;

    const isSizeOrPosition = fieldName.includes('size') || fieldName.includes('position');

    const droneZoneFormValues = formValues?.current?.values;

    if (isSizeOrPosition) {
      const fieldNameSplit = fieldName.split('.');
      fieldName = fieldNameSplit[0] as keyof ControlledZone;
      const keyName = fieldNameSplit[1] as keyof ControlledZone['sizeAndPosition'];

      const height = getHeight({
        maxZ: droneZoneFormValues?.sizeAndPosition.maxZ,
        minZ: droneZoneFormValues?.sizeAndPosition.minZ,
        [keyName]: fieldValue,
      });
      setHeight(height);

      fieldValue = {
        ...(droneZoneFormValues?.sizeAndPosition ?? {}),
        [keyName]: Number(fieldValue),
        h: height,
      };
    }

    if (droneZoneFormValues) {
      onUpdate({ ...droneZoneFormValues, [fieldName]: fieldValue });
    }
  };

  const updateDroneZone = async (droneZone: ControlledZone) => {
    let response;

    if (droneZone.type === DroneZoneTypes.controlledZone) {
      try {
        response = await updateControlledZone(droneZone, systemId, flightDomainId);
      } catch (error) {
        const err = error as AxiosError<Error, Error>;
        const message =
          err?.response?.data?.message ||
          'Something went wrong when updating drone "Controlled zone"';

        enqueueSnackbar(message, { variant: 'error' });
      }
    }

    if (response) {
      onSubmit(droneZone);
    }
  };

  const createDroneZone = async (droneZone: ControlledZone) => {
    let response;

    if (droneZone.type === DroneZoneTypes.controlledZone) {
      try {
        response = await createControlledZone(droneZone, systemId, flightDomainId);
      } catch (error) {
        const err = error as AxiosError<Error, Error>;
        const message =
          err?.response?.data?.message ||
          'Something went wrong when creating drone "Controlled zone"';

        enqueueSnackbar(message, { variant: 'error' });
      }
    }

    if (response) {
      onSubmit(droneZone);
    }
  };

  const handleSubmit = async (droneZone: ControlledZone) => {
    const isNew = droneZone.id === NEW_DRONE_ZONE_ID;
    const transformedDroneZone = transformZoneSizeAndPosition(droneZone);

    if (isNew) {
      await createDroneZone(transformedDroneZone);
    } else {
      await updateDroneZone(transformedDroneZone);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={defaultValues}
      innerRef={formValues}
      onSubmit={handleSubmit}
      validate={validate(worldBox)}
    >
      {({ values, isSubmitting, isValid, setFieldError }) => (
        <Form noValidate className={classes.form}>
          <Typography variant="h1" className={classes.formHeader}>
            {`${title} details`}
          </Typography>

          <div className={classes.formBody}>
            <FormInput
              name="name"
              label={`${title} name`}
              required
              onChange={handleChange}
              fullWidth
            />

            <FormInput
              name="description"
              label="Description"
              multiline
              fullWidth
              onChange={handleChange}
            />

            <div className={classes.formSection}>
              <FormInputDistances
                inputProps={{ ...inputProps, min: 0, max: facilityMaxZ }}
                name="sizeAndPosition.minZ"
                label="Bottom"
                fullWidth
                onChange={handleChange}
                isMaxVisible={true}
                initialValue={droneZone.sizeAndPosition.minZ}
                setFieldError={(errorMessage) =>
                  setFieldError('sizeAndPosition.minZ', errorMessage)
                }
              />

              <FormInputDistances
                inputProps={{ ...inputProps, min: 0, max: facilityMaxZ }}
                name="sizeAndPosition.maxZ"
                label="Top"
                fullWidth
                onChange={handleChange}
                isMaxVisible={true}
                initialValue={droneZone.sizeAndPosition.maxZ}
                setFieldError={(errorMessage) =>
                  setFieldError('sizeAndPosition.maxZ', errorMessage)
                }
              />
            </div>

            <FormInputDistances
              disabled
              label="Height"
              name="sizeAndPosition.h"
              helperText=""
              initialValue={height}
              value={height}
              error={false}
              fullWidth
              setFieldError={(errorMessage) => setFieldError('sizeAndPosition.h', errorMessage)}
            />

            <FormInputDistances
              name="sizeAndPosition.w"
              label="Width"
              inputProps={{
                ...inputProps,
                min: 0,
                max: facilityMaxX - values.sizeAndPosition.minX,
              }}
              fullWidth
              onChange={handleChange}
              initialValue={droneZone.sizeAndPosition.w}
              setFieldError={(errorMessage) => setFieldError('sizeAndPosition.w', errorMessage)}
            />

            <FormInputDistances
              name="sizeAndPosition.l"
              label="Length"
              inputProps={{
                ...inputProps,
                min: 0,
                max: facilityMaxY - values.sizeAndPosition.minY,
              }}
              fullWidth
              onChange={handleChange}
              initialValue={droneZone.sizeAndPosition.l}
              setFieldError={(errorMessage) => setFieldError('sizeAndPosition.l', errorMessage)}
            />

            <div className={classes.formSection}>
              <FormInputDistances
                name="sizeAndPosition.minX"
                inputProps={{
                  step: inputProps.step,
                  min: facilityMinX,
                  max: facilityMaxX - values.sizeAndPosition.w,
                }}
                onChange={handleChange}
                label="X-Position"
                fullWidth
                initialValue={droneZone.sizeAndPosition.minX}
                setFieldError={(errorMessage) =>
                  setFieldError('sizeAndPosition.minX', errorMessage)
                }
              />

              <FormInputDistances
                name="sizeAndPosition.minY"
                inputProps={{
                  step: inputProps.step,
                  min: facilityMinY,
                  max: facilityMaxY - values.sizeAndPosition.l,
                }}
                fullWidth
                onChange={handleChange}
                label="Y-Position"
                initialValue={droneZone.sizeAndPosition.minY}
                setFieldError={(errorMessage) =>
                  setFieldError('sizeAndPosition.minY', errorMessage)
                }
              />
            </div>
          </div>

          <div className={classes.formFooter}>
            <Button
              variant="outlined"
              sx={{ flex: 1, mr: 1 }}
              onClick={() => onCancel(originalDroneZone.current)}
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              sx={{ flex: 1 }}
              type="submit"
              disabled={!isValid || isSubmitting}
            >
              Save
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
