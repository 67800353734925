import Tooltip from '@mui/material/Tooltip';
import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Box } from 'components/common/Box';

const useStyles = makeStyles()((theme: Theme) => ({
  tooltipedIcon: {
    margin: theme.spacing(0, 1),
  },
  tooltip: {
    padding: 0,
    backgroundColor: 'transparent',
    maxWidth: 550,
    right: 65,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 490,
      right: 16,
    },
  },
}));

export const TooltipAlertIcon = (props: {
  tooltip: string | React.ReactNode;
  icon: string | React.ReactNode;
  text?: string;
}) => {
  const { tooltip, icon, text } = props;

  const { classes } = useStyles();

  return (
    <Tooltip placement="bottom-start" className={classes.tooltip} title={tooltip}>
      <Box className={classes.tooltipedIcon} display="flex" alignItems="center">
        <Box display="flex" alignItems="center">
          {icon}
        </Box>
        {text}
      </Box>
    </Tooltip>
  );
};
