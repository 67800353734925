import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { initialGridState } from 'udb/inventory/features/premium-grid/defaults/initialGridState.default';
import { ISSUE_TYPES } from 'common/issueTypesAndStates';
import { IIssueSTStateEnum, ISlotSettingsSTExclusionStatusEnum } from 'codegen/report';
import { ILocationDataST } from 'codegen/warehouse_status';
import { getLatestOverwriteFromSlotStatus } from 'common/functions/slot/utils/getLatestOverwriteFromSlotStatus';
import { userHasPermission } from 'features/permissions/userHasPermission';
import { PERMISSION } from 'features/permissions/permissions.model';
import { labelTypeEnumToText } from 'components/ModalsAndPopups/LocationModal/features/labels-card/models/labelTypeEnumToText.model';

type TabObject = {
  label: string;
  show: boolean;
  gridState: GridInitialStatePremium;
  issueTypeFilters?: ISSUE_TYPES[];
  issueStateFilters?: IIssueSTStateEnum[];
  slotSettingFilter?: ISlotSettingsSTExclusionStatusEnum;
  filter?: (location: ILocationDataST) => boolean;
};

export const getReportTabs = ({
  isUserAmendAllowed,
  areLabelsShown,
}: {
  isUserAmendAllowed: boolean;
  areLabelsShown: boolean;
}): TabObject[] => [
  {
    label: 'FULL REPORT',
    show: true,
    gridState: {
      ...initialGridState,
      columns: {
        columnVisibilityModel: {
          ...initialGridState.columns?.columnVisibilityModel,
          exclusion_status: false,
          'issues.0.first_found_on': false,
        },
        orderedFields: [
          'slot_label',
          'wms_status.state',
          'wasFoundAt',
          'wms_status.article_nos.0',
          'wms_status.qtys.0',
          'wms_status.customers.0',
          'wms_status.changed_at',
          'verity_status.state',
          'shouldBeAt',
          'contentFoundDate',
          'issues.0.type',
          'issues.0.state',
        ],
      },
    },
  },
  {
    label: 'ISSUES',
    show: true,
    gridState: {
      ...initialGridState,
      columns: {
        columnVisibilityModel: {
          ...initialGridState.columns?.columnVisibilityModel,
          exclusion_status: false,
          'issues.0.first_found_on': true,
          contentFoundDate: false,
          'wms_status.changed_at': false,
          'wms_status.qtys.0': false,
          'wms_status.article_nos.0': false,
          wasFoundAt: false,
        },
        orderedFields: [
          'slot_label',
          'issues.0.type',
          'wms_status.state',
          'verity_status.state',
          'wms_status.customers.0',
          'shouldBeAt',
          'issues.0.first_found_on',
          'issues.0.state',
        ],
      },
    },
    issueTypeFilters: [
      ISSUE_TYPES.WMS_BARCODE_VERITY_EMPTY,
      ISSUE_TYPES.WMS_BARCODE_NOT_EQUAL_VERITY_BARCODE,
      ISSUE_TYPES.WMS_EMPTY_VERITY_BARCODE,
      ISSUE_TYPES.WMS_EMPTY_VERITY_NOTEMPTY,
      ISSUE_TYPES.WMS_BARCODE_DUPLICATE,
    ],
    issueStateFilters: [IIssueSTStateEnum.New, IIssueSTStateEnum.Known],
  },
  {
    label: 'POTENTIAL',
    show: true,
    gridState: {
      ...initialGridState,
      columns: {
        columnVisibilityModel: {
          ...initialGridState.columns?.columnVisibilityModel,
          exclusion_status: false,
          wasFoundAt: false,
          'wms_status.article_nos.0': false,
          'wms_status.qtys.0': false,
          shouldBeAt: false,
          'issues.0.type': false,
          'wms_status.changed_at': false,
          contentFoundDate: false,
        },
        orderedFields: [
          'slot_label',
          'wms_status.state',
          'verity_status.state',
          'wms_status.customers.0',
          'issues.0.first_found_on',
          'issues.0.state',
        ],
      },
    },
    issueTypeFilters: [
      ISSUE_TYPES.INCONCLUSIVE_WMS_BARCODE_VERITY_NOTEMPTY,
      ISSUE_TYPES.C001C_MISSING_BC,
    ],
    issueStateFilters: [IIssueSTStateEnum.New, IIssueSTStateEnum.Known],
  },
  {
    label: 'SNOOZED',
    show: true,
    gridState: {
      ...initialGridState,
      columns: {
        columnVisibilityModel: {
          ...initialGridState.columns?.columnVisibilityModel,
          exclusion_status: false,
          wasFoundAt: false,
          'wms_status.article_nos.0': false,
          'wms_status.qtys.0': false,
          'wms_status.changed_at': false,
          contentFoundDate: false,
        },
        orderedFields: [
          'slot_label',
          'issues.0.type',
          'wms_status.state',
          'verity_status.state',
          'wms_status.customers.0',
          'shouldBeAt',
          'issues.0.first_found_on',
          'issues.0.state',
        ],
      },
    },
    issueStateFilters: [IIssueSTStateEnum.Snoozed],
  },
  {
    label: 'AMENDED',
    show: userHasPermission(PERMISSION.AMEND_REPORT) && isUserAmendAllowed,
    gridState: {
      ...initialGridState,
      columns: {
        columnVisibilityModel: {
          ...initialGridState.columns?.columnVisibilityModel,
          content_found_amended: true,
          exclusion_status: false,
          shouldBeAt: false,
          'issues.0.first_found_on': false,
          'issues.0.state': false,
          wasFoundAt: false,
          'wms_status.article_nos.0': false,
          'wms_status.qtys.0': false,
        },
        orderedFields: [
          'slot_label',
          'wms_status.state',
          'verity_status.state',
          'wms_status.customers.0',
          'wms_status.changed_at',
          'contentFoundDate',
          'issues.0.type',
          'content_found_amended',
        ],
      },
    },
    filter: (locationData: ILocationDataST) =>
      !!getLatestOverwriteFromSlotStatus(locationData.verity_status, 'client'),
  },
  {
    label: 'INCONCLUSIVE',
    show: true,
    gridState: {
      ...initialGridState,
      columns: {
        columnVisibilityModel: {
          ...initialGridState.columns?.columnVisibilityModel,
          exclusion_status: false,
          wasFoundAt: false,
          'wms_status.article_nos.0': false,
          'wms_status.qtys.0': false,
          'wms_status.changed_at': false,
          shouldBeAt: false,
          contentFoundDate: false,
          'issues.0.first_found_on': true,
          'issues.0.type': false,
        },
        orderedFields: [
          'slot_label',
          'wms_status.state',
          'verity_status.state',
          'wms_status.customers.0',
          'issues.0.first_found_on',
          'issues.0.state',
        ],
      },
    },
    issueTypeFilters: [
      ISSUE_TYPES.INCONCLUSIVE_WMS_BARCODE_VERITY_NORESULT,
      ISSUE_TYPES.INCONCLUSIVE_WMS_EMPTY_VERITY_NORESULT,
    ],
    issueStateFilters: [IIssueSTStateEnum.New, IIssueSTStateEnum.Known],
  },
  {
    label: 'INVALID',
    show: true,
    gridState: {
      ...initialGridState,
      columns: {
        columnVisibilityModel: {
          ...initialGridState.columns?.columnVisibilityModel,
          exclusion_status: false,
          wasFoundAt: false,
          'wms_status.article_nos.0': false,
          'wms_status.qtys.0': false,
          shouldBeAt: false,
          'issues.0.type': false,
        },
        orderedFields: [
          'slot_label',
          'wms_status.state',
          'wms_status.customers.0',
          'wms_status.changed_at',
          'verity_status.state',
          'contentFoundDate',
          'issues.0.first_found_on',
          'issues.0.state',
        ],
      },
    },
    issueTypeFilters: [ISSUE_TYPES.INCONCLUSIVE_WMS_INVALID],
  },
  {
    label: 'LABELED',
    show: areLabelsShown,
    gridState: {
      ...initialGridState,
      columns: {
        columnVisibilityModel: {
          ...initialGridState.columns?.columnVisibilityModel,
          location_label: true,
        },
      },
      filter: {
        filterModel: {
          items: [
            {
              field: 'location_label',
              operator: 'isAnyOf',
              value: Object.values(labelTypeEnumToText),
            },
          ],
        },
      },
    },
  },
];
