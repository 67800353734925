import { styled, Theme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';

const drawerWidth = 300;

const openedMixin = (theme: Theme) => ({
  width: drawerWidth,
  height: 'calc(100vh - 35px)',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme) => ({
  height: 'calc(100vh - 35px)',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(3)} + 40px)`,
});

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
  // @ts-expect-error typing for extending styles directly on component
})(({ theme, open }: { theme: Theme; open: boolean }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',

  ...(open && {
    ...openedMixin(theme),
    height: 'auto',

    '& .MuiDrawer-paper': {
      borderRight: '0 !important',
      ...openedMixin(theme),
    },
  }),

  ...(!open && {
    ...closedMixin(theme),
    height: 'auto',

    '& .MuiDrawer-paper': {
      ...closedMixin(theme),
      width: '65px',
    },
  }),
}));
