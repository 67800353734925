import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

export const useLabelsRadioButtonStyles = makeStyles()((theme: Theme) => ({
  labelsRadioButton: {
    border: `${theme.palette.primary.main} 1px solid`,
    textTransform: 'none',
    borderRadius: '4px',
    padding: theme.spacing(0, 2.5),
    whiteSpace: 'nowrap',
    minWidth: 'max-content',
    justifyContent: 'flex-start',
  },
}));
