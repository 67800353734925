import { CustomMultiSelect } from 'components/common/CustomFormComponents/CustomMultiSelect';
import { SingleChoiceParamFieldProps } from './NameAndParamsForm.model';

type MultiChoiceParamFieldProps = Omit<SingleChoiceParamFieldProps, 'value' | 'paramValue'> & {
  values: string[];
  onBlur: () => void;
  onClear: () => void;
};

export const MultiChoiceParamField = (props: MultiChoiceParamFieldProps) => (
  <CustomMultiSelect
    id={props.param.unique_name}
    testId={`c-multi-choice-dropdown-${props.index}`}
    variant="outlined"
    margin="normal"
    label={props.param.user_facing_name}
    name={props.param.unique_name}
    values={props.values}
    valueOptions={props.valueOptions}
    onChange={(e) => props.onChange(e)}
    onBlur={() => props.onBlur()}
    onClear={() => props.onClear()}
    error={props.isInvalidParamValue}
    errorMessage={props.helperText}
  />
);
