import { ILocationDataST } from 'codegen/report';
import { ILocationData } from 'store/Modals/facilityModals/IFacilityModalsStore';

export const locationHasIssues = (locationData: ILocationData | ILocationDataST) => {
  if (Object.hasOwn(locationData, 'issueType')) {
    return !!(locationData as ILocationData).issueType;
  }
  const issues = (locationData as ILocationDataST)?.issues;
  return !!issues.length;
};
