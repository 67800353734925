import { OpenFlyZone } from 'shared/map/model/open-fly-zone.model';
import { geometryToBottomLeftDimensions } from 'shared/map-container/utils/creation.util';
import { IOpenFlyZoneStatus1ST } from 'codegen/open_fly_zone';
import { DroneZoneTypes } from './droneZones.model';

export const transformIntoOpenFlyZone = (
  zone: IOpenFlyZoneStatus1ST & { id: string },
): OpenFlyZone => {
  const { w, l, h, minX, minY, minZ, maxZ } = geometryToBottomLeftDimensions(zone.geometry);

  return {
    id: zone.id,
    type: DroneZoneTypes.openFlyZone,
    description: zone.description,
    name: zone.name,
    sizeAndPosition: { w, l, h, minX, minY, minZ, maxZ },
    isSelected: false,
    isActive: true,
  };
};
