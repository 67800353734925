import { ReactNode } from 'react';

import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import Link from '@mui/material/Link';

import { Box } from 'components/common/Box';
import { CSSProperties } from '@mui/material/styles/createTypography';
import { simpleItemStyle } from './styles';

import { BaseCardSimpleItemAlertBox } from './BaseCardSimpleItemAlertBox';

export interface IBaseCardSimpleItemSectionItems {
  icon?: ReactNode;
  /**
   * Add a divider (hr) before this item
   */
  divider?: boolean;
  /**
   * Test ID of the section item
   */
  testId?: string;
  id: string;
  label?: string;
  value?: any;
  style?: () => Record<string, CSSProperties | undefined>;
  visible?: boolean;
  alertBox?: { visible: boolean; status: any; text: string };
}

export interface IBaseCardSimpleItemProps {
  /**
   * Selector for cypress tests integrations
   */
  testId?: string;
  /**
   * Section title
   */
  title?: string;
  file?: { name: string; url: string };
  /**
   * Is the item to be rendered a widget (Dropzone...), default=false
   */
  isWidget?: boolean;
  /**
   * Defines Section items to render, labels and values
   */
  sectionItems: IBaseCardSimpleItemSectionItems[];
  noData?: boolean;
}

export const BaseCardSimpleItem = ({
  title,
  file,
  testId,
  isWidget = false,
  sectionItems,
  noData,
}: IBaseCardSimpleItemProps) => {
  const { classes } = simpleItemStyle();
  return (
    <ListItem data-testid={testId} className={classes.listItem}>
      {title && (
        <Box display="flex" justifyContent="space-between">
          <Typography
            data-testid="c-card-item-title"
            className={classes.title}
            gutterBottom
            color="textPrimary"
            component="p"
          >
            {title}
          </Typography>

          {file && file.name && file.url && (
            <Link data-testid="c-item-file-link" className={classes.fileLink} href={file.url}>
              {file.name}
            </Link>
          )}
        </Box>
      )}

      {sectionItems.map((item) => (
        <Box key={item.id}>
          {item.divider && (
            <Box my={1}>
              <Divider />
            </Box>
          )}
          <Box data-testid={item.testId} display="flex" justifyContent="space-between">
            {isWidget ? (
              item.value
            ) : (
              <Box width="100%" display="flex" flexDirection="column">
                <Box width="100%" display="flex" alignItems="center" justifyContent="space-between">
                  <Box py={item.icon ? 1.2 : 0} display="flex" alignItems="center">
                    {item.icon && <Box mr={2}>{item.icon}</Box>}
                    <Typography
                      style={item?.style && item?.style()?.label}
                      data-testid="c-card-item-label"
                      variant="subtitle1"
                      color="textSecondary"
                      component="div"
                    >
                      {item.visible === undefined ? item.label : item.visible && item.label}
                    </Typography>
                    {item.alertBox?.visible && (
                      <BaseCardSimpleItemAlertBox
                        status={item.alertBox?.status}
                        text={item.alertBox?.text}
                      />
                    )}
                  </Box>
                  <Typography
                    data-testid={`${item.testId}-value`}
                    className={classes.wordBreak}
                    variant="subtitle1"
                    color="textSecondary"
                    align="right"
                    style={!noData ? item?.style && item?.style()?.value : undefined}
                    component="div"
                  >
                    {!noData ? (
                      <span>
                        {item.visible === undefined ? item.value : item.visible && item.value}
                      </span>
                    ) : (
                      '-'
                    )}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      ))}
    </ListItem>
  );
};
