import { FormControl } from '@mui/material';
import { CustomSelect } from 'components/common/CustomFormComponents/CustomSelect';
import { locationStyles } from '../../../../styles';

export const SelectContentCorrect = (props: {
  overwriteVerityCorrect: string;
  setOverwriteVerityCorrect: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const { overwriteVerityCorrect, setOverwriteVerityCorrect } = props;
  const { classes } = locationStyles();
  return (
    <FormControl className={classes.formControl} variant="outlined">
      <CustomSelect
        id="SelectOverwriteVerityCorrect"
        name="SelectOverwriteVerityCorrect"
        testId="SelectOverwriteVerityCorrect"
        error={false}
        errorMessage=""
        defaultValue=""
        disabled={false}
        variant="outlined"
        label="Is the content found by Verity correct?"
        value={overwriteVerityCorrect}
        onChange={(e) => setOverwriteVerityCorrect(e.target.value)}
        valueOptions={[
          {
            value: false,
            label: 'No',
          },
          {
            value: true,
            label: 'Yes',
          },
        ]}
      />
    </FormControl>
  );
};
