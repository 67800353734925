import { formatDisplayValue } from 'udb/ground-control/utils/formatDisplayValue';

export const getLabel = ({
  baseLabel,
  unit,
  decimalPlaces,
  isMetricSystem,
  extra,
}: {
  baseLabel: string;
  unit: string;
  isMetricSystem: boolean;
  decimalPlaces: number;
  extra: { max?: number; isMaxVisible?: boolean; min?: number; isMinVisible?: boolean };
}) => {
  let fieldLabel = `${baseLabel} (${unit})`;

  if (extra.max && extra.isMaxVisible) {
    fieldLabel += ` - max: ${formatDisplayValue(extra.max, {
      isUnitVisible: isMetricSystem,
      isMetricSystem,
      decimalPlaces,
    })}`;
  }

  if (extra.min && extra.isMinVisible) {
    fieldLabel += ` - min: ${formatDisplayValue(extra.min, {
      isUnitVisible: isMetricSystem,
      isMetricSystem,
      decimalPlaces,
    })}`;
  }

  return fieldLabel;
};
