import { callSubReducer, notEver } from 'common/functions/otherFunctions';
import { IReportSpecificationST } from 'codegen/report_specification/api';
import { getInitialScheduleFormState, scheduleFormReducer } from './ScheduleFormReducer';
import { ScheduleFormAction, ScheduleFormState } from './ScheduleForm.model';

/**
 * Report scheduler state
 */
export type ReportSchedulerState = {
  selectedReportSpec: IReportSpecificationST | null;
  spinner: number;
  reportSpecAvailable: boolean;
  formState: ScheduleFormState;
};

/**
 * Get initial report scheduler state
 * @returns ReportSchedulerState state
 */
export const getInitialReportSchedulerState = (): ReportSchedulerState => ({
  selectedReportSpec: null,
  spinner: 0,
  reportSpecAvailable: true,
  formState: { ...getInitialScheduleFormState() },
});

type ScheduleActions =
  | ScheduleFormAction
  | { type: 'SET_SELECTED_SPEC'; payload: IReportSpecificationST }
  | { type: 'RESET_SPINNER' }
  | { type: 'INCREMENT_SPINNER' }
  | { type: 'DECREMENT_SPINNER' }
  | { type: 'SET_REPORT_SPEC_AVAILABLE'; payload: boolean };

/**
 *
 * @param state ReportSchedulerState params
 * @param action ScheduleActions params
 * @returns ReportSchedulerState state
 */
export const reportSchedulerReducer = (
  state: ReportSchedulerState,
  action: ScheduleActions,
): ReportSchedulerState => {
  [state, action] = callSubReducer(state, action, 'formState', scheduleFormReducer);

  switch (action.type) {
    case 'SET_SELECTED_SPEC':
      return { ...state, selectedReportSpec: action.payload };
    case 'RESET_SPINNER':
      return { ...state, spinner: 0 };
    case 'INCREMENT_SPINNER':
      return { ...state, spinner: 1 };
    case 'DECREMENT_SPINNER':
      return { ...state, spinner: 0 };
    case 'SET_REPORT_SPEC_AVAILABLE':
      return { ...state, reportSpecAvailable: action.payload };
    default:
      notEver(action);
      return state;
  }
};
