import React from 'react';

import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { SortDirection } from '@mui/material';
import { tableStyles } from '../../styles';
import { HeadCellProp } from '../../types/cell';

import { EnhancedTableHeadCellString } from './String/EnhancedTableHeadCellString';
import { EnhancedTableHeadCellCheckbox } from './Checkbox/EnhancedTableHeadCellCheckbox';
import { EnhancedTableHeadCellDefault } from './EnhancedTableHeadCellDefault';

export const EnhancedTableHead = ({
  headCells,
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  allSelected,
  rowCount,
  onRequestSort,
  allowSorting,
}: {
  headCells: Array<HeadCellProp>;
  numSelected: number;
  allSelected: boolean;
  onRequestSort: (event: Event, property: string) => void;
  onSelectAllClick: (e: React.ChangeEvent<HTMLInputElement>) => void;
  order?: SortDirection;
  orderBy?: string;
  rowCount: number;
  allowSorting: boolean;
}) => {
  const { classes } = tableStyles();

  const createSortHandler = (property: string) => (event: Event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* #SG-2020-11-30: Allow sorting only for columns in which values are strings */}
        {headCells.map((headCell) => {
          if (!headCell.show) return null;

          switch (headCell.type) {
            case 'checkbox':
              return (
                <EnhancedTableHeadCellCheckbox
                  key={headCell.id}
                  onChange={onSelectAllClick}
                  headCell={headCell}
                  classes={classes}
                  numSelected={numSelected}
                  allSelected={allSelected}
                  rowCount={rowCount}
                />
              );
            case 'string':
            case 'item_list':
            case 'badge':
              return (
                <EnhancedTableHeadCellString
                  key={headCell.id}
                  headCell={headCell}
                  classes={classes}
                  orderBy={orderBy}
                  order={order}
                  allowSorting={allowSorting}
                  onClick={createSortHandler(headCell.id)}
                />
              );
            default:
              return (
                <EnhancedTableHeadCellDefault
                  key={headCell.id}
                  headCell={headCell}
                  classes={classes}
                  orderBy={orderBy}
                  order={order}
                />
              );
          }
        })}
      </TableRow>
    </TableHead>
  );
};
