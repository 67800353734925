import { IReportParameterUniqueNameST, IReportSpecificationST } from 'codegen/report_specification';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { getParamSpec, prepareParamValue } from 'common/functionsReportSpec';
import { INPUT_MODES } from 'common/reportSpecifications';
import { ScheduleFormAction } from '../reducers/ScheduleForm.model';

// This function updates the values of ONE given report param name, if it exists on the reportSpec
// Input - the parameter to be updated and its new value

export const updateReportParamValues = (
  paramUniqueName: IReportParameterUniqueNameST,
  value: string | string[],
  reportSpec: IReportSpecificationST,
  dispatch: (value: ScheduleFormAction) => void,
): void => {
  const lp = getLogPrefixForType('FUNCTION', 'updateReportParamValues');
  console.debug(lp, 'paramUniqueName:', paramUniqueName, 'value:', value);
  const normalizedValue = typeof value === 'string' ? [value] : value;
  const paramSpec = getParamSpec(reportSpec, paramUniqueName);
  if (paramSpec) {
    /**
     * Custom values need to be held in separate state for inputs.
     */
    if (paramSpec.input_mode === INPUT_MODES.CUSTOM_VALUES) {
      dispatch({
        type: 'ADD_CUSTOM_PARAM_VALUES',
        payload: { paramUniqueName, values: normalizedValue },
      });
      const newValue = prepareParamValue(paramUniqueName, value);
      dispatch({
        type: 'SET_REPORT_PARAM_VALUE',
        payload: { paramUniqueName, value: newValue },
      });
    } else {
      dispatch({
        type: 'SET_REPORT_PARAM_VALUE',
        payload: { paramUniqueName, value: normalizedValue },
      });
    }
    dispatch({ type: 'VALIDATE_PARAM_VALUE', payload: { reportSpec, paramUniqueName } });
  } else {
    console.error(
      lp,
      `Error => ScheduleForm.js:updateReportParamValues(): param ${paramUniqueName} does not exist on reportSpec: ${reportSpec.report_spec_name}`,
    );
  }
};
