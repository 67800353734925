import { Alert, AlertTitle } from '@mui/material';
import { AlertColor } from '@mui/material/Alert';
import Fade from '@mui/material/Fade';
import { ReactElement } from 'react';
import { Box } from './Box';

export interface IBasicAlertProps {
  isActive: boolean;
  status: AlertColor;
  icon?: React.ReactNode;
  title?: string;
  message: string;
  action?: ReactElement;
  testId?: string;
  iconMapping?: any;
}

/**
 * Basic alert
 * @param props IBasicAlert props
 * @returns component
 */
export const BasicAlert = ({
  isActive,
  status,
  icon,
  title,
  message,
  action,
  testId,
  iconMapping,
}: IBasicAlertProps) => {
  if (!isActive) return null;

  return (
    <Fade in={isActive}>
      <Box>
        <Alert
          data-testid={testId}
          iconMapping={iconMapping}
          icon={icon}
          action={action}
          severity={status}
        >
          {title && <AlertTitle>{title}</AlertTitle>}
          {message || ''}
        </Alert>
      </Box>
    </Fade>
  );
};
