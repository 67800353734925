import { IBarcodeMatchLogicST } from 'codegen/facility_settings';

export const getBarcodeMatchingLogicDescription = (
  barcodeMatchingLogic?: IBarcodeMatchLogicST | null,
) => {
  switch (barcodeMatchingLogic) {
    case IBarcodeMatchLogicST.ExactMatch:
      return (
        <>
          Consider a match <b>if Verity finds at least one barcode of the expected WMS barcodes</b>.
          Therefore, this strategy allows for missing and unexpected barcodes to be present.
        </>
      );
    case IBarcodeMatchLogicST.IntersectionNotEmpty:
      return (
        <>
          Consider a match <b>if Verity finds at least one barcode of the expected WMS barcodes</b>.
          Therefore, this strategy allows for missing and unexpected barcodes to be present.
        </>
      );
    case IBarcodeMatchLogicST.VeritySuperset:
      return (
        <>
          Consider a match{' '}
          <b>if at least all WMS barcodes are contained in the list of barcodes found by Verity</b>.
          Therefore, the comparison results a no match every time there is a missing barcode (Verity
          did not manage to find at least all the WMS barcodes). It allows unexpected barcodes
        </>
      );
    case IBarcodeMatchLogicST.WmsSuperset:
      return (
        <>
          Consider a match{' '}
          <b>if all Verity barcodes are contained in the list of expected WMS barcodes</b>.
          Therefore, the comparison results in a no match every time Verity encounters an unexpected
          barcode. It allows missing barcodes.
        </>
      );
    default:
      return <>No Barcode matching logic detected.</>;
  }
};
