import axios, { AxiosResponse } from 'axios';
import capitalize from 'lodash/capitalize';
import isEmpty from 'lodash/isEmpty';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TimezoneName } from 'countries-and-timezones';
import Grid from '@mui/material/Grid';
import { SelectChangeEvent } from '@mui/material/Select';
import Container from '@mui/material/Container';
import { useParams } from 'react-router-dom';
import { getAllCountries, getTimezones } from 'common/countriesTimezones';
import { FACILITY_UPDATE_STATUS } from 'common/facilityUpdateStatus';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { UploadInfo } from 'components/common/UploadInfo';
import { PageHeaderSection } from 'components/Page/PageHeaderSection';
import { IFacilityInfoST, IFacilityPostResponseST, IPresignedPostST } from 'codegen/facility';
import { ReportSpecificationsUploadCard } from './ReportSpecificationsUploadCard';
import { facilityServices } from '../../../services/FacilityServices';
import { uploadServices } from '../../../services/UploadServices';
import { useClientLevelStore } from '../../../store/ClientLevelStore/clientLevelStore';
import { useFacilityLevelStore } from '../../../store/FacilityLevelStore/facilityLevelStore';
import { useUserLevelStore } from '../../../store/UserLevelStore/userLevelStore';
import { IRequestController, useRequestController } from '../../../hooks';
import { useAlert } from '../../../hooks/useAlert';
import { FacilityDetailsCard } from './FacilityDetailsCard';
import { FacilityMapCard } from './FacilityMapCard';
import { FlightDomainsCard } from './FlightDomainsCard';
import { MapDetailsCard } from './MapDetailsCard';
import { userHasSomePermissions } from '../../../features/permissions/userHasPermission';
import { safetyPermissions } from '../../../features/permissions/permissions.model';
import { DirtyFacilityDetailsFields } from './FacilityDetails.model';

const noFileDetails: File = new File([], '');

/**
 * Initial state of the facility details. All empty strings.
 */
const noDetails: IFacilityInfoST = {
  client: '',
  name: '',
  country: '',
  timezone: '',
  latitude: 0,
  longitude: 0,
  logo_url: '',
  store_id: '',
  created_at: '',
  system_id: 0,
  version: 0,
};

/**
 * Initial state of the DirtyFacilityDetailsFields, all fields are false
 */
const noDirtyFacilityDetailsFields: DirtyFacilityDetailsFields = Object.keys(noDetails).reduce(
  (acc, key) => {
    acc[key as keyof DirtyFacilityDetailsFields] = false;
    return acc;
  },
  {} as DirtyFacilityDetailsFields,
);

const logPrefix = getLogPrefixForType('COMPONENT', 'FacilityDetails');

export const FacilityDetails = () => {
  const [fileDetails, setFileDetails] = useState(noFileDetails);
  const [timezones, setTimezones] = useState<TimezoneName[]>([]);
  const [logos, setLogos] = useState([]);
  const [isUploadDisabled, setIsUploadDisabled] = useState(false);
  const [mapProgress, setMapProgress] = useState(0);

  const { requestController } = useRequestController('Facility Details');

  const [values, setValues] = useState<IFacilityInfoST>(noDetails);
  // pristine vs dirty approach for form validation (see angular pristine)
  const [dirtyFields, setDirtyFields] = useState(noDirtyFacilityDetailsFields);

  const { stateUserLevel } = useUserLevelStore();
  const { asyncRefreshFacilities } = useClientLevelStore();
  const {
    stateFacilityLevel,
    asyncGetFacilityInfo,
    awaitGetFacilitySpecificData,
    asyncGetFacilityMapInfo,
    asyncGetFlightDomains,
    asyncGetFacilitySlots,
  } = useFacilityLevelStore();

  const { systemId = '' } = useParams();

  const { facilityData, facilityMapInfo, isFacilityInfoLoading } = stateFacilityLevel;
  const facilityActive = !isEmpty(facilityData);

  const countries = useMemo(() => getAllCountries(), []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { alertState, showAlert } = useAlert();
  const { enqueueSnackbar } = useSnackbar();

  /**
   * Get facility logos
   */
  const getLogos = useCallback(
    (systemId: string) => {
      const { signal } = requestController.reserveSlotForRequest();
      requestController.doRequest({
        request: facilityServices.getFacilityLogos,
        requestParams: [systemId, signal],
        messageErrorFallback: `Failed to fetch the Logos for the facility with id ${systemId}`,
        callbackSuccess: (r) => {
          console.debug(logPrefix, 'got logos', r.data);
          setLogos(
            r.data.logo_urls.map((logo: string) => ({
              value: logo,
              label: capitalize(logo.split('/').slice(-1)[0].split('.')[0]),
            })),
          );
        },
      });
    },
    [requestController],
  );

  /**
   * Get map file
   */
  const downloadMapFile = useCallback(() => {
    requestController.doRequest({
      request: facilityServices.getFacilityMapFile,
      requestParams: [systemId],
      messageErrorFallback: `Failed to fetch the Map File for the facility with id ${systemId}`,
      callbackSuccess: (r) => {
        window.open(r.data.url, '_blank');
      },
    });
  }, [requestController, systemId]);

  const downloadMapProcessingLog = useCallback(() => {
    requestController.doRequest({
      request: facilityServices.getFacilityMapProcessingLog,
      requestParams: [systemId],
      messageErrorFallback: `Failed to fetch the Processing Log for the facility with id ${systemId}`,
      callbackSuccess: (r) => {
        window.open(r.data.url, '_blank');
      },
    });
  }, [requestController, systemId]);

  const updateProgressBar = useCallback((percentage: number) => {
    console.debug(logPrefix, 'updateProgressBar', percentage);
    setMapProgress(percentage);
  }, []);

  const resetUploadForm = useCallback(() => {
    setFileDetails(noFileDetails);
    setIsUploadDisabled(false);
    updateProgressBar(0);
  }, [updateProgressBar]);

  const asyncGetFacilityAndMapInfo = useCallback(
    async (requestController: IRequestController, systemId: string) => {
      await asyncGetFacilityInfo(requestController, systemId);
      await asyncGetFacilityMapInfo(requestController, systemId);
    },
    [asyncGetFacilityInfo, asyncGetFacilityMapInfo],
  );

  const awaitFetchUserFacilitiesAndLoadData = useCallback(
    async (systemId: string) => {
      await asyncRefreshFacilities(requestController, false);
      await asyncGetFacilityAndMapInfo(requestController, systemId);
      await asyncGetFacilitySlots(requestController, systemId);
      if (userHasSomePermissions(safetyPermissions)) {
        await asyncGetFlightDomains(requestController, systemId, true);
      }
      console.debug(logPrefix, 'Facility data loaded');
    },
    [
      asyncGetFacilitySlots,
      asyncGetFlightDomains,
      asyncRefreshFacilities,
      asyncGetFacilityAndMapInfo,
      requestController,
    ],
  );

  // Handle Websocket notifications for map
  useEffect(() => {
    // all notifications must carry a status
    const status = stateFacilityLevel.facilityMapUpdate?.data?.status;
    // prevent acting upon notifications if they do not carry a status
    if (isEmpty(status)) {
      return;
    }
    console.debug(logPrefix, 'facility update status:', status);

    // only ongoing and finished notifications carry values for progress
    const progress = stateFacilityLevel.facilityMapUpdate?.data?.progress;

    // user - only error notifications carry the username of the uploader
    const currentUser = stateUserLevel.username;
    const uploadingUser = stateFacilityLevel.facilityMapUpdate?.data?.user;
    const doUsersMatch = currentUser === uploadingUser;

    // facility
    const doFacilitiesMatch =
      stateFacilityLevel.facilityMapUpdate?.data?.systemId?.toString() === systemId;

    // Prevent acting upon notifications if they relate to other facilities
    if (!doFacilitiesMatch) {
      return;
    }

    switch (status) {
      case FACILITY_UPDATE_STATUS.ERROR:
        resetUploadForm();
        awaitFetchUserFacilitiesAndLoadData(systemId);
        // show the error notification only if the uploader user
        // is the same as the current user
        if (doUsersMatch) {
          showAlert.error({
            message: 'There was an error processing the map',
            preventClose: false,
            label: 'FacilityMap',
          });
        }
        break;

      case FACILITY_UPDATE_STATUS.ONGOING:
        setIsUploadDisabled(true);
        updateProgressBar(progress);
        showAlert.info({
          message: 'The map is currently being processed',
          preventClose: true,
          label: 'FacilityMap',
        });
        break;

      case FACILITY_UPDATE_STATUS.FINISHED:
        resetUploadForm();
        awaitFetchUserFacilitiesAndLoadData(systemId);
        showAlert.success({
          message: 'The map was uploaded successfully',
          preventClose: false,
          label: 'FacilityMap',
        });
        break;

      default:
        break;
    }

    // FIXME:::SS-2021-07-15
    // Including "showAlert" hook in dependency array
    // creates memory leak which causes freezing the app
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    stateFacilityLevel.facilityMapUpdate,
    awaitGetFacilitySpecificData,
    isUploadDisabled,
    resetUploadForm,
    requestController,
  ]);

  useEffect(() => {
    if (facilityData?.store_id) {
      setValues((n) => ({ ...n, store_id: facilityData.store_id }));
    }
  }, [facilityData]);

  useEffect(() => {
    getLogos(systemId);
  }, [getLogos, systemId]);

  const handleChange =
    (prop: keyof IFacilityInfoST) =>
    (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>,
    ) => {
      const newValue = (
        event as
          | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          | SelectChangeEvent<string>
      ).target.value;

      // FIXME: because of UD-3439 we want to prevent non-ASCII fields in clients and facilities names
      // this should be properly addressed at some stage, either with proper support of non-ASCII chars
      // or by having definition for strictly-ascii text fields.
      if (prop === 'client' || prop === 'name') {
        // eslint-disable-next-line no-control-regex
        if (!/^[\x00-\x7F]*$/.test(newValue)) return;
      }

      setValues({
        ...values,
        [prop]: newValue,
      });
      setDirtyFields({ ...dirtyFields, [prop]: true });
    };

  const getMapFormDataForS3Upload = (
    presignedPostData: IPresignedPostST,
    metadata: IFacilityInfoST,
    file: File,
  ) => {
    const formData = new FormData();

    // add server-supplied fields and values to form
    Object.entries(presignedPostData.fields).forEach(([field, value]) => {
      formData.append(field, value as string);
    });

    // add metadata to form
    formData.set('x-amz-meta-file-name', file.name);

    const valuesForUpload = {
      client: metadata.client,
      country: metadata.country,
      latitude: metadata.latitude,
      logo_url: metadata.logo_url,
      longitude: metadata.longitude,
      name: metadata.name,
      store_id: metadata.store_id,
      timezone: metadata.timezone,
    };

    Object.entries(valuesForUpload).forEach(([key, value]) => {
      // Do not send empty values
      if (value === '') {
        return;
      }

      // Do not send read-only data if facility is active.
      if (
        facilityActive &&
        (key === 'client' ||
          key === 'name' ||
          key === 'latitude' ||
          key === 'longitude' ||
          key === 'timezone' ||
          key === 'country')
      ) {
        return;
      }

      // Server expects hyphen instead of _.
      key = key.replace('_', '-');

      formData.set(`x-amz-meta-${key}`, value as string);
    });

    // Actual file has to be appended last.
    formData.append('file', file);

    return formData;
  };

  // Validate form data
  const validateFormData = () => {
    if (facilityActive) {
      // validate that file exists, others (logo_url, only) become optional
      return !isEmpty(fileDetails);
    }

    // validate that all required fields are not empty
    const toBeValidated = [
      values.client,
      values.country,
      values.latitude,
      values.logo_url,
      values.longitude,
      values.name,
      values.store_id,
      values.timezone,
    ];

    const isValid = toBeValidated.reduce(
      (valid, val: string | number) => valid && !!val.toString() && !isEmpty(val.toString()),
      true,
    );

    return isValid && !isEmpty(fileDetails);
  };

  const handleUpload = () => {
    if (!validateFormData()) {
      // set all fields to dirty
      // this will activate the error state for pristine fields
      //  - see: pristine/dirty definitions in angular context
      setDirtyFields({
        client: true,
        name: true,
        country: true,
        timezone: true,
        latitude: true,
        longitude: true,
        logo_url: true,
        store_id: true,
        created_at: false,
        system_id: false,
        version: false,
      });

      // show an alert
      enqueueSnackbar('Confirm you have selected a file, and filled all required fields.', {
        variant: 'error',
      });
      // abort upload, if form fields not valid
      return;
    }
    // Verify whether the file is of type JSON.
    if (fileDetails.type !== 'application/json') {
      enqueueSnackbar(`The file you selected (${fileDetails.name}) is a not a JSON file.`, {
        variant: 'error',
      });
      setFileDetails(noFileDetails);
      return;
    }

    /**
     * Prepare to load json file from disk
     * we need to read the file and add metadata to it before uploading
     */
    const reader = new FileReader();
    reader.onload = (onLoadEvent) => {
      try {
        const { signal } = requestController.reserveSlotForRequest();
        // Get pre-signed post data
        requestController.doRequest({
          request: uploadServices.getMapUploadPresignedUrl,
          requestParams: [systemId, signal],
          callbackBeforeSend: () => setIsUploadDisabled(true),
          messageErrorFallback: `Failed to upload the Map for the facility with id ${systemId}`,
          callbackSuccess: (response: AxiosResponse<IFacilityPostResponseST, unknown>) => {
            const presignedPostData = response.data.presigned_post;

            const text = (onLoadEvent.target as FileReader).result;

            try {
              JSON.parse(text as string);
            } catch (e) {
              enqueueSnackbar(`The map file "${fileDetails.name}" is not a valid json file.`, {
                variant: 'error',
              });
              setFileDetails(noFileDetails);
              setIsUploadDisabled(false);
              return;
            }
            const formData = getMapFormDataForS3Upload(presignedPostData, values, fileDetails);

            // Prepare axios config to deal with upload progress
            const axiosConfig = {
              onUploadProgress: (progressEvent: { loaded: number }) => {
                const progress = Math.floor((progressEvent.loaded / fileDetails.size) * 100);
                // round the progress (sometimes it goes over 100)
                const pRound = progress < 100 ? progress : 100;
                if (pRound === 100) {
                  showAlert.info({
                    message: 'The map will now be processed, please wait...',
                    preventClose: true,
                    label: 'FacilityMap',
                  });
                }
                updateProgressBar(pRound);
              },
            };

            // Send request
            requestController.doRequest({
              // we use axios directly here, because this url is loaded from the server
              // and it contains it's own keys
              request: axios.post,
              requestParams: [presignedPostData.url, formData, axiosConfig],
              callbackBeforeSend: () => {
                showAlert.info({
                  message: 'Waiting for the server to validate the map...',
                  preventClose: true,
                  label: 'FacilityMap',
                });
              },
              callbackError: () => {
                resetUploadForm();
              },
              messageErrorFallback: 'Failed to upload the map',
              callbackFinally: () => updateProgressBar(0),
            });
          },
        });
      } catch (ex) {
        console.error(`ERROR => exception when trying to parse json = ${ex}`);
        resetUploadForm();
      }
    };
    // Load json file from disk
    reader.readAsText(fileDetails);
  };

  // Update timezone input field when country field is changing
  useEffect(() => {
    if (values.country) {
      const timeZones = getTimezones(values.country);

      console.debug(logPrefix, 'useEffect set timeZones:', timeZones);

      setTimezones(timeZones);

      if (timeZones?.length) {
        setValues((prevState) => ({ ...prevState, timezone: timeZones[0] }));
      }
    }
  }, [values.country]);

  const uploadInfo = (
    <UploadInfo
      testId="c-upload-info"
      key="0"
      state={alertState.FacilityMap}
      closeAlert={() => showAlert.close('FacilityMap')}
      progress={mapProgress}
    />
  );

  return (
    <>
      <PageHeaderSection title="Administration" showLoadedSince={false} />

      <Container maxWidth="xl" sx={{ paddingTop: '32px' }}>
        <Grid direction="column" container className="c-page-content">
          <Grid spacing={3} style={{ marginBottom: '16px' }} alignItems="flex-start" container>
            <Grid md={6} sm={12} xs={12} item>
              <FacilityDetailsCard
                facilityActive={facilityActive}
                facilityData={facilityData}
                values={values}
                dirtyFields={dirtyFields}
                countries={countries}
                timezones={timezones}
                isLoading={!isEmpty(facilityData) && isFacilityInfoLoading}
                isUploadDisabled={isUploadDisabled}
                setValues={setValues}
                handleChange={handleChange}
              />
              {stateFacilityLevel.facilityMapInfoLoaded && (
                <MapDetailsCard
                  downloadMapFile={downloadMapFile}
                  downloadMapProcessingLog={downloadMapProcessingLog}
                  facilityData={facilityData}
                  facilityMapInfo={facilityMapInfo}
                />
              )}
              {stateFacilityLevel.flightDomainsLoaded && (
                <FlightDomainsCard
                  facilityActive={facilityActive}
                  flightDomains={stateFacilityLevel.flightDomains}
                />
              )}
            </Grid>
            <Grid md={6} sm={12} xs={12} item>
              <FacilityMapCard
                facilityActive={facilityActive}
                facilityData={facilityData}
                fileDetails={fileDetails}
                values={values}
                dirtyFields={dirtyFields}
                logos={logos}
                uploadInfo={uploadInfo}
                isUploadDisabled={isUploadDisabled}
                isLoading={!isEmpty(facilityData) && isFacilityInfoLoading}
                handleChange={handleChange}
                handleUpload={handleUpload}
                setFileDetails={setFileDetails}
              />
              {facilityActive && <ReportSpecificationsUploadCard systemId={systemId} />}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
