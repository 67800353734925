import { TextField } from '@mui/material';
import { VALUE_TYPES } from 'common/reportSpecifications';
import { ParamFieldProps } from './NameAndParamsForm.model';

type TextParamFieldProps = ParamFieldProps & {
  type: 'text' | 'number';
};

export const TextParamField = ({
  param,
  index,
  paramValue,
  isInvalidParamValue,
  onChange,
  type,
  helperText,
}: TextParamFieldProps) => {
  const isMultiline = param.value_type === VALUE_TYPES.STRING;
  return (
    <TextField
      data-testid={`c-text-field-${index}`}
      variant="outlined"
      margin="normal"
      fullWidth
      label={param.user_facing_name}
      value={paramValue || ''}
      multiline={isMultiline}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)}
      error={isInvalidParamValue}
      helperText={helperText}
      type={type}
    />
  );
};
