import { useState } from 'react';

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import isEmpty from 'lodash/isEmpty';
import { Tooltip } from '@mui/material';

export type ActionMenuOptions = {
  label: JSX.Element | string;
  disabled?: boolean;
  clickHandler: () => void;
};

export const ActionsMenu = (props: {
  size?: 'small' | 'medium' | 'large';
  isTable?: boolean;
  options: ActionMenuOptions[];
}) => {
  const { size = 'large', isTable = true, options } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (externalEvent: () => void) => {
    setAnchorEl(null);
    externalEvent();
  };

  return (
    <>
      <Tooltip title="More options">
        <IconButton
          aria-label="More options"
          data-testid="c-actions-button"
          onClick={handleClick}
          size={size}
        >
          <MoreVertIcon
            color={!isTable ? 'secondary' : 'inherit'}
            fontSize={!isTable ? 'large' : 'medium'}
          />
        </IconButton>
      </Tooltip>
      <Menu
        data-testid="c-actions-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        {!isEmpty(options) ? (
          options.map((option: ActionMenuOptions, i: number) => (
            <MenuItem
              data-testid={`c-action-${i}`}
              key={`option.label-${crypto.randomUUID()}`}
              disabled={option.disabled}
              onClick={() => handleMenuItemClick(option.clickHandler)}
            >
              {option.label}
            </MenuItem>
          ))
        ) : (
          <MenuItem data-testid="c-action-no-items" key="option.label-no-items" disabled={true}>
            no items to display
          </MenuItem>
        )}
      </Menu>
    </>
  );
};
