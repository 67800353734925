export enum USER_GROUPS {
  INVENTORY_MANAGER = 'InventoryManager',
  VERITY_USER = 'VerityUser',
  REVIEWER = 'Reviewer',
  GROUND_CONTROL_OFFICER = 'GroundControlOfficer',
  ANALYTICS = 'Analytics',
  SAFETY_CONTROLS = 'SafetyControls',
  SUPER_ADMIN = 'SuperAdmin',
  CLIENT_ADMIN = 'ClientAdmin',
  FACILITY_ADMIN = 'FacilityAdmin',
}
