import { useCallback, useEffect, useRef } from 'react';
import { ReactZoomPanPinchRef } from 'react-zoom-pan-pinch';
import { makeStyles } from 'tss-react/mui';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { IconButton, MobileStepper, useTheme } from '@mui/material';
import { KeyboardNavigation } from './ImageCarousel.model';
import { ZoomPanPinch } from '../ZoomPanPinch';

const useStyles = makeStyles()(() => ({
  root: {
    width: '100%',
    background: '#fff',
    boxSizing: 'border-box',
  },
  hidden: {
    visibility: 'hidden',
  },
}));

/**
 * Image controls props interface
 */
interface IImageControlsProps {
  activeStep: number;
  steps: number;
  handleNext: () => void;
  handleBack: () => void;
}

/**
 * Image controls
 * @param param0 IImageControlsProps params
 * @returns component
 */
const ImageControls = ({ activeStep, steps, handleNext, handleBack }: IImageControlsProps) => {
  const { classes, cx } = useStyles();
  const theme = useTheme();
  return (
    <MobileStepper
      position="static"
      variant="dots"
      className={cx(classes.root, { [classes.hidden]: steps === 1 })}
      steps={steps}
      activeStep={activeStep}
      nextButton={
        <IconButton onClick={handleNext} disabled={activeStep === steps - 1} size="small">
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
      }
      backButton={
        <IconButton onClick={handleBack} disabled={activeStep === 0} size="small">
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
      }
    />
  );
};

/**
 * Image carousel props interface
 */
interface IImageCarouselProps {
  imageUrls: string[];
  renderTopControls?: boolean;
  renderBottomControls?: boolean;
  keyboardNavigation: KeyboardNavigation;
  onStepChange: (params: (prevState: number) => number) => void;
  activeStep: number;
}

/**
 * Image carousel
 * @param param0 IImageCarouselProps params
 * @returns component
 */
export const ImageCarousel = ({
  imageUrls = [],
  renderTopControls,
  renderBottomControls,
  keyboardNavigation,
  onStepChange,
  activeStep,
}: IImageCarouselProps) => {
  const transformWrapperRef = useRef<ReactZoomPanPinchRef>(null);

  const numOfSteps = imageUrls.length;

  /**
   * Switch to previous image
   */
  const handleBack = useCallback(() => {
    onStepChange((prevActiveStep: number) => Math.max(prevActiveStep - 1, 0));
    transformWrapperRef?.current?.resetTransform();
  }, [onStepChange]);

  /**
   * Switch to next image
   */
  const handleNext = useCallback(() => {
    onStepChange((prevActiveStep: number) => Math.min(prevActiveStep + 1, numOfSteps - 1));
    transformWrapperRef?.current?.resetTransform();
  }, [numOfSteps, onStepChange]);

  /**
   * Up-Down arrow keys navigation through images
   * @param event Keyboard event
   */
  const handleArrowKeyPress = useCallback(
    (event: KeyboardEvent) => {
      const enableKeyBinding = !!keyboardNavigation && numOfSteps > 1;

      if (enableKeyBinding) {
        const [prev, next] =
          keyboardNavigation === 'vertical'
            ? ['ArrowUp', 'ArrowDown']
            : ['ArrowLeft', 'ArrowRight'];

        if (event.key === prev) {
          handleBack();
        } else if (event.key === next) {
          handleNext();
        }
      }
    },
    [handleBack, handleNext, keyboardNavigation, numOfSteps],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleArrowKeyPress);

    return () => {
      document.removeEventListener('keydown', handleArrowKeyPress);
    };
  }, [handleArrowKeyPress]);

  return (
    <>
      {renderTopControls && (
        <ImageControls
          activeStep={activeStep}
          handleBack={handleBack}
          handleNext={handleNext}
          steps={numOfSteps}
        />
      )}

      <ZoomPanPinch
        imageProps={{
          src: imageUrls[activeStep],
          width: '100%',
        }}
        transformWrapperProps={{
          limitToBounds: true,
          ref: transformWrapperRef,
        }}
      />

      {renderBottomControls && (
        <ImageControls
          activeStep={activeStep}
          handleBack={handleBack}
          handleNext={handleNext}
          steps={imageUrls.length}
        />
      )}
    </>
  );
};
