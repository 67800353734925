import { IIssue1STStateEnum } from 'codegen/report';
import { DISPLAY_ISSUE_TYPES_KEYS } from 'common/issueTypesAndStates';
import { ILocationData } from 'store/Modals/facilityModals/IFacilityModalsStore';

export const initialLocationData: ILocationData = {
  userOverride: undefined,
  location: '',
  verityValue: '',
  wmsValue: '',
  isReview: false,
  userOverrideValue: [],
  issueType: DISPLAY_ISSUE_TYPES_KEYS.NONE_V_DATA_OUTDATED,
  id: '',
  imageIds: [],
  rowData: {
    actions: null,
    state: IIssue1STStateEnum.New,
    barcodeExpectedFoundOn: '',
    barcodeFoundShouldBeOn: '',
    contentExpected: [],
    contentFound: [],
    contentFoundDate: '',
    issueId: '',
    LocationName: '',
    matchArray: [],
    wmsArticleNo: [],
    wmsCustomer: [],
    wmsDate: '',
    wmsQty: [],
    wmsSlotStatusVersion: null,
    customer: '',
    slotStatus: {
      verity_status: {
        result_id: '',
        slot_label: '',
        state: 'EMPTY',
        pending_backup: false,
        collected_at: '',
        barcodes: [],
        image_ids: [],
        next_scheduled_at: null,
        user_overrides: null,
        version: 0,
        staging_version: null,
        updated_at: '',
        approved: false,
        approved_by: null,
      },
    },
  },
};
