import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { DroneZoneTypes } from 'shared/map/model/drone-zones.model';
import { useStyles } from './DroneZoneVisibilityToggle.style';

export const DroneZoneVisibilityToggle = ({
  type,
  label,
  isChecked,
  onChange,
}: {
  type: DroneZoneTypes;
  label: string;
  isChecked: boolean;
  onChange: (event: React.SyntheticEvent, checked: boolean) => void;
}) => {
  const { classes, cx } = useStyles();

  const getTypeTheme = () => {
    switch (type) {
      case DroneZoneTypes.noFlyZone:
        return classes.checkboxTypeNoFlyZone;
      case DroneZoneTypes.controlledZone:
        return classes.checkboxTypeControlledZone;
      case DroneZoneTypes.openFlyZone:
      default:
        return classes.checkboxTypeOpenFlyZone;
    }
  };

  const typeThemeClassName = getTypeTheme();

  return (
    <FormControlLabel
      className={classes.labelWrapper}
      label={label}
      control={
        <Checkbox
          className={cx(classes.checkbox, typeThemeClassName)}
          checked={isChecked}
          icon={<VisibilityOffIcon />}
          checkedIcon={<VisibilityIcon />}
        />
      }
      onChange={onChange}
    />
  );
};
