import { GridFilterItem } from '@mui/x-data-grid-premium';

/**
 * A function that gets the filter on multiple columns
 * @param searchString the string to be searched
 * @param columnName the list of the columns the search will be applied to
 */
export const getFilterOnColumns = (searchString: string, columnNames: string[]): GridFilterItem[] =>
  columnNames.map((columnName) => ({
    id: columnName,
    columnField: columnName,
    operator: 'contains',
    value: searchString,
    field: columnName,
  }));
