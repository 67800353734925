import { useCallback } from 'react';

import Typography from '@mui/material/Typography';
import ListItemButton from '@mui/material/ListItemButton';
import { makeStyles } from 'tss-react/mui';

import { Spinner } from 'components/common/Spinner';
import { Box } from 'components/common/Box';

import { useClientLevelStore } from '../../store/ClientLevelStore/clientLevelStore';

const useStyles = makeStyles()((theme) => ({
  listWrapper: {
    minHeight: '220px',
    overflow: 'none',
  },
  title: {
    wordBreak: 'break-all',
  },
  subHeader: {
    fontSize: '1rem',
    wordBreak: 'break-all',
    marginRight: theme.spacing(4),
  },
  listItem: {
    width: '100%',
    height: '7rem',
    padding: theme.spacing(3),
    color: theme.palette.textSecondary.main,
    alignItems: 'center',
  },
}));

/**
 * Get the sub-title text for items in the facility list
 * @param facilityId ID of the facility
 * @param isConfigured flag indicating whether the facility is (already) configured
 * @param facilityCurrentlyLoaded ID of the facility which is currently loaded (if any)
 * @returns
 */
const getListItemSubtext = (
  facilityId: string,
  isConfigured: boolean,
  facilityCurrentlyLoaded?: string,
) => {
  let subtext = '';
  if (!isConfigured) {
    subtext += 'Not yet configured';
  }

  if (facilityId === facilityCurrentlyLoaded) {
    subtext += subtext ? ' - ' : '';
    subtext += 'Currently loaded';
  }

  return subtext;
};

/**
 * Functional component representing the list of facilities
 * @param props props of the facility list
 * @returns a facility list
 */
export const FacilitiesList = (props: {
  onSelect: (systemId: string, isConfigured?: boolean) => void;
  isListItemDisabled?: boolean;
  facilityCurrentlyLoaded?: string;
}) => {
  const { onSelect, facilityCurrentlyLoaded, isListItemDisabled } = props;

  const { stateClientLevel } = useClientLevelStore();

  const { classes } = useStyles();

  const renderFacilityList = useCallback(
    () => (
      <Box
        sx={{
          maxHeight: '35rem',
          overflowY: stateClientLevel.facilityList.length > 5 ? 'scroll' : 'auto',
        }}
      >
        {stateClientLevel.facilityList.map(({ id, name, isConfigured }) => {
          const css = classes;
          const subText = getListItemSubtext(id, isConfigured, facilityCurrentlyLoaded);

          return (
            <Box key={id}>
              <ListItemButton
                data-testid={`c-facility-${id}`}
                className={css.listItem}
                disabled={isListItemDisabled}
                divider
                onClick={() => onSelect(id, isConfigured)}
              >
                <Box width="100%">
                  <Typography data-testid="c-facility-title" className={css.title} variant="h6">
                    {name}
                  </Typography>
                  <Typography
                    data-testid="c-facility-subtitle"
                    className={css.subHeader}
                    variant="subtitle1"
                    color="textSecondary"
                  >
                    {subText}
                  </Typography>
                </Box>
              </ListItemButton>
            </Box>
          );
        })}
      </Box>
    ),
    [stateClientLevel.facilityList, classes, facilityCurrentlyLoaded, isListItemDisabled, onSelect],
  );

  return !stateClientLevel.isFacilityListLoading ? renderFacilityList() : <Spinner />;
};
