import React, { useMemo } from 'react';
import { Outlines, Box as ThreeBox } from '@react-three/drei';
import { useTheme } from '@mui/material';
import { Color, Vector3 } from 'three';

interface BinHighlightProps {
  position: Vector3;
  scale: Vector3;
  normal: Vector3;
}

export const BinHighlight: React.FC<BinHighlightProps> = ({ position, scale, normal }) => {
  const theme = useTheme();
  const outlineColor = useMemo(
    () => new Color(theme.palette.primary.main),
    [theme.palette.primary.main],
  );
  const highlightScale = useMemo(
    () =>
      scale
        .clone()
        .add(
          new Vector3(0.01, 0.01, 0.01).multiply(
            new Vector3(Math.abs(normal.y), Math.abs(normal.x), 1),
          ),
        ),
    [scale, normal.x, normal.y],
  );

  return (
    <ThreeBox position={position} scale={highlightScale}>
      <Outlines thickness={0.05} color={outlineColor} />
      <meshBasicMaterial transparent opacity={0} />
    </ThreeBox>
  );
};

BinHighlight.displayName = 'BinHighlight';
