import { Box, useTheme } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { IRuleActionSTIssueLogicEnum } from 'codegen/facility_settings';
import { getBarcodeColor } from 'common/functions/barcodes/getBarcodeColor.util';
import { BarcodeRow } from '../models/BarcodeRow.model';
import { BarcodeMatchLogic } from '../../../../../../common/functions/barcodes/getBarcodeMuiColor.util';

export const BarcodeText = ({
  params,
  barcodeMatchLogic,
  issueLogic,
  isStale,
}: {
  params: GridRenderCellParams<BarcodeRow>;
  barcodeMatchLogic?: BarcodeMatchLogic | null;
  issueLogic?: IRuleActionSTIssueLogicEnum | null;
  isStale?: boolean;
}) => {
  const theme = useTheme();

  const statusColor = isStale
    ? theme.palette.grey[500]
    : getBarcodeColor(params.row.barcodeStatus, barcodeMatchLogic, issueLogic, theme);

  return (
    <Box color={statusColor} component="div">
      {params.value}
    </Box>
  );
};
