import { ILocationDataST, IVeritySlotStatus1ST } from 'codegen/report';
import { ILocationData } from 'store/Modals/facilityModals/IFacilityModalsStore';

export const getContentFound = (locationData: ILocationData | ILocationDataST) => {
  if (Object.hasOwn(locationData, 'rowData')) {
    return (locationData as ILocationData)?.rowData?.contentFound;
  }
  const verityStatus = (locationData as ILocationDataST)?.verity_status as IVeritySlotStatus1ST;
  return verityStatus?.barcodes;
};
