import { AlertTitle } from '@mui/material';
import Alert from '@mui/material/Alert';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';

export const SystemWillUpdateAlert = ({ updateDate }: { updateDate: moment.Moment }) => {
  const twoHoursFromUpdateDate = updateDate.clone().add(2, 'hours').format('HH:mm DD/MM/YYYY');

  return (
    <Alert severity="warning">
      <AlertTitle>System update planned</AlertTitle>
      <p>
        Between {updateDate.format('HH:mm')} and {twoHoursFromUpdateDate} the system will be down
        for an update.
      </p>
      Check our release notes:&nbsp;
      <Link to="https://support.verity.net/support/solutions/articles/80001074063-release-notes-verity-system">
        here
      </Link>
    </Alert>
  );
};
