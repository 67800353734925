import { CustomSelect } from 'components/common/CustomFormComponents/CustomSelect';
import { SingleChoiceParamFieldProps } from './NameAndParamsForm.model';

export const SingleChoiceParamField = (props: SingleChoiceParamFieldProps) => (
  <CustomSelect
    testId={`c-single-choice-dropdown-${props.index}`}
    variant="outlined"
    margin="normal"
    disabled={false}
    id={props.param.unique_name}
    label={props.param.user_facing_name}
    name={props.param.unique_name}
    value={props.paramValue}
    defaultValue=""
    valueOptions={props.valueOptions}
    onChange={(e) => props.onChange(e)}
    error={props.isInvalidParamValue}
    errorMessage={props.helperText}
  />
);
