import { IReportParameterST } from 'codegen/inventory_request';
import {
  isParamTextInput,
  isParamSingleChoiceSelect,
  isParamMultiChoiceSelect,
} from 'common/functionsReportSpec';

export const getTypeOfParam = (
  param: IReportParameterST,
): 'TEXT' | 'SINGLE_CHOICE' | 'MULTI_CHOICE' | 'unknown' => {
  if (isParamTextInput(param)) return 'TEXT';
  if (isParamSingleChoiceSelect(param)) return 'SINGLE_CHOICE';
  if (isParamMultiChoiceSelect(param)) return 'MULTI_CHOICE';
  return 'unknown';
};
