import { IReportParameterST } from 'codegen/inventory_request';
import { DropDownInputSettingsST, InputSettingsOptionsST } from 'interfaces';

export const getValueOptions = (param: IReportParameterST) =>
  (param.input_settings as DropDownInputSettingsST).options?.map(
    (option: InputSettingsOptionsST) => ({
      label: option.label,
      value: option.value,
    }),
  );
