import { Theme } from '@mui/material';
import { createSvgIcon } from '@mui/material/utils';
import { Box } from 'components/common/Box';

interface IIconProps {
  theme: Theme;
  color?: string;
  active: boolean;
  fontSize?: string;
}

export const DroneArrowDownIcon = ({ theme, color, active, fontSize }: IIconProps) => {
  const fill = color || (active ? theme.palette.primary.light : theme.palette.grey[600]);

  const Icon = createSvgIcon(
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 1.86V2.13601C10 2.34151 9.84428 2.51358 9.6398 2.53402L5.0398 2.99402C5.01333 2.99667 4.98667 2.99667 4.9602 2.99402L0.360199 2.53402C0.155718 2.51358 0 2.34151 0 2.13601V1.86C0 1.6545 0.155718 1.48243 0.360199 1.46199L4.9602 1.00199C4.98667 0.999338 5.01333 0.999338 5.0398 1.00199L9.6398 1.46199C9.84428 1.48243 10 1.6545 10 1.86Z"
        fill={fill}
      />
      <path
        d="M24 1.86V2.13601C24 2.34151 23.8443 2.51358 23.6398 2.53402L19.0398 2.99402C19.0133 2.99667 18.9867 2.99667 18.9602 2.99402L14.3602 2.53402C14.1557 2.51358 14 2.34151 14 2.13601V1.86C14 1.6545 14.1557 1.48243 14.3602 1.46199L18.9602 1.00199C18.9867 0.999338 19.0133 0.999338 19.0398 1.00199L23.6398 1.46199C23.8443 1.48243 24 1.6545 24 1.86Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 3.99799C4.22386 3.99799 4 4.22184 4 4.49799V5.99799C3.44772 5.99799 3 6.4457 3 6.99799C3 7.55027 3.44772 7.99799 4 7.99799H6.25439C6.5051 8.35741 6.78868 8.69221 7.10076 8.99803C6.0283 10.049 5.29253 11.4422 5.07025 13.001C4.99229 13.5477 5.4475 13.998 5.99979 13.998C6.55207 13.998 6.98936 13.5458 7.09851 13.0044C7.32309 11.8905 7.91784 10.9108 8.74889 10.199C9.72028 10.7093 10.8263 10.998 11.9997 10.998C13.1732 10.998 14.2792 10.7092 15.2506 10.1989C16.0817 10.9107 16.6765 11.8905 16.9011 13.0044C17.0102 13.5458 17.4475 13.998 17.9998 13.998C18.5521 13.998 19.0073 13.5477 18.9293 13.001C18.707 11.4422 17.9712 10.0489 16.8987 8.99794C17.2108 8.69215 17.4943 8.35738 17.745 7.99799H20C20.5523 7.99799 21 7.55027 21 6.99799C21 6.4457 20.5523 5.99799 20 5.99799V4.49799C20 4.22184 19.7761 3.99799 19.5 3.99799H18.5C18.2239 3.99799 18 4.22184 18 4.49799V5.99799H6V4.49799C6 4.22184 5.77614 3.99799 5.5 3.99799H4.5Z"
        fill={fill}
      />
      <path
        d="M16.8922 18.7618C17.0359 18.603 17.0359 18.3454 16.8922 18.1866L16.5043 17.7579C16.3607 17.5992 16.1278 17.5991 15.9841 17.7577L13.2721 20.7495C13.0401 21.0054 12.6441 20.8238 12.6441 20.4616V14.4067C12.6441 14.1821 12.4793 14 12.276 14H11.724C11.5207 14 11.3559 14.1821 11.3559 14.4067V20.4592C11.3559 20.8217 10.9592 21.0031 10.7274 20.7465L8.02235 17.7516C7.87867 17.5925 7.64546 17.5924 7.50164 17.7513L7.10779 18.1866C6.96407 18.3454 6.96407 18.603 7.10779 18.7618L11.7398 23.8809C11.8835 24.0397 12.1165 24.0397 12.2602 23.8809L16.8922 18.7618Z"
        fill={fill}
      />
      <path
        d="M16.8922 18.7618C17.0359 18.603 17.0359 18.3454 16.8922 18.1866L16.5043 17.7579C16.3607 17.5992 16.1278 17.5991 15.9841 17.7577L13.2721 20.7495C13.0401 21.0054 12.6441 20.8238 12.6441 20.4616V14.4067C12.6441 14.1821 12.4793 14 12.276 14H11.724C11.5207 14 11.3559 14.1821 11.3559 14.4067V20.4592C11.3559 20.8217 10.9592 21.0031 10.7274 20.7465L8.02235 17.7516C7.87867 17.5925 7.64546 17.5924 7.50164 17.7513L7.10779 18.1866C6.96407 18.3454 6.96407 18.603 7.10779 18.7618L11.7398 23.8809C11.8835 24.0397 12.1165 24.0397 12.2602 23.8809L16.8922 18.7618Z"
        fill={fill}
      />
    </svg>,
    'DroneArrowDownIcon',
  );

  return (
    <Box>
      <Icon sx={{ fontSize }} />
    </Box>
  );
};
