import { useTheme } from '@mui/material';
import { muiColorToTheme } from 'common/functions/issues/issueColorFunctions';
import { IBarcodeST } from '../../../../../codegen/location_information';
import { calculateBarcodeFrameDimensions } from './utils/calculateBarcodeFrameDimensions';
import { getBarcodeOnImageColor } from './utils/getBarcodeOnImageColor';

export const BarcodeLabel = ({
  barcodeMetadata,
  color,
  isStale,
}: {
  barcodeMetadata: IBarcodeST;
  color?: 'success' | 'error' | 'warning';
  isStale?: boolean;
}) => {
  const theme = useTheme();
  const themeColor = color
    ? muiColorToTheme(color, theme)
    : {
        main: 'GrayText',
      };

  const dimensions = calculateBarcodeFrameDimensions(barcodeMetadata);
  return (
    <div
      style={{
        position: 'absolute',
        // Add top+height here, so the label is placed underneath the respective barcode frame
        top: `${dimensions.topPc + dimensions.heightPc}%`,
        left: `${dimensions.leftPc}%`,
        color: '#FFFFFF',
        fontSize: '8px',
        background: getBarcodeOnImageColor(!!isStale, barcodeMetadata.status, themeColor, theme),
        padding: '1px 4px 0px 4px',
      }}
    >
      {barcodeMetadata.udb_value}
    </div>
  );
};
