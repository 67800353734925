import {
  Collapse,
  Divider,
  FormControl,
  FormControlLabel,
  Switch,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { DesktopDateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { IReportSpecificationST } from 'codegen/report_specification';
import { PICKER_DATETIME_FORMAT, WeekDayShort } from 'common/datetimeFormats';
import { TRIGGERING_SOURCE } from 'common/reportSpecifications';
import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Box } from 'components/common/Box';
import { schedulerFormStyles } from '../styles';
import { handleStartTimeChange } from '../utils/handleStartTimeChange';
import { handleEndTimeChange } from '../utils/handleEndTimeChange';
import { Recurrence } from './Recurrence/Recurrence';
import { handleRecurring } from '../utils/handleRecurring';
import { ScheduleFormAction, ScheduleFormState } from '../reducers/ScheduleForm.model';

export const ScheduleFormDateAndRecurrence = ({
  systemId,
  timezone,
  reportSpec,
  state,
  allowPastStartDate,
  setDays,
  dispatch,
}: {
  systemId: string;
  timezone: string;
  reportSpec: IReportSpecificationST;
  state: ScheduleFormState;
  allowPastStartDate: boolean;
  setDays: (days: WeekDayShort[]) => void;
  dispatch: React.Dispatch<ScheduleFormAction>;
}) => {
  const { classes } = schedulerFormStyles();
  return (
    <form className={classes.form} noValidate>
      <Box className={!state.isRecurring ? '' : classes.formFlexWrapper}>
        <FormControl className={classes.formControl} variant="outlined">
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDateTimePicker
              label="Start"
              ampm={false}
              disablePast={true}
              inputFormat={PICKER_DATETIME_FORMAT}
              mask="____/__/__ __:__"
              value={state.dateFrom}
              onChange={(dateFrom: moment.MomentInput) =>
                handleStartTimeChange(dateFrom, allowPastStartDate, reportSpec, dispatch)
              }
              PopperProps={{
                placement: 'top-end',
              }}
              renderInput={(params: TextFieldProps) => (
                <TextField
                  error={Boolean(state.errors.validDateFrom)}
                  helperText={state.errors.validDateFrom?.toString()}
                  data-testid="c-datetime-from-input"
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
        </FormControl>

        {reportSpec.scheduling.triggering_source === TRIGGERING_SOURCE.EXTERNAL_EVENT && (
          <FormControl className={classes.formControl} variant="outlined">
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDateTimePicker
                label="End"
                minDate={moment(state.dateFrom)}
                ampm={false}
                inputFormat={PICKER_DATETIME_FORMAT}
                mask="____/__/__ __:__"
                value={state.dateUntil}
                onChange={(dateUntil: moment.MomentInput) =>
                  handleEndTimeChange(dateUntil, allowPastStartDate, reportSpec, dispatch)
                }
                PopperProps={{
                  placement: 'top-end',
                }}
                renderInput={(params: TextFieldProps) => (
                  <TextField
                    error={Boolean(state.errors.validDateUntil)}
                    helperText={state.errors.validDateUntil?.toString()}
                    data-testid="c-datetime-until-input"
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </FormControl>
        )}
        <Collapse className={classes.expandContent} in={state.isRecurring}>
          {state.isRecurring &&
            reportSpec.scheduling.triggering_source === TRIGGERING_SOURCE.TIME_BASED_SCHEDULE && (
              <FormControl className={classes.formControl} variant="outlined">
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDateTimePicker
                    label="End"
                    disablePast={!allowPastStartDate}
                    ampm={false}
                    inputFormat={PICKER_DATETIME_FORMAT}
                    mask="____/__/__ __:__"
                    value={state.dateUntil}
                    onChange={(dateUntil: moment.MomentInput) =>
                      handleEndTimeChange(dateUntil, allowPastStartDate, reportSpec, dispatch)
                    }
                    PopperProps={{
                      placement: 'top-end',
                    }}
                    renderInput={(params: TextFieldProps) => (
                      <TextField
                        error={Boolean(state.errors.validDateUntil)}
                        helperText={state.errors.validDateUntil?.toString()}
                        data-testid="c-datetime-until-input"
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </FormControl>
            )}
        </Collapse>
      </Box>
      <Divider className={classes.divider} />
      {reportSpec.scheduling.allows_recurrence ? (
        <Box display="flex" flexDirection="column" justifyContent="center">
          <FormControlLabel
            style={{ textAlign: 'center' }}
            control={
              <Switch
                data-testid="c-recurring-checkbox"
                checked={state.isRecurring}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleRecurring(event, dispatch)
                }
                name="recurring"
              />
            }
            label="Make recurring"
          />
          <Collapse className={classes.expandContent} in={state.isRecurring}>
            <Recurrence
              systemId={systemId}
              scheduleFormState={state}
              setDays={setDays}
              dispatch={dispatch}
              reportSpecTriggering={reportSpec.scheduling.triggering_source}
              timezone={timezone}
            />
          </Collapse>
        </Box>
      ) : null}
    </form>
  );
};
