import { useQuery } from '@tanstack/react-query';
import { getMap3DService } from 'services/services';
import { NodeST } from 'shared/map-container/MapContainer.model';
import { QueryNames } from 'ts-types/QueryNames';

const getMap = async (systemId: string): Promise<NodeST> => {
  const mapInfo = await getMap3DService().getFacilityMap3d(systemId);

  const mapJSON = await fetch(mapInfo.data.url).then((response) => response.json());

  return mapJSON;
};

export function useFacilityMap(systemId: string) {
  return useQuery({
    queryKey: [QueryNames.GET_FACILITY_MAP_3D, systemId],
    queryFn: () => getMap(systemId ?? ''),
    staleTime: 24 * 60 * 1000,
  });
}
