import isArray from 'lodash/isArray';
import takeRight from 'lodash/takeRight';
import find from 'lodash/find';
import LZString from 'lz-string';

import { HeadCellProp } from 'components/EnhancedTable/types/cell';
import { OrderValue } from 'components/EnhancedTable/types/rows';

import { getComparator, stableSort } from '../functions/sortFunctions';

export const getInitialTableColumnSortOrder = (
  orderFromHistory: 'asc' | 'desc' | '',
  orderFromProp: 'asc' | 'desc' | '',
) => {
  let tableInitialOrder = '';

  // 1. First check if there is order info in the window.history.state
  //    (orderFromHistory) and set it as table order if there is (this
  //    keeps track of table order when navigating back and forth through
  //    the app by using browser navigation buttons or in-app back button
  //    or when reloading the page)
  // 2. Else, if the component's initialOrder prop is provided (orderFromProp),
  //    set it as table order
  // 3. Else set order to 'asc' (ascending)
  if (orderFromHistory) {
    tableInitialOrder = orderFromHistory;
  } else if (orderFromProp) {
    tableInitialOrder = orderFromProp;
  } else {
    tableInitialOrder = 'asc';
  }

  return tableInitialOrder as 'asc' | 'desc' | '';
};

export const getInitialTableColumnToSortBy = (
  orderByFromHistory: string,
  orderByFromProp: string,
  firstTableColumnId: string,
) => {
  let tableInitialOrderBy = '';

  // 1. First check if there is orderBy info in the window.history.state
  //    (orderByFromHistory) and set it as a column by which the table is
  //    ordered by if there is (this keeps track of table orderBy column
  //    when navigating back and forth through the app by using browser
  //    navigation buttons or in-app back button or when reloading the page)
  // 2. Else, if the component's initialOrderBy prop is provided (orderByFromProp),
  //    set it as a column by which the table is ordered by
  // 3. Else set a column by which the table is ordered by to the first
  //    one that is visible in the table (firstTableColumnId)
  if (orderByFromHistory) {
    tableInitialOrderBy = orderByFromHistory;
  } else if (orderByFromProp) {
    tableInitialOrderBy = orderByFromProp;
  } else {
    tableInitialOrderBy = firstTableColumnId;
  }

  return tableInitialOrderBy;
};

/**
 * Get a normalized value of the content of a cell
 */
export const getCellValueCopy = (key: string, cellValue: any) => {
  const cellValueType = typeof cellValue;

  let cellValueCopy;
  switch (cellValueType) {
    case 'string':
      cellValueCopy = cellValue.toLowerCase();
      break;

    case 'boolean':
      if (key !== 'canFly') break;
      cellValueCopy = cellValue.toString().toLowerCase();
      break;

    case 'object':
      if (isArray(cellValue)) {
        // create a deep copy of the array
        // so that we don't change the values displayed on the table
        cellValueCopy = [...cellValue];
        // set string values in the array to lower case
        cellValueCopy.forEach((el, index) => {
          if (typeof el === 'string') {
            cellValueCopy[index] = el.toLowerCase();
          }
        });
      }
      break;

    case 'number':
    default:
      cellValueCopy = cellValue;
      break;
  }
  return cellValueCopy;
};

/**
 * Get the amending data for the given list of rows
 * @param rows rows
 * @param currentRow current row
 * @param reportId report ID
 * @returns rows for amending
 */
export const getAmendingRowsData = (rows: any[], currentRow: any, reportId?: string) =>
  // takeRight takes last two elements of the verityValue string and
  // checks if it is equal to ' *'. In case it is, we know this value was already
  // amended by Verity user
  rows.map((issueData: any) => ({
    ...issueData.actions?.data,
    locationIssueType: issueData.locationIssueType,
    verityAmended: takeRight(issueData.verityValue, 2).join('') === ' *' ? ' *' : '',
    selected: issueData.id === currentRow.id,
    showContentSetByUser: issueData.userValue !== '-',
    reportId,
    verityDate: issueData.verityDate,
  }));

/**
 * Return the data of the given list of rows and and a selected flag
 * @param rows list of rows
 * @param selectedRow row which is currently selected
 */
export const getLocationListData = (rows: any[], selectedRow: any) => {
  const payload = rows?.map((locationData: any) => ({
    ...locationData.actions?.data,
    selected: locationData?.location === selectedRow?.location,
  }));

  return payload;
};

export const getSearchableColumns = (headCells: Array<HeadCellProp>) =>
  headCells.filter((column) => column.searchable).map((column) => column.id);

export const applyHeadCellTransformation = (
  key: string,
  value: any,
  headCells: Array<HeadCellProp>,
) => {
  let cellValueCopy = getCellValueCopy(key, value);
  // apply cell value formatting, if defined, so we compare formatted cell value
  // (what is displayed in the table) with the search term
  const currentCell = find(headCells, { id: key });

  if (currentCell) {
    const { format } = currentCell;
    if (format) {
      cellValueCopy = format(value);
    }
  }
  return cellValueCopy;
};

export const filterRowsBySearchTerm = <T extends object>(
  rows: T[],
  searchTerm: string,
  headCells: Array<HeadCellProp>,
) => {
  const searchableColumns = getSearchableColumns(headCells);

  return rows.filter((row) => {
    let matchFound = false;

    Object.entries(row).forEach(([key, value]: [string, any]) => {
      // if a match was already found in some cell of the current row,
      // skip all the other cells
      if (matchFound) return [key, value];
      // ignore boolean cell values (except canFly) and cells that are defined as non-searchable
      if (typeof value === 'boolean' && key !== 'canFly') return [key, value];
      if (!searchableColumns.includes(key)) return [key, value];

      const cellValueCopy = applyHeadCellTransformation(key, value, headCells);

      // If searchTerm is found in table cell, set matchFound to true
      return (matchFound = String(cellValueCopy).toLowerCase().includes(searchTerm));
    });

    return matchFound;
  });
};

export const sortRows = <T>(rowsToSort: T[], order: OrderValue, orderBy: keyof T) =>
  stableSort<T>(rowsToSort, getComparator(order, orderBy));

export const decodeGridStateFromUrl = (dataGridStateFromURL: string | null) => {
  if (!dataGridStateFromURL) {
    return undefined;
  }
  try {
    const parsedGridState = JSON.parse(
      LZString.decompressFromEncodedURIComponent(dataGridStateFromURL),
    );
    return parsedGridState || undefined;
  } catch {
    return undefined;
  }
};
