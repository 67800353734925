import { Grid, Typography } from '@mui/material';
import { Box } from 'components/common/Box';
import { ILocationData } from 'store/Modals/facilityModals/IFacilityModalsStore';
import { IBarcodeMatchLogicST, IIssueLogicST } from 'codegen/report';
import { DISPLAY_ISSUE_TYPES } from 'common/issueTypesAndStates';
import { IRuleActionSTIssueLogicEnum } from 'codegen/facility_settings';
import { getIssueLogicDescription } from './utils/getIssueLogicDescription';
import { getBarcodeMatchingLogicDescription } from './utils/getBarcodeMatchingLogicDescription';
import { getVerityValuesAppliedByIssueLogic } from '../../../../../../common/functions/locations/getVerityValuesAppliedByIssueLogic';

export const LocationDiagnostics = ({
  locationData,
  issueLogic,
}: {
  locationData: ILocationData;
  issueLogic?: IRuleActionSTIssueLogicEnum;
}) => {
  const hasBeenVisited = locationData.rowData.contentFoundDate !== '-';

  const { verityState: appliedState } = getVerityValuesAppliedByIssueLogic(
    locationData,
    issueLogic,
  );

  const isEsdEmptyButBarcodesFound =
    locationData.verityState === 'EMPTY' && appliedState === 'BARCODE';

  return (
    <Box
      textAlign="left"
      paddingTop="1em"
      paddingRight="2em"
      paddingLeft="2em"
      paddingBottom="2em"
      width="42em"
    >
      <Grid container>
        <Grid item md={12}>
          <Typography variant="h5">Diagnostics for location {locationData.location}</Typography>
        </Grid>
        {!hasBeenVisited && (
          <Grid item md={12} mb={2}>
            <Typography variant="h6" mt="1em">
              This Location has never been visited
            </Typography>
          </Grid>
        )}
        {hasBeenVisited && (
          <>
            <Grid item md={12} mt="1em">
              <Typography variant="h6">
                Issue type:{' '}
                {locationData.issueType
                  ? DISPLAY_ISSUE_TYPES[locationData.issueType]
                  : 'no issue detected'}
              </Typography>
            </Grid>
            <Grid item md={12} mb={2}>
              <Typography variant="h6" mt="1em">
                Applied Issue Logic
              </Typography>
            </Grid>
            <Grid item md={5}>
              <Typography mt="1em" sx={{ fontWeight: 'bold' }}>
                {locationData.rowData.slotStatus.verity_status.issue_logic_used}
              </Typography>
            </Grid>
            <Grid item md={7}>
              {getIssueLogicDescription(
                locationData.rowData.slotStatus.verity_status.issue_logic_used as IIssueLogicST,
              )}
            </Grid>
            <Grid item md={12}>
              <Typography variant="h6" mt="2em" mb={2}>
                Applied Barcode Matching Logic
              </Typography>
            </Grid>
            <Grid item md={5}>
              <Typography mt="1em" sx={{ fontWeight: 'bold' }}>
                {locationData.rowData.slotStatus.verity_status.barcode_match_logic_used}
              </Typography>
            </Grid>
            <Grid item md={7}>
              {getBarcodeMatchingLogicDescription(
                locationData.rowData.slotStatus.verity_status
                  .barcode_match_logic_used as IBarcodeMatchLogicST,
              )}
            </Grid>
            <>
              <Grid item md={12}>
                <Typography variant="h6" mt="2em" mb={2}>
                  {isEsdEmptyButBarcodesFound && (
                    <span>
                      ESD (Empty Slot Detection) returns Empty but Barcodes have been found
                    </span>
                  )}
                  {!isEsdEmptyButBarcodesFound && (
                    <span>ESD (Empty Slot Detection) and Barcodes detection results</span>
                  )}
                </Typography>
              </Grid>
              <Grid
                item
                container
                md={12}
                pl={2}
                pr={2}
                pb={1}
                mb={1}
                sx={{ borderBottom: '2px solid black' }}
              >
                <Grid item md={4}>
                  <Typography fontWeight="bold">ESD</Typography>
                </Grid>
                <Grid item md={4}>
                  <Typography fontWeight="bold">Barcodes</Typography>
                </Grid>
                <Grid item md={4}>
                  <Typography fontWeight="bold">Issue logic opted for</Typography>
                </Grid>
              </Grid>
              <Grid item md={12} pl={2} pr={2} container>
                <Grid item md={4}>
                  <Typography>{locationData.verityState}</Typography>
                </Grid>
                <Grid item md={4}>
                  <Typography>
                    {appliedState === 'BARCODE' ? 'detected' : 'not-detected'}
                  </Typography>
                </Grid>
                <Grid item md={4}>
                  <Typography>
                    {locationData.verityState === appliedState && (
                      <Typography>ESD and Barcodes detection match</Typography>
                    )}
                    {locationData.verityState !== appliedState && (
                      <Typography>{appliedState === 'BARCODE' ? 'barcodes' : 'ESD'}</Typography>
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </>
          </>
        )}
      </Grid>
    </Box>
  );
};
