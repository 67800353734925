import React from 'react';
import { IAuthenticationResponseST, IUserAttributesST } from 'codegen/authentication';
import { ClientLevelAction } from 'store/ClientLevelStore/ClientLevelStore.model';
import { IFacilityInfoST } from 'codegen/facility';
import { IFacilitySettingsST } from 'codegen/facility_settings';
import {
  FacilityActionNames,
  FacilityLevelAction,
} from '../../store/FacilityLevelStore/facilityLevelActions';
import { ClientLevelActionNames } from '../../store/ClientLevelStore/ClientLevelActions';
import { UserLevelAction, UserLevelActionNames } from '../../store/UserLevelStore/userLevelActions';
import { getLogPrefixForType } from './logFunctions';

/**
 * Static class for dealings with the local storage
 * Will trigger react render on any change
 */
export class LocalStore {
  /**
   * Function for triggering react update
   * Should be called after any change to the local storage
   */
  private static triggerReactUpdate: (s?: string) => void;

  private static logPrefix = getLogPrefixForType('STORE', 'LocalStore');

  /**
   * Set function for triggering react update
   * @param triggerReactUpdate function for triggering react update
   */
  static setTriggerUpdate(triggerReactUpdate: VoidFunction) {
    console.debug(LocalStore.logPrefix, '=> setTriggerUpdate called (react force rerender set)');
    this.triggerReactUpdate = triggerReactUpdate;
  }

  // TODO:: this function needs to be improved significantly
  // so that it clears all stores and local storage. A potential solution
  // is to  wrap it in a component or in a hook.
  static cleanStateAndLocalStorage = ({
    dispatchFacilityLevel,
    dispatchClientLevel,
    dispatchUserLevelStore,
  }: {
    dispatchFacilityLevel: React.Dispatch<FacilityLevelAction>;
    dispatchClientLevel: React.Dispatch<ClientLevelAction>;
    dispatchUserLevelStore: (action: UserLevelAction) => void;
  }) => {
    dispatchFacilityLevel({ type: FacilityActionNames.CLEAR_FACILITY_STORE });
    dispatchClientLevel({ type: ClientLevelActionNames.RESET, payload: null });
    dispatchUserLevelStore({ type: UserLevelActionNames.CLEAR_AUTH_PROCESS_DETAILS });
    // NOTE: these settings needs to be kept alive across sessions
    // in the future we should consider moving this to a back-end based solution
    // eno 2024-10-03
    const entriesToBeKept = [
      'savedGridState-warehouse-status',
      'savedGridState-report',
      'barcodeTableConfig',
      'showBarcodeHighlighting',
    ];

    Object.keys(localStorage).forEach((key) => {
      if (!entriesToBeKept.includes(key)) {
        localStorage.removeItem(key);
      }
    });
    this.triggerReactUpdate('cleanStateAndLocalStorage');
  };

  static clearFacilityDataFromLocalStorage = (): void => {
    localStorage.removeItem('settingsData');
    localStorage.removeItem('facilityName');
    localStorage.removeItem('clientName');
    localStorage.removeItem('clientLogoUrl');
    localStorage.removeItem('timezone');
    localStorage.removeItem('slots');
    this.triggerReactUpdate('clearFacilityDataFromLocalStorage');
  };

  /**
   * Store data to local storage
   * @param param0 data to be stored
   */
  static dataToLocalStorage = ({
    username,
    facilitySlots,
    facilityData,
    settingsData,
  }: {
    username?: string;
    facilitySlots?: string[];
    facilityData?: IFacilityInfoST;
    settingsData?: IFacilitySettingsST;
  }) => {
    if (facilityData) {
      localStorage.setItem('facilityName', facilityData.name);
      localStorage.setItem('clientName', facilityData.client);
      localStorage.setItem('clientLogoUrl', facilityData.logo_url);
      localStorage.setItem('timezone', facilityData.timezone);
    }

    if (username) {
      localStorage.setItem('username', username);
    }

    if (facilitySlots) {
      localStorage.setItem('slots', JSON.stringify(facilitySlots));
    }

    if (settingsData) {
      localStorage.setItem('settingsData', JSON.stringify(settingsData));
    }

    this.triggerReactUpdate('dataToLocalStorage');
  };

  /**
   * AuthData functions
   */
  static setAuthData = (authData: IAuthenticationResponseST) => {
    const authDataString = JSON.stringify(authData);
    localStorage.setItem('authData', authDataString);
  };

  static getAuthData = (): IAuthenticationResponseST | null => {
    const authDataString = localStorage.getItem('authData');
    if (authDataString) {
      return JSON.parse(authDataString);
    }
    console.debug(LocalStore.logPrefix, '=> getAuthData called (no authData found)');
    return null;
  };

  /**
   * UserInfo functions
   */
  static setUserAttributes = (userInfo: IUserAttributesST) => {
    const userAttributesString = JSON.stringify(userInfo);
    localStorage.setItem('userAttributes', userAttributesString);
  };

  static getUserAttributes = (): IUserAttributesST | null => {
    const userAttributesString = localStorage.getItem('userAttributes');
    if (userAttributesString) {
      return JSON.parse(userAttributesString);
    }
    console.debug(LocalStore.logPrefix, '=> getUserAttributes called (no userInfo found)');
    return null;
  };

  /**
   *  Refresh token functions
   */
  static setRefreshToken = (refreshToken: string) => {
    console.debug(LocalStore.logPrefix, '=> setRefreshToken called');
    localStorage.setItem('refreshToken', refreshToken);
  };

  static setRefreshTokenReceivedTime = (time: string) => {
    console.debug(LocalStore.logPrefix, '=> setRefreshTokenReceivedTime called');
    localStorage.setItem('refreshTokenReceivedAt', time);
  };

  static getRefreshTokenReceivedTime = (): string | null =>
    localStorage.getItem('refreshTokenReceivedAt');

  static getRefreshToken = (): string | null => localStorage.getItem('refreshToken');

  /**
   *  Id token functions
   */
  static setIdToken = (idToken: string) => {
    console.debug(LocalStore.logPrefix, '=> setIdToken called');
    localStorage.setItem('idToken', idToken);
  };

  static getIdToken = (): string => localStorage.getItem('idToken') || '';

  static setFlightDomainId = (flightDomainID: string) => {
    localStorage.setItem('flightDomainID', flightDomainID);
  };

  static getFlightDomainId = () => localStorage.getItem('flightDomainID');

  /**
   * Access token functions
   */
  static setAccessToken = (accessToken: string) => {
    console.debug(LocalStore.logPrefix, '=> setAccessToken called');
    localStorage.setItem('accessToken', accessToken);
  };

  static setAccessAndIdTokenReceivedTime = (time: string) => {
    console.debug(LocalStore.logPrefix, '=> setAccessAndIdTokenReceivedTime called');
    localStorage.setItem('authTokenReceivedAt', time);
  };

  static getAccessAndIdTokenReceivedTime = (): string | null =>
    localStorage.getItem('authTokenReceivedAt');

  static getAccessToken = (): string | null => localStorage.getItem('accessToken');

  /**
   * Session functions
   */
  static setSession = (session: string) => {
    console.debug(LocalStore.logPrefix, '=> setSession called');
    localStorage.setItem('session', session);
  };

  static setUsername = (username: string) => localStorage.setItem('username', username);

  static getTimezone = () =>
    localStorage.getItem('timezone') ?? Intl.DateTimeFormat().resolvedOptions().timeZone;

  static setTimezone = (timezone: string) => localStorage.setItem('timezone', timezone);

  /**
   * Functions for removing tokens and auth data
   */
  static removeAuthData = () => {
    localStorage.removeItem('authData');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('idToken');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('authTokenReceivedAt');
    localStorage.removeItem('refreshTokenReceivedAt');
  };
}
