import { IRequestController } from 'hooks';
import { IBaseLevelContext } from 'interfaces/inputs/storeInterfaces';
import { ClientLevelActionNames } from './ClientLevelActions';

/**
 * Types of client level actions
 */

export type ClientLevelAction =
  | { type: ClientLevelActionNames.RESET; payload: null }
  | { type: ClientLevelActionNames.SET_FACILITY_LIST; payload: IClientLevelStateFacility[] }
  | { type: ClientLevelActionNames.SET_FAILED_TO_LOAD_FACILITY_LIST; payload: boolean }
  | { type: ClientLevelActionNames.SET_FACILITY_LIST_POPULATED; payload: boolean }
  | { type: ClientLevelActionNames.SET_FACILITY_LIST_LOADING; payload: boolean };

/**
 * Interface defining the Client Level Context
 */
export interface IClientLevelContext extends IBaseLevelContext {
  /**
   * Store state
   */
  stateClientLevel: IClientLevelState;
  /**
   * Verifies whether the store contains data ready to be used.
   */
  isDataReady: () => boolean;
  /**
   * Dispatcher
   */
  dispatchClientLevel?: React.Dispatch<ClientLevelAction>;
  /**
   * Retrieve the data related with the facilities and populate facility list.
   * @param {IRequestController} requestController cancellation controller.
   * @param {boolean} populateIssues flag indicating whether the active issues per facility will
   * be retrieved and stored.
   * @returns a promise which will resolve if the operation is successful.
   */
  asyncPopulateFacilities: (
    requestController: IRequestController,
    populateIssues: boolean,
  ) => Promise<void>;
  /**
   * todo - ss
   */
  asyncRefreshFacilities: (
    requestController: IRequestController,
    populateIssues: boolean,
  ) => Promise<void>;
}

/**
 * Interface describing the State of the Client Level Store
 */
export interface IClientLevelState {
  /**
   * Facility list
   */
  facilityList: IClientLevelStateFacility[];
  /**
   * Flag which is true when there is more than one facility available.
   */
  isMultiFacility: null | boolean;
  /**
   * Flag which is true when the facility list is populated.
   */
  isFacilityListPopulated: boolean;
  /**
   * Flag which is true when the facility list is being loaded.
   */
  isFacilityListLoading: boolean;
  /**
   * Flag which is true if the facility list could not be retrieved.
   */
  failedToLoadFacilityList: boolean;
}

/**
 * Interface describing a facility in the facility list.
 */
export interface IClientLevelStateFacility {
  /**
   * (System) ID of the facility.
   */
  id: string;
  /**
   * Name of the facility.
   */
  name: string;
  /**
   * Flag which is true if the facility has already been configured.
   */
  isConfigured: boolean;
}
export const noClientLevelStateFacility: IClientLevelStateFacility = {
  id: '',
  name: '',
  isConfigured: false,
};
