import { TextField } from '@mui/material';

export const NameField = ({
  reportName,
  isInputInvalid,
  helperText,
  onChangeOrBlur,
}: {
  reportName: string;
  isInputInvalid: boolean;
  helperText?: string;
  onChangeOrBlur: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
}): JSX.Element => (
  <TextField
    id="report_name"
    data-testid="c-report-name"
    label="Report Name"
    variant="outlined"
    fullWidth={true}
    value={reportName}
    error={isInputInvalid}
    helperText={helperText}
    onBlur={(e) => onChangeOrBlur(e)}
    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeOrBlur(e)}
  />
);
