/* tslint:disable */
/* eslint-disable */
/**
 * Report
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @enum {string}
 */

export const IBarcodeMatchLogicST = {
  IntersectionNotEmpty: 'INTERSECTION_NOT_EMPTY',
  ExactMatch: 'EXACT_MATCH',
  VeritySuperset: 'VERITY_SUPERSET',
  WmsSuperset: 'WMS_SUPERSET',
} as const;

export type IBarcodeMatchLogicST = typeof IBarcodeMatchLogicST[keyof typeof IBarcodeMatchLogicST];

/**
 *
 * @export
 * @interface IErrorResponseErrorsInnerST
 */
export interface IErrorResponseErrorsInnerST {
  /**
   * Error code
   * @type {string}
   * @memberof IErrorResponseErrorsInnerST
   */
  code: string;
}
/**
 *
 * @export
 * @interface IErrorResponseST
 */
export interface IErrorResponseST {
  /**
   * Error description
   * @type {string}
   * @memberof IErrorResponseST
   */
  message: string;
  /**
   * Error timestamp
   * @type {string}
   * @memberof IErrorResponseST
   */
  timestamp: string;
  /**
   * User triggering the request
   * @type {string}
   * @memberof IErrorResponseST
   */
  user_name: string;
  /**
   * The request trace id
   * @type {string}
   * @memberof IErrorResponseST
   */
  trace_id: string;
  /**
   * The URL that can be used to report the error
   * @type {string}
   * @memberof IErrorResponseST
   */
  report_url: string | null;
  /**
   * List of Error objects.
   * @type {Array<IErrorResponseErrorsInnerST>}
   * @memberof IErrorResponseST
   */
  errors: Array<IErrorResponseErrorsInnerST>;
}
/**
 *
 * @export
 * @interface IInventoryRequestParameterST
 */
export interface IInventoryRequestParameterST {
  /**
   *
   * @type {IReportParameterUniqueNameST}
   * @memberof IInventoryRequestParameterST
   */
  unique_name: IReportParameterUniqueNameST;
  /**
   * List of values for param
   * @type {Array<string>}
   * @memberof IInventoryRequestParameterST
   */
  values: Array<string>;
}

/**
 *
 * @export
 * @interface IInventoryRequestST
 */
export interface IInventoryRequestST {
  /**
   * Timestamp of when the description was created
   * @type {string}
   * @memberof IInventoryRequestST
   */
  created_at: string;
  /**
   * Next scheduling time UTC
   * @type {string}
   * @memberof IInventoryRequestST
   */
  next_scheduling_time_utc: string;
  /**
   *
   * @type {Array<IInventoryRequestParameterST>}
   * @memberof IInventoryRequestST
   */
  params: Array<IInventoryRequestParameterST>;
  /**
   * Detailed recurrence description
   * @type {string}
   * @memberof IInventoryRequestST
   */
  recurrence_description: string;
  /**
   * A name for the report
   * @type {string}
   * @memberof IInventoryRequestST
   */
  report_name: string;
  /**
   *
   * @type {IReportSpecificationST}
   * @memberof IInventoryRequestST
   */
  report_spec: IReportSpecificationST;
  /**
   * The unique identifier of the request
   * @type {string}
   * @memberof IInventoryRequestST
   */
  request_id: string;
  /**
   * The version of the request
   * @type {number}
   * @memberof IInventoryRequestST
   */
  request_version: number;
  /**
   * The name the user creating the request
   * @type {string}
   * @memberof IInventoryRequestST
   */
  requesting_user_email: string;
  /**
   * The unique identifier of the user creating the request
   * @type {string}
   * @memberof IInventoryRequestST
   */
  requesting_user_id: string;
  /**
   * The recurrence rule of an inventory request specifies its recurrence
   * @type {string}
   * @memberof IInventoryRequestST
   */
  rrule: string;
  /**
   * Inventory request state
   * @type {string}
   * @memberof IInventoryRequestST
   */
  state: IInventoryRequestSTStateEnum;
  /**
   * Priority of an inventory request. Higher number means higher priority.
   * @type {number}
   * @memberof IInventoryRequestST
   */
  priority: number;
  /**
   * Report timeout in minutes. Auto-abort will be triggered if the report isn\'t finish within the expected time. When not set, the deadline in facility settings will be used.
   * @type {number}
   * @memberof IInventoryRequestST
   */
  timeout: number | null;
}

export const IInventoryRequestSTStateEnum = {
  Enabled: 'ENABLED',
  Expired: 'EXPIRED',
  Disabled: 'DISABLED',
} as const;

export type IInventoryRequestSTStateEnum =
  typeof IInventoryRequestSTStateEnum[keyof typeof IInventoryRequestSTStateEnum];

/**
 *
 * @export
 * @interface IIssue1ST
 */
export interface IIssue1ST {
  /**
   * Unique id of this issue
   * @type {string}
   * @memberof IIssue1ST
   */
  issue_id: string;
  /**
   * The location associated with this issue
   * @type {string}
   * @memberof IIssue1ST
   */
  location: string;
  /**
   * The type of the issue
   * @type {string}
   * @memberof IIssue1ST
   */
  type: IIssue1STTypeEnum;
  /**
   *
   * @type {IIssueSlotStatusST}
   * @memberof IIssue1ST
   */
  slot_status: IIssueSlotStatusST;
  /**
   * The state the issue is in
   * @type {string}
   * @memberof IIssue1ST
   */
  state: IIssue1STStateEnum;
  /**
   * When this issue was first found
   * @type {string}
   * @memberof IIssue1ST
   */
  first_found_on: string | null;
  /**
   * When this issue transitioned to either SOLVED or NA
   * @type {string}
   * @memberof IIssue1ST
   */
  resolved_on: string | null;
  /**
   * Type of the issue after resolution
   * @type {string}
   * @memberof IIssue1ST
   */
  resolved_type: IIssue1STResolvedTypeEnum;
  /**
   * List of ids of issues that are linked to this issue
   * @type {Array<string>}
   * @memberof IIssue1ST
   */
  linked_issues: Array<string> | null;
  /**
   *
   * @type {IIssueWmsSlotStatusWithBarcodeDuplicateST}
   * @memberof IIssue1ST
   */
  wms_slot_status_with_barcode_duplicate: IIssueWmsSlotStatusWithBarcodeDuplicateST | null;
  /**
   *
   * @type {IIssueWmsSlotStatusWithBarcodeDuplicateST}
   * @memberof IIssue1ST
   */
  wms_slot_status_with_verity_bc: IIssueWmsSlotStatusWithBarcodeDuplicateST | null;
  /**
   *
   * @type {IIssueVeritySlotStatusWithWmsBcST}
   * @memberof IIssue1ST
   */
  verity_slot_status_with_wms_bc: IIssueVeritySlotStatusWithWmsBcST | null;
  /**
   * When the issue was snoozed
   * @type {string}
   * @memberof IIssue1ST
   */
  snoozed_on: string | null;
  /**
   * ID of user who snoozed the issue
   * @type {string}
   * @memberof IIssue1ST
   */
  snoozed_by_user_id: string | null;
  /**
   * Name of user who snoozed the issue
   * @type {string}
   * @memberof IIssue1ST
   */
  snoozed_by_user_name: string | null;
  /**
   * Date and time until the issue is snoozed
   * @type {string}
   * @memberof IIssue1ST
   */
  snoozed_until: string | null;
  /**
   * When the issue was last updated
   * @type {string}
   * @memberof IIssue1ST
   */
  updated_at: string;
  /**
   * Version of this issue
   * @type {number}
   * @memberof IIssue1ST
   */
  version: number;
}

export const IIssue1STTypeEnum = {
  Issue1WBcVEmpty: 'ISSUE1_W_BC_V_EMPTY',
  Issue2WBcNeVBc: 'ISSUE2_W_BC_NE_V_BC',
  Issue3WEmptyVBc: 'ISSUE3_W_EMPTY_V_BC',
  Issue4WEmptyVNotempty: 'ISSUE4_W_EMPTY_V_NOTEMPTY',
  Issue5WBcDuplicate: 'ISSUE5_W_BC_DUPLICATE',
  Inconclusive1WBcVNotempty: 'INCONCLUSIVE1_W_BC_V_NOTEMPTY',
  Inconclusive2WBcVNoresult: 'INCONCLUSIVE2_W_BC_V_NORESULT',
  Inconclusive3WEmptyVNoresult: 'INCONCLUSIVE3_W_EMPTY_V_NORESULT',
  Inconclusive4WInvalid: 'INCONCLUSIVE4_W_INVALID',
  Inconclusive5VInvalid: 'INCONCLUSIVE5_V_INVALID',
  C0014DoubleDepth: 'C0014_DOUBLE_DEPTH',
  C001CMissingBc: 'C001C_MISSING_BC',
} as const;

export type IIssue1STTypeEnum = typeof IIssue1STTypeEnum[keyof typeof IIssue1STTypeEnum];
export const IIssue1STStateEnum = {
  New: 'NEW',
  Known: 'KNOWN',
  Snoozed: 'SNOOZED',
  Solved: 'SOLVED',
  Na: 'NA',
} as const;

export type IIssue1STStateEnum = typeof IIssue1STStateEnum[keyof typeof IIssue1STStateEnum];
export const IIssue1STResolvedTypeEnum = {
  Correct1WEmptyVEmpty: 'CORRECT1_W_EMPTY_V_EMPTY',
  Correct2WBcEqVBc: 'CORRECT2_W_BC_EQ_V_BC',
  NoneVDataOutdated: 'NONE_V_DATA_OUTDATED',
  NoneVDataMissing: 'NONE_V_DATA_MISSING',
  NoneWDataMissing: 'NONE_W_DATA_MISSING',
  NoneSlotBlocked: 'NONE_SLOT_BLOCKED',
  C001BCorrectWBcNaVEmpty: 'C001B_CORRECT_W_BC_NA_V_EMPTY',
  C001BCorrectWBcNaVBc: 'C001B_CORRECT_W_BC_NA_V_BC',
  C001BCorrectWBcNaVNotempty: 'C001B_CORRECT_W_BC_NA_V_NOTEMPTY',
  NonePictureOnly: 'NONE_PICTURE_ONLY',
  Null: 'null',
} as const;

export type IIssue1STResolvedTypeEnum =
  typeof IIssue1STResolvedTypeEnum[keyof typeof IIssue1STResolvedTypeEnum];

/**
 *
 * @export
 * @interface IIssueCount1ST
 */
export interface IIssueCount1ST {
  [key: string]: any;

  /**
   *
   * @type {IIssueStateST}
   * @memberof IIssueCount1ST
   */
  INCONCLUSIVE1_W_BC_V_NOTEMPTY: IIssueStateST;
  /**
   *
   * @type {IIssueStateST}
   * @memberof IIssueCount1ST
   */
  INCONCLUSIVE2_W_BC_V_NORESULT: IIssueStateST;
  /**
   *
   * @type {IIssueStateST}
   * @memberof IIssueCount1ST
   */
  INCONCLUSIVE3_W_EMPTY_V_NORESULT: IIssueStateST;
  /**
   *
   * @type {IIssueStateST}
   * @memberof IIssueCount1ST
   */
  INCONCLUSIVE4_W_INVALID: IIssueStateST;
  /**
   *
   * @type {IIssueStateST}
   * @memberof IIssueCount1ST
   */
  INCONCLUSIVE5_V_INVALID: IIssueStateST;
  /**
   *
   * @type {IIssueStateST}
   * @memberof IIssueCount1ST
   */
  ISSUE1_W_BC_V_EMPTY: IIssueStateST;
  /**
   *
   * @type {IIssueStateST}
   * @memberof IIssueCount1ST
   */
  ISSUE2_W_BC_NE_V_BC: IIssueStateST;
  /**
   *
   * @type {IIssueStateST}
   * @memberof IIssueCount1ST
   */
  ISSUE3_W_EMPTY_V_BC: IIssueStateST;
  /**
   *
   * @type {IIssueStateST}
   * @memberof IIssueCount1ST
   */
  ISSUE4_W_EMPTY_V_NOTEMPTY: IIssueStateST;
  /**
   *
   * @type {IIssueStateST}
   * @memberof IIssueCount1ST
   */
  ISSUE5_W_BC_DUPLICATE: IIssueStateST;
  /**
   *
   * @type {IIssueStateST}
   * @memberof IIssueCount1ST
   */
  C0014_DOUBLE_DEPTH: IIssueStateST;
  /**
   *
   * @type {IIssueStateST}
   * @memberof IIssueCount1ST
   */
  C001C_MISSING_BC: IIssueStateST;
  /**
   *
   * @type {Array<string>}
   * @memberof IIssueCount1ST
   */
  states_in_totals: Array<IIssueCount1STStatesInTotalsEnum>;
  /**
   *
   * @type {IIssueCountTotalsST}
   * @memberof IIssueCount1ST
   */
  totals: IIssueCountTotalsST;
}

export const IIssueCount1STStatesInTotalsEnum = {
  New: 'NEW',
  Known: 'KNOWN',
  Snoozed: 'SNOOZED',
  Solved: 'SOLVED',
  Na: 'NA',
} as const;

export type IIssueCount1STStatesInTotalsEnum =
  typeof IIssueCount1STStatesInTotalsEnum[keyof typeof IIssueCount1STStatesInTotalsEnum];

/**
 *
 * @export
 * @interface IIssueCountST
 */
export interface IIssueCountST {
  [key: string]: any;

  /**
   *
   * @type {IIssueStateST}
   * @memberof IIssueCountST
   */
  INCONCLUSIVE1_W_BC_V_NOTEMPTY: IIssueStateST;
  /**
   *
   * @type {IIssueStateST}
   * @memberof IIssueCountST
   */
  INCONCLUSIVE2_W_BC_V_NORESULT: IIssueStateST;
  /**
   *
   * @type {IIssueStateST}
   * @memberof IIssueCountST
   */
  INCONCLUSIVE3_W_EMPTY_V_NORESULT: IIssueStateST;
  /**
   *
   * @type {IIssueStateST}
   * @memberof IIssueCountST
   */
  INCONCLUSIVE4_W_INVALID: IIssueStateST;
  /**
   *
   * @type {IIssueStateST}
   * @memberof IIssueCountST
   */
  INCONCLUSIVE5_V_INVALID: IIssueStateST;
  /**
   *
   * @type {IIssueStateST}
   * @memberof IIssueCountST
   */
  ISSUE1_W_BC_V_EMPTY: IIssueStateST;
  /**
   *
   * @type {IIssueStateST}
   * @memberof IIssueCountST
   */
  ISSUE2_W_BC_NE_V_BC: IIssueStateST;
  /**
   *
   * @type {IIssueStateST}
   * @memberof IIssueCountST
   */
  ISSUE3_W_EMPTY_V_BC: IIssueStateST;
  /**
   *
   * @type {IIssueStateST}
   * @memberof IIssueCountST
   */
  ISSUE4_W_EMPTY_V_NOTEMPTY: IIssueStateST;
  /**
   *
   * @type {IIssueStateST}
   * @memberof IIssueCountST
   */
  ISSUE5_W_BC_DUPLICATE: IIssueStateST;
  /**
   *
   * @type {IIssueStateST}
   * @memberof IIssueCountST
   */
  C0014_DOUBLE_DEPTH: IIssueStateST;
  /**
   *
   * @type {IIssueStateST}
   * @memberof IIssueCountST
   */
  C001C_MISSING_BC: IIssueStateST;
  /**
   *
   * @type {Array<string>}
   * @memberof IIssueCountST
   */
  states_in_totals: Array<IIssueCountSTStatesInTotalsEnum>;
  /**
   *
   * @type {IIssueCountTotalsST}
   * @memberof IIssueCountST
   */
  totals: IIssueCountTotalsST;
}

export const IIssueCountSTStatesInTotalsEnum = {
  New: 'NEW',
  Known: 'KNOWN',
  Snoozed: 'SNOOZED',
  Solved: 'SOLVED',
  Na: 'NA',
} as const;

export type IIssueCountSTStatesInTotalsEnum =
  typeof IIssueCountSTStatesInTotalsEnum[keyof typeof IIssueCountSTStatesInTotalsEnum];

/**
 *
 * @export
 * @interface IIssueCountTotalsST
 */
export interface IIssueCountTotalsST {
  /**
   *
   * @type {number}
   * @memberof IIssueCountTotalsST
   */
  ISSUE1_W_BC_V_EMPTY: number;
  /**
   *
   * @type {number}
   * @memberof IIssueCountTotalsST
   */
  ISSUE2_W_BC_NE_V_BC: number;
  /**
   *
   * @type {number}
   * @memberof IIssueCountTotalsST
   */
  ISSUE3_W_EMPTY_V_BC: number;
  /**
   *
   * @type {number}
   * @memberof IIssueCountTotalsST
   */
  ISSUE4_W_EMPTY_V_NOTEMPTY: number;
  /**
   *
   * @type {number}
   * @memberof IIssueCountTotalsST
   */
  ISSUE5_W_BC_DUPLICATE: number;
  /**
   *
   * @type {number}
   * @memberof IIssueCountTotalsST
   */
  INCONCLUSIVE1_W_BC_V_NOTEMPTY: number;
  /**
   *
   * @type {number}
   * @memberof IIssueCountTotalsST
   */
  INCONCLUSIVE2_W_BC_V_NORESULT: number;
  /**
   *
   * @type {number}
   * @memberof IIssueCountTotalsST
   */
  INCONCLUSIVE3_W_EMPTY_V_NORESULT: number;
  /**
   *
   * @type {number}
   * @memberof IIssueCountTotalsST
   */
  INCONCLUSIVE4_W_INVALID: number;
  /**
   *
   * @type {number}
   * @memberof IIssueCountTotalsST
   */
  INCONCLUSIVE5_V_INVALID: number;
  /**
   *
   * @type {number}
   * @memberof IIssueCountTotalsST
   */
  C0014_DOUBLE_DEPTH: number;
  /**
   *
   * @type {number}
   * @memberof IIssueCountTotalsST
   */
  C001C_MISSING_BC: number;
}
/**
 *
 * @export
 * @enum {string}
 */

export const IIssueLogicST = {
  Default: 'DEFAULT',
  DoubleDepth21: 'DOUBLE_DEPTH_21',
  DoubleDepth22: 'DOUBLE_DEPTH_22',
  DoubleDepthMapBased: 'DOUBLE_DEPTH_MAP_BASED',
  NaBarcode: 'NA_BARCODE',
  PictureOnly: 'PICTURE_ONLY',
  C001CMissingBc: 'C001C_MISSING_BC',
} as const;

export type IIssueLogicST = typeof IIssueLogicST[keyof typeof IIssueLogicST];

/**
 *
 * @export
 * @interface IIssueST
 */
export interface IIssueST {
  /**
   * Unique id of this issue
   * @type {string}
   * @memberof IIssueST
   */
  issue_id: string;
  /**
   * The location associated with this issue
   * @type {string}
   * @memberof IIssueST
   */
  location: string;
  /**
   * The type of the issue
   * @type {string}
   * @memberof IIssueST
   */
  type: IIssueSTTypeEnum;
  /**
   *
   * @type {IIssueSlotStatusST}
   * @memberof IIssueST
   */
  slot_status: IIssueSlotStatusST;
  /**
   * The state the issue is in
   * @type {string}
   * @memberof IIssueST
   */
  state: IIssueSTStateEnum;
  /**
   * When this issue was first found
   * @type {string}
   * @memberof IIssueST
   */
  first_found_on: string | null;
  /**
   * When this issue transitioned to either SOLVED or NA
   * @type {string}
   * @memberof IIssueST
   */
  resolved_on: string | null;
  /**
   * Type of the issue after resolution
   * @type {string}
   * @memberof IIssueST
   */
  resolved_type: IIssueSTResolvedTypeEnum;
  /**
   * List of ids of issues that are linked to this issue
   * @type {Array<string>}
   * @memberof IIssueST
   */
  linked_issues: Array<string> | null;
  /**
   *
   * @type {IIssueWmsSlotStatusWithBarcodeDuplicateST}
   * @memberof IIssueST
   */
  wms_slot_status_with_barcode_duplicate: IIssueWmsSlotStatusWithBarcodeDuplicateST | null;
  /**
   *
   * @type {IIssueWmsSlotStatusWithBarcodeDuplicateST}
   * @memberof IIssueST
   */
  wms_slot_status_with_verity_bc: IIssueWmsSlotStatusWithBarcodeDuplicateST | null;
  /**
   *
   * @type {IIssueVeritySlotStatusWithWmsBcST}
   * @memberof IIssueST
   */
  verity_slot_status_with_wms_bc: IIssueVeritySlotStatusWithWmsBcST | null;
  /**
   * When the issue was snoozed
   * @type {string}
   * @memberof IIssueST
   */
  snoozed_on: string | null;
  /**
   * ID of user who snoozed the issue
   * @type {string}
   * @memberof IIssueST
   */
  snoozed_by_user_id: string | null;
  /**
   * Name of user who snoozed the issue
   * @type {string}
   * @memberof IIssueST
   */
  snoozed_by_user_name: string | null;
  /**
   * Date and time until the issue is snoozed
   * @type {string}
   * @memberof IIssueST
   */
  snoozed_until: string | null;
  /**
   * When the issue was last updated
   * @type {string}
   * @memberof IIssueST
   */
  updated_at: string;
  /**
   * Version of this issue
   * @type {number}
   * @memberof IIssueST
   */
  version: number;
}

export const IIssueSTTypeEnum = {
  Issue1WBcVEmpty: 'ISSUE1_W_BC_V_EMPTY',
  Issue2WBcNeVBc: 'ISSUE2_W_BC_NE_V_BC',
  Issue3WEmptyVBc: 'ISSUE3_W_EMPTY_V_BC',
  Issue4WEmptyVNotempty: 'ISSUE4_W_EMPTY_V_NOTEMPTY',
  Issue5WBcDuplicate: 'ISSUE5_W_BC_DUPLICATE',
  Inconclusive1WBcVNotempty: 'INCONCLUSIVE1_W_BC_V_NOTEMPTY',
  Inconclusive2WBcVNoresult: 'INCONCLUSIVE2_W_BC_V_NORESULT',
  Inconclusive3WEmptyVNoresult: 'INCONCLUSIVE3_W_EMPTY_V_NORESULT',
  Inconclusive4WInvalid: 'INCONCLUSIVE4_W_INVALID',
  Inconclusive5VInvalid: 'INCONCLUSIVE5_V_INVALID',
  C0014DoubleDepth: 'C0014_DOUBLE_DEPTH',
  C001CMissingBc: 'C001C_MISSING_BC',
} as const;

export type IIssueSTTypeEnum = typeof IIssueSTTypeEnum[keyof typeof IIssueSTTypeEnum];
export const IIssueSTStateEnum = {
  New: 'NEW',
  Known: 'KNOWN',
  Snoozed: 'SNOOZED',
  Solved: 'SOLVED',
  Na: 'NA',
} as const;

export type IIssueSTStateEnum = typeof IIssueSTStateEnum[keyof typeof IIssueSTStateEnum];
export const IIssueSTResolvedTypeEnum = {
  Correct1WEmptyVEmpty: 'CORRECT1_W_EMPTY_V_EMPTY',
  Correct2WBcEqVBc: 'CORRECT2_W_BC_EQ_V_BC',
  NoneVDataOutdated: 'NONE_V_DATA_OUTDATED',
  NoneVDataMissing: 'NONE_V_DATA_MISSING',
  NoneWDataMissing: 'NONE_W_DATA_MISSING',
  NoneSlotBlocked: 'NONE_SLOT_BLOCKED',
  C001BCorrectWBcNaVEmpty: 'C001B_CORRECT_W_BC_NA_V_EMPTY',
  C001BCorrectWBcNaVBc: 'C001B_CORRECT_W_BC_NA_V_BC',
  C001BCorrectWBcNaVNotempty: 'C001B_CORRECT_W_BC_NA_V_NOTEMPTY',
  NonePictureOnly: 'NONE_PICTURE_ONLY',
  Null: 'null',
} as const;

export type IIssueSTResolvedTypeEnum =
  typeof IIssueSTResolvedTypeEnum[keyof typeof IIssueSTResolvedTypeEnum];

/**
 *
 * @export
 * @interface IIssueSlotStatusST
 */
export interface IIssueSlotStatusST {
  /**
   *
   * @type {string}
   * @memberof IIssueSlotStatusST
   */
  slot_label?: string;
  /**
   *
   * @type {IVeritySlotStatus1ST}
   * @memberof IIssueSlotStatusST
   */
  verity_status?: IVeritySlotStatus1ST;
  /**
   *
   * @type {IWMSSlotStatusST}
   * @memberof IIssueSlotStatusST
   */
  wms_status?: IWMSSlotStatusST;
}
/**
 *
 * @export
 * @interface IIssueStateST
 */
export interface IIssueStateST {
  /**
   *
   * @type {number}
   * @memberof IIssueStateST
   */
  NEW: number;
  /**
   *
   * @type {number}
   * @memberof IIssueStateST
   */
  KNOWN: number;
  /**
   *
   * @type {number}
   * @memberof IIssueStateST
   */
  SNOOZED: number;
  /**
   *
   * @type {number}
   * @memberof IIssueStateST
   */
  SOLVED: number;
  /**
   *
   * @type {number}
   * @memberof IIssueStateST
   */
  NA: number;
}
/**
 * @type IIssueVeritySlotStatusWithWmsBcST
 * @export
 */
export type IIssueVeritySlotStatusWithWmsBcST = IVeritySlotStatus1ST | string;

/**
 * @type IIssueWmsSlotStatusWithBarcodeDuplicateST
 * @export
 */
export type IIssueWmsSlotStatusWithBarcodeDuplicateST = IWMSSlotStatusST | string;

/**
 *
 * @export
 * @enum {string}
 */

export const ILabelStatusST = {
  Active: 'ACTIVE',
  Archived: 'ARCHIVED',
} as const;

export type ILabelStatusST = typeof ILabelStatusST[keyof typeof ILabelStatusST];

/**
 *
 * @export
 * @enum {string}
 */

export const ILabelTypeST = {
  WmsUpdateRequired: 'WMS_UPDATE_REQUIRED',
  OnPremiseCheck: 'ON_PREMISE_CHECK',
  AddToDoList: 'ADD_TO_DO_LIST',
  AddToRescanList: 'ADD_TO_RESCAN_LIST',
} as const;

export type ILabelTypeST = typeof ILabelTypeST[keyof typeof ILabelTypeST];

/**
 *
 * @export
 * @interface ILocationData1ST
 */
export interface ILocationData1ST {
  /**
   *
   * @type {string}
   * @memberof ILocationData1ST
   */
  slot_label: string;
  /**
   *
   * @type {IIssueVeritySlotStatusWithWmsBcST}
   * @memberof ILocationData1ST
   */
  verity_status: IIssueVeritySlotStatusWithWmsBcST | null;
  /**
   *
   * @type {IIssueWmsSlotStatusWithBarcodeDuplicateST}
   * @memberof ILocationData1ST
   */
  wms_status: IIssueWmsSlotStatusWithBarcodeDuplicateST | null;
  /**
   *
   * @type {ILocationDataForReviewSlotSettingsST}
   * @memberof ILocationData1ST
   */
  slot_settings?: ILocationDataForReviewSlotSettingsST;
  /**
   *
   * @type {ILocationDataForReviewLocationLabelST}
   * @memberof ILocationData1ST
   */
  location_label?: ILocationDataForReviewLocationLabelST | null;
  /**
   * State of the report location
   * @type {string}
   * @memberof ILocationData1ST
   */
  state?: ILocationData1STStateEnum;
  /**
   *
   * @type {Array<IIssue1ST>}
   * @memberof ILocationData1ST
   */
  issues: Array<IIssue1ST>;
}

export const ILocationData1STStateEnum = {
  ToBeExecuted: 'TO_BE_EXECUTED',
  PendingBackup: 'PENDING_BACKUP',
  Scanned: 'SCANNED',
  Aborted: 'ABORTED',
  Failed: 'FAILED',
  Excluded: 'EXCLUDED',
  Unreachable: 'UNREACHABLE',
} as const;

export type ILocationData1STStateEnum =
  typeof ILocationData1STStateEnum[keyof typeof ILocationData1STStateEnum];

/**
 *
 * @export
 * @interface ILocationDataCountST
 */
export interface ILocationDataCountST {
  /**
   *
   * @type {number}
   * @memberof ILocationDataCountST
   */
  ABORTED: number;
  /**
   *
   * @type {number}
   * @memberof ILocationDataCountST
   */
  FAILED: number;
  /**
   *
   * @type {number}
   * @memberof ILocationDataCountST
   */
  PENDING_BACKUP: number;
  /**
   *
   * @type {number}
   * @memberof ILocationDataCountST
   */
  SCANNED: number;
  /**
   *
   * @type {number}
   * @memberof ILocationDataCountST
   */
  TO_BE_EXECUTED: number;
  /**
   *
   * @type {number}
   * @memberof ILocationDataCountST
   */
  EXCLUDED?: number;
  /**
   *
   * @type {number}
   * @memberof ILocationDataCountST
   */
  UNREACHABLE?: number;
}
/**
 *
 * @export
 * @interface ILocationDataForReview1ST
 */
export interface ILocationDataForReview1ST {
  /**
   *
   * @type {string}
   * @memberof ILocationDataForReview1ST
   */
  slot_label: string;
  /**
   *
   * @type {IIssueVeritySlotStatusWithWmsBcST}
   * @memberof ILocationDataForReview1ST
   */
  verity_status: IIssueVeritySlotStatusWithWmsBcST | null;
  /**
   *
   * @type {IIssueWmsSlotStatusWithBarcodeDuplicateST}
   * @memberof ILocationDataForReview1ST
   */
  wms_status: IIssueWmsSlotStatusWithBarcodeDuplicateST | null;
  /**
   *
   * @type {ILocationDataForReviewSlotSettingsST}
   * @memberof ILocationDataForReview1ST
   */
  slot_settings?: ILocationDataForReviewSlotSettingsST;
  /**
   *
   * @type {ILocationDataForReviewLocationLabelST}
   * @memberof ILocationDataForReview1ST
   */
  location_label?: ILocationDataForReviewLocationLabelST | null;
  /**
   * State of the report location
   * @type {string}
   * @memberof ILocationDataForReview1ST
   */
  state?: ILocationDataForReview1STStateEnum;
  /**
   *
   * @type {Array<IIssue1ST>}
   * @memberof ILocationDataForReview1ST
   */
  issues?: Array<IIssue1ST>;
}

export const ILocationDataForReview1STStateEnum = {
  ToBeExecuted: 'TO_BE_EXECUTED',
  PendingBackup: 'PENDING_BACKUP',
  Scanned: 'SCANNED',
  Aborted: 'ABORTED',
  Failed: 'FAILED',
  Excluded: 'EXCLUDED',
  Unreachable: 'UNREACHABLE',
} as const;

export type ILocationDataForReview1STStateEnum =
  typeof ILocationDataForReview1STStateEnum[keyof typeof ILocationDataForReview1STStateEnum];

/**
 * @type ILocationDataForReviewLocationLabelST
 * @export
 */
export type ILocationDataForReviewLocationLabelST = ILocationLabelST | string;

/**
 *
 * @export
 * @interface ILocationDataForReviewST
 */
export interface ILocationDataForReviewST {
  /**
   *
   * @type {string}
   * @memberof ILocationDataForReviewST
   */
  slot_label: string;
  /**
   *
   * @type {IIssueVeritySlotStatusWithWmsBcST}
   * @memberof ILocationDataForReviewST
   */
  verity_status: IIssueVeritySlotStatusWithWmsBcST | null;
  /**
   *
   * @type {IIssueWmsSlotStatusWithBarcodeDuplicateST}
   * @memberof ILocationDataForReviewST
   */
  wms_status: IIssueWmsSlotStatusWithBarcodeDuplicateST | null;
  /**
   *
   * @type {ILocationDataForReviewSlotSettingsST}
   * @memberof ILocationDataForReviewST
   */
  slot_settings?: ILocationDataForReviewSlotSettingsST;
  /**
   *
   * @type {ILocationDataForReviewLocationLabelST}
   * @memberof ILocationDataForReviewST
   */
  location_label?: ILocationDataForReviewLocationLabelST | null;
  /**
   * State of the report location
   * @type {string}
   * @memberof ILocationDataForReviewST
   */
  state?: ILocationDataForReviewSTStateEnum;
  /**
   *
   * @type {Array<IIssue1ST>}
   * @memberof ILocationDataForReviewST
   */
  issues?: Array<IIssue1ST>;
}

export const ILocationDataForReviewSTStateEnum = {
  ToBeExecuted: 'TO_BE_EXECUTED',
  PendingBackup: 'PENDING_BACKUP',
  Scanned: 'SCANNED',
  Aborted: 'ABORTED',
  Failed: 'FAILED',
  Excluded: 'EXCLUDED',
  Unreachable: 'UNREACHABLE',
} as const;

export type ILocationDataForReviewSTStateEnum =
  typeof ILocationDataForReviewSTStateEnum[keyof typeof ILocationDataForReviewSTStateEnum];

/**
 *
 * @export
 * @interface ILocationDataForReviewSlotSettingsST
 */
export interface ILocationDataForReviewSlotSettingsST {
  /**
   * Label of the slot
   * @type {string}
   * @memberof ILocationDataForReviewSlotSettingsST
   */
  slot_label: string;
  /**
   * Exclusion Status of this slot defined in UDB.
   * @type {string}
   * @memberof ILocationDataForReviewSlotSettingsST
   */
  exclusion_status: ILocationDataForReviewSlotSettingsSTExclusionStatusEnum;
  /**
   *
   * @type {string}
   * @memberof ILocationDataForReviewSlotSettingsST
   */
  exclusion_request_id: string | null;
  /**
   * Time when this information was created / last updated
   * @type {string}
   * @memberof ILocationDataForReviewSlotSettingsST
   */
  updated_at: string | null;
}

export const ILocationDataForReviewSlotSettingsSTExclusionStatusEnum = {
  Include: 'INCLUDE',
  Exclude: 'EXCLUDE',
  NotSet: 'NOT_SET',
} as const;

export type ILocationDataForReviewSlotSettingsSTExclusionStatusEnum =
  typeof ILocationDataForReviewSlotSettingsSTExclusionStatusEnum[keyof typeof ILocationDataForReviewSlotSettingsSTExclusionStatusEnum];

/**
 *
 * @export
 * @interface ILocationDataST
 */
export interface ILocationDataST {
  /**
   *
   * @type {string}
   * @memberof ILocationDataST
   */
  slot_label: string;
  /**
   *
   * @type {IIssueVeritySlotStatusWithWmsBcST}
   * @memberof ILocationDataST
   */
  verity_status: IIssueVeritySlotStatusWithWmsBcST | null;
  /**
   *
   * @type {IIssueWmsSlotStatusWithBarcodeDuplicateST}
   * @memberof ILocationDataST
   */
  wms_status: IIssueWmsSlotStatusWithBarcodeDuplicateST | null;
  /**
   *
   * @type {ILocationDataForReviewSlotSettingsST}
   * @memberof ILocationDataST
   */
  slot_settings?: ILocationDataForReviewSlotSettingsST;
  /**
   *
   * @type {ILocationDataForReviewLocationLabelST}
   * @memberof ILocationDataST
   */
  location_label?: ILocationDataForReviewLocationLabelST | null;
  /**
   * State of the report location
   * @type {string}
   * @memberof ILocationDataST
   */
  state?: ILocationDataSTStateEnum;
  /**
   *
   * @type {Array<IIssue1ST>}
   * @memberof ILocationDataST
   */
  issues: Array<IIssue1ST>;
}

export const ILocationDataSTStateEnum = {
  ToBeExecuted: 'TO_BE_EXECUTED',
  PendingBackup: 'PENDING_BACKUP',
  Scanned: 'SCANNED',
  Aborted: 'ABORTED',
  Failed: 'FAILED',
  Excluded: 'EXCLUDED',
  Unreachable: 'UNREACHABLE',
} as const;

export type ILocationDataSTStateEnum =
  typeof ILocationDataSTStateEnum[keyof typeof ILocationDataSTStateEnum];

/**
 *
 * @export
 * @interface ILocationLabelST
 */
export interface ILocationLabelST {
  /**
   * The location identifier.
   * @type {string}
   * @memberof ILocationLabelST
   */
  location?: string | null;
  /**
   *
   * @type {ILabelTypeST}
   * @memberof ILocationLabelST
   */
  label_type?: ILabelTypeST;
  /**
   *
   * @type {ILabelStatusST}
   * @memberof ILocationLabelST
   */
  status?: ILabelStatusST;
  /**
   * Location Label version
   * @type {number}
   * @memberof ILocationLabelST
   */
  version?: number | null;
  /**
   * The email associated with the label.
   * @type {string}
   * @memberof ILocationLabelST
   */
  created_by?: string | null;
  /**
   * The timestamp when the label was created.
   * @type {string}
   * @memberof ILocationLabelST
   */
  created_at?: string | null;
  /**
   *
   * @type {ILocationLabelVeritySlotStatusST}
   * @memberof ILocationLabelST
   */
  verity_slot_status?: ILocationLabelVeritySlotStatusST | null;
  /**
   *
   * @type {ILocationLabelWmsSlotStatusST}
   * @memberof ILocationLabelST
   */
  wms_slot_status?: ILocationLabelWmsSlotStatusST | null;
  /**
   * The email associated with the user who solved the label.
   * @type {string}
   * @memberof ILocationLabelST
   */
  updated_by?: string | null;
  /**
   * The timestamp when the label was solved.
   * @type {string}
   * @memberof ILocationLabelST
   */
  updated_at?: string | null;
}

/**
 *
 * @export
 * @interface ILocationLabelVeritySlotStatusST
 */
export interface ILocationLabelVeritySlotStatusST {
  /**
   * Label of the slot
   * @type {string}
   * @memberof ILocationLabelVeritySlotStatusST
   */
  slot_label: string;
  /**
   *
   * @type {IVeritySlotStatusStateST}
   * @memberof ILocationLabelVeritySlotStatusST
   */
  state: IVeritySlotStatusStateST;
  /**
   * Indicates wether the slot information needs to be refined with a backup flight
   * @type {boolean}
   * @memberof ILocationLabelVeritySlotStatusST
   */
  pending_backup: boolean;
  /**
   * Time when Verity collected the data
   * @type {string}
   * @memberof ILocationLabelVeritySlotStatusST
   */
  collected_at: string;
  /**
   * List of barcodes that were detected
   * @type {Array<string>}
   * @memberof ILocationLabelVeritySlotStatusST
   */
  barcodes: Array<string>;
  /**
   * Ids of images collected
   * @type {Array<string>}
   * @memberof ILocationLabelVeritySlotStatusST
   */
  image_ids: Array<string>;
  /**
   * Result ID for this Verity slot status
   * @type {string}
   * @memberof ILocationLabelVeritySlotStatusST
   */
  result_id: string | null;
  /**
   * Time when Verity will next start collecting data for this slot
   * @type {string}
   * @memberof ILocationLabelVeritySlotStatusST
   */
  next_scheduled_at: string | null;
  /**
   * List of overrides to Verity data
   * @type {Array<IVeritySlotStatusUserOverride1ST>}
   * @memberof ILocationLabelVeritySlotStatusST
   */
  user_overrides: Array<IVeritySlotStatusUserOverride1ST> | null;
  /**
   * Version of the slot status
   * @type {number}
   * @memberof ILocationLabelVeritySlotStatusST
   */
  version: number;
  /**
   * Version of the slot status in the staging table
   * @type {number}
   * @memberof ILocationLabelVeritySlotStatusST
   */
  staging_version: number | null;
  /**
   * Time when this information was created/last updated
   * @type {string}
   * @memberof ILocationLabelVeritySlotStatusST
   */
  updated_at: string;
  /**
   * Indicates if the slot status was approved as part of a review
   * @type {boolean}
   * @memberof ILocationLabelVeritySlotStatusST
   */
  approved: boolean;
  /**
   * Indicates the username of the person that approved the slot status
   * @type {string}
   * @memberof ILocationLabelVeritySlotStatusST
   */
  approved_by: string | null;
  /**
   *
   * @type {IVeritySlotStatusIssueLogicUsedST}
   * @memberof ILocationLabelVeritySlotStatusST
   */
  issue_logic_used?: IVeritySlotStatusIssueLogicUsedST | null;
  /**
   *
   * @type {IVeritySlotStatusBarcodeMatchLogicUsedST}
   * @memberof ILocationLabelVeritySlotStatusST
   */
  barcode_match_logic_used?: IVeritySlotStatusBarcodeMatchLogicUsedST | null;
}

/**
 *
 * @export
 * @interface ILocationLabelWmsSlotStatusST
 */
export interface ILocationLabelWmsSlotStatusST {
  /**
   * Label of the slot
   * @type {string}
   * @memberof ILocationLabelWmsSlotStatusST
   */
  slot_label: string;
  /**
   * State of the slot
   * @type {string}
   * @memberof ILocationLabelWmsSlotStatusST
   */
  state: ILocationLabelWmsSlotStatusSTStateEnum;
  /**
   * Timestamp of when the content of this slot changed
   * @type {string}
   * @memberof ILocationLabelWmsSlotStatusST
   */
  changed_at: string;
  /**
   * ID of the WMS snapshot providing these data
   * @type {string}
   * @memberof ILocationLabelWmsSlotStatusST
   */
  snapshot_id: string;
  /**
   * List of barcodes expected at location
   * @type {Array<string>}
   * @memberof ILocationLabelWmsSlotStatusST
   */
  barcodes: Array<string>;
  /**
   * List of article numbers expected at location
   * @type {Array<string>}
   * @memberof ILocationLabelWmsSlotStatusST
   */
  article_nos: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ILocationLabelWmsSlotStatusST
   */
  slot_usage: ILocationLabelWmsSlotStatusSTSlotUsageEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof ILocationLabelWmsSlotStatusST
   */
  customers: Array<string>;
  /**
   *
   * @type {Array<number>}
   * @memberof ILocationLabelWmsSlotStatusST
   */
  unit_load_dimensions: Array<number> | null;
  /**
   * Version of the slot status
   * @type {number}
   * @memberof ILocationLabelWmsSlotStatusST
   */
  version: number;
  /**
   * Time when this information was created/last updated
   * @type {string}
   * @memberof ILocationLabelWmsSlotStatusST
   */
  updated_at: string;
  /**
   * List of quantity of each article
   * @type {Array<number>}
   * @memberof ILocationLabelWmsSlotStatusST
   */
  qtys: Array<number>;
  /**
   *
   * @type {string}
   * @memberof ILocationLabelWmsSlotStatusST
   */
  query_time: string;
  /**
   * RAW data
   * @type {string}
   * @memberof ILocationLabelWmsSlotStatusST
   */
  raw_data: string | null;
  /**
   * An identifier for the given location check in the client system (Optional)
   * @type {string}
   * @memberof ILocationLabelWmsSlotStatusST
   */
  client_location_check_id?: string | null;
  /**
   * Exclusion Status of this slot defined in WMS data.
   * @type {string}
   * @memberof ILocationLabelWmsSlotStatusST
   */
  exclusion_status: ILocationLabelWmsSlotStatusSTExclusionStatusEnum;
}

export const ILocationLabelWmsSlotStatusSTStateEnum = {
  Empty: 'EMPTY',
  Barcode: 'BARCODE',
  Invalid: 'INVALID',
} as const;

export type ILocationLabelWmsSlotStatusSTStateEnum =
  typeof ILocationLabelWmsSlotStatusSTStateEnum[keyof typeof ILocationLabelWmsSlotStatusSTStateEnum];
export const ILocationLabelWmsSlotStatusSTSlotUsageEnum = {
  Blocked: 'BLOCKED',
  Normal: 'NORMAL',
  Null: 'null',
} as const;

export type ILocationLabelWmsSlotStatusSTSlotUsageEnum =
  typeof ILocationLabelWmsSlotStatusSTSlotUsageEnum[keyof typeof ILocationLabelWmsSlotStatusSTSlotUsageEnum];
export const ILocationLabelWmsSlotStatusSTExclusionStatusEnum = {
  Include: 'INCLUDE',
  Exclude: 'EXCLUDE',
} as const;

export type ILocationLabelWmsSlotStatusSTExclusionStatusEnum =
  typeof ILocationLabelWmsSlotStatusSTExclusionStatusEnum[keyof typeof ILocationLabelWmsSlotStatusSTExclusionStatusEnum];

/**
 *
 * @export
 * @enum {string}
 */

export const INullableFieldST = {
  Null: 'null',
  None: 'None',
} as const;

export type INullableFieldST = typeof INullableFieldST[keyof typeof INullableFieldST];

/**
 *
 * @export
 * @interface IReport1ST
 */
export interface IReport1ST {
  /**
   * The unique identifier of the report
   * @type {string}
   * @memberof IReport1ST
   */
  report_id: string;
  /**
   * The state of the report
   * @type {string}
   * @memberof IReport1ST
   */
  state: string;
  /**
   * The previous state of the report
   * @type {string}
   * @memberof IReport1ST
   */
  previous_state: string | null;
  /**
   * The unique identifier of the inventory request from which the report was generated
   * @type {string}
   * @memberof IReport1ST
   */
  request_id: string;
  /**
   * The version of the inventory request from which the report was generated
   * @type {number}
   * @memberof IReport1ST
   */
  request_version: number;
  /**
   * The unique identifier of the specification for this report
   * @type {string}
   * @memberof IReport1ST
   */
  specification_id: string;
  /**
   * The version of the report specification for this report
   * @type {number}
   * @memberof IReport1ST
   */
  specification_version: number;
  /**
   * The version of the facility for which this report was generated
   * @type {number}
   * @memberof IReport1ST
   */
  facility_version: number;
  /**
   * The time at which the report was scheduled to start
   * @type {string}
   * @memberof IReport1ST
   */
  scheduled_for_utc: string;
  /**
   * The time at which the report was created
   * @type {string}
   * @memberof IReport1ST
   */
  created_at: string;
  /**
   * The last time the report was updated
   * @type {string}
   * @memberof IReport1ST
   */
  updated_at: string;
  /**
   * The time at which the report transitioned to ONGOING
   * @type {string}
   * @memberof IReport1ST
   */
  started_at: string | null;
  /**
   * The time at which the report transitioned to FINISHED or INCOMPLETE
   * @type {string}
   * @memberof IReport1ST
   */
  finished_at: string | null;
  /**
   * The number of locations that have to be analyzed
   * @type {number}
   * @memberof IReport1ST
   */
  num_locations: number;
  /**
   * The number of locations that have to be inspected by drones
   * @type {number}
   * @memberof IReport1ST
   */
  num_locations_to_inspect: number;
  /**
   * The number of locations for which data are available
   * @type {number}
   * @memberof IReport1ST
   */
  num_completed_locations: number;
  /**
   * The number of locations for which data have been collected as part of a task
   * @type {number}
   * @memberof IReport1ST
   */
  num_inspected_locations: number;
  /**
   * The number of locations that have to be reviewed
   * @type {number}
   * @memberof IReport1ST
   */
  num_locations_to_review?: number;
  /**
   * The number of issues found in locations data
   * @type {number}
   * @memberof IReport1ST
   */
  num_issues: number;
  /**
   * The number of unreachable locations found in locations data
   * @type {number}
   * @memberof IReport1ST
   */
  num_unreachable_locations: number;
  /**
   *
   * @type {IIssueCount1ST}
   * @memberof IReport1ST
   */
  issue_count: IIssueCount1ST;
  /**
   *
   * @type {ILocationDataCountST}
   * @memberof IReport1ST
   */
  location_data_count: ILocationDataCountST;
  /**
   *
   * @type {IInventoryRequestST}
   * @memberof IReport1ST
   */
  request: IInventoryRequestST;
  /**
   *
   * @type {number}
   * @memberof IReport1ST
   */
  progress: number;
  /**
   *
   * @type {number}
   * @memberof IReport1ST
   */
  completion: number;
  /**
   *
   * @type {Array<string>}
   * @memberof IReport1ST
   */
  locations?: Array<string>;
  /**
   *
   * @type {{ [key: string]: ILocationData1ST; }}
   * @memberof IReport1ST
   */
  locations_data?: { [key: string]: ILocationData1ST };
  /**
   *
   * @type {{ [key: string]: Array<IIssue1ST>; }}
   * @memberof IReport1ST
   */
  issues?: { [key: string]: Array<IIssue1ST> };
  /**
   *
   * @type {{ [key: string]: ILocationDataForReview1ST; }}
   * @memberof IReport1ST
   */
  locations_data_for_review?: { [key: string]: ILocationDataForReview1ST };
  /**
   *
   * @type {number}
   * @memberof IReport1ST
   */
  timeout?: number | null;
}
/**
 *
 * @export
 * @interface IReportAbortPutResponseST
 */
export interface IReportAbortPutResponseST {
  /**
   * Message
   * @type {string}
   * @memberof IReportAbortPutResponseST
   */
  message: string;
}
/**
 *
 * @export
 * @interface IReportArchivePutRequestST
 */
export interface IReportArchivePutRequestST {
  /**
   * Report IDs
   * @type {Array<string>}
   * @memberof IReportArchivePutRequestST
   */
  report_ids: Array<string>;
}
/**
 *
 * @export
 * @interface IReportArchivePutResponseST
 */
export interface IReportArchivePutResponseST {
  /**
   * Message
   * @type {string}
   * @memberof IReportArchivePutResponseST
   */
  message: string;
  /**
   * Failed report IDs
   * @type {Array<string>}
   * @memberof IReportArchivePutResponseST
   */
  failed_reports_ids: Array<string>;
}
/**
 *
 * @export
 * @interface IReportData1ST
 */
export interface IReportData1ST {
  /**
   *
   * @type {{ [key: string]: ILocationData1ST; }}
   * @memberof IReportData1ST
   */
  locations_data: { [key: string]: ILocationData1ST };
  /**
   *
   * @type {Array<string>}
   * @memberof IReportData1ST
   */
  locations: Array<string>;
  /**
   *
   * @type {{ [key: string]: ILocationDataForReview1ST; }}
   * @memberof IReportData1ST
   */
  locations_data_for_review?: { [key: string]: ILocationDataForReview1ST };
  /**
   *
   * @type {{ [key: string]: Array<IIssue1ST>; }}
   * @memberof IReportData1ST
   */
  issues: { [key: string]: Array<IIssue1ST> };
}
/**
 *
 * @export
 * @interface IReportDataGetResponseST
 */
export interface IReportDataGetResponseST {
  /**
   *
   * @type {IReportData1ST}
   * @memberof IReportDataGetResponseST
   */
  item: IReportData1ST;
}
/**
 *
 * @export
 * @interface IReportDataST
 */
export interface IReportDataST {
  /**
   *
   * @type {{ [key: string]: ILocationData1ST; }}
   * @memberof IReportDataST
   */
  locations_data: { [key: string]: ILocationData1ST };
  /**
   *
   * @type {Array<string>}
   * @memberof IReportDataST
   */
  locations: Array<string>;
  /**
   *
   * @type {{ [key: string]: ILocationDataForReview1ST; }}
   * @memberof IReportDataST
   */
  locations_data_for_review?: { [key: string]: ILocationDataForReview1ST };
  /**
   *
   * @type {{ [key: string]: Array<IIssue1ST>; }}
   * @memberof IReportDataST
   */
  issues: { [key: string]: Array<IIssue1ST> };
}
/**
 *
 * @export
 * @interface IReportGetResponseST
 */
export interface IReportGetResponseST {
  /**
   *
   * @type {IReportWithLocations1ST}
   * @memberof IReportGetResponseST
   */
  item: IReportWithLocations1ST;
}
/**
 *
 * @export
 * @interface IReportParameterCustomValuesInputSettingsST
 */
export interface IReportParameterCustomValuesInputSettingsST {
  /**
   * Value lower bound
   * @type {number}
   * @memberof IReportParameterCustomValuesInputSettingsST
   */
  lower_bound: number | null;
  /**
   * Value upper bound
   * @type {number}
   * @memberof IReportParameterCustomValuesInputSettingsST
   */
  upper_bound: number | null;
  /**
   * A regex for cases in which something more strict than upper and lower bounds is needed
   * @type {string}
   * @memberof IReportParameterCustomValuesInputSettingsST
   */
  allowed_values_regex: string;
  /**
   *
   * @type {IUiWidgetTypeST}
   * @memberof IReportParameterCustomValuesInputSettingsST
   */
  ui_widget_type: IUiWidgetTypeST;
  /**
   * The label to be displayed next to the widget
   * @type {string}
   * @memberof IReportParameterCustomValuesInputSettingsST
   */
  ui_widget_label: string;
  /**
   * List of accepted values to be shown to the user as hint
   * @type {Array<string>}
   * @memberof IReportParameterCustomValuesInputSettingsST
   */
  accepted_values: Array<string>;
}

/**
 * @type IReportParameterInputSettingsST
 * @export
 */
export type IReportParameterInputSettingsST =
  | IReportParameterCustomValuesInputSettingsST
  | IReportParameterPredefinedValuesInputSettingsST;

/**
 *
 * @export
 * @interface IReportParameterPredefinedValuesInputSettingsOptionsST
 */
export interface IReportParameterPredefinedValuesInputSettingsOptionsST {
  /**
   * Name for option value
   * @type {string}
   * @memberof IReportParameterPredefinedValuesInputSettingsOptionsST
   */
  label: string;
  /**
   * Value for option
   * @type {string}
   * @memberof IReportParameterPredefinedValuesInputSettingsOptionsST
   */
  value: string;
}
/**
 *
 * @export
 * @interface IReportParameterPredefinedValuesInputSettingsST
 */
export interface IReportParameterPredefinedValuesInputSettingsST {
  /**
   *
   * @type {Array<IReportParameterPredefinedValuesInputSettingsOptionsST>}
   * @memberof IReportParameterPredefinedValuesInputSettingsST
   */
  options: Array<IReportParameterPredefinedValuesInputSettingsOptionsST>;
  /**
   *
   * @type {IUiWidgetTypeST}
   * @memberof IReportParameterPredefinedValuesInputSettingsST
   */
  ui_widget_type: IUiWidgetTypeST;
  /**
   * The label to be displayed next to the widget
   * @type {string}
   * @memberof IReportParameterPredefinedValuesInputSettingsST
   */
  ui_widget_label: string;
  /**
   *
   * @type {IReportParameterCustomValuesInputSettingsST}
   * @memberof IReportParameterPredefinedValuesInputSettingsST
   */
  custom_values_input_settings?: IReportParameterCustomValuesInputSettingsST;
}

/**
 *
 * @export
 * @interface IReportParameterST
 */
export interface IReportParameterST {
  /**
   *
   * @type {IReportParameterUniqueNameST}
   * @memberof IReportParameterST
   */
  unique_name: IReportParameterUniqueNameST;
  /**
   * The name of the parameter shown to the user
   * @type {string}
   * @memberof IReportParameterST
   */
  user_facing_name: string;
  /**
   * Type for the parameter value
   * @type {string}
   * @memberof IReportParameterST
   */
  value_type: IReportParameterSTValueTypeEnum;
  /**
   * If set to false, the parameter is ignored in the query
   * @type {boolean}
   * @memberof IReportParameterST
   */
  enabled: boolean;
  /**
   * If set to false, the parameter is shown to the user but is not editable
   * @type {boolean}
   * @memberof IReportParameterST
   */
  editable: boolean;
  /**
   * If set to false, the parameter is not shown to the user
   * @type {boolean}
   * @memberof IReportParameterST
   */
  visible: boolean;
  /**
   * Defines how the user can select the values for this parameter
   * @type {string}
   * @memberof IReportParameterST
   */
  input_mode: IReportParameterSTInputModeEnum;
  /**
   * Minimum number of values for this parameter
   * @type {number}
   * @memberof IReportParameterST
   */
  cardinality_min: number;
  /**
   * Maximum number of values for this parameter
   * @type {number}
   * @memberof IReportParameterST
   */
  cardinality_max: number;
  /**
   * Default values for this parameter
   * @type {Array<string>}
   * @memberof IReportParameterST
   */
  default_values: Array<string>;
  /**
   *
   * @type {IReportParameterInputSettingsST}
   * @memberof IReportParameterST
   */
  input_settings?: IReportParameterInputSettingsST;
}

export const IReportParameterSTValueTypeEnum = {
  Integer: 'INTEGER',
  Decimal: 'DECIMAL',
  String: 'STRING',
  Date: 'DATE',
  Time: 'TIME',
  DateTime: 'DATE_TIME',
} as const;

export type IReportParameterSTValueTypeEnum =
  typeof IReportParameterSTValueTypeEnum[keyof typeof IReportParameterSTValueTypeEnum];
export const IReportParameterSTInputModeEnum = {
  FixedValue: 'FIXED_VALUE',
  CustomValues: 'CUSTOM_VALUES',
  PredefinedValues: 'PREDEFINED_VALUES',
  Automatic: 'AUTOMATIC',
} as const;

export type IReportParameterSTInputModeEnum =
  typeof IReportParameterSTInputModeEnum[keyof typeof IReportParameterSTInputModeEnum];

/**
 * Unique name of the parameter
 * @export
 * @enum {string}
 */

export const IReportParameterUniqueNameST = {
  LocationLabel: 'LOCATION_LABEL',
  PalletNumber: 'PALLET_NUMBER',
  ArticleNumber: 'ARTICLE_NUMBER',
  Customer: 'CUSTOMER',
  WmsStatusAge: 'WMS_STATUS_AGE',
  NewIssueAge: 'NEW_ISSUE_AGE',
  LocationsPercentage: 'LOCATIONS_PERCENTAGE',
} as const;

export type IReportParameterUniqueNameST =
  typeof IReportParameterUniqueNameST[keyof typeof IReportParameterUniqueNameST];

/**
 *
 * @export
 * @interface IReportST
 */
export interface IReportST {
  /**
   * The unique identifier of the report
   * @type {string}
   * @memberof IReportST
   */
  report_id: string;
  /**
   * The state of the report
   * @type {string}
   * @memberof IReportST
   */
  state: string;
  /**
   * The previous state of the report
   * @type {string}
   * @memberof IReportST
   */
  previous_state: string | null;
  /**
   * The unique identifier of the inventory request from which the report was generated
   * @type {string}
   * @memberof IReportST
   */
  request_id: string;
  /**
   * The version of the inventory request from which the report was generated
   * @type {number}
   * @memberof IReportST
   */
  request_version: number;
  /**
   * The unique identifier of the specification for this report
   * @type {string}
   * @memberof IReportST
   */
  specification_id: string;
  /**
   * The version of the report specification for this report
   * @type {number}
   * @memberof IReportST
   */
  specification_version: number;
  /**
   * The version of the facility for which this report was generated
   * @type {number}
   * @memberof IReportST
   */
  facility_version: number;
  /**
   * The time at which the report was scheduled to start
   * @type {string}
   * @memberof IReportST
   */
  scheduled_for_utc: string;
  /**
   * The time at which the report was created
   * @type {string}
   * @memberof IReportST
   */
  created_at: string;
  /**
   * The last time the report was updated
   * @type {string}
   * @memberof IReportST
   */
  updated_at: string;
  /**
   * The time at which the report transitioned to ONGOING
   * @type {string}
   * @memberof IReportST
   */
  started_at: string | null;
  /**
   * The time at which the report transitioned to FINISHED or INCOMPLETE
   * @type {string}
   * @memberof IReportST
   */
  finished_at: string | null;
  /**
   * The number of locations that have to be analyzed
   * @type {number}
   * @memberof IReportST
   */
  num_locations: number;
  /**
   * The number of locations that have to be inspected by drones
   * @type {number}
   * @memberof IReportST
   */
  num_locations_to_inspect: number;
  /**
   * The number of locations for which data are available
   * @type {number}
   * @memberof IReportST
   */
  num_completed_locations: number;
  /**
   * The number of locations for which data have been collected as part of a task
   * @type {number}
   * @memberof IReportST
   */
  num_inspected_locations: number;
  /**
   * The number of locations that have to be reviewed
   * @type {number}
   * @memberof IReportST
   */
  num_locations_to_review?: number;
  /**
   * The number of issues found in locations data
   * @type {number}
   * @memberof IReportST
   */
  num_issues: number;
  /**
   * The number of unreachable locations found in locations data
   * @type {number}
   * @memberof IReportST
   */
  num_unreachable_locations: number;
  /**
   *
   * @type {IIssueCount1ST}
   * @memberof IReportST
   */
  issue_count: IIssueCount1ST;
  /**
   *
   * @type {ILocationDataCountST}
   * @memberof IReportST
   */
  location_data_count: ILocationDataCountST;
  /**
   *
   * @type {IInventoryRequestST}
   * @memberof IReportST
   */
  request: IInventoryRequestST;
  /**
   *
   * @type {number}
   * @memberof IReportST
   */
  progress: number;
  /**
   *
   * @type {number}
   * @memberof IReportST
   */
  completion: number;
  /**
   *
   * @type {Array<string>}
   * @memberof IReportST
   */
  locations?: Array<string>;
  /**
   *
   * @type {{ [key: string]: ILocationData1ST; }}
   * @memberof IReportST
   */
  locations_data?: { [key: string]: ILocationData1ST };
  /**
   *
   * @type {{ [key: string]: Array<IIssue1ST>; }}
   * @memberof IReportST
   */
  issues?: { [key: string]: Array<IIssue1ST> };
  /**
   *
   * @type {{ [key: string]: ILocationDataForReview1ST; }}
   * @memberof IReportST
   */
  locations_data_for_review?: { [key: string]: ILocationDataForReview1ST };
  /**
   *
   * @type {number}
   * @memberof IReportST
   */
  timeout?: number | null;
}
/**
 *
 * @export
 * @interface IReportSchedulingParametersST
 */
export interface IReportSchedulingParametersST {
  /**
   * Specifies the source that triggers the inventory request for this report
   * @type {string}
   * @memberof IReportSchedulingParametersST
   */
  triggering_source: IReportSchedulingParametersSTTriggeringSourceEnum;
  /**
   * Specifies what kind of event can trigger an inventory request for this report
   * @type {Array<string>}
   * @memberof IReportSchedulingParametersST
   */
  triggering_events?: Array<IReportSchedulingParametersSTTriggeringEventsEnum>;
  /**
   * If set to true, the task can be recurrent
   * @type {boolean}
   * @memberof IReportSchedulingParametersST
   */
  allows_recurrence: boolean;
}

export const IReportSchedulingParametersSTTriggeringSourceEnum = {
  TimeBasedSchedule: 'TIME_BASED_SCHEDULE',
  ExternalEvent: 'EXTERNAL_EVENT',
} as const;

export type IReportSchedulingParametersSTTriggeringSourceEnum =
  typeof IReportSchedulingParametersSTTriggeringSourceEnum[keyof typeof IReportSchedulingParametersSTTriggeringSourceEnum];
export const IReportSchedulingParametersSTTriggeringEventsEnum = {
  None: 'NONE',
  WmsSnapshotUpload: 'WMS_SNAPSHOT_UPLOAD',
  WmsMovedLocationUpload: 'WMS_MOVED_LOCATION_UPLOAD',
  WmsStockCheckListUpload: 'WMS_STOCK_CHECK_LIST_UPLOAD',
} as const;

export type IReportSchedulingParametersSTTriggeringEventsEnum =
  typeof IReportSchedulingParametersSTTriggeringEventsEnum[keyof typeof IReportSchedulingParametersSTTriggeringEventsEnum];

/**
 *
 * @export
 * @interface IReportSendEmailPutResponseST
 */
export interface IReportSendEmailPutResponseST {
  /**
   *
   * @type {string}
   * @memberof IReportSendEmailPutResponseST
   */
  message: string;
}
/**
 *
 * @export
 * @interface IReportSpecificationBaseST
 */
export interface IReportSpecificationBaseST {
  /**
   * Report specification ID
   * @type {string}
   * @memberof IReportSpecificationBaseST
   */
  id?: string;
  /**
   * Report specification version
   * @type {number}
   * @memberof IReportSpecificationBaseST
   */
  version?: number;
  /**
   * Timestamp of when the report specification was created
   * @type {string}
   * @memberof IReportSpecificationBaseST
   */
  created_at?: string;
  /**
   * Name of the the report type
   * @type {string}
   * @memberof IReportSpecificationBaseST
   */
  report_spec_name: string;
  /**
   * Default name for the inventory request generated for this report specification
   * @type {string}
   * @memberof IReportSpecificationBaseST
   */
  request_default_name: string;
  /**
   * Description of this report specification to be shown to the user
   * @type {string}
   * @memberof IReportSpecificationBaseST
   */
  report_description: string;
  /**
   * Short description of this report specification to be shown to the user
   * @type {string}
   * @memberof IReportSpecificationBaseST
   */
  report_short_description: string;
  /**
   * Path of the picture for this report specification to be shown to the user
   * @type {string}
   * @memberof IReportSpecificationBaseST
   */
  report_picture: string;
  /**
   * Path of the thumbnail picture for this report specification to be shown to the user
   * @type {string}
   * @memberof IReportSpecificationBaseST
   */
  report_thumbnail_picture: string;
  /**
   *
   * @type {IReportSchedulingParametersST}
   * @memberof IReportSpecificationBaseST
   */
  scheduling: IReportSchedulingParametersST;
  /**
   * Defines what slots should be considered expired
   * @type {number}
   * @memberof IReportSpecificationBaseST
   */
  max_slot_status_age_sec: number;
  /**
   * Defines the list of user groups that have access to this report specification
   * @type {Array<string>}
   * @memberof IReportSpecificationBaseST
   */
  allowed_user_groups: Array<IReportSpecificationBaseSTAllowedUserGroupsEnum>;
  /**
   *
   * @type {Array<IReportParameterST>}
   * @memberof IReportSpecificationBaseST
   */
  params: Array<IReportParameterST>;
}

export const IReportSpecificationBaseSTAllowedUserGroupsEnum = {
  InventoryManager: 'InventoryManager',
  SuperAdmin: 'SuperAdmin',
  GroundControlOfficer: 'GroundControlOfficer',
  SafetyControls: 'SafetyControls',
  Analytics: 'Analytics',
  VerityUser: 'VerityUser',
  Reviewer: 'Reviewer',
  Client: 'Client',
  Verity: 'Verity',
  GroundControl: 'GroundControl',
} as const;

export type IReportSpecificationBaseSTAllowedUserGroupsEnum =
  typeof IReportSpecificationBaseSTAllowedUserGroupsEnum[keyof typeof IReportSpecificationBaseSTAllowedUserGroupsEnum];

/**
 *
 * @export
 * @interface IReportSpecificationST
 */
export interface IReportSpecificationST {
  /**
   * Report specification ID
   * @type {string}
   * @memberof IReportSpecificationST
   */
  id: string;
  /**
   * Report specification version
   * @type {number}
   * @memberof IReportSpecificationST
   */
  version: number;
  /**
   * Timestamp of when the report specification was created
   * @type {string}
   * @memberof IReportSpecificationST
   */
  created_at: string;
  /**
   * Name of the the report type
   * @type {string}
   * @memberof IReportSpecificationST
   */
  report_spec_name: string;
  /**
   * Default name for the inventory request generated for this report specification
   * @type {string}
   * @memberof IReportSpecificationST
   */
  request_default_name: string;
  /**
   * Description of this report specification to be shown to the user
   * @type {string}
   * @memberof IReportSpecificationST
   */
  report_description: string;
  /**
   * Short description of this report specification to be shown to the user
   * @type {string}
   * @memberof IReportSpecificationST
   */
  report_short_description: string;
  /**
   * Path of the picture for this report specification to be shown to the user
   * @type {string}
   * @memberof IReportSpecificationST
   */
  report_picture: string;
  /**
   * Path of the thumbnail picture for this report specification to be shown to the user
   * @type {string}
   * @memberof IReportSpecificationST
   */
  report_thumbnail_picture: string;
  /**
   *
   * @type {IReportSchedulingParametersST}
   * @memberof IReportSpecificationST
   */
  scheduling: IReportSchedulingParametersST;
  /**
   * Defines what slots should be considered expired
   * @type {number}
   * @memberof IReportSpecificationST
   */
  max_slot_status_age_sec: number;
  /**
   * Defines the list of user groups that have access to this report specification
   * @type {Array<string>}
   * @memberof IReportSpecificationST
   */
  allowed_user_groups: Array<IReportSpecificationSTAllowedUserGroupsEnum>;
  /**
   *
   * @type {Array<IReportParameterST>}
   * @memberof IReportSpecificationST
   */
  params: Array<IReportParameterST>;
}

export const IReportSpecificationSTAllowedUserGroupsEnum = {
  InventoryManager: 'InventoryManager',
  SuperAdmin: 'SuperAdmin',
  GroundControlOfficer: 'GroundControlOfficer',
  SafetyControls: 'SafetyControls',
  Analytics: 'Analytics',
  VerityUser: 'VerityUser',
  Reviewer: 'Reviewer',
  Client: 'Client',
  Verity: 'Verity',
  GroundControl: 'GroundControl',
} as const;

export type IReportSpecificationSTAllowedUserGroupsEnum =
  typeof IReportSpecificationSTAllowedUserGroupsEnum[keyof typeof IReportSpecificationSTAllowedUserGroupsEnum];

/**
 *
 * @export
 * @interface IReportSummaryGetAllResponseST
 */
export interface IReportSummaryGetAllResponseST {
  /**
   *
   * @type {Array<IReport1ST>}
   * @memberof IReportSummaryGetAllResponseST
   */
  items: Array<IReport1ST>;
  /**
   *
   * @type {string}
   * @memberof IReportSummaryGetAllResponseST
   */
  page_token: string | null;
}
/**
 *
 * @export
 * @interface IReportSummaryGetResponseST
 */
export interface IReportSummaryGetResponseST {
  /**
   *
   * @type {IReport1ST}
   * @memberof IReportSummaryGetResponseST
   */
  item: IReport1ST;
}
/**
 *
 * @export
 * @interface IReportUpdateAllPutResponseST
 */
export interface IReportUpdateAllPutResponseST {
  /**
   * Message
   * @type {string}
   * @memberof IReportUpdateAllPutResponseST
   */
  message: string;
  /**
   * Updated reports count
   * @type {number}
   * @memberof IReportUpdateAllPutResponseST
   */
  report_count: number;
  /**
   * List of updated report IDs as string
   * @type {string}
   * @memberof IReportUpdateAllPutResponseST
   */
  updated_reports: string;
}
/**
 *
 * @export
 * @interface IReportUpdatePutResponseST
 */
export interface IReportUpdatePutResponseST {
  /**
   * Message
   * @type {string}
   * @memberof IReportUpdatePutResponseST
   */
  message: string;
  /**
   * Updated report ID
   * @type {string}
   * @memberof IReportUpdatePutResponseST
   */
  report_id: string;
}
/**
 *
 * @export
 * @interface IReportWithLocations1ST
 */
export interface IReportWithLocations1ST {
  /**
   * The unique identifier of the report
   * @type {string}
   * @memberof IReportWithLocations1ST
   */
  report_id: string;
  /**
   * The state of the report
   * @type {string}
   * @memberof IReportWithLocations1ST
   */
  state: string;
  /**
   * The previous state of the report
   * @type {string}
   * @memberof IReportWithLocations1ST
   */
  previous_state: string | null;
  /**
   * The unique identifier of the inventory request from which the report was generated
   * @type {string}
   * @memberof IReportWithLocations1ST
   */
  request_id: string;
  /**
   * The version of the inventory request from which the report was generated
   * @type {number}
   * @memberof IReportWithLocations1ST
   */
  request_version: number;
  /**
   * The unique identifier of the specification for this report
   * @type {string}
   * @memberof IReportWithLocations1ST
   */
  specification_id: string;
  /**
   * The version of the report specification for this report
   * @type {number}
   * @memberof IReportWithLocations1ST
   */
  specification_version: number;
  /**
   * The version of the facility for which this report was generated
   * @type {number}
   * @memberof IReportWithLocations1ST
   */
  facility_version: number;
  /**
   * The time at which the report was scheduled to start
   * @type {string}
   * @memberof IReportWithLocations1ST
   */
  scheduled_for_utc: string;
  /**
   * The time at which the report was created
   * @type {string}
   * @memberof IReportWithLocations1ST
   */
  created_at: string;
  /**
   * The last time the report was updated
   * @type {string}
   * @memberof IReportWithLocations1ST
   */
  updated_at: string;
  /**
   * The time at which the report transitioned to ONGOING
   * @type {string}
   * @memberof IReportWithLocations1ST
   */
  started_at: string | null;
  /**
   * The time at which the report transitioned to FINISHED or INCOMPLETE
   * @type {string}
   * @memberof IReportWithLocations1ST
   */
  finished_at: string | null;
  /**
   * The number of locations that have to be analyzed
   * @type {number}
   * @memberof IReportWithLocations1ST
   */
  num_locations: number;
  /**
   * The number of locations that have to be inspected by drones
   * @type {number}
   * @memberof IReportWithLocations1ST
   */
  num_locations_to_inspect: number;
  /**
   * The number of locations for which data are available
   * @type {number}
   * @memberof IReportWithLocations1ST
   */
  num_completed_locations: number;
  /**
   * The number of locations for which data have been collected as part of a task
   * @type {number}
   * @memberof IReportWithLocations1ST
   */
  num_inspected_locations: number;
  /**
   * The number of locations that have to be reviewed
   * @type {number}
   * @memberof IReportWithLocations1ST
   */
  num_locations_to_review?: number;
  /**
   * The number of issues found in locations data
   * @type {number}
   * @memberof IReportWithLocations1ST
   */
  num_issues: number;
  /**
   * The number of unreachable locations found in locations data
   * @type {number}
   * @memberof IReportWithLocations1ST
   */
  num_unreachable_locations: number;
  /**
   *
   * @type {IIssueCount1ST}
   * @memberof IReportWithLocations1ST
   */
  issue_count: IIssueCount1ST;
  /**
   *
   * @type {ILocationDataCountST}
   * @memberof IReportWithLocations1ST
   */
  location_data_count: ILocationDataCountST;
  /**
   *
   * @type {IInventoryRequestST}
   * @memberof IReportWithLocations1ST
   */
  request: IInventoryRequestST;
  /**
   *
   * @type {number}
   * @memberof IReportWithLocations1ST
   */
  progress: number;
  /**
   *
   * @type {number}
   * @memberof IReportWithLocations1ST
   */
  completion: number;
  /**
   *
   * @type {Array<string>}
   * @memberof IReportWithLocations1ST
   */
  locations: Array<string>;
  /**
   *
   * @type {{ [key: string]: ILocationData1ST; }}
   * @memberof IReportWithLocations1ST
   */
  locations_data?: { [key: string]: ILocationData1ST };
  /**
   *
   * @type {{ [key: string]: Array<IIssue1ST>; }}
   * @memberof IReportWithLocations1ST
   */
  issues?: { [key: string]: Array<IIssue1ST> };
  /**
   *
   * @type {{ [key: string]: ILocationDataForReview1ST; }}
   * @memberof IReportWithLocations1ST
   */
  locations_data_for_review?: { [key: string]: ILocationDataForReview1ST };
  /**
   *
   * @type {number}
   * @memberof IReportWithLocations1ST
   */
  timeout?: number | null;
}
/**
 *
 * @export
 * @interface IReportWithLocationsST
 */
export interface IReportWithLocationsST {
  /**
   * The unique identifier of the report
   * @type {string}
   * @memberof IReportWithLocationsST
   */
  report_id: string;
  /**
   * The state of the report
   * @type {string}
   * @memberof IReportWithLocationsST
   */
  state: string;
  /**
   * The previous state of the report
   * @type {string}
   * @memberof IReportWithLocationsST
   */
  previous_state: string | null;
  /**
   * The unique identifier of the inventory request from which the report was generated
   * @type {string}
   * @memberof IReportWithLocationsST
   */
  request_id: string;
  /**
   * The version of the inventory request from which the report was generated
   * @type {number}
   * @memberof IReportWithLocationsST
   */
  request_version: number;
  /**
   * The unique identifier of the specification for this report
   * @type {string}
   * @memberof IReportWithLocationsST
   */
  specification_id: string;
  /**
   * The version of the report specification for this report
   * @type {number}
   * @memberof IReportWithLocationsST
   */
  specification_version: number;
  /**
   * The version of the facility for which this report was generated
   * @type {number}
   * @memberof IReportWithLocationsST
   */
  facility_version: number;
  /**
   * The time at which the report was scheduled to start
   * @type {string}
   * @memberof IReportWithLocationsST
   */
  scheduled_for_utc: string;
  /**
   * The time at which the report was created
   * @type {string}
   * @memberof IReportWithLocationsST
   */
  created_at: string;
  /**
   * The last time the report was updated
   * @type {string}
   * @memberof IReportWithLocationsST
   */
  updated_at: string;
  /**
   * The time at which the report transitioned to ONGOING
   * @type {string}
   * @memberof IReportWithLocationsST
   */
  started_at: string | null;
  /**
   * The time at which the report transitioned to FINISHED or INCOMPLETE
   * @type {string}
   * @memberof IReportWithLocationsST
   */
  finished_at: string | null;
  /**
   * The number of locations that have to be analyzed
   * @type {number}
   * @memberof IReportWithLocationsST
   */
  num_locations: number;
  /**
   * The number of locations that have to be inspected by drones
   * @type {number}
   * @memberof IReportWithLocationsST
   */
  num_locations_to_inspect: number;
  /**
   * The number of locations for which data are available
   * @type {number}
   * @memberof IReportWithLocationsST
   */
  num_completed_locations: number;
  /**
   * The number of locations for which data have been collected as part of a task
   * @type {number}
   * @memberof IReportWithLocationsST
   */
  num_inspected_locations: number;
  /**
   * The number of locations that have to be reviewed
   * @type {number}
   * @memberof IReportWithLocationsST
   */
  num_locations_to_review?: number;
  /**
   * The number of issues found in locations data
   * @type {number}
   * @memberof IReportWithLocationsST
   */
  num_issues: number;
  /**
   * The number of unreachable locations found in locations data
   * @type {number}
   * @memberof IReportWithLocationsST
   */
  num_unreachable_locations: number;
  /**
   *
   * @type {IIssueCount1ST}
   * @memberof IReportWithLocationsST
   */
  issue_count: IIssueCount1ST;
  /**
   *
   * @type {ILocationDataCountST}
   * @memberof IReportWithLocationsST
   */
  location_data_count: ILocationDataCountST;
  /**
   *
   * @type {IInventoryRequestST}
   * @memberof IReportWithLocationsST
   */
  request: IInventoryRequestST;
  /**
   *
   * @type {number}
   * @memberof IReportWithLocationsST
   */
  progress: number;
  /**
   *
   * @type {number}
   * @memberof IReportWithLocationsST
   */
  completion: number;
  /**
   *
   * @type {Array<string>}
   * @memberof IReportWithLocationsST
   */
  locations: Array<string>;
  /**
   *
   * @type {{ [key: string]: ILocationData1ST; }}
   * @memberof IReportWithLocationsST
   */
  locations_data?: { [key: string]: ILocationData1ST };
  /**
   *
   * @type {{ [key: string]: Array<IIssue1ST>; }}
   * @memberof IReportWithLocationsST
   */
  issues?: { [key: string]: Array<IIssue1ST> };
  /**
   *
   * @type {{ [key: string]: ILocationDataForReview1ST; }}
   * @memberof IReportWithLocationsST
   */
  locations_data_for_review?: { [key: string]: ILocationDataForReview1ST };
  /**
   *
   * @type {number}
   * @memberof IReportWithLocationsST
   */
  timeout?: number | null;
}
/**
 * Slot settings.
 * @export
 * @interface ISlotSettingsST
 */
export interface ISlotSettingsST {
  /**
   * Label of the slot
   * @type {string}
   * @memberof ISlotSettingsST
   */
  slot_label: string;
  /**
   * Exclusion Status of this slot defined in UDB.
   * @type {string}
   * @memberof ISlotSettingsST
   */
  exclusion_status: ISlotSettingsSTExclusionStatusEnum;
  /**
   *
   * @type {string}
   * @memberof ISlotSettingsST
   */
  exclusion_request_id: string | null;
  /**
   * Time when this information was created / last updated
   * @type {string}
   * @memberof ISlotSettingsST
   */
  updated_at: string | null;
}

export const ISlotSettingsSTExclusionStatusEnum = {
  Include: 'INCLUDE',
  Exclude: 'EXCLUDE',
  NotSet: 'NOT_SET',
} as const;

export type ISlotSettingsSTExclusionStatusEnum =
  typeof ISlotSettingsSTExclusionStatusEnum[keyof typeof ISlotSettingsSTExclusionStatusEnum];

/**
 * Widget that should be used to show and select them
 * @export
 * @enum {string}
 */

export const IUiWidgetTypeST = {
  TextField: 'TEXT_FIELD',
  DropDown: 'DROP_DOWN',
  RadioBox: 'RADIO_BOX',
  CheckBox: 'CHECK_BOX',
  FileChooser: 'FILE_CHOOSER',
  DatePicker: 'DATE_PICKER',
  DatetimePicker: 'DATETIME_PICKER',
  Custom: 'CUSTOM',
} as const;

export type IUiWidgetTypeST = typeof IUiWidgetTypeST[keyof typeof IUiWidgetTypeST];

/**
 *
 * @export
 * @interface IVeritySlotStatus1ST
 */
export interface IVeritySlotStatus1ST {
  /**
   * Label of the slot
   * @type {string}
   * @memberof IVeritySlotStatus1ST
   */
  slot_label: string;
  /**
   *
   * @type {IVeritySlotStatusStateST}
   * @memberof IVeritySlotStatus1ST
   */
  state: IVeritySlotStatusStateST;
  /**
   * Indicates wether the slot information needs to be refined with a backup flight
   * @type {boolean}
   * @memberof IVeritySlotStatus1ST
   */
  pending_backup: boolean;
  /**
   * Time when Verity collected the data
   * @type {string}
   * @memberof IVeritySlotStatus1ST
   */
  collected_at: string;
  /**
   * List of barcodes that were detected
   * @type {Array<string>}
   * @memberof IVeritySlotStatus1ST
   */
  barcodes: Array<string>;
  /**
   * Ids of images collected
   * @type {Array<string>}
   * @memberof IVeritySlotStatus1ST
   */
  image_ids: Array<string>;
  /**
   * Result ID for this Verity slot status
   * @type {string}
   * @memberof IVeritySlotStatus1ST
   */
  result_id: string | null;
  /**
   * Time when Verity will next start collecting data for this slot
   * @type {string}
   * @memberof IVeritySlotStatus1ST
   */
  next_scheduled_at: string | null;
  /**
   * List of overrides to Verity data
   * @type {Array<IVeritySlotStatusUserOverride1ST>}
   * @memberof IVeritySlotStatus1ST
   */
  user_overrides: Array<IVeritySlotStatusUserOverride1ST> | null;
  /**
   * Version of the slot status
   * @type {number}
   * @memberof IVeritySlotStatus1ST
   */
  version: number;
  /**
   * Version of the slot status in the staging table
   * @type {number}
   * @memberof IVeritySlotStatus1ST
   */
  staging_version: number | null;
  /**
   * Time when this information was created/last updated
   * @type {string}
   * @memberof IVeritySlotStatus1ST
   */
  updated_at: string;
  /**
   * Indicates if the slot status was approved as part of a review
   * @type {boolean}
   * @memberof IVeritySlotStatus1ST
   */
  approved: boolean;
  /**
   * Indicates the username of the person that approved the slot status
   * @type {string}
   * @memberof IVeritySlotStatus1ST
   */
  approved_by: string | null;
  /**
   *
   * @type {IVeritySlotStatusIssueLogicUsedST}
   * @memberof IVeritySlotStatus1ST
   */
  issue_logic_used?: IVeritySlotStatusIssueLogicUsedST | null;
  /**
   *
   * @type {IVeritySlotStatusBarcodeMatchLogicUsedST}
   * @memberof IVeritySlotStatus1ST
   */
  barcode_match_logic_used?: IVeritySlotStatusBarcodeMatchLogicUsedST | null;
}

/**
 * @type IVeritySlotStatusBarcodeMatchLogicUsedST
 * @export
 */
export type IVeritySlotStatusBarcodeMatchLogicUsedST = IBarcodeMatchLogicST | INullableFieldST;

/**
 * @type IVeritySlotStatusIssueLogicUsedST
 * @export
 */
export type IVeritySlotStatusIssueLogicUsedST = IIssueLogicST | INullableFieldST;

/**
 *
 * @export
 * @interface IVeritySlotStatusST
 */
export interface IVeritySlotStatusST {
  /**
   * Label of the slot
   * @type {string}
   * @memberof IVeritySlotStatusST
   */
  slot_label: string;
  /**
   *
   * @type {IVeritySlotStatusStateST}
   * @memberof IVeritySlotStatusST
   */
  state: IVeritySlotStatusStateST;
  /**
   * Indicates wether the slot information needs to be refined with a backup flight
   * @type {boolean}
   * @memberof IVeritySlotStatusST
   */
  pending_backup: boolean;
  /**
   * Time when Verity collected the data
   * @type {string}
   * @memberof IVeritySlotStatusST
   */
  collected_at: string;
  /**
   * List of barcodes that were detected
   * @type {Array<string>}
   * @memberof IVeritySlotStatusST
   */
  barcodes: Array<string>;
  /**
   * Ids of images collected
   * @type {Array<string>}
   * @memberof IVeritySlotStatusST
   */
  image_ids: Array<string>;
  /**
   * Result ID for this Verity slot status
   * @type {string}
   * @memberof IVeritySlotStatusST
   */
  result_id: string | null;
  /**
   * Time when Verity will next start collecting data for this slot
   * @type {string}
   * @memberof IVeritySlotStatusST
   */
  next_scheduled_at: string | null;
  /**
   * List of overrides to Verity data
   * @type {Array<IVeritySlotStatusUserOverride1ST>}
   * @memberof IVeritySlotStatusST
   */
  user_overrides: Array<IVeritySlotStatusUserOverride1ST> | null;
  /**
   * Version of the slot status
   * @type {number}
   * @memberof IVeritySlotStatusST
   */
  version: number;
  /**
   * Version of the slot status in the staging table
   * @type {number}
   * @memberof IVeritySlotStatusST
   */
  staging_version: number | null;
  /**
   * Time when this information was created/last updated
   * @type {string}
   * @memberof IVeritySlotStatusST
   */
  updated_at: string;
  /**
   * Indicates if the slot status was approved as part of a review
   * @type {boolean}
   * @memberof IVeritySlotStatusST
   */
  approved: boolean;
  /**
   * Indicates the username of the person that approved the slot status
   * @type {string}
   * @memberof IVeritySlotStatusST
   */
  approved_by: string | null;
  /**
   *
   * @type {IVeritySlotStatusIssueLogicUsedST}
   * @memberof IVeritySlotStatusST
   */
  issue_logic_used?: IVeritySlotStatusIssueLogicUsedST | null;
  /**
   *
   * @type {IVeritySlotStatusBarcodeMatchLogicUsedST}
   * @memberof IVeritySlotStatusST
   */
  barcode_match_logic_used?: IVeritySlotStatusBarcodeMatchLogicUsedST | null;
}

/**
 * State of the slot
 * @export
 * @enum {string}
 */

export const IVeritySlotStatusStateST = {
  Empty: 'EMPTY',
  Barcode: 'BARCODE',
  NotEmpty: 'NOT_EMPTY',
  Unreachable: 'UNREACHABLE',
  NoResult: 'NO_RESULT',
} as const;

export type IVeritySlotStatusStateST =
  typeof IVeritySlotStatusStateST[keyof typeof IVeritySlotStatusStateST];

/**
 *
 * @export
 * @interface IVeritySlotStatusUserOverride1ST
 */
export interface IVeritySlotStatusUserOverride1ST {
  /**
   * Id of user making the override
   * @type {string}
   * @memberof IVeritySlotStatusUserOverride1ST
   */
  user_id: string;
  /**
   * Name of user making the override
   * @type {string}
   * @memberof IVeritySlotStatusUserOverride1ST
   */
  user_name: string;
  /**
   * Timestamp of when the user submitted the override
   * @type {string}
   * @memberof IVeritySlotStatusUserOverride1ST
   */
  timestamp: string;
  /**
   * User-provided flag indicating if original Verity data were correct
   * @type {boolean}
   * @memberof IVeritySlotStatusUserOverride1ST
   */
  verity_correct: boolean;
  /**
   * True, if override corresponds to data provided by WMS
   * @type {boolean}
   * @memberof IVeritySlotStatusUserOverride1ST
   */
  set_to_expected: boolean;
  /**
   *
   * @type {IVeritySlotStatusStateST}
   * @memberof IVeritySlotStatusUserOverride1ST
   */
  state: IVeritySlotStatusStateST;
  /**
   * List of barcodes that should override those found by Verity
   * @type {Array<string>}
   * @memberof IVeritySlotStatusUserOverride1ST
   */
  barcodes: Array<string>;
  /**
   * Free-text comment by user
   * @type {string}
   * @memberof IVeritySlotStatusUserOverride1ST
   */
  comment: string | null;
  /**
   * Indicates if the update is part of a review
   * @type {boolean}
   * @memberof IVeritySlotStatusUserOverride1ST
   */
  review: boolean;
}

/**
 *
 * @export
 * @interface IVeritySlotStatusUserOverrideST
 */
export interface IVeritySlotStatusUserOverrideST {
  /**
   * Id of user making the override
   * @type {string}
   * @memberof IVeritySlotStatusUserOverrideST
   */
  user_id: string;
  /**
   * Name of user making the override
   * @type {string}
   * @memberof IVeritySlotStatusUserOverrideST
   */
  user_name: string;
  /**
   * Timestamp of when the user submitted the override
   * @type {string}
   * @memberof IVeritySlotStatusUserOverrideST
   */
  timestamp: string;
  /**
   * User-provided flag indicating if original Verity data were correct
   * @type {boolean}
   * @memberof IVeritySlotStatusUserOverrideST
   */
  verity_correct: boolean;
  /**
   * True, if override corresponds to data provided by WMS
   * @type {boolean}
   * @memberof IVeritySlotStatusUserOverrideST
   */
  set_to_expected: boolean;
  /**
   *
   * @type {IVeritySlotStatusStateST}
   * @memberof IVeritySlotStatusUserOverrideST
   */
  state: IVeritySlotStatusStateST;
  /**
   * List of barcodes that should override those found by Verity
   * @type {Array<string>}
   * @memberof IVeritySlotStatusUserOverrideST
   */
  barcodes: Array<string>;
  /**
   * Free-text comment by user
   * @type {string}
   * @memberof IVeritySlotStatusUserOverrideST
   */
  comment: string | null;
  /**
   * Indicates if the update is part of a review
   * @type {boolean}
   * @memberof IVeritySlotStatusUserOverrideST
   */
  review: boolean;
}

/**
 *
 * @export
 * @interface IWMSSlotStatusST
 */
export interface IWMSSlotStatusST {
  /**
   * Label of the slot
   * @type {string}
   * @memberof IWMSSlotStatusST
   */
  slot_label: string;
  /**
   * State of the slot
   * @type {string}
   * @memberof IWMSSlotStatusST
   */
  state: IWMSSlotStatusSTStateEnum;
  /**
   * Timestamp of when the content of this slot changed
   * @type {string}
   * @memberof IWMSSlotStatusST
   */
  changed_at: string;
  /**
   * ID of the WMS snapshot providing these data
   * @type {string}
   * @memberof IWMSSlotStatusST
   */
  snapshot_id: string;
  /**
   * List of barcodes expected at location
   * @type {Array<string>}
   * @memberof IWMSSlotStatusST
   */
  barcodes: Array<string>;
  /**
   * List of article numbers expected at location
   * @type {Array<string>}
   * @memberof IWMSSlotStatusST
   */
  article_nos: Array<string>;
  /**
   *
   * @type {string}
   * @memberof IWMSSlotStatusST
   */
  slot_usage: IWMSSlotStatusSTSlotUsageEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof IWMSSlotStatusST
   */
  customers: Array<string>;
  /**
   *
   * @type {Array<number>}
   * @memberof IWMSSlotStatusST
   */
  unit_load_dimensions: Array<number> | null;
  /**
   * Version of the slot status
   * @type {number}
   * @memberof IWMSSlotStatusST
   */
  version: number;
  /**
   * Time when this information was created/last updated
   * @type {string}
   * @memberof IWMSSlotStatusST
   */
  updated_at: string;
  /**
   * List of quantity of each article
   * @type {Array<number>}
   * @memberof IWMSSlotStatusST
   */
  qtys: Array<number>;
  /**
   *
   * @type {string}
   * @memberof IWMSSlotStatusST
   */
  query_time: string;
  /**
   * RAW data
   * @type {string}
   * @memberof IWMSSlotStatusST
   */
  raw_data: string | null;
  /**
   * An identifier for the given location check in the client system (Optional)
   * @type {string}
   * @memberof IWMSSlotStatusST
   */
  client_location_check_id?: string | null;
  /**
   * Exclusion Status of this slot defined in WMS data.
   * @type {string}
   * @memberof IWMSSlotStatusST
   */
  exclusion_status: IWMSSlotStatusSTExclusionStatusEnum;
}

export const IWMSSlotStatusSTStateEnum = {
  Empty: 'EMPTY',
  Barcode: 'BARCODE',
  Invalid: 'INVALID',
} as const;

export type IWMSSlotStatusSTStateEnum =
  typeof IWMSSlotStatusSTStateEnum[keyof typeof IWMSSlotStatusSTStateEnum];
export const IWMSSlotStatusSTSlotUsageEnum = {
  Blocked: 'BLOCKED',
  Normal: 'NORMAL',
  Null: 'null',
} as const;

export type IWMSSlotStatusSTSlotUsageEnum =
  typeof IWMSSlotStatusSTSlotUsageEnum[keyof typeof IWMSSlotStatusSTSlotUsageEnum];
export const IWMSSlotStatusSTExclusionStatusEnum = {
  Include: 'INCLUDE',
  Exclude: 'EXCLUDE',
} as const;

export type IWMSSlotStatusSTExclusionStatusEnum =
  typeof IWMSSlotStatusSTExclusionStatusEnum[keyof typeof IWMSSlotStatusSTExclusionStatusEnum];

/**
 * ReportApi - axios parameter creator
 * @export
 */
export const ReportApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Abort report.
     * @summary Abort report.
     * @param {string} systemIdStr
     * @param {string} reportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    abortReport: async (
      systemIdStr: string,
      reportId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('abortReport', 'systemIdStr', systemIdStr);
      // verify required parameter 'reportId' is not null or undefined
      assertParamExists('abortReport', 'reportId', reportId);
      const localVarPath = `/{system_id_str}/report-abort/{report_id}`
        .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
        .replace(`{${'report_id'}}`, encodeURIComponent(String(reportId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Archive report.
     * @summary Abort archive.
     * @param {string} systemIdStr
     * @param {IReportArchivePutRequestST} iReportArchivePutRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    archiveReport: async (
      systemIdStr: string,
      iReportArchivePutRequestST: IReportArchivePutRequestST,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('archiveReport', 'systemIdStr', systemIdStr);
      // verify required parameter 'iReportArchivePutRequestST' is not null or undefined
      assertParamExists('archiveReport', 'iReportArchivePutRequestST', iReportArchivePutRequestST);
      const localVarPath = `/{system_id_str}/report-archive`.replace(
        `{${'system_id_str'}}`,
        encodeURIComponent(String(systemIdStr)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iReportArchivePutRequestST,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Return specific report.
     * @summary Get specific report.
     * @param {string} systemIdStr
     * @param {string} reportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReport: async (
      systemIdStr: string,
      reportId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getReport', 'systemIdStr', systemIdStr);
      // verify required parameter 'reportId' is not null or undefined
      assertParamExists('getReport', 'reportId', reportId);
      const localVarPath = `/{system_id_str}/report/{report_id}`
        .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
        .replace(`{${'report_id'}}`, encodeURIComponent(String(reportId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Return specific report data.
     * @summary Get specific report data.
     * @param {string} systemIdStr
     * @param {string} reportId
     * @param {string} [from] Start location for filter
     * @param {string} [until] End location for filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReportData: async (
      systemIdStr: string,
      reportId: string,
      from?: string,
      until?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getReportData', 'systemIdStr', systemIdStr);
      // verify required parameter 'reportId' is not null or undefined
      assertParamExists('getReportData', 'reportId', reportId);
      const localVarPath = `/{system_id_str}/report/{report_id}/data`
        .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
        .replace(`{${'report_id'}}`, encodeURIComponent(String(reportId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (from !== undefined) {
        localVarQueryParameter['from'] = from;
      }

      if (until !== undefined) {
        localVarQueryParameter['until'] = until;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Return all report summaries.
     * @summary Get all report summaries.
     * @param {string} systemIdStr
     * @param {string} [from] Start timestamp for filter
     * @param {string} [until] End timestamp for filter
     * @param {number} [limit] Page size
     * @param {Array<string>} [states]
     * @param {string} [pageToken] Token used to iterate pages
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReportSummaries: async (
      systemIdStr: string,
      from?: string,
      until?: string,
      limit?: number,
      states?: Array<string>,
      pageToken?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getReportSummaries', 'systemIdStr', systemIdStr);
      const localVarPath = `/{system_id_str}/report-summary`.replace(
        `{${'system_id_str'}}`,
        encodeURIComponent(String(systemIdStr)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from;
      }

      if (until !== undefined) {
        localVarQueryParameter['until'] =
          (until as any) instanceof Date ? (until as any).toISOString() : until;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (states) {
        localVarQueryParameter['states'] = states;
      }

      if (pageToken !== undefined) {
        localVarQueryParameter['page_token'] = pageToken;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Return specific report summary.
     * @summary Get specific report summary.
     * @param {string} systemIdStr
     * @param {string} reportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReportSummary: async (
      systemIdStr: string,
      reportId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getReportSummary', 'systemIdStr', systemIdStr);
      // verify required parameter 'reportId' is not null or undefined
      assertParamExists('getReportSummary', 'reportId', reportId);
      const localVarPath = `/{system_id_str}/report-summary/{report_id}`
        .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
        .replace(`{${'report_id'}}`, encodeURIComponent(String(reportId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Restore archived reports.
     * @summary Abort restore.
     * @param {string} systemIdStr
     * @param {IReportArchivePutRequestST} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    restoreReport: async (
      systemIdStr: string,
      body: IReportArchivePutRequestST,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('restoreReport', 'systemIdStr', systemIdStr);
      // verify required parameter 'body' is not null or undefined
      assertParamExists('restoreReport', 'body', body);
      const localVarPath = `/{system_id_str}/report-restore`.replace(
        `{${'system_id_str'}}`,
        encodeURIComponent(String(systemIdStr)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Send report export to email.
     * @summary Send report export to email.
     * @param {string} systemIdStr
     * @param {string} reportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendEmailReport: async (
      systemIdStr: string,
      reportId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('sendEmailReport', 'systemIdStr', systemIdStr);
      // verify required parameter 'reportId' is not null or undefined
      assertParamExists('sendEmailReport', 'reportId', reportId);
      const localVarPath = `/{system_id_str}/report-send-email/{report_id}`
        .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
        .replace(`{${'report_id'}}`, encodeURIComponent(String(reportId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update all reports.
     * @summary Update all reports.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAllReports: async (
      systemIdStr: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('updateAllReports', 'systemIdStr', systemIdStr);
      const localVarPath = `/{system_id_str}/report-update-all`.replace(
        `{${'system_id_str'}}`,
        encodeURIComponent(String(systemIdStr)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update report.
     * @summary Update report.
     * @param {string} systemIdStr
     * @param {string} reportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateReport: async (
      systemIdStr: string,
      reportId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('updateReport', 'systemIdStr', systemIdStr);
      // verify required parameter 'reportId' is not null or undefined
      assertParamExists('updateReport', 'reportId', reportId);
      const localVarPath = `/{system_id_str}/report-update/{report_id}`
        .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
        .replace(`{${'report_id'}}`, encodeURIComponent(String(reportId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ReportApi - functional programming interface
 * @export
 */
export const ReportApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ReportApiAxiosParamCreator(configuration);
  return {
    /**
     * Abort report.
     * @summary Abort report.
     * @param {string} systemIdStr
     * @param {string} reportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async abortReport(
      systemIdStr: string,
      reportId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IReportAbortPutResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.abortReport(
        systemIdStr,
        reportId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Archive report.
     * @summary Abort archive.
     * @param {string} systemIdStr
     * @param {IReportArchivePutRequestST} iReportArchivePutRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async archiveReport(
      systemIdStr: string,
      iReportArchivePutRequestST: IReportArchivePutRequestST,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IReportArchivePutResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.archiveReport(
        systemIdStr,
        iReportArchivePutRequestST,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Return specific report.
     * @summary Get specific report.
     * @param {string} systemIdStr
     * @param {string} reportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getReport(
      systemIdStr: string,
      reportId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IReportGetResponseST>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getReport(
        systemIdStr,
        reportId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Return specific report data.
     * @summary Get specific report data.
     * @param {string} systemIdStr
     * @param {string} reportId
     * @param {string} [from] Start location for filter
     * @param {string} [until] End location for filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getReportData(
      systemIdStr: string,
      reportId: string,
      from?: string,
      until?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IReportDataGetResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getReportData(
        systemIdStr,
        reportId,
        from,
        until,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Return all report summaries.
     * @summary Get all report summaries.
     * @param {string} systemIdStr
     * @param {string} [from] Start timestamp for filter
     * @param {string} [until] End timestamp for filter
     * @param {number} [limit] Page size
     * @param {Array<string>} [states]
     * @param {string} [pageToken] Token used to iterate pages
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getReportSummaries(
      systemIdStr: string,
      from?: string,
      until?: string,
      limit?: number,
      states?: Array<string>,
      pageToken?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IReportSummaryGetAllResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getReportSummaries(
        systemIdStr,
        from,
        until,
        limit,
        states,
        pageToken,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Return specific report summary.
     * @summary Get specific report summary.
     * @param {string} systemIdStr
     * @param {string} reportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getReportSummary(
      systemIdStr: string,
      reportId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IReportSummaryGetResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getReportSummary(
        systemIdStr,
        reportId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Restore archived reports.
     * @summary Abort restore.
     * @param {string} systemIdStr
     * @param {IReportArchivePutRequestST} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async restoreReport(
      systemIdStr: string,
      body: IReportArchivePutRequestST,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IReportArchivePutResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.restoreReport(
        systemIdStr,
        body,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Send report export to email.
     * @summary Send report export to email.
     * @param {string} systemIdStr
     * @param {string} reportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sendEmailReport(
      systemIdStr: string,
      reportId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IReportSendEmailPutResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.sendEmailReport(
        systemIdStr,
        reportId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Update all reports.
     * @summary Update all reports.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateAllReports(
      systemIdStr: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IReportUpdateAllPutResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateAllReports(
        systemIdStr,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Update report.
     * @summary Update report.
     * @param {string} systemIdStr
     * @param {string} reportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateReport(
      systemIdStr: string,
      reportId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IReportUpdatePutResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateReport(
        systemIdStr,
        reportId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ReportApi - factory interface
 * @export
 */
export const ReportApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ReportApiFp(configuration);
  return {
    /**
     * Abort report.
     * @summary Abort report.
     * @param {string} systemIdStr
     * @param {string} reportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    abortReport(
      systemIdStr: string,
      reportId: string,
      options?: any,
    ): AxiosPromise<IReportAbortPutResponseST> {
      return localVarFp
        .abortReport(systemIdStr, reportId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Archive report.
     * @summary Abort archive.
     * @param {string} systemIdStr
     * @param {IReportArchivePutRequestST} iReportArchivePutRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    archiveReport(
      systemIdStr: string,
      iReportArchivePutRequestST: IReportArchivePutRequestST,
      options?: any,
    ): AxiosPromise<IReportArchivePutResponseST> {
      return localVarFp
        .archiveReport(systemIdStr, iReportArchivePutRequestST, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Return specific report.
     * @summary Get specific report.
     * @param {string} systemIdStr
     * @param {string} reportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReport(
      systemIdStr: string,
      reportId: string,
      options?: any,
    ): AxiosPromise<IReportGetResponseST> {
      return localVarFp
        .getReport(systemIdStr, reportId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Return specific report data.
     * @summary Get specific report data.
     * @param {string} systemIdStr
     * @param {string} reportId
     * @param {string} [from] Start location for filter
     * @param {string} [until] End location for filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReportData(
      systemIdStr: string,
      reportId: string,
      from?: string,
      until?: string,
      options?: any,
    ): AxiosPromise<IReportDataGetResponseST> {
      return localVarFp
        .getReportData(systemIdStr, reportId, from, until, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Return all report summaries.
     * @summary Get all report summaries.
     * @param {string} systemIdStr
     * @param {string} [from] Start timestamp for filter
     * @param {string} [until] End timestamp for filter
     * @param {number} [limit] Page size
     * @param {Array<string>} [states]
     * @param {string} [pageToken] Token used to iterate pages
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReportSummaries(
      systemIdStr: string,
      from?: string,
      until?: string,
      limit?: number,
      states?: Array<string>,
      pageToken?: string,
      options?: any,
    ): AxiosPromise<IReportSummaryGetAllResponseST> {
      return localVarFp
        .getReportSummaries(systemIdStr, from, until, limit, states, pageToken, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Return specific report summary.
     * @summary Get specific report summary.
     * @param {string} systemIdStr
     * @param {string} reportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReportSummary(
      systemIdStr: string,
      reportId: string,
      options?: any,
    ): AxiosPromise<IReportSummaryGetResponseST> {
      return localVarFp
        .getReportSummary(systemIdStr, reportId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Restore archived reports.
     * @summary Abort restore.
     * @param {string} systemIdStr
     * @param {IReportArchivePutRequestST} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    restoreReport(
      systemIdStr: string,
      body: IReportArchivePutRequestST,
      options?: any,
    ): AxiosPromise<IReportArchivePutResponseST> {
      return localVarFp
        .restoreReport(systemIdStr, body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Send report export to email.
     * @summary Send report export to email.
     * @param {string} systemIdStr
     * @param {string} reportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendEmailReport(
      systemIdStr: string,
      reportId: string,
      options?: any,
    ): AxiosPromise<IReportSendEmailPutResponseST> {
      return localVarFp
        .sendEmailReport(systemIdStr, reportId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Update all reports.
     * @summary Update all reports.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAllReports(
      systemIdStr: string,
      options?: any,
    ): AxiosPromise<IReportUpdateAllPutResponseST> {
      return localVarFp
        .updateAllReports(systemIdStr, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Update report.
     * @summary Update report.
     * @param {string} systemIdStr
     * @param {string} reportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateReport(
      systemIdStr: string,
      reportId: string,
      options?: any,
    ): AxiosPromise<IReportUpdatePutResponseST> {
      return localVarFp
        .updateReport(systemIdStr, reportId, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ReportApi - object-oriented interface
 * @export
 * @class ReportApi
 * @extends {BaseAPI}
 */
export class ReportApi extends BaseAPI {
  /**
   * Abort report.
   * @summary Abort report.
   * @param {string} systemIdStr
   * @param {string} reportId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportApi
   */
  public abortReport(systemIdStr: string, reportId: string, options?: AxiosRequestConfig) {
    return ReportApiFp(this.configuration)
      .abortReport(systemIdStr, reportId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Archive report.
   * @summary Abort archive.
   * @param {string} systemIdStr
   * @param {IReportArchivePutRequestST} iReportArchivePutRequestST
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportApi
   */
  public archiveReport(
    systemIdStr: string,
    iReportArchivePutRequestST: IReportArchivePutRequestST,
    options?: AxiosRequestConfig,
  ) {
    return ReportApiFp(this.configuration)
      .archiveReport(systemIdStr, iReportArchivePutRequestST, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Return specific report.
   * @summary Get specific report.
   * @param {string} systemIdStr
   * @param {string} reportId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportApi
   */
  public getReport(systemIdStr: string, reportId: string, options?: AxiosRequestConfig) {
    return ReportApiFp(this.configuration)
      .getReport(systemIdStr, reportId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Return specific report data.
   * @summary Get specific report data.
   * @param {string} systemIdStr
   * @param {string} reportId
   * @param {string} [from] Start location for filter
   * @param {string} [until] End location for filter
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportApi
   */
  public getReportData(
    systemIdStr: string,
    reportId: string,
    from?: string,
    until?: string,
    options?: AxiosRequestConfig,
  ) {
    return ReportApiFp(this.configuration)
      .getReportData(systemIdStr, reportId, from, until, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Return all report summaries.
   * @summary Get all report summaries.
   * @param {string} systemIdStr
   * @param {string} [from] Start timestamp for filter
   * @param {string} [until] End timestamp for filter
   * @param {number} [limit] Page size
   * @param {Array<string>} [states]
   * @param {string} [pageToken] Token used to iterate pages
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportApi
   */
  public getReportSummaries(
    systemIdStr: string,
    from?: string,
    until?: string,
    limit?: number,
    states?: Array<string>,
    pageToken?: string,
    options?: AxiosRequestConfig,
  ) {
    return ReportApiFp(this.configuration)
      .getReportSummaries(systemIdStr, from, until, limit, states, pageToken, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Return specific report summary.
   * @summary Get specific report summary.
   * @param {string} systemIdStr
   * @param {string} reportId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportApi
   */
  public getReportSummary(systemIdStr: string, reportId: string, options?: AxiosRequestConfig) {
    return ReportApiFp(this.configuration)
      .getReportSummary(systemIdStr, reportId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Restore archived reports.
   * @summary Abort restore.
   * @param {string} systemIdStr
   * @param {IReportArchivePutRequestST} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportApi
   */
  public restoreReport(
    systemIdStr: string,
    body: IReportArchivePutRequestST,
    options?: AxiosRequestConfig,
  ) {
    return ReportApiFp(this.configuration)
      .restoreReport(systemIdStr, body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Send report export to email.
   * @summary Send report export to email.
   * @param {string} systemIdStr
   * @param {string} reportId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportApi
   */
  public sendEmailReport(systemIdStr: string, reportId: string, options?: AxiosRequestConfig) {
    return ReportApiFp(this.configuration)
      .sendEmailReport(systemIdStr, reportId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update all reports.
   * @summary Update all reports.
   * @param {string} systemIdStr
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportApi
   */
  public updateAllReports(systemIdStr: string, options?: AxiosRequestConfig) {
    return ReportApiFp(this.configuration)
      .updateAllReports(systemIdStr, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update report.
   * @summary Update report.
   * @param {string} systemIdStr
   * @param {string} reportId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportApi
   */
  public updateReport(systemIdStr: string, reportId: string, options?: AxiosRequestConfig) {
    return ReportApiFp(this.configuration)
      .updateReport(systemIdStr, reportId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
