import { PERMISSION } from 'features/permissions/permissions.model';
import { userHasPermission } from 'features/permissions/userHasPermission';
import { IClientLevelStateFacility } from 'store/ClientLevelStore/ClientLevelStore.model';

export const filterFacilitiesBasedOnPermission = (
  allFacilities: { id: number; name: string }[],
  userFacilities: IClientLevelStateFacility[],
) =>
  userHasPermission(PERMISSION.SEE_ALL_FACILITIES)
    ? allFacilities
    : allFacilities.filter((facility) =>
        userFacilities.find((f) => f.id === facility.id.toString()),
      );
