import { IReportSpecificationST } from 'codegen/report_specification';
import {
  validateAllParamValues,
  validateEndDate,
  validateOccurrenceInterval,
  validateRecurrenceInterval,
  validateReportName,
  validateStartDate,
} from 'common/functionsReportSpec';
import { ScheduleFormAction, ScheduleFormState } from '../reducers/ScheduleForm.model';

/**
 * Validates all fields, sets errors in state and returns true if all fields are valid.
 * @returns are all fields valid?
 */
export const validateAllBeforeSubmit = (
  reportSpec: IReportSpecificationST,
  state: ScheduleFormState,
  allowPastStartDate: boolean,
  dispatch: (value: ScheduleFormAction) => void,
) => {
  const validReportName = validateReportName(state.reportName);
  const validParams = validateAllParamValues(reportSpec, state.reportParamValues);
  const validDateFrom = validateStartDate(
    reportSpec,
    state.isRecurring,
    state.dateFrom,
    state.dateUntil,
    allowPastStartDate,
  );
  const validDateUntil = validateEndDate(
    reportSpec,
    state.isRecurring,
    state.dateFrom,
    state.dateUntil,
  );
  const validOccInterval = validateOccurrenceInterval(
    state.isRecurring,
    state.occurrenceInterval,
    state.allowedOccurrenceIntervals,
  );
  const validInterval = validateRecurrenceInterval(state.interval, state.isRecurring);

  dispatch({
    type: 'UPDATE_ERRORS',
    payload: {
      validReportName: validReportName.errorMsg,
      validParams: validParams.errorMessages,
      validDateFrom: validDateFrom.errorMsg,
      validDateUntil: validDateUntil.errorMsg,
      validOccurrence: validOccInterval.errorMsg,
      validInterval: validInterval.errorMsg,
    },
  });

  const valid = [
    validReportName.valid,
    validParams.valid,
    validDateFrom.valid,
    validDateUntil.valid,
    validOccInterval.valid,
    validInterval.valid,
  ].every((inputValid) => inputValid);

  return valid;
};
