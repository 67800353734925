import { ILocationData1ST, ILocationDataST } from 'codegen/report';
import { IFacilityModalsAction } from 'store/Modals/facilityModals/IFacilityModalsActions';
import { FacilityModalsActionTypes } from 'store/Modals/types';
import { getRowForFullReportTable } from 'common/functions/locationRows/locationRowsFunctions';

export const openLocationModal = (
  selectedLocationName: string,
  filteredLocationData: ILocationDataST[],
  dispatchFacilityModals: React.Dispatch<IFacilityModalsAction>,
  parentPage: 'WarehouseStatus' | 'Report',
  loadLocationData?: () => void,
  locationData?: ILocationData1ST[],
  reportId?: string,
) => {
  const selectedLocation = locationData?.find(
    (location) => location.slot_label === selectedLocationName,
  );
  if (!selectedLocation) {
    return;
  }

  const selectedLocationRows = filteredLocationData.map((location) => ({
    ...getRowForFullReportTable(location.slot_label, location, location?.issues).actions.data,
    ...(location.slot_label === selectedLocation.slot_label && { selected: true }),
  }));

  dispatchFacilityModals({
    type: FacilityModalsActionTypes.TOGGLE_LOCATION_MODAL,
    payload: selectedLocationRows,
    refreshDataOnClose: {
      refreshData: loadLocationData,
    },
    parentPage,
    reportId,
    allLocations: locationData,
  });
};
