import { DEFAULT_DECIMAL_PLACES } from 'components/FormFields/defaults/FormInput.defaults';

export const metricFormatter = ({
  isUnitVisible,
  decimalPlaces = DEFAULT_DECIMAL_PLACES,
}: {
  isUnitVisible?: boolean;
  decimalPlaces?: number;
}) =>
  new Intl.NumberFormat('en-US', {
    style: isUnitVisible ? 'unit' : undefined,
    unit: 'meter',
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
    useGrouping: false,
  });
