import { metricFormatter } from './metricFormatter';

const convertFeetToMeters = (metricValue: string): string => {
  const regex = /^-?([0-9]*')?([0-9]*?("{1}|'{2}))?$/;
  const matches = metricValue.match(regex);

  if (!matches) {
    throw new Error("Invalid input format. Please use the format 'XX'YY\".");
  }

  const feet = matches[1] ? parseInt(matches[1], 10) : 0;
  const inches = matches[2] ? parseInt(matches[2], 10) : 0;

  const signFactor = metricValue.charAt(0) === '-' ? -1 : 1;
  const totalInches = feet * 12 + inches;
  const meters = totalInches * 0.0254 * signFactor;

  return meters.toString();
};

export const convertInputValue = (
  value: string,
  { isMetricSystem }: { isMetricSystem: boolean },
): string => {
  if (isMetricSystem) {
    const number = parseFloat(value);

    // eslint-disable-next-line no-restricted-globals
    return isNaN(number)
      ? '0'
      : metricFormatter({
          isUnitVisible: false,
        }).format(number);
  }

  return convertFeetToMeters(value);
};
