import { useState } from 'react';
import Grid from '@mui/material/Grid';

import { Spinner } from 'components/common/Spinner';

import { useLocation, useSearchParams } from 'react-router-dom';
import { useFacilityLevelStore } from 'store/FacilityLevelStore/facilityLevelStore';
import { ILocationData1ST } from 'codegen/report';
import { getMostRecent } from 'common/functions/dateTimeFunctions';
import { getIssueAndBarcodeMatchingLogic } from 'common/functions/issueLogic/issueLogicFunctions';
import { Box, Typography } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import { BaseCard } from 'components/BaseCard/BaseCard';
import { INVENTORY_PAGES_URLS } from 'common/pages';
import {
  IFacilityModalsState,
  ILocationData,
} from 'store/Modals/facilityModals/IFacilityModalsStore';
import { FEATURE_FLAG_MAP_3D } from 'shared/map-3d/map3DFeatureFlag.model';
import { ModalBase } from '../ModalBase';
import { LocationModalHelp } from './LocationModalHelp';
import { LocationModalHeader } from './features/header/LocationModalHeader';
import { ImageGrid } from './features/image-grid/ImageGrid';
import { FeedbackDrawer } from './features/feedback-drawer/FeedbackDrawer';
import { FeedbackDrawerToggler } from './features/feedback-drawer/FeedbackDrawerToggler';
import { LocationModalSubHeader } from './features/header/LocationModalSubHeader';
import { BarcodesInformation } from './features/barcodes/BarcodesInformation';
import { LocationHistory } from './features/location-history/LocationHistory';
import { WMSRawData } from './features/wms-raw-data/WMSRawData';
import { useLocationModal } from './hooks/useLocationModal';
import { Map3DCard } from './features/map-3d/Map3DCard';
import { useLocationImagesState } from './hooks/useLocationImagesState';
import { useLocationModalStyle } from './LocationModal.styles';
import { LabelsCard } from './features/labels-card/LabelsCard';

export const LocationModal = ({
  opened,
  closeModal,
  refreshDataOnClose,
  filteredLocationList,
  parentPage,
  reportId,
  allLocations,
}: {
  opened: boolean;
  closeModal: () => void;
  refreshDataOnClose: IFacilityModalsState['refreshDataOnClose'];
  filteredLocationList: ILocationData[];
  allLocations?: ILocationData1ST[];
  parentPage: 'WarehouseStatus' | 'Report';
  reportId: string | null | undefined;
}) => {
  const { classes } = useLocationModalStyle();

  const [searchParams, setSearchParams] = useSearchParams();
  const [feedbackDrawerOpen, setFeedbackDrawerOpen] = useState(false);
  const [didUserOverwrite, setDidUserOverwrite] = useState(false);
  const [highlightedBarcode, setHighlightedBarcode] = useState('');

  const { currentSystemId: systemId = '', facilitySettings } =
    useFacilityLevelStore().stateFacilityLevel;

  const {
    sendLocationInfo,
    refreshLocationData,
    spinnerLoading,
    currentLocationData,
    currentLocationIndex,
    setCurrentLocationIndex,
  } = useLocationModal(systemId, filteredLocationList, parentPage, reportId);

  const { barcodeMatchLogic, issueLogic } = getIssueAndBarcodeMatchingLogic(
    currentLocationData,
    facilitySettings,
  );

  const locationImagesState = useLocationImagesState(systemId ?? '', currentLocationData);

  const isSnoozeEnabled = parentPage === 'WarehouseStatus';

  const handleCloseModal = () => {
    if (didUserOverwrite) {
      refreshDataOnClose?.refreshData();
    }
    searchParams.delete('location');
    setSearchParams(searchParams);
    closeModal();
  };

  const historyLimitTimePoint =
    parentPage === 'Report'
      ? getMostRecent(
          currentLocationData.rowData.wmsDate,
          currentLocationData.rowData.contentFoundDate,
        )
      : undefined;

  const location = useLocation();
  const show3DMap =
    (searchParams.has(FEATURE_FLAG_MAP_3D) || facilitySettings?.enable_3d_location_view) &&
    location.pathname.includes(INVENTORY_PAGES_URLS.WAREHOUSE_STATUS_X);

  return (
    <ModalBase
      help={<LocationModalHelp />}
      testId="c-location-modal"
      opened={opened}
      maxWidth={false}
      fullScreen
      contentPadding="0 60px"
      disableBackdropClick={true}
      title={
        <LocationModalHeader
          enableSnooze={isSnoozeEnabled}
          locationData={currentLocationData}
          filteredLocationList={filteredLocationList}
          refreshLocationData={refreshLocationData}
          currentLocationIndex={currentLocationIndex}
          setCurrentLocationIndex={setCurrentLocationIndex}
          facilitySettings={facilitySettings}
          locationImagesState={locationImagesState}
          issueLogic={issueLogic}
        />
      }
      handleClose={handleCloseModal}
    >
      <Grid container className={classes.container}>
        <Grid
          container
          item
          lg={feedbackDrawerOpen ? 9 : 12}
          sm={feedbackDrawerOpen ? 8 : 12}
          rowSpacing={3}
        >
          <Grid item>
            {spinnerLoading && <Spinner />}

            {currentLocationData && (
              <LocationModalSubHeader
                locationData={currentLocationData}
                facilitySettings={facilitySettings}
                parentPage={parentPage}
              />
            )}
          </Grid>

          <Grid container className={classes.barcodesContainer}>
            <Grid sm={12} item className={classes.barcodesContainerItem}>
              <BarcodesInformation
                locationData={currentLocationData}
                facilitySettings={facilitySettings}
                showBarcodeHighlighting={locationImagesState.showBarcodeHighlighting}
                highlightedBarcode={highlightedBarcode}
                setHighlightedBarcode={setHighlightedBarcode}
              />
            </Grid>
          </Grid>

          <Grid sm={12} item>
            <ImageGrid
              systemId={systemId ?? ''}
              locationData={currentLocationData}
              locationImagesState={locationImagesState}
              highlightedBarcode={highlightedBarcode}
              barcodeMatchLogic={barcodeMatchLogic}
              issueLogic={issueLogic}
              setHighlightedBarcode={setHighlightedBarcode}
            />
          </Grid>
          {show3DMap ? (
            <Grid xs={12} item paddingTop={3} position="relative">
              <ErrorBoundary
                fallback={
                  <BaseCard
                    cardFor="neighboring locations"
                    title="Neighboring locations"
                    subtitle="See surrounding issues and navigate to other locations"
                  >
                    <Typography
                      color="textSecondary"
                      textAlign="center"
                      variant="h6"
                      component="p"
                      gutterBottom
                    >
                      Sorry an error occured loading the map
                    </Typography>
                  </BaseCard>
                }
              >
                <Map3DCard
                  allLocations={allLocations}
                  systemId={systemId ?? ''}
                  currentLocationName={currentLocationData.location}
                  issueLogic={issueLogic}
                />
              </ErrorBoundary>
            </Grid>
          ) : null}

          <Grid container item spacing={2}>
            <Grid lg={6} sm={12} item>
              {currentLocationData.location && systemId && (
                <WMSRawData
                  slotLabel={currentLocationData.location}
                  systemId={systemId}
                  key={currentLocationData.location}
                  wmsVersion={currentLocationData.rowData.wmsSlotStatusVersion}
                  // This will be gone with the feature flag removal
                  // Gone with this approach because of the "magic number" as a height
                  // sfarkas - 2024-10-16
                  height={facilitySettings.show_location_labels ? '700px' : '424px'}
                />
              )}
            </Grid>

            <Grid lg={6} sm={12} item>
              {facilitySettings.show_location_labels ? (
                <Box display="flex" flexDirection="column" gap={2}>
                  <LabelsCard
                    locationId={currentLocationData.location}
                    wmsDateTime={
                      // hard to tell what type of data we have here
                      // requires clean up
                      // sfarkas - 2024-10-21
                      (currentLocationData as unknown as { verityDate: string; wmsDate: string })
                        .wmsDate
                    }
                    verityDatetime={
                      (currentLocationData as unknown as { verityDate: string; wmsDate: string })
                        .verityDate
                    }
                  />

                  {currentLocationData.location && systemId && (
                    <LocationHistory
                      slotLabel={currentLocationData.location}
                      systemId={systemId}
                      timePoint={historyLimitTimePoint}
                    />
                  )}
                </Box>
              ) : (
                currentLocationData.location &&
                systemId && (
                  <LocationHistory
                    slotLabel={currentLocationData.location}
                    systemId={systemId}
                    timePoint={historyLimitTimePoint}
                  />
                )
              )}
            </Grid>
          </Grid>
        </Grid>
        {feedbackDrawerOpen && (
          <Grid item>
            <FeedbackDrawer
              isOpen={feedbackDrawerOpen}
              locationData={currentLocationData}
              enableSnooze={isSnoozeEnabled}
              refreshLocationData={refreshLocationData}
              setDidUserOverwrite={setDidUserOverwrite}
              sendLocationInfo={sendLocationInfo}
            />
          </Grid>
        )}
      </Grid>
      <FeedbackDrawerToggler
        feedbackDrawerOpen={feedbackDrawerOpen}
        setFeedbackDrawerOpen={setFeedbackDrawerOpen}
      />
    </ModalBase>
  );
};
