import { Children, ReactNode } from 'react';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import { SxProps, Theme } from '@mui/system';

import { Box } from 'components/common/Box';
import { Spinner } from 'components/common/Spinner';
import { Tooltip } from '@mui/material';
import { baseCardStyle } from './styles';

/**
 * Interface for the action buttons of the Base Card Props
 */
export interface IBaseCardActionButton {
  testId?: string;
  /**
   * Button label
   */
  label: string;
  clickHandler?: React.MouseEventHandler<HTMLButtonElement>;
  variant?: 'text' | 'outlined' | 'contained';
  disabled?: boolean;
  fullWidth?: boolean;
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
}

/**
 * Interface for the props of the BaseCard
 */
export interface IBaseCardProps {
  /**
   * specifies what is this card used for
   */
  cardFor: string;
  /**
   * show Card header section, default=true
   */
  showHeader?: boolean;

  sx?: SxProps<Theme>;
  /**
   * show Card content section, default=true
   */
  showContent?: boolean;
  /**
   * show Card action buttons section, default=true
   */
  showActionButtons?: boolean;
  /**
   * show Card header divider, default=true
   */
  showHeaderDivider?: boolean;
  /**
   * if this value is other then 0, spinner will be shown in content section, default=0
   */
  isLoading?: boolean | number;
  /**
   * Label attached to the spinner (when it is shown)
   */
  spinnerLabel?: string;
  /**
   * icon to be shown in the left part of Card header
   */
  avatar?: ReactNode;
  /**
   * Card header title
   */
  title: string | ReactNode;
  /**
   * Card header subtitle
   */
  subtitle: string | ReactNode;
  /**
   * Shows number of items in the upper right corner of Card header (ex. number of issues)
   */
  counter?: number | ReactNode;
  /**
   * String under the counter (ex. "issues")
   */
  counterSubject?: string;
  hideCounterSubject?: boolean;
  /**
   * Defines buttons to be rendered
   */
  actionButtons?: IBaseCardActionButton[];
  /**
   * Custom text for an empty state of the card
   */
  customEmptyState?: string;
  children: ReactNode;
  uploadCard?: boolean;
  dataTestId?: string;
  alertElement?: ReactNode;
  noData?: boolean;
  scrollable?: boolean;
}

export const BaseCard = ({
  cardFor,
  showHeader = true,
  showContent = true,
  showActionButtons = true,
  showHeaderDivider = true,
  isLoading = 0,
  spinnerLabel,
  avatar,
  title,
  subtitle,
  noData,
  counter,
  counterSubject,
  hideCounterSubject,
  actionButtons,
  children,
  uploadCard,
  dataTestId,
  customEmptyState,
  alertElement,
  sx = {},
  scrollable,
}: IBaseCardProps) => {
  const { cx, classes } = baseCardStyle();

  // NOTE: here we're dealing with the case in which there is no subtitle
  // but there's a counter subject: we had a non breaking space so that
  // Title and counter are aligned
  // Eno: 2024-09-12
  const subPlaceHolder = counterSubject && !hideCounterSubject ? <span>&nbsp;</span> : undefined;

  return (
    <Card
      data-testid={dataTestId}
      className={cx(classes.card, {
        [classes.variantPrimary]: !noData,
        [classes.variantSecondary]: noData,
      })}
      elevation={3}
      sx={sx}
    >
      {showHeader && (
        <CardHeader
          avatar={avatar}
          title={title}
          subheaderTypographyProps={{ style: { wordBreak: 'break-word' } }}
          subheader={noData ? 'No data available.' : subtitle || subPlaceHolder}
          action={
            <Box
              className={classes.actionContainer}
              display="flex"
              alignItems="center"
              flexDirection="column"
              sx={{ cursor: 'default' }}
            >
              <Tooltip title={counterSubject}>
                {noData ? (
                  <Box mr={2.5} mt={2.5}>
                    <CloudOffIcon
                      sx={(theme: Theme) => ({
                        color: theme.palette.iconDisabled,
                      })}
                    />
                  </Box>
                ) : (
                  <Typography
                    data-testid={`c-occupancy-summary-${counterSubject}`}
                    className={classes.action}
                    variant="h4"
                    lineHeight={1.3}
                  >
                    {counter ?? ' '}
                  </Typography>
                )}
              </Tooltip>
              {!hideCounterSubject && (
                <Typography className={classes.action}>{counterSubject}</Typography>
              )}
            </Box>
          }
          className={cx(classes.cardHeader, { [classes.cardHeaderDivider]: showHeaderDivider })}
          classes={{
            title: classes.title,
            subheader: classes.subheader,
          }}
        />
      )}

      {alertElement}

      {showContent && (
        <CardContent
          className={scrollable ? classes.cardContentSectionScrollable : classes.cardContentSection}
        >
          {!isLoading && Children.count(children) ? (
            <List
              data-testid="c-card-content-wrapper"
              className={uploadCard ? classes.uploadCardContentList : classes.cardContentList}
              component="ul"
            >
              {children}
            </List>
          ) : (
            <Typography
              className={classes.emptyCard}
              variant="subtitle1"
              color="textSecondary"
              component="p"
            >
              {!isLoading && customEmptyState ? customEmptyState : `No ${cardFor} to show`}
            </Typography>
          )}
          {isLoading ? <Spinner label={spinnerLabel} /> : null}
        </CardContent>
      )}

      {showActionButtons && actionButtons && (
        <CardActions className={classes.cardAction}>
          {!isLoading &&
            actionButtons.map((button) => (
              <Button
                data-testid={button.testId}
                onClick={button.clickHandler}
                variant={button.variant}
                disabled={button.disabled || noData}
                fullWidth={button.fullWidth}
                color={button.color}
                key={`${title}-${dataTestId}-${button.label}`}
              >
                {button.label}
              </Button>
            ))}
        </CardActions>
      )}
    </Card>
  );
};
