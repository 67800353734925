import Slide from '@mui/material/Slide';
import { useHideOnScroll } from '../../hooks/useHideOnScroll';

interface IHideOnScrollProps {
  children: JSX.Element;
  threshold: number;
}

/**
 * Wrapper that will hide children element when treshold satisfied scroll position
 */
export const HideOnScroll = ({ children, threshold }: IHideOnScrollProps): JSX.Element => {
  const hide = useHideOnScroll({ threshold });

  return (
    <Slide appear={false} direction="down" in={!hide}>
      {children}
    </Slide>
  );
};
