import { Grid } from '@mui/material';
import { userHasPermission } from 'features/permissions/userHasPermission';
import { IReportParameterUniqueNameST, IReportSpecificationST } from 'codegen/report_specification';
import { PERMISSION } from 'features/permissions/permissions.model';
import { ParamForm } from './name-and-params-form/NameAndParamsForm';
import { NameField } from './name-and-params-form/NameField';
import { schedulerFormStyles } from '../styles';
import { updateReportParamValues } from '../utils/updateReportParamValue';
import { clearReportParamValues } from '../utils/clearReportParamValues';
import { ReportPriorityInput } from './ReportPriorityInput';
import { ReportPriorityPicker } from './ReportPriorityPicker';
import { ScheduleFormAction, ScheduleFormState } from '../reducers/ScheduleForm.model';

export const ScheduleFormParameters = ({
  reportSpec,
  state,
  dispatch,
}: {
  reportSpec: IReportSpecificationST;
  state: ScheduleFormState;
  dispatch: React.Dispatch<ScheduleFormAction>;
}) => {
  const { classes } = schedulerFormStyles();

  return (
    <form className={classes.form} noValidate>
      <Grid className={classes.gridItem} xs={12} item>
        <NameField
          reportName={state.reportName}
          isInputInvalid={!!state.errors.validReportName}
          helperText={state.errors.validReportName as string}
          onChangeOrBlur={(e) => dispatch({ type: 'SET_REPORT_NAME', payload: e.target.value })}
        />
        <ParamForm
          classes={classes}
          params={reportSpec.params}
          customParams={state.customParamValues}
          reportParamValues={state.reportParamValues}
          errors={state.errors}
          updateReportParamValues={(
            paramUniqueName: IReportParameterUniqueNameST,
            value: string | string[],
          ) => updateReportParamValues(paramUniqueName, value, reportSpec, dispatch)}
          clearReportParamValues={(paramUniqueName: string) =>
            clearReportParamValues(paramUniqueName, state.reportParamValues, reportSpec, dispatch)
          }
          onBlur={(paramUniqueName) =>
            dispatch({ type: 'VALIDATE_PARAM_VALUE', payload: { reportSpec, paramUniqueName } })
          }
        />
        {userHasPermission(PERMISSION.PRIORITY_PICKER_INTERNAL) ? (
          <ReportPriorityInput dispatch={dispatch} priority={state.priority} />
        ) : (
          <ReportPriorityPicker dispatch={dispatch} priority={state.priority} />
        )}
      </Grid>
    </form>
  );
};
