import {
  GridFilterInputSingleSelect,
  GridFilterItem,
  GridFilterOperator,
} from '@mui/x-data-grid-premium';
import { ILocationDataST } from 'codegen/report';

export const IsNotSelectedFilterOperator: GridFilterOperator<ILocationDataST, string> = {
  label: 'is not',
  value: 'isNot',
  getApplyFilterFn: (filterItem: GridFilterItem) => {
    if (!filterItem.field || !filterItem.value || !filterItem.operator) {
      return null;
    }

    return (value) => filterItem.value !== value;
  },
  InputComponent: GridFilterInputSingleSelect,
};
