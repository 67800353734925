import { encode as UTF8encode, decode as UTF8decode } from 'utf8';

const downloadBlobAsFile = (blob: Blob | MediaSource, fileName: string, extension: string) => {
  try {
    const fileDownloadUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = fileDownloadUrl;
    link.download = `${fileName}.${extension}`;
    link.click();
    link.remove();
  } catch (e) {
    console.error('fileFunctions.js::downloadBlobAsFile:', e);
  }
};

export const downloadInMemoryJSON = (jsonInMemory: object, fileName: string) => {
  try {
    const blob = new Blob([JSON.stringify(jsonInMemory, null, '\t')], {
      type: 'application/json',
    });
    downloadBlobAsFile(blob, fileName, '.json');
  } catch (e) {
    console.error('fileFunctions.js::downloadInMemoryJSON:', e);
  }
};

export const downloadBase64FromJSON = (
  jsonInMemory: object,
  fileName: string,
  FACILITY_SETTINGS_BASE64_FILE_EXTENSION: string,
) => {
  try {
    const utf8 = UTF8encode(JSON.stringify(jsonInMemory, null, '\t'));
    const base64 = window.btoa(utf8);
    const blob = new Blob([base64], { type: 'text/plain' });
    downloadBlobAsFile(blob, fileName, FACILITY_SETTINGS_BASE64_FILE_EXTENSION);
  } catch (e) {
    console.error('fileFunctions.js::downloadBase64FromJSON:', e);
  }
};

// Load a file from disk and execute a callback
export const loadFileFromDisk = (
  file: File,
  callback: { (settings: any): void; (arg0: any): void },
) => {
  const reader = new FileReader();
  reader.onload = (file) => {
    try {
      callback(JSON.parse(UTF8decode(window.atob(file?.target?.result as string))));
    } catch (e) {
      console.error('fileFunctions.js::loadFileFromDisk:', e);
    }
  };
  // Load file from disk
  reader.readAsText(file);
};

// Convert bytes to human readable size
export const bytesToSize = (bytes: number) => {
  try {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) {
      return '0 Byte';
    }
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return `${Math.round(bytes / 1024 ** i)} ${sizes[i]}`;
  } catch (e) {
    console.error('fileFunctions.js::bytesToSize:', e);
    return '(no size)';
  }
};
