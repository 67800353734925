import { isValidDecimalLength } from '../../utils/isValidDecimalLength';
import { DEFAULT_DECIMAL_PLACES } from '../../defaults/FormInput.defaults';

const isMetricValid = (value: string | number, decimalPlaces: number): string | undefined => {
  if (!isValidDecimalLength(value.toString(), decimalPlaces)) {
    return `Input values must be no more than ${decimalPlaces} decimal places`;
  }

  return undefined;
};

const isValidImperialFormat = (value: string) =>
  /^-?(\d[0-9]*')?((1[0-1]|[0-9])("{1}|'{2}))?$/.test(value);

const isImperialValid = (value: string): string | undefined => {
  if (!isValidImperialFormat(value)) {
    return 'Please enter valid feet and inches (e.g., 5\'10")';
  }

  return undefined;
};

export const validateInput = ({
  value,
  isMetricSystem,
  decimalPlaces,
}: {
  value: string;
  isMetricSystem: boolean;
  decimalPlaces?: number;
}) =>
  isMetricSystem
    ? isMetricValid(value, decimalPlaces ?? DEFAULT_DECIMAL_PLACES)
    : isImperialValid(value);
