import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { ChangeEvent } from 'react';

type TableTabsProps = {
  tabs: {
    label: string;
    show: boolean;
  }[];
  activeTab: number;
  handleTabChange: (event: React.ChangeEvent<HTMLInputElement>, value: number) => void;
  tabCounts: {
    [key: string]: number;
  };
};

export const ReportTabs = (props: TableTabsProps) => {
  const { tabs, activeTab, handleTabChange, tabCounts } = props;

  return (
    <Tabs
      aria-label="tabs"
      value={activeTab}
      scrollButtons
      allowScrollButtonsMobile
      variant="scrollable"
      onChange={handleTabChange}
    >
      {tabs
        .filter((tab) => tab.show)
        .map((tab) => {
          const tabCount =
            typeof tabCounts[tab.label] === 'number' ? ` (${tabCounts[tab.label]})` : '';
          return (
            <Tab data-testid="c-table-tab" key={tab.label} label={`${tab.label}${tabCount}`} />
          );
        })}
    </Tabs>
  );
};
