import Skeleton from '@mui/material/Skeleton';
import { ILocationData1ST } from 'codegen/report';
import { BaseCard } from 'components/BaseCard/BaseCard';
import { Box } from 'components/common/Box';
import { AisleSummary } from 'shared/map-3d/aisle-summary/AisleSummary';
import { AisleView } from 'shared/map-3d/aisle-view/AisleView';
import { useFacilityMap } from 'shared/map-3d/aisle-view/api/useFacilityMap';
import { useAisleView } from 'shared/map-3d/aisle-view/hooks/useAisleView';
import { Map3DCanvas } from 'shared/map-3d/map-3d-canvas/Map3DCanvas';
import { useCallback, useMemo, useRef, useState } from 'react';
import { ThreeEvent } from '@react-three/fiber';
import { Drawer } from '@mui/material';
import { getRowForFullReportTable } from 'common/functions/locationRows/locationRowsFunctions';
import { ZoomControls } from 'shared/map-3d/zoom-controls/ZoomControls';
import { AisleViewProps } from 'shared/map-3d/aisle-view/model/aisleViewProps.model';
import { ZoomDirection } from 'shared/map-3d/zoom-controls/ZoomControls.model';
import { IRuleActionSTIssueLogicEnum } from 'codegen/facility_settings';
import { BinHighlight } from 'shared/map-3d/bin-highlight/BinHighlight';
import { LocationSummaryPopup } from '../location-summary-popup/LocationSummaryPopup';
import { transformBarcodeRows } from '../barcodes/utils/transformBarcodeRows.util';
import { LocationSummaryPopupDetail } from '../location-summary-popup/LocationSummaryPopup.model';
import { barcodeStatusEnum } from '../barcodes/models/BarcodeRow.model';
import { useMap3DCard } from './map3DCard.styles';

export const Map3DCard = ({
  allLocations,
  systemId,
  currentLocationName,
  issueLogic,
}: {
  allLocations?: ILocationData1ST[];
  systemId: string;
  currentLocationName: string;
  issueLogic?: IRuleActionSTIssueLogicEnum;
}) => {
  const { classes, cx } = useMap3DCard();
  const { data: facilityMap, isLoading } = useFacilityMap(systemId ?? '');
  const {
    aisleBins,
    current,
    summary = [],
  } = useAisleView({
    facilityMap,
    allLocations,
    currentLocationName,
  });

  const [activeBinName, setActiveBinName] = useState('');

  const aisleBinsWithHandlers = useMemo(
    () =>
      aisleBins.map((bin) => ({
        ...bin,
        onClick: (event: ThreeEvent<MouseEvent>) => {
          setActiveBinName(event.object.parent?.name ?? 'Unknown');
        },
      })),
    [aisleBins],
  );

  const closePopup = useCallback(() => {
    setActiveBinName('');
  }, []);

  const selectedLocation = useMemo(() => {
    if (!activeBinName) {
      return {};
    }

    const location = allLocations?.find(
      (location) => location.slot_label === activeBinName,
    ) as ILocationData1ST;
    const selectedLocation = getRowForFullReportTable(
      location?.slot_label ?? '',
      location,
      location?.issues ?? [],
    );
    const barcodeRows = selectedLocation
      ? transformBarcodeRows(selectedLocation.actions.data, issueLogic)
      : null;
    const missing: LocationSummaryPopupDetail = {
      title: 'Missing',
      color: 'error.main',
      items:
        barcodeRows
          ?.filter((row) => row.barcodeStatus === barcodeStatusEnum.Missing)
          .map((row) => row.expectedContent) ?? [],
    };
    const unexpected: LocationSummaryPopupDetail = {
      title: 'Unexpected',
      color: 'warning.main',
      items:
        barcodeRows
          ?.filter((row) => row.barcodeStatus === barcodeStatusEnum.Unexpected)
          .map((row) => row.contentFound) ?? [],
    };

    return {
      bin3d: aisleBins.find((bin) => bin.name === activeBinName),
      details: [missing, unexpected].filter((detail) => detail.items.length),
    };
  }, [activeBinName, allLocations, aisleBins, issueLogic]);

  const [zoomType, setZoomType] = useState<AisleViewProps['fit']>({ type: 'height' });
  const handleZoomChange = useCallback((action: ZoomDirection) => {
    setZoomType(action === 'in' ? { type: 'height' } : { type: 'width' });
  }, []);

  const contentWrapperRef = useRef(null);

  return (
    <BaseCard
      cardFor="neighboring locations"
      title="Neighboring locations"
      subtitle="See surrounding issues and navigate to other locations"
      showHeaderDivider={false}
    >
      <Box ref={contentWrapperRef} className={classes.canvasWrapper}>
        {!allLocations?.length || isLoading ? (
          <Skeleton height="100%" />
        ) : (
          <>
            <Map3DCanvas className={classes.canvas}>
              <AisleView bins={aisleBinsWithHandlers} current={current} fit={zoomType} />
              {selectedLocation?.bin3d?.position && selectedLocation?.bin3d?.normal ? (
                <BinHighlight
                  normal={selectedLocation.bin3d.normal}
                  position={selectedLocation.bin3d.position}
                  scale={selectedLocation.bin3d.scale}
                />
              ) : null}
            </Map3DCanvas>

            <AisleSummary
              className={cx(classes.aisleSummary, classes.bottomDistance)}
              issues={summary}
            />
            <ZoomControls
              className={cx(classes.zoomControls, classes.bottomDistance)}
              onZoom={handleZoomChange}
            />
            <Drawer
              variant="persistent"
              open={!!activeBinName && !!selectedLocation}
              anchor="right"
              container={contentWrapperRef.current}
              PaperProps={{
                className: classes.drawerPaper,
              }}
            >
              <LocationSummaryPopup
                locationName={activeBinName}
                status={selectedLocation?.bin3d?.status ?? 'NOT_SCANNED'}
                details={selectedLocation?.details ?? []}
                onClose={closePopup}
              />
            </Drawer>
          </>
        )}
      </Box>
    </BaseCard>
  );
};

Map3DCard.displayName = 'Map3DCard';
