import { ClientLevelActionNames } from './ClientLevelActions';
import {
  ClientLevelAction,
  IClientLevelState,
  IClientLevelStateFacility,
} from './ClientLevelStore.model';

/**
 * initial state of the client level store
 */
export const initialClientLevelState: IClientLevelState = {
  facilityList: [] as IClientLevelStateFacility[],
  isMultiFacility: null,
  isFacilityListPopulated: false,
  isFacilityListLoading: false,
  failedToLoadFacilityList: false,
};

export const ClientLevelReducer = (state: IClientLevelState, action: ClientLevelAction) => {
  switch (action.type) {
    case ClientLevelActionNames.RESET:
      return { ...initialClientLevelState };

    case ClientLevelActionNames.SET_FACILITY_LIST:
      return {
        ...state,
        facilityList: action.payload,
        isMultiFacility: action.payload.length > 1,
      };

    case ClientLevelActionNames.SET_FAILED_TO_LOAD_FACILITY_LIST:
      return {
        ...state,
        failedToLoadFacilityList: action.payload,
      };

    case ClientLevelActionNames.SET_FACILITY_LIST_POPULATED:
      return {
        ...state,
        isFacilityListPopulated: action.payload,
      };

    case ClientLevelActionNames.SET_FACILITY_LIST_LOADING:
      return {
        ...state,
        isFacilityListLoading: action.payload,
      };

    default:
      return state;
  }
};
