import { styled } from '@mui/material/styles';

export const DrawerHeader = styled('div')<{ enableOffset: boolean }>(({ theme, enableOffset }) => ({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  height: '60px',
  marginTop: enableOffset ? 0 : 60,
  transition: theme.transitions.create(['padding', 'background', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...theme.mixins.toolbar,
  minHeight: 'auto !important',
  '&:hover': {
    cursor: 'pointer',
    background: '#f3f3f3',
  },
}));
