import { ILocationDataST, IVeritySlotStatusST } from 'codegen/warehouse_status';
import { DISPLAY_VERITY_STATES } from 'common/slotStates';
import { emptyCellString } from './collectionDelayValueGetter';

export const contentFoundValueGetter = (row: ILocationDataST): string => {
  const verityStatus = row.verity_status as IVeritySlotStatusST;

  if (!verityStatus || typeof verityStatus === 'string') {
    return verityStatus;
  }

  const { barcodes: verityBarcodes, user_overrides: userOverrides } = verityStatus;

  let content: string = emptyCellString;

  const currentOverride = userOverrides?.length ? userOverrides[userOverrides.length - 1] : null;

  if (currentOverride && currentOverride.state !== 'BARCODE') {
    return `${DISPLAY_VERITY_STATES[currentOverride.state]} *`;
  }
  const barcodes = currentOverride ? currentOverride.barcodes : verityBarcodes;

  if (barcodes.length > 0) {
    const list = barcodes.map((barcode) => `${barcode}${currentOverride ? ' *' : ''}`);
    content = list.join(', ');
  } else {
    content = DISPLAY_VERITY_STATES[verityStatus.state];
  }

  return content;
};
