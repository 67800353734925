import { Vec6 } from 'shared/map-container/MapContainer.model';
import { mapFacilityVectors } from 'shared/map-container/utils/mapFacilityVectors.util';
import { formatDisplayValue } from 'udb/ground-control/utils/formatDisplayValue';
import { BottomLeftDimensions } from '../../../model/NoFlyZoneGeometry.model';

export const validateSizeAndPositionFitsWithinWorldBox = ({
  zoneVectors,
  facilityVectors,
  fieldKey,
  isMetricSystem,
}: {
  zoneVectors: BottomLeftDimensions;
  facilityVectors: Vec6;
  fieldKey: keyof BottomLeftDimensions;
  isMetricSystem: boolean;
}): string | null => {
  const {
    w: zoneWidth,
    l: zoneLength,
    h: zoneHeight,
    minX: zoneMinX,
    minY: zoneMinY,
    minZ: zoneMinZ,
    maxZ: zoneMaxZ,
  } = Object.keys(zoneVectors).reduce(
    (acc, key) => ({ ...acc, [key]: Number(zoneVectors[key as keyof BottomLeftDimensions]) }),
    {} as BottomLeftDimensions,
  );

  const {
    minX: facilityMinX,
    minY: facilityMinY,
    maxX: facilityMaxX,
    maxY: facilityMaxY,
    minZ: facilityMinZ,
    maxZ: facilityMaxZ,
  } = mapFacilityVectors(facilityVectors);

  switch (fieldKey) {
    case 'w': {
      const validationMaxX = zoneMinX + zoneWidth;
      const deltaX = validationMaxX - facilityMaxX;

      if (zoneWidth < 0) {
        return '"Width" must be a positive number';
      }

      if (validationMaxX > facilityMaxX) {
        return `"Width" of ${formatDisplayValue(zoneWidth, {
          isUnitVisible: true,
          isMetricSystem,
        })} places this zone outside of the facility by ${formatDisplayValue(deltaX, {
          isUnitVisible: true,
          isMetricSystem,
        })}`;
      }

      return null;
    }
    case 'l': {
      const validationMaxY = zoneMinY + zoneLength;
      const deltaY = validationMaxY - facilityMaxY;

      if (zoneLength < 0) {
        return '"Length" must be a positive number';
      }

      if (validationMaxY > facilityMaxY) {
        return `"Length" of ${formatDisplayValue(zoneLength, {
          isUnitVisible: true,
          isMetricSystem,
        })} places this zone outside of the facility by ${formatDisplayValue(deltaY, {
          isUnitVisible: true,
          isMetricSystem,
        })}`;
      }

      return null;
    }
    case 'h': {
      const validationMaxZ = zoneMinZ + zoneHeight;
      const deltaZ = validationMaxZ - facilityMaxZ;

      if (zoneHeight < 0) {
        return '"Height" must be a positive number';
      }

      if (validationMaxZ > facilityMaxZ) {
        return `"Height" of ${formatDisplayValue(zoneHeight, {
          isUnitVisible: true,
          isMetricSystem,
        })} places this zone outside of the facility by ${formatDisplayValue(deltaZ, {
          isUnitVisible: true,
          isMetricSystem,
        })}`;
      }

      return null;
    }
    case 'minX': {
      const validationMaxX = zoneMinX + zoneWidth;
      const isBelowMin = zoneMinX < facilityMinX;
      const isAboveMax = validationMaxX > facilityMaxX;

      if (!isBelowMin && !isAboveMax) {
        return null;
      }

      const deltaMinX = facilityMinX - zoneMinX;
      const deltaMaxX = validationMaxX - facilityMaxX;
      const validationValue = isBelowMin ? deltaMinX : deltaMaxX;

      return `"X-Position" of ${formatDisplayValue(zoneMinX, {
        isUnitVisible: true,
        isMetricSystem,
      })} places this zone outside of the facility by ${formatDisplayValue(validationValue, {
        isUnitVisible: true,
        isMetricSystem,
      })}`;
    }
    case 'minY': {
      const validationMaxY = zoneMinY + zoneLength;
      const isBelowMin = zoneMinY < facilityMinY;
      const isAboveMax = validationMaxY > facilityMaxY;

      if (!isBelowMin && !isAboveMax) {
        return null;
      }

      const deltaMinY = facilityMinY - zoneMinY;
      const deltaMaxY = validationMaxY - facilityMaxY;
      const validationValue = isBelowMin ? deltaMinY : deltaMaxY;

      return `"Y-Position" of ${formatDisplayValue(zoneMinY, {
        isUnitVisible: true,
        isMetricSystem,
      })} places this zone outside of the facility by ${formatDisplayValue(validationValue, {
        isUnitVisible: true,
        isMetricSystem,
      })}`;
    }

    case 'minZ': {
      const isBelowMin = zoneMinZ < facilityMinZ;
      const isAboveMax = zoneMinZ > facilityMaxZ;
      const isAboveZoneMax = zoneMinZ > zoneMaxZ;

      if (!isAboveZoneMax && !isBelowMin && !isAboveMax) {
        return null;
      }

      if (isAboveZoneMax) {
        return '"Bottom" value cannot be higher than the "top" value';
      }

      const deltaMinZ = facilityMinZ - zoneMinZ;
      const deltaMaxZ = zoneMinZ - facilityMaxZ;

      if (isBelowMin) {
        return `"Bottom" of ${formatDisplayValue(zoneMinZ, {
          isUnitVisible: true,
          isMetricSystem,
        })} places this zone below the facility by ${formatDisplayValue(deltaMinZ, {
          isUnitVisible: true,
          isMetricSystem,
        })}`;
      }

      return `"Bottom" of ${formatDisplayValue(zoneMinZ, {
        isUnitVisible: true,
        isMetricSystem,
      })} places this zone above the facility by ${formatDisplayValue(deltaMaxZ, {
        isUnitVisible: true,
        isMetricSystem,
      })}`;
    }
    case 'maxZ': {
      const isBelowMin = zoneMaxZ < facilityMinZ;
      const isAboveMax = zoneMaxZ > facilityMaxZ;
      const isBelowZoneMin = zoneMaxZ < zoneMinZ;

      if (isBelowZoneMin) {
        return '"Top" value cannot be lower than the "bottom" value';
      }

      if (isAboveMax) {
        const deltaMaxZAbove = zoneMaxZ - facilityMaxZ;

        return `"Top" of ${formatDisplayValue(zoneMaxZ, {
          isUnitVisible: true,
          isMetricSystem,
        })} places this zone above the facility by ${formatDisplayValue(deltaMaxZAbove, {
          isUnitVisible: true,
          isMetricSystem,
        })}`;
      }

      if (isBelowMin) {
        const deltaMaxZBelow = facilityMinZ - zoneMaxZ;

        return `"Top" of ${formatDisplayValue(zoneMaxZ, {
          isUnitVisible: true,
          isMetricSystem,
        })} places this zone below the facility by ${formatDisplayValue(deltaMaxZBelow, {
          isUnitVisible: true,
          isMetricSystem,
        })}`;
      }

      return null;
    }

    default:
      return null;
  }
};
