import { ILocationData1ST, ILocationLabelST } from 'codegen/report';
import { labelTypeEnumToText } from 'components/ModalsAndPopups/LocationModal/features/labels-card/models/labelTypeEnumToText.model';

export const getLabelOptionsFromData = (locations: ILocationData1ST[]) => {
  const distinctOptions = [
    ...new Set(
      locations
        .filter((l) => !!l.location_label)
        .map((l) => l.location_label as ILocationLabelST)
        .filter((l) => l?.status === 'ACTIVE' && !!l?.label_type)
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .map((l) => labelTypeEnumToText[l.label_type!]),
    ),
  ];

  return [
    ...[...new Set(distinctOptions)].map((val) => ({
      label: val,
      value: val,
    })),
  ];
};
