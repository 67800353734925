import Link from '@mui/material/Link';
import { AccordionDetails, AlertTitle } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';

export const BarcodeMatchLogicAlertInfo = () => (
  <Typography component="div">
    <AlertTitle>Custom issue logic rules</AlertTitle>
    Current documentation on issue and barcode match logic can be found&nbsp;
    <Link
      href="https://verity-ag.atlassian.net/wiki/x/O4DfjQ"
      rel="noopener noreferrer"
      target="_blank"
    >
      here
    </Link>
    <br />
    Documentation on how to write issue logic rules can be found here&nbsp;
    <Link
      href="https://verity-ag.atlassian.net/wiki/x/ooHRh"
      rel="noopener noreferrer"
      target="_blank"
    >
      here
    </Link>
    <br />
    Edit and save the JSON code below to make a custom issue logic.
    <p>How to set up Logic</p>
    <br />
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>Action</AccordionSummary>
      <AccordionDetails>
        <ul>
          <li>
            Issue logic: DEFAULT, DOUBLE_DEPTH_MAP_BASED, NA_BARCODE, PICTURE_ONLY, C001C_MISSING_BC
          </li>
        </ul>
      </AccordionDetails>
    </Accordion>
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>Conditions</AccordionSummary>
      <AccordionDetails>
        Condition
        <ul>
          <li>
            Operator: CONTAINS, EQUALS, STARTS_WITH, ENDS_WITH, MATCH, ANY_OF, NOT_IN, IS_BARCODE,
            IS_EMPTY, IS_INVALID, IS_DOUBLE_DEPTH
          </li>
          <li>Field: LOCATION_NAME, EXPECTED_STATE, EXPECTED_CONTENT, CUSTOMERS, LOCATION</li>
          <li>Quantifier: ALL, ANY, NONE</li>
        </ul>
        <br />
        Logical operator: AND, OR, NOT
      </AccordionDetails>
    </Accordion>
  </Typography>
);
