import { Grid } from '@mui/material';
import { IFacilitySettingsST } from 'codegen/facility_settings';
import { useFacilityModalsStore } from 'store/Modals';
import { SettingsCard } from '../../SettingsCard';
import { getSystemResetOptions } from '../../options/getSystemResetOptions';

export const SystemReset = ({
  isLoading,
  facilitySettings,
  handleValuesChange,
  initialSettings,
  facilityName,
  loadData,
}: {
  isLoading: boolean;
  initialSettings: IFacilitySettingsST;
  facilitySettings: IFacilitySettingsST;
  handleValuesChange: (prop: string, value: boolean) => void;
  facilityName: string;
  loadData: () => void;
}) => {
  const { dispatchFacilityModals } = useFacilityModalsStore();

  return (
    <Grid xs={12} item>
      <SettingsCard
        title="System Reset"
        isLoading={!!isLoading}
        items={getSystemResetOptions(
          facilitySettings,
          handleValuesChange,
          initialSettings,
          facilityName || '',
          dispatchFacilityModals,
          loadData,
        )}
        testId="c-system-reset-settings-card"
      />
    </Grid>
  );
};
