import { forwardRef } from 'react';
import { useMeasurementSystem } from 'udb/ground-control/hooks/useMeasurementSystem';
import { formatDisplayValue } from 'udb/ground-control/utils/formatDisplayValue';
import { ZoneBaseMeshProps, ZoneBaseMeshRef } from './drone-zone-base-mesh.model';
import { meshRenderOrder } from '../../defaults/mesh-render-order';
import { MeshNodeLabel } from '../../../map-container/features/mesh-node-label/MeshNodeLabel';

export const DroneZoneBaseMesh = forwardRef(
  (
    {
      label,
      material,
      width,
      length,
      height,
      position,
      options,
      onClick,
      textColors = {},
      renderOrder = meshRenderOrder.zones,
      isLabelVisible = true,
      ...props
    }: ZoneBaseMeshProps,
    ref?: ZoneBaseMeshRef,
  ) => {
    const { isMetricSystem } = useMeasurementSystem();
    const labelText = `${label} (${formatDisplayValue(height, {
      isUnitVisible: true,
      decimalPlaces: 1,
      isMetricSystem,
    })})`;

    return (
      <mesh
        ref={ref}
        position={position}
        renderOrder={renderOrder}
        material={material}
        onClick={(event) => {
          if (onClick) {
            event.stopPropagation();

            onClick(event);
          }
        }}
        {...props}
      >
        <boxGeometry args={[width, length, height]} />

        {isLabelVisible && (
          <MeshNodeLabel
            w={width}
            l={length}
            options={options}
            textLines={[labelText]}
            renderOrder={renderOrder}
            colors={{ background: 'white', border: 'white', text: 'black', ...textColors }}
          />
        )}
      </mesh>
    );
  },
);
