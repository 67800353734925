import Hotjar from '@hotjar/browser';
import { rolesFromUserGroups } from 'features/permissions/roleFromUserGroups';
import { Role, ROLE } from 'features/permissions/role.model';
import { isProductionEnvironment } from './functions/environments';
import { USER_GROUPS } from './userGroups';
import { TokenManager } from './tokenManager';

const INTERNAL_ROLES: Role[] = [ROLE.SUPER_ADMIN, ROLE.VERITY_USER];

type HjRoleAttributes = {
  [key in ROLE]: boolean;
} & {
  IS_INTERNAL: boolean;
  CLIENT: string;
};

export function initializeHotjar() {
  if (!isProductionEnvironment()) {
    const hjid = import.meta.env.VITE_HOTJAR_ID;
    const hjsv = import.meta.env.VITE_HOTJAR_VERSION;
    if (hjid && hjsv) {
      Hotjar.init(hjid, hjsv);
    }
  }
}

function setHotjarUserAttributes(client?: string) {
  const tokenManager = TokenManager.getInstance();
  const userId = tokenManager.getUserAttributes()?.sub;

  const userGroups: USER_GROUPS[] = tokenManager.userGroupsFromAccessToken() || [];
  const roles: Role[] = rolesFromUserGroups(userGroups);

  const hjUserAttributes = Object.values(ROLE).reduce(
    (acc, role) => {
      acc[role] = roles.includes(role);
      return acc;
    },
    { CLIENT: client ?? 'undefined' } as HjRoleAttributes,
  );

  const isInternal = roles.some((role) => INTERNAL_ROLES.includes(role));
  hjUserAttributes.IS_INTERNAL = isInternal;

  console.debug('Setting HJ Attributes', userId, hjUserAttributes);

  if (Hotjar?.isReady() && userId) {
    Hotjar.identify(userId, {
      ...hjUserAttributes,
    });
  }
}

export function setHotjarUser() {
  setHotjarUserAttributes();
}

export function setHotjarClient(client?: string) {
  setHotjarUserAttributes(client);
}
