import type { Vector3 as FiberVector3 } from '@react-three/fiber';
import { Vector3 } from 'three';

interface Vector3Object {
  x: number;
  y: number;
  z: number;
}

const isVectorObject = (maybeVector: unknown): maybeVector is Vector3Object =>
  typeof maybeVector === 'object' &&
  maybeVector !== null &&
  'x' in maybeVector &&
  typeof maybeVector.x === 'number' &&
  'y' in maybeVector &&
  typeof maybeVector.y === 'number' &&
  'z' in maybeVector &&
  typeof maybeVector.z === 'number';

/**
 * ThreeJS and r3f have slightly different concept of what Vector3 is.
 * In Three Vector3 is a class, but for r3f it has magic under the hood
 * and understands arrays and scalars as vectors.
 *
 * This works in majority of the cases and is suitable for JSON
 * serialisation, but occasionally we need to use three to add some logic.
 * For this purpose we have a helper to desialise it into good old Threejs
 * Vector3
 *
 * More info:
 * https://r3f.docs.pmnd.rs/api/objects
 */
// eslint-disable-next-line import/no-unused-modules
export function vectorLike2Vector(v: FiberVector3): Vector3 {
  if (v instanceof Vector3) {
    return v.clone();
  }

  if (Array.isArray(v)) {
    if (v.length !== 3) {
      throw new Error('Array must have exactly 3 elements');
    }

    return new Vector3(v[0], v[1], v[2]);
  }
  if (typeof v === 'number') {
    return new Vector3(v, v, v);
  }
  if (isVectorObject(v)) {
    return new Vector3(v.x, v.y, v.z);
  }
  throw new Error('Invalid input for Vector3');
}
