import { Grid } from '@mui/material';
import { schedulerFormStyles } from '../styles';
import { ReportDeadlines } from './ReportDeadlines';
import { ScheduleFormAction, ScheduleFormState } from '../reducers/ScheduleForm.model';

export const ScheduleFormDeadlines = ({
  systemId,
  state,
  rStringDeadline,
  dispatch,
}: {
  systemId: string;
  state: ScheduleFormState;
  rStringDeadline: string;
  dispatch: React.Dispatch<ScheduleFormAction>;
}) => {
  const { classes } = schedulerFormStyles();
  return (
    <form className={classes.form} noValidate>
      <Grid className={classes.gridItem} xs={12} item>
        <ReportDeadlines
          systemId={systemId}
          reportDeadline={state.reportDeadline}
          reportDeadlineTimeoutInMinutes={state.reportDeadlineTimeoutInMinutes}
          reportFromDate={state.dateFrom}
          reportUntilDate={state.dateUntil}
          reportDeadlineText={state.isRecurring ? state.reportDeadlineText : ''}
          rString={rStringDeadline}
          dispatch={dispatch}
        />
      </Grid>
    </form>
  );
};
