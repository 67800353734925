import { useMemo } from 'react';
import { NodeST } from 'shared/map-container/MapContainer.model';
import { ILocationData1ST } from 'codegen/report';
import { vectorLike2Vector } from 'shared/map-3d/vectorLike2Vector.util';
import { AisleSummaryProps } from 'shared/map-3d/aisle-summary/model/aisleSummaryProps.model';
import { BinStatus } from '../../bin3D/model/bin3DProps.model';
import { AisleViewProps } from '../model/aisleViewProps.model';
import { getBinStatus } from '../utils/getBinStatus.util';

const SCALE_FACTOR = 0.95;

const flattenNodes = (node: NodeST): NodeST[] => {
  let result: NodeST[] = [node];

  if (node.nodes) {
    node.nodes.forEach((childNode) => {
      result = result.concat(flattenNodes(childNode));
    });
  }

  return result;
};

function findNodesByChildName(
  root: NodeST,
  targetType: NodeST['type'],
  childName: string,
): NodeST | null {
  let result = null;

  function hasDescendantWithName(node: NodeST, name: string): boolean {
    if (node.name === name) {
      return true;
    }
    if (node.nodes) {
      return node.nodes.some((child) => hasDescendantWithName(child, name));
    }
    return false;
  }

  function dfs(node: NodeST): void {
    if (node.type === targetType) {
      if (hasDescendantWithName(node, childName)) {
        result = node;
      }
    }
    if (node.nodes) {
      node.nodes.forEach(dfs);
    }
  }

  dfs(root);
  return result;
}

export function useAisleView({
  facilityMap,
  allLocations,
  currentLocationName,
}: {
  allLocations?: ILocationData1ST[];
  facilityMap?: NodeST;
  currentLocationName: string;
}) {
  return useMemo(() => {
    if (facilityMap && allLocations) {
      const aisleSideNode = findNodesByChildName(facilityMap, 'AISLE_SIDE', currentLocationName);
      if (!aisleSideNode) {
        return { aisleBins: [] };
      }
      const bins = flattenNodes(aisleSideNode)
        .filter((node) => node.type === 'BIN')
        .map((bin) => ({
          ...bin,
          scale: vectorLike2Vector(bin.scale).multiplyScalar(SCALE_FACTOR),
          position: vectorLike2Vector(bin.position),
          normal: vectorLike2Vector(bin.normal),
        }));

      const binNames = bins.map((bin) => bin.name);
      const aisleLocations = allLocations.filter((location) =>
        binNames.includes(location.slot_label),
      );

      const binsWithStatus: AisleViewProps['bins'] = bins.map((bin) => ({
        ...bin,
        status: getBinStatus(aisleLocations.find((location) => location.slot_label === bin.name)),
        current: currentLocationName === bin.name,
      }));

      const summary = Object.entries(
        binsWithStatus.reduce<Record<BinStatus, number>>(
          (acc, bin) => ({
            ...acc,
            [bin.status]: acc[bin.status] + 1,
          }),
          {
            ISSUE: 0,
            POTENTIAL_ISSUE: 0,
            MATCH: 0,
            EMPTY: 0,
            EXCLUDED: 0,
            NOT_SCANNED: 0,
          },
        ),
      ).map(([status, count]) => ({ status, count })) as AisleSummaryProps['issues'];

      return {
        aisleBins: binsWithStatus,
        current: binsWithStatus.find((bin) => bin.current),
        summary,
      };
    }
    return { aisleBins: [] };
  }, [facilityMap, allLocations, currentLocationName]);
}
