import { styled } from '@mui/material/styles';

export const ToggleWrapper = styled('div')(({ theme }) => ({
  zIndex: 1,
  bottom: '0',
  left: 0,
  padding: '10px 12px',
  background: '#fff',
  position: 'absolute',
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  borderTop: `1px solid ${theme.palette.grey[300]}`,
  transition: theme.transitions.create(['left', 'width', 'top'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));
