import { formatDisplayValue } from 'udb/ground-control/utils/formatDisplayValue';
import { DroneZones } from '../../../../../../../udb/ground-control/drone-zones/model/droneZones.model';

export const getHeightRangeValues = ({
  min,
  max,
  zones,
  isMetricSystem,
}: {
  min: number;
  max: number;
  zones: DroneZones[];
  isMetricSystem: boolean;
}): { id: string; position: string; value: string }[] => {
  const zoneZAxisValues = [
    ...zones
      .map((zone) => [zone.sizeAndPosition.minZ, zone.sizeAndPosition.maxZ])
      .flat(1)
      .filter((value) => max - value >= 1 && value - min >= 1),
  ];

  const deduplicateRangeValues = [...new Set([min, ...zoneZAxisValues, max])];

  const sortedLegendRange = deduplicateRangeValues
    .sort((legendA, legendB) => legendB - legendA)
    .map((legend) => ({
      id: crypto.randomUUID(),
      position: `${(legend / max) * 100}%`,
      value: formatDisplayValue(Number(legend), { isMetricSystem, decimalPlaces: 1 }),
    }));

  return sortedLegendRange;
};
