import moment from 'moment';
import { IIssueST, IIssueSTStateEnum, IIssueSTTypeEnum } from 'codegen/report';
import { isEmpty } from 'lodash';
import { ISSUE_ACTIONS } from 'common/Actions/actionTypes';
import {
  ISSUE_STATES,
  DISPLAY_ISSUE_TYPES,
  LOCATION_ISSUE_TYPES,
  ISSUE_TYPES,
} from '../../issueTypesAndStates';
import { getLatestOverwriteFromSlotStatus } from '../slot/utils/getLatestOverwriteFromSlotStatus';
import { INCONCLUSIVE_TAB_ALLOWED_DATA, INVALID_TAB_ALLOWED_DATA } from '../../tabDataDefinitions';
import { sort } from '../otherFunctions';

/**
 * Returns the IssueType from location issue data, for the Review table
 * @param issue
 * @returns IssueType or "-"
 */
export const getIssueTypeFromSingleIssueForReviewTable = (
  state: IIssueSTStateEnum,
  type: IIssueSTTypeEnum,
) => (state !== ISSUE_STATES.SOLVED ? DISPLAY_ISSUE_TYPES[type] : 'None');

/**
 * Returns the issue type from issue data, for the Full report table
 * @param issue
 * @returns Issue type
 */
export const getIssueTypeForFullReportTable = (
  state: IIssueSTStateEnum,
  type: IIssueSTTypeEnum,
) => {
  if (
    state === ISSUE_STATES.NEW ||
    state === ISSUE_STATES.KNOWN ||
    state === ISSUE_STATES.SNOOZED
  ) {
    return DISPLAY_ISSUE_TYPES[type];
  }
  return 'None';
};

/**
 * Returns the location issue type from issue data
 * @param issue
 * @param verityDate
 * @param wmsDate
 * @returns issue type
 */
export const getLocationIssueType = (
  state: IIssueSTStateEnum,
  verityDate: string,
  wmsDate: string,
) => {
  const vD = verityDate && verityDate !== '-' ? verityDate : null;
  const wD = wmsDate && wmsDate !== '-' ? wmsDate : null;
  const compareDates = vD && wD;

  if (
    state === ISSUE_STATES.NEW ||
    state === ISSUE_STATES.KNOWN ||
    state === ISSUE_STATES.SNOOZED
  ) {
    return LOCATION_ISSUE_TYPES.HAS_ISSUE;
  }

  if ((compareDates && moment(vD).isBefore(wD)) || state === ISSUE_STATES.NA) {
    return LOCATION_ISSUE_TYPES.SYSTEM_CANT_INFER_IF_HAS_ISSUE;
  }
  return LOCATION_ISSUE_TYPES.HAS_NO_ISSUE;
};

/**
 * Get the verity barcode location according to wms data (from issue data)
 * @param issue
 * @returns location name or "-"
 */
export const getVerityBarcodeLocationAccordingToWMSFromSingleIssue = (issue: IIssueST) =>
  // if there is an user overwrite, this location shall not be displayed
  // because it refers to the verity result or verity user overwrite only
  !isEmpty(issue) &&
  !getLatestOverwriteFromSlotStatus(issue.slot_status.verity_status, 'client') &&
  issue.wms_slot_status_with_verity_bc &&
  typeof issue.wms_slot_status_with_verity_bc !== 'string' &&
  issue.wms_slot_status_with_verity_bc.slot_label
    ? issue.wms_slot_status_with_verity_bc.slot_label
    : '-';

/**
 * Get the wms barcode location according to verity data (from issue data)
 * @param issue
 * @returns location name or "-"
 */
export const getWmsBarcodeLocationAccordingToVerityFromSingleIssue = (issue: IIssueST) => {
  let processedValue = '-';
  // We only want to return the location if it is different from the issue location
  // otherwise it brings no added value to the user
  if (
    !isEmpty(issue) &&
    issue.state !== 'SOLVED' &&
    issue.verity_slot_status_with_wms_bc &&
    typeof issue.verity_slot_status_with_wms_bc !== 'string' &&
    issue.verity_slot_status_with_wms_bc.slot_label !== issue.location
  ) {
    processedValue = issue.verity_slot_status_with_wms_bc.slot_label;
  }
  return processedValue;
};

/**
 * Get the snooze / unsnooze label
 */
export const getSnoozeActionLabel = (state: IIssueSTStateEnum) =>
  state === ISSUE_STATES.SNOOZED ? ISSUE_ACTIONS.UNSNOOZE : ISSUE_ACTIONS.SNOOZE;

/**
 * Check if snoozing / unsnoozing shall be enabled
 */
export const isSnoozedActionDisabled = (issue: IIssueST) => {
  const snoozeDisabled =
    issue.state === ISSUE_STATES.SOLVED ||
    issue.state === ISSUE_STATES.NA ||
    INCONCLUSIVE_TAB_ALLOWED_DATA.includes(issue.type as ISSUE_TYPES) ||
    INVALID_TAB_ALLOWED_DATA.includes(issue.type as ISSUE_TYPES);

  return snoozeDisabled;
};

export const getLatestIssue = (issues: IIssueST[]) => {
  let locationIssue: IIssueST = {} as IIssueST;
  if (!isEmpty(issues)) {
    const sortedIssuesData = sort({
      array: issues,
      sortingOrder: 'desc',
      sortBy: 'updated_at',
    }) as IIssueST[];
    [locationIssue] = sortedIssuesData;
  }
  return locationIssue;
};
