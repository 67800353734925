import { IDroneStatusST, IFleetVersionResponseST } from 'codegen/flight_domain';
import {
  makeBatteryLevelString,
  makeBatteryStateString,
  makeWiFiStatusString,
} from '../../../../pages/GroundControl/pages/FleetOverview/FleetOverviewFunctions';
import { dateFormatFleet } from '../utils/dateFormatFleet';
import { IFleetTableRows } from './IFleetTableRows';

/**
 * Prepare the rows for the fleet table (in the delivery tab)
 * @param fleet list of drone statuses
 * @param versions list of SW version for the drones
 * @param expectedVersion SW version the drones are expected to have: this is used
 * to highlight in the table drones which might have some SW issues
 */
export const transformToFleetTableRows = (
  fleet: IDroneStatusST[],
  versions: IFleetVersionResponseST['drone_versions'],
  expectedVersion?: string,
): IFleetTableRows[] =>
  Object.keys(fleet)
    .map((key) => parseInt(key, 10))
    .map((key) => {
      const drone: IDroneStatusST = fleet[key];
      const droneVersion: string = versions[drone.drone_name]?.version || '';

      return {
        additional_diagnostics: drone.additional_diagnostics ? drone.additional_diagnostics : '',
        battery_current: drone.battery_current,
        battery_level: makeBatteryLevelString(drone.battery_level),
        battery_serial_number: drone.battery_serial_number,
        battery_state: makeBatteryStateString(drone.battery_state),
        battery_temperature: drone.battery_temperature,
        battery_voltage: drone.battery_voltage
          ? Math.round(drone.battery_voltage * 100) / 100
          : drone.battery_voltage,
        chargerId: drone.charger_id,
        charging_current_limit: drone.charging_current_limit,
        charging_strategy: drone.charging_strategy,
        chargerPosition: drone.charger_pos_description,
        detailed_battery_state: drone.detailed_battery_state,
        drone_name: drone.drone_name,
        droneName: drone.drone_name,
        drone_state: drone.drone_state,
        drone_online: drone.drone_online,
        expectedVersion,
        id: key,
        last_operation_feedback: drone.last_operation_feedback ? drone.last_operation_feedback : '',
        max_recommended_num_charging_cycles: drone.max_recommended_num_charging_cycles,
        not_ready_reason: drone.not_ready_reason ? drone.not_ready_reason : '',
        num_charging_cycles: drone.num_charging_cycles,
        serial: versions[drone.drone_name]?.drone_serial,
        time_to_optimal_charge: drone.time_to_optimal_charge,
        updatedAt: dateFormatFleet(drone.updated_at),
        version: droneVersion,
        wifiStatus: makeWiFiStatusString(drone.drone_online),
      };
    });
