import { IVeritySlotStatusST } from 'codegen/location_information';
import { ILocationData1ST } from 'codegen/report';
import { ISSUE_TYPES } from 'common/issueTypesAndStates';
import { Bin3DProps } from '../../bin3D/model/bin3DProps.model';

// TODO: import these masks from table?
const ISSUE_MASK: string[] = [
  ISSUE_TYPES.WMS_BARCODE_VERITY_EMPTY,
  ISSUE_TYPES.WMS_BARCODE_NOT_EQUAL_VERITY_BARCODE,
  ISSUE_TYPES.WMS_EMPTY_VERITY_BARCODE,
  ISSUE_TYPES.WMS_EMPTY_VERITY_NOTEMPTY,
  ISSUE_TYPES.WMS_BARCODE_DUPLICATE,
];

const POTENTIAL_ISSUE_MASK: string[] = [
  ISSUE_TYPES.INCONCLUSIVE_WMS_BARCODE_VERITY_NOTEMPTY,
  ISSUE_TYPES.C001C_MISSING_BC,
];

type BinFilter = (location: ILocationData1ST) => boolean;

const excludeFilter: BinFilter = (location) =>
  location.slot_settings?.exclusion_status === 'EXCLUDE';
const notScannedFilter: BinFilter = (location) => !location.verity_status || !location.slot_label;
const issueFilter: BinFilter = (location) =>
  location.issues?.some((issue) => ISSUE_MASK.includes(issue.type));
const potentialIssueFilter: BinFilter = (location) =>
  location.issues?.some((issue) => POTENTIAL_ISSUE_MASK.includes(issue.type));
const matchFilter: BinFilter = (location) => !location.issues.length;
const emptyFilter: BinFilter = (location) =>
  (location.verity_status as IVeritySlotStatusST)?.state === 'EMPTY';

export function getBinStatus(location?: ILocationData1ST): Bin3DProps['status'] {
  if (typeof location === 'undefined') {
    return 'NOT_SCANNED';
  }
  // order of the `type` is priority order higest
  // to lowest in which status is assigned
  const binStates: Array<{
    type: Bin3DProps['status'];
    filterFn: BinFilter;
  }> = [
    {
      type: 'EXCLUDED',
      filterFn: excludeFilter,
    },
    {
      type: 'NOT_SCANNED',
      filterFn: notScannedFilter,
    },
    {
      type: 'ISSUE',
      filterFn: issueFilter,
    },
    {
      type: 'POTENTIAL_ISSUE',
      filterFn: potentialIssueFilter,
    },
    // TODO: probably can drop the extra conditoins assuming that we use the priority order of the bin states to attach the status
    {
      type: 'EMPTY',
      filterFn: emptyFilter,
    },
    {
      type: 'MATCH',
      filterFn: matchFilter,
    },
  ];

  return binStates.find((binState) => binState.filterFn(location))?.type ?? 'NOT_SCANNED';
}
