import { Typography } from '@mui/material';
import moment from 'moment';
import { Event } from 'react-big-calendar';
import { Box } from 'components/common/Box';
import { DroneIcon } from 'components/common/FacilityMenu/Icons/DroneIcon';

export const FlightHoursCalendarEvent = ({ event }: { event: Event }) => (
  <Box display="flex" alignItems="center" flexDirection="column">
    <div>
      <DroneIcon color="#fff" />
    </div>
    <Typography fontSize={12}>
      {`${moment(event.start).format('HH:mm')} - ${moment(event.end).format('HH:mm')}`}
    </Typography>
  </Box>
);
