import { makeStyles } from 'tss-react/mui';

export const useLabelsStatusStyles = makeStyles()(() => ({
  labelsStatus: {
    alignItems: 'center',
    '& .MuiAlert-action': {
      paddingTop: 0,
    },
  },
}));
