import {
  IFacilitySettingsST,
  IRuleST,
  IConditionOrLogicalOperatorST,
  IRuleActionSTIssueLogicEnum,
} from 'codegen/facility_settings';
import { isArray } from 'lodash';
import { ILocationData } from 'store/Modals/facilityModals/IFacilityModalsStore';
import { ILocationDataST, IWMSSlotStatusST } from 'codegen/report';
import { BarcodeMatchLogic } from '../barcodes/getBarcodeMuiColor.util';

type ComparisonData = {
  location: string;
  customer: string;
  wmsState: string;
  wmsValue: string;
  locationName: string;
};

export const compareValues = (locationValue: string, condition: IConditionOrLogicalOperatorST) => {
  if (!condition.operator) {
    return false;
  }

  const { type, value } = condition.operator;
  switch (type) {
    case 'EQUALS':
      return value === locationValue;
    case 'MATCH':
      return !!value && typeof value === 'string' && RegExp(value).test(locationValue);
    case 'STARTS_WITH':
      return !!value && typeof value === 'string' && locationValue.startsWith(value);
    case 'ENDS_WITH':
      return !!value && typeof value === 'string' && locationValue.endsWith(value);
    case 'ANY_OF':
      return isArray(value) && [...value].includes(locationValue);
    case 'CONTAINS':
      return !!value && typeof value === 'string' && value.indexOf(locationValue) > 0;
    case 'IS_EMPTY':
      return !value;
    case 'NOT_IN':
      return isArray(value) && ![...value].includes(locationValue);
    case 'IS_INVALID':
      return !!value && typeof value === 'string' && value?.toUpperCase() === 'INVALID';
    case 'IS_BARCODE':
      return !!value && typeof value === 'string' && /d+(,d+)*/.test(value);
    // FIXME - TODO: the double depth case cannot be handled yet (info not available in the FE)
    // eno 2024-07-03
    case 'IS_DOUBLE_DEPTH':
    default:
      return false;
  }
};

export const evaluateCondition = (
  comparisonData: ComparisonData,
  condition: IConditionOrLogicalOperatorST,
) => {
  switch (condition.field) {
    case 'LOCATION':
      return compareValues(comparisonData.location, condition);
    case 'CUSTOMERS':
      return compareValues(comparisonData.customer, condition);
    case 'EXPECTED_CONTENT':
      return compareValues(comparisonData.wmsValue, condition);
    case 'EXPECTED_STATE':
      return compareValues(comparisonData.wmsState, condition);
    case 'LOCATION_NAME':
      return compareValues(comparisonData.locationName, condition);
    default:
      return false;
  }
};

export const extractComparisonData = (
  locationData: ILocationData | ILocationDataST,
): ComparisonData => {
  const isILocationDataST = Object.keys(locationData).includes('slot_label');
  if (isILocationDataST) {
    const data = locationData as ILocationDataST;
    const wmsState = typeof data.wms_status === 'string' ? data.wms_status : data.wms_status?.state;
    const wmsValue =
      typeof data.wms_status === 'string' ? data.wms_status : data.wms_status?.barcodes.join(' ,');
    return {
      location: data.slot_label,
      customer: (data.wms_status as IWMSSlotStatusST)?.customers?.[0],
      wmsState: wmsState || '',
      wmsValue: wmsValue || '',
      locationName: data.slot_label,
    };
  }
  return {
    location: (locationData as ILocationData).location,
    customer: (locationData as ILocationData).rowData?.customer as string,
    wmsValue: (locationData as ILocationData).wmsValue,
    wmsState: (locationData as ILocationData).wmsState || '',
    locationName:
      (locationData as ILocationData).rowData?.LocationName ||
      (locationData as ILocationData).location,
  };
};

export const findMatchingRule = (comparisonData: ComparisonData, rules: IRuleST[]) => {
  const matchingRules = rules.map((rule) => {
    const { quantifier } = rule.conditions?.[0] || { quantifier: 'NONE' };

    switch (quantifier) {
      case 'ANY':
        if (
          rule.conditions?.reduce(
            (acc, condition) => acc || evaluateCondition(comparisonData, condition),
            false,
          )
        ) {
          return rule;
        }
        return null;
      case 'ALL':
        if (
          rule.conditions?.reduce(
            (acc, condition) => acc && evaluateCondition(comparisonData, condition),
            true,
          )
        ) {
          return rule;
        }
        return null;
      case 'NONE':
        if (
          rule.conditions?.reduce(
            (acc, condition) => acc && !evaluateCondition(comparisonData, condition),
            true,
          )
        ) {
          return rule;
        }
        return null;
      default:
        return null;
    }
  });
  return matchingRules && matchingRules.length ? matchingRules[0] : null;
};

export const getIssueAndBarcodeMatchingLogic = (
  locationData: ILocationData | ILocationDataST,
  facilitySettings?: IFacilitySettingsST,
) => {
  const barcodeMatchLogic = facilitySettings?.barcode_match_logic_name as
    | BarcodeMatchLogic
    | undefined;

  // NOTE: if multiple issue logics have been selected, then only the first one
  // is actually applied. This approach is in any case to be removed (soon?)
  // eno 2024-07-11
  let issueLogic = facilitySettings?.issue_logic_filter_names?.[0];

  const rules = facilitySettings?.issue_logic_rules?.rules;

  if (rules) {
    const comparisonData = extractComparisonData(locationData);
    const matchingRule = findMatchingRule(comparisonData, rules);
    const action = matchingRule?.action?.issue_logic;
    if (action) {
      issueLogic = action;
    }
  }

  return {
    barcodeMatchLogic,
    issueLogic: issueLogic as IRuleActionSTIssueLogicEnum | undefined,
  };
};
