import { AxiosResponse } from 'axios';
import * as rax from 'retry-axios';
import isFunction from 'lodash/isFunction';

import {
  tokenExpiryRequestInterceptor,
  badTokenResponseInterceptor,
} from './interceptorsFunctions';
import { tokenManager } from './tokenConfiguration';
import { axiosInstance } from './axios/axiosInstance';

const idToken = tokenManager.getIdToken();
if (idToken) {
  axiosInstance.defaults.headers.common.Authorization = idToken;
}

/**
 * Request interceptor will check for for token expiration before sending
 * the request to the server. Refresh the token if its expired and recall
 * sent request
 */
axiosInstance?.interceptors.request.use(tokenExpiryRequestInterceptor);

/**
 * Response interceptor will check if response has error 401
 * which indicate to token expiration before it get to client
 * with .then() and .catch(). It will refresh the token, and
 * resend request
 */
axiosInstance?.interceptors.response.use(
  async (response: AxiosResponse) => {
    // Here we intercept signin and auth-challenge (ex: mfa authentication) request
    // and override instance headers with fresh token
    if (response.data.access_token && response.data.id_token) {
      axiosInstance.defaults.headers.common.Authorization = response.data.id_token;
    }
    return response;
  },
  async (error) => badTokenResponseInterceptor(error),
);
export const apiServices = (raxConfig?: rax.RetryConfig | undefined) => {
  axiosInstance.defaults.raxConfig = {
    // These are the default values which are applied to network requests if none
    // of the below configurations are explicitly defined:
    //
    // retry: 3,
    // noResponseRetries: 2,
    // retryDelay: 100,
    // httpMethodsToRetry: ['GET', 'HEAD', 'OPTIONS', 'DELETE', 'PUT'],
    // statusCodesToRetry: [[100, 199], [429, 429], [500, 599]],
    // backoffType: 'exponential',
    //
    // Below, we are setting some new default values (retry, noResponseRetries, statusCodesToRetry)
    // for cases when no raxConfig is passed to an axios instance in services functions.
    // For example, retry for a service with no raxConfig passed will fallback to 0,
    // meaning it will never retry once it fails

    // How many times to retry failed network request
    retry: raxConfig?.retry || 0,

    // How many times to retry failed network request that don't return a response (ENOTFOUND, ETIMEDOUT, etc)
    noResponseRetries: raxConfig?.noResponseRetries || 0,

    // Milliseconds to delay at first. Only considered when backoffType is 'static'
    retryDelay: raxConfig?.retryDelay || 100,

    // HTTP methods to automatically retry
    httpMethodsToRetry: raxConfig?.httpMethodsToRetry || [
      'GET',
      'HEAD',
      'OPTIONS',
      'DELETE',
      'PUT',
    ],

    // The response status codes to retry
    statusCodesToRetry: raxConfig?.statusCodesToRetry || [
      [100, 199],
      [408, 408],
      [429, 429],
      [500, 599],
    ],

    // If you are using a non static instance of Axios you need
    // to pass that instance here (in our case it is literally called instance)
    instance: axiosInstance,

    // Set the backoff type. Options are 'exponential' (default), 'static' or 'linear'
    backoffType: raxConfig?.backoffType || 'exponential',

    // A callback function invoked when a retry is happening
    onRetryAttempt: (err) => {
      isFunction(raxConfig?.onRetryAttempt) && raxConfig?.onRetryAttempt(err);
    },
  };

  rax.attach(axiosInstance);

  return axiosInstance;
};
