import { Paper, Typography } from '@mui/material';
import { ILocationData } from 'store/Modals/facilityModals/IFacilityModalsStore';
import { Spinner } from 'components/common/Spinner';
import { Box } from 'components/common/Box';
import { useEffect } from 'react';
import { BarcodeMatchLogic } from 'common/functions/barcodes/getBarcodeMuiColor.util';
import { IRuleActionSTIssueLogicEnum } from 'codegen/facility_settings';
import { isTextAreaOrInputActive } from 'common/functions/domFunctions';
import { ImageGridComponent } from './ImageGridComponent';
import { getImageText } from './utils/getImageText';
import { LocationImagesState } from '../../hooks/useLocationImagesState';
import { useImageGridStyles } from './ImageGrid.styles';

export const ImageGrid = ({
  systemId,
  locationData,
  locationImagesState,
  highlightedBarcode,
  issueLogic,
  barcodeMatchLogic,
  setHighlightedBarcode,
}: {
  locationData: ILocationData;
  systemId: string;
  locationImagesState: LocationImagesState;
  highlightedBarcode: string;
  issueLogic: IRuleActionSTIssueLogicEnum | undefined;
  barcodeMatchLogic: BarcodeMatchLogic | undefined;
  setHighlightedBarcode: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const { verityState } = locationData;
  const {
    imageUrls,
    isLoadingImages,
    numberOfImagesRequested,
    showBarcodeHighlighting,
    toggleBarcodeHighlighting,
  } = locationImagesState;
  const { classes } = useImageGridStyles();

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (isTextAreaOrInputActive()) return;

      if (event.key === 'b') {
        toggleBarcodeHighlighting();
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [toggleBarcodeHighlighting]);

  return imageUrls.length ? (
    <div>
      {!isLoadingImages ? (
        <ImageGridComponent
          imageUrls={imageUrls}
          locationData={locationData}
          systemId={systemId}
          showBarcodeHighlighting={showBarcodeHighlighting}
          highlightedBarcode={highlightedBarcode}
          barcodeMatchLogic={barcodeMatchLogic}
          issueLogic={issueLogic}
          setHighlightedBarcode={setHighlightedBarcode}
        />
      ) : (
        <Box className={classes.spinner}>
          <Spinner />
        </Box>
      )}
    </div>
  ) : (
    <Paper className={classes.paper} elevation={0}>
      <div className={classes.imageContainer}>
        <Typography
          className={classes.imageContainerText}
          color="textSecondary"
          align="center"
          variant="h6"
        >
          {getImageText({
            verityState,
            isLoadingImages,
            imageUrlsLength: imageUrls.length,
            numberOfImagesRequested,
          })}
        </Typography>
      </div>
    </Paper>
  );
};
