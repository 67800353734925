import { IReportTabsParams, getReportsTabsMappings, reportsTabs } from 'common/Tables';
import { userHasPermission } from 'features/permissions/userHasPermission';
import { PERMISSION } from 'features/permissions/permissions.model';
import { ITableTabProp } from 'interfaces';
import { TableTabData } from '../../../utils/get-tab-data/TabData';
import { TabData } from '../../../models/tabData.model';

export const getTabData = (
  allTabData: {
    [key: string]: TableTabData;
  },
  REPORTS_TABLE_TABS: ITableTabProp[],
) => {
  let tabs: IReportTabsParams = {
    REPORTS_TABLE_TABS,
  };

  const { ongoingTab, finishedTab, scheduledTab, toReviewTab, archivedTab } =
    getReportsTabsMappings(REPORTS_TABLE_TABS);

  const tabsData: TabData[] = [
    {
      permission: PERMISSION.SCHEDULED_REPORTS_MANAGEMENT,
      key: 'numberOfReportsScheduled',
      numOfReports: allTabData[scheduledTab].rows.length,
    },
    {
      permission: PERMISSION.VIEW_ONGOING_REPORTS,
      key: 'numberOfReportsOngoing',
      numOfReports: allTabData[ongoingTab].rows.length,
    },
    {
      permission: PERMISSION.VIEW_FINISHED_REPORTS,
      key: 'numberOfReportsFinished',
      numOfReports: allTabData[finishedTab].rows.length,
    },
    {
      permission: PERMISSION.REPORTS_FOR_REVIEW_MANAGEMENT,
      key: 'numberOfReportsToReview',
      numOfReports: allTabData[toReviewTab].rows.length,
    },
    {
      permission: PERMISSION.ARCHIVED_REPORTS_MANAGEMENT,
      key: 'numberOfReportsArchived',
      numOfReports: allTabData[archivedTab].rows.length,
    },
  ];

  tabsData.forEach((p) => {
    if (userHasPermission(p.permission)) {
      tabs = {
        ...tabs,
        [p.key]: p.numOfReports,
      };
    }
  });

  return reportsTabs(tabs);
};
