import { TextField } from '@mui/material';
import { Dispatch, ChangeEvent } from 'react';
import { ReportPriority } from '../models/reportPriority.model';
import { ScheduleFormAction } from '../reducers/ScheduleForm.model';

const MIN_PRIORITY = -1;
const MAX_PRIORITY = 100;

type ReportPriorityInputProps = {
  priority: ReportPriority;
  dispatch: Dispatch<ScheduleFormAction>;
};

/**
 * Report priority input
 * @param props ReportPriorityInputProps
 * @returns component
 */
export const ReportPriorityInput = ({ priority, dispatch }: ReportPriorityInputProps) => {
  const handleChange = (e: ChangeEvent<{ value: string }>) => {
    const inputValue =
      Number(e.target.value) >= MIN_PRIORITY && Number(e.target.value) <= MAX_PRIORITY
        ? Number(e.target.value)
        : 0;

    dispatch({ type: 'SET_PRIORITY', payload: inputValue as ReportPriority });
  };

  return (
    <TextField
      fullWidth
      variant="outlined"
      id="report-priority"
      label="Priority"
      name="report-priority"
      type="number"
      value={priority}
      onChange={handleChange}
      inputProps={{ min: MIN_PRIORITY, max: MAX_PRIORITY }}
      onFocus={(e) => e.target.select()}
    />
  );
};
