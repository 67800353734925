import { IRuleActionSTIssueLogicEnum } from 'codegen/facility_settings';
import {
  IVeritySlotStatusST,
  IVeritySlotStatusStateST,
  IWMSSlotStatusSTStateEnum,
} from 'codegen/location_information';

export const getVerityValuesAppliedByIssueLogicLocationNoIssue = ({
  wmsState,
  verityStatus,
  contentFound,
  issueLogic,
}: {
  wmsState?: IWMSSlotStatusSTStateEnum;
  verityStatus: IVeritySlotStatusST;
  contentFound: string[];
  issueLogic: IRuleActionSTIssueLogicEnum | undefined;
}) => {
  const barcodesDetected = !!verityStatus?.barcodes?.length;

  if (issueLogic === 'PICTURE_ONLY' && barcodesDetected) {
    return { verityState: IVeritySlotStatusStateST.Barcode, contentFound: verityStatus.barcodes };
  }

  const verityState = verityStatus?.state;

  if (wmsState !== verityState) {
    if (wmsState === 'BARCODE' && barcodesDetected) {
      return { verityState: IVeritySlotStatusStateST.Barcode, contentFound: verityStatus.barcodes };
    }
    if (wmsState === 'EMPTY') {
      return { verityState: IVeritySlotStatusStateST.Empty, contentFound: [] };
    }
  }

  return {
    verityState,
    contentFound,
  };
};
