import React from 'react';
import { IFleetVersionResponseST, IFlightDomainStatusST } from 'codegen/flight_domain';
import { AlertColor } from '@mui/material';
import {
  FleetOverviewST,
  FleetStatusUpdateWS,
} from '../../pages/GroundControl/pages/FleetOverview/API';
import { IRequestController } from '../../hooks';

import { FlightDomainScheduleST } from '../../udb/ground-control/schedule/features/droneFlightHours/model/operatingHours.model';

export interface IGroundControlState {
  flightDomainLoaded: boolean;
  isExecutingEmergencyLanding: boolean;
  isExecutingReturnHome: boolean;
  dronesFlying: boolean;
  flightDomain: IFlightDomainData;
  flightDomainSchedule: FlightDomainScheduleST;
  fleetOverview: FleetOverviewST;
  fleetVersions: IFleetVersionResponseST;
  webSocketError: boolean; // <= TBD
  alertInfo: IAlertInfo;
  activeAlertVariant: AlertColor;
}

/**
 * Server type for a FleetStatusSummary.
 */
export type FleetStatusSummaryST = {
  num_drones_flying: number;
  num_drones_on_charger: number;
  num_drones_landed_in_error: number;
  updated_at: string;
};

/**
 * Server type for a FlightDomain.
 */
export interface IFlightDomainData {
  flight_domain_id: string;
  flight_domain_name: string;
  flight_domain_status?: IFlightDomainStatusST | null;
  fleet_status_summary?: FleetStatusSummaryST | null;
  isLoadingFleetData: boolean;
}

interface IAlertInfo {
  main: IAlertInfoData;
  fleetCard: IAlertInfoData;
  statusCard: IAlertInfoData;
  flightHoursCard: IAlertInfoData;
}

export interface IAlertInfoData {
  isOpened: boolean;
  variant?: string;
  message?: string;
}

/**
 * Fleet icon tooltips values
 */
export enum TooltipTypes {
  FLYING = 'FLYING',
  ON_CHARGERS = 'ON CHARGERS',
  ERRORS = 'ERRORS',
}

export interface IGroundControlLevelContext {
  stateGroundControl: IGroundControlState;
  dispatchGroundControlLevel: React.Dispatch<GroundControlLevelAction>;
  getFlightDomainData: (
    requestController: IRequestController,
    systemId: string,
    flightDomainId: string,
  ) => void;
  refreshFlightDomainData: (
    requestController: IRequestController,
    systemId: string,
    flightDomainId: string,
  ) => void;
  isDataReady: () => boolean;
  asyncGetFleetOverview: (
    requestController: IRequestController,
    flightDomainId: string,
  ) => Promise<FleetOverviewST>;
  asyncGetFleetVersion: (
    requestController: IRequestController,
    flightDomainId: string,
  ) => Promise<IFleetVersionResponseST>;
  populateFlightDomainSchedule: (flightDomainId: string) => Promise<any>;
  saveFlightDomainSchedule: (schedule: FlightDomainScheduleST) => Promise<any>;
  asyncGetFlightDomainInfo: (
    requestController: IRequestController,
    systemId: string,
    flightDomainId: string,
  ) => void;
}

/*
 * Ground control store action types
 */
export enum GroundControlActionNames {
  SET_FLIGHT_DOMAIN,
  SET_CURRENT_FLIGHT_DOMAIN_PERMISSIONS,
  SET_FLEET_OVERVIEW,
  SET_FLEET_VERSION,
  UPDATE_FLEET_OVERVIEW,
  SET_NO_FLY_ZONES,
  TOGGLE_STATUS_BAR,
  SET_FLIGHT_DOMAIN_SETTINGS,
  SET_WEBSOCKET_ERROR,
  SET_ALERT_INFO,
  SET_IS_LOADING_FLEET_DATA,
  CLEAR_GROUND_CONTROL_STORE,
}

export type GroundControlLevelAction =
  | { type: GroundControlActionNames.SET_FLIGHT_DOMAIN; payload: IFlightDomainData }
  | { type: GroundControlActionNames.SET_FLEET_OVERVIEW; payload: FleetOverviewST }
  | { type: GroundControlActionNames.SET_FLEET_VERSION; payload: IFleetVersionResponseST }
  | { type: GroundControlActionNames.UPDATE_FLEET_OVERVIEW; payload: FleetStatusUpdateWS }
  | {
      type: GroundControlActionNames.SET_FLIGHT_DOMAIN_SETTINGS;
      payload: FlightDomainScheduleST;
    }
  | {
      type: GroundControlActionNames.SET_ALERT_INFO;
      payload: {
        key: 'fleetCard' | 'statusCard' | 'main' | 'flightHoursCard';
        variant?: IAlertInfoData['variant'];
        message?: IAlertInfoData['message'];
      };
    }
  | {
      type: GroundControlActionNames.SET_WEBSOCKET_ERROR;
      payload: boolean;
    }
  | {
      type: GroundControlActionNames.SET_IS_LOADING_FLEET_DATA;
      payload: boolean;
    }
  | {
      type: GroundControlActionNames.CLEAR_GROUND_CONTROL_STORE;
    };
