import { useNavigate, useParams } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import GetAppIcon from '@mui/icons-material/GetApp';
import { Box } from 'components/common/Box';
import { TooltipedIcon } from 'components/common/TooltipedIcon';
import { AlertComponent as Alert } from 'components/common/Alert';
import { Spinner } from 'components/common/Spinner';
import { SkeletonPageHeader } from 'components/Skeletons/SkeletonPageHeader';
import { loadSinceInterval } from 'common/functions/dateTimeFunctions';
import { ReactNode } from 'react';
import { AlertColor } from '@mui/material';
import { SelectTimeInterval } from './select-time-interval/SelectTimeInterval';
import { PageHeaderSectionTitle } from './section-title/PageHeaderSectionTitle';
import { useClientLevelStore } from '../../store/ClientLevelStore/clientLevelStore';
import { useFacilityLevelStore } from '../../store/FacilityLevelStore/facilityLevelStore';
import { PageHeaderBreadcrumbs } from './PageHeaderBreadcrumbs';
import { intervalSelectorType } from './select-time-interval/selectTimeInterval.model';
import { pageHeaderStyles } from './styles';

export const PageHeaderSection = ({
  children,
  title,
  subtitle,
  showLoadedSince = false,
  showDownloadBtn,
  downloadSpinning,
  onClickDownload,
  defaultTimeInterval = 'all',
  showFacilityName = true,
  getDataFrom,
  enableIntervalSelectors = true,
  customBtn,
  isReportPage = false,
  alert,
}: {
  children?: ReactNode;
  title: string;
  subtitle?: string;
  showLoadedSince?: boolean;
  showDownloadBtn?: boolean;
  downloadSpinning?: boolean;
  onClickDownload?: () => void;
  defaultTimeInterval?: loadSinceInterval | intervalSelectorType;
  showFacilityName?: boolean;
  getDataFrom?: (...args: any) => void;
  enableIntervalSelectors?: boolean;
  customBtn?: JSX.Element | null;
  isReportPage?: boolean;
  alert?: {
    label?: string;
    close?: (label: string) => void;
    state: {
      show: boolean;
      title?: string;
      message: string;
      status: AlertColor;
      preventClose: boolean;
    };
  };
}) => {
  const navigate = useNavigate();

  const { stateClientLevel } = useClientLevelStore();
  const { stateFacilityLevel } = useFacilityLevelStore();

  const { classes } = pageHeaderStyles();

  const { systemId } = useParams();
  const currentFacility = stateClientLevel.facilityList.find(
    (facility) => facility.id === systemId,
  );
  const currentFacilityName = currentFacility?.name || '';

  const haveMultipleFlightDomains = stateFacilityLevel.flightDomains.length > 1;
  const showDetails = title || subtitle;

  const handleAlertClose = () => {
    if (alert && alert.close && alert.label) {
      alert.close(alert.label);
    }
  };

  return (
    <Container maxWidth="xl" className={classes.header} data-testid="c-page-header">
      <div>
        {showDetails ? (
          <div className={classes.topHeader}>
            <div className={classes.details}>
              <div>
                <PageHeaderBreadcrumbs
                  navigate={navigate}
                  title={title}
                  facilityName={currentFacilityName}
                  systemId={systemId || ''}
                  isReportPage={isReportPage}
                  multipleFacilitiesAccess={stateClientLevel.isMultiFacility || false}
                  multipleFlightDomains={haveMultipleFlightDomains}
                />

                <PageHeaderSectionTitle
                  title={title}
                  currentFacilityName={currentFacilityName}
                  isMultiFacility={stateClientLevel.isMultiFacility || false}
                  showFacilityName={showFacilityName}
                />
              </div>

              <Box height="auto" display="flex" alignItems="flex-start">
                <Typography
                  data-testid="c-overview-subtitle"
                  className={classes.subtitle}
                  color="textSecondary"
                  sx={{ fontWeight: 400 }}
                  variant="h6"
                >
                  {subtitle}
                </Typography>

                {showLoadedSince && (
                  <SelectTimeInterval
                    enableIntervalSelectors={enableIntervalSelectors}
                    defaultTimeInterval={defaultTimeInterval}
                    getDataFrom={getDataFrom}
                  />
                )}
              </Box>
            </div>

            <div className={classes.searchAndMenu}>
              {customBtn}

              {showDownloadBtn && (
                <TooltipedIcon
                  tooltip="Download"
                  icon={
                    <IconButton
                      onClick={onClickDownload}
                      aria-label="page-header-download-button"
                      size="large"
                    >
                      <GetAppIcon color="secondary" />
                      {downloadSpinning && <Spinner />}
                    </IconButton>
                  }
                />
              )}
            </div>
          </div>
        ) : (
          <SkeletonPageHeader />
        )}
      </div>

      {alert && alert.state.show && (
        <div>
          <Alert
            handleClose={handleAlertClose}
            title={alert.state.title}
            message={alert.state.message}
            status={alert.state.status}
            isActive={true}
            preventClose={alert.state.preventClose}
          />
        </div>
      )}

      {children && <div>{children}</div>}
    </Container>
  );
};
