import { Typography } from '@mui/material';
import { locationStyles } from '../../../styles';

export const AmendValuesNoOverride = () => {
  const { classes } = locationStyles();

  return (
    <div className={classes.imageContainer}>
      <Typography
        className={classes.imageContainerText}
        align="center"
        color="textSecondary"
        variant="h6"
      >
        Locations for which Verity has no data can not be edited.
      </Typography>
    </div>
  );
};
