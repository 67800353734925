import { IVeritySlotStatus1ST, IWMSSlotStatusST } from 'codegen/report';
import { REPORT_ENTRY_ACTIONS } from 'common/Actions/actionTypes';
import { PERMISSION } from 'features/permissions/permissions.model';
import { userHasPermission } from 'features/permissions/userHasPermission';
import { isEmpty } from 'lodash';
import { IOverwriteData } from 'store/Modals/facilityModals/IFacilityModalsStore';
import {
  getVeritySlotStatusVersionFromSlotStatus,
  getVerityStateFromSlotStatus,
  getImageIdsFromSlotStatus,
  getOriginalVerityValue,
  getOriginalVerityState,
} from './veritySlotFunctions';
import { getVerityValueFromSlotStatus } from './getVerityValueFromSlotStatus';
import { getLatestOverwriteFromSlotStatus } from './getLatestOverwriteFromSlotStatus';
import { getWMSStateFromSlotStatus, getWMSValueFromSlotStatus } from './wmsSlotFunctions';

/**
 * Get the actions for the given slot
 * @returns data structure with the definitions of the actions for the given slot / location
 */

export const getSlotActions = (
  location: string,
  wms_status: IWMSSlotStatusST | string | null,
  verity_status: IVeritySlotStatus1ST | string | null,
) => {
  const actions = [];

  if (userHasPermission(PERMISSION.AMEND_LOCATION)) {
    actions.push({
      label: REPORT_ENTRY_ACTIONS.AMEND,
      disabled: isEmpty(verity_status),
    });
  }

  const data: IOverwriteData = {
    isReview: true,
    version: getVeritySlotStatusVersionFromSlotStatus(verity_status),
    location,
    wmsValue: getWMSValueFromSlotStatus(wms_status).contentExpectedValues.join(', '),
    wmsState: getWMSStateFromSlotStatus(wms_status),
    userOverride: getLatestOverwriteFromSlotStatus(verity_status, 'verity'),
    userOverrideValue: getVerityValueFromSlotStatus(verity_status),
    userOverrideUserName:
      getLatestOverwriteFromSlotStatus(verity_status, 'verity')?.user_name || '',
    verityValue: getVerityValueFromSlotStatus(verity_status),
    verityState: getVerityStateFromSlotStatus(verity_status),
    imageIds: getImageIdsFromSlotStatus(verity_status),
    originalVerityValue: getOriginalVerityValue(verity_status),
    originalVerityState: getOriginalVerityState(verity_status),
  };

  return {
    data,
    actions,
  };
};
