import { AxiosResponse } from 'axios';
import { IInventoryRequestRRuleInfoGetResponseST } from 'codegen/inventory_request';
import { Dispatch } from 'react';
import { requestStore } from 'store/RequestStore/RequestStore';
import { ScheduleFormAction } from 'udb/inventory/features/reports/features/report-scheduler/reducers/ScheduleForm.model';
import { singleRequestHandler } from './requestHelpers';

/**
 * Get recurrence data for deadlines
 * @param systemId system id
 * @param rString r string
 * @param dispatch dispatch function
 * @returns promise for request call
 */

export const getRecurrenceDataReportDeadline = (
  systemId: string,
  rString: string,
  dispatch: Dispatch<ScheduleFormAction>,
): Promise<AxiosResponse<IInventoryRequestRRuleInfoGetResponseST, unknown>> =>
  singleRequestHandler({
    request: requestStore.countOccurrences,
    requestParams: [systemId, rString],
    callbackBeforeSend: () => dispatch({ type: 'SET_IS_LOADING', isLoading: true }),
    callbackSuccess: (r) => {
      dispatch({
        type: 'SET_REPORT_DEADLINE_TEXT',
        payload: `The report will finish ${r.data.description}`.replace('at', 'by'),
      });
    },
    callbackFinally: () => dispatch({ type: 'SET_IS_LOADING', isLoading: false }),
  });
