import isEmpty from 'lodash/isEmpty';

import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';

import { bytesToSize } from 'common/functions/fileFunctions';
import { Box } from 'components/common/Box';
import { UploadInfo, IUploadInfoStateParam } from 'components/common/UploadInfo';
import { Spinner } from 'components/common/Spinner';
import { CustomDropZone } from 'components/common/CustomDropZone';
import { Theme } from '@mui/material';
import { BaseCardSimpleItem } from '../BaseCard/BaseCardSimpleItem';
import { BaseCard, IBaseCardActionButton } from '../BaseCard/BaseCard';

const useStyles = makeStyles()((theme: Theme) => ({
  messageWrapper: {
    height: '80px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
  },
}));

export interface IUploadCardData {
  title: string;
  subtitle: string;
  sections: {
    id: string;
    sectionItems: {
      id: string;
      label: string;
      value: any;
    }[];
  }[];
  actionButtons: IBaseCardActionButton[];
}

type UploadInfoLabel = 'WMSSnapshot' | 'StockCheck' | 'MovedLocations';

interface IUploadCardProps {
  cardFor: string;
  uploadInfoLabel: UploadInfoLabel;
  data: IUploadCardData;
  showAlert: { close: (uploadInfoLabel: UploadInfoLabel) => void };
  alertState: IUploadInfoStateParam;
  progress: number;
  onDrop: <T extends File>(acceptedFiles: T[]) => void;
  fileDetails: {
    file: File;
  };
  isLoading: boolean;
  testId: string;
}

export const UploadCard = ({
  cardFor,
  uploadInfoLabel,
  data,
  showAlert,
  alertState,
  progress,
  onDrop,
  fileDetails,
  isLoading,
  testId,
}: IUploadCardProps) => {
  const { classes } = useStyles();

  return (
    <BaseCard
      uploadCard
      cardFor={cardFor}
      showHeader={true}
      showContent={true}
      showActionButtons={true}
      showHeaderDivider={false}
      title={data.title}
      subtitle={data.subtitle}
      actionButtons={data.actionButtons}
    >
      <UploadInfo
        testId={`c-${testId}-alert`}
        state={alertState}
        closeAlert={() => showAlert.close(uploadInfoLabel)}
        progress={progress}
      />
      {!isEmpty(data.sections) ? (
        data.sections.map((section) => (
          <BaseCardSimpleItem
            key={section.sectionItems
              .map((si) => si.label)
              .concat('-')
              .toString()}
            sectionItems={section.sectionItems}
          />
        ))
      ) : (
        <Box className={classes.messageWrapper}>
          <Typography align="center" color="textSecondary" variant="body1">
            No Files uploaded
          </Typography>
        </Box>
      )}
      <Box data-testid={`c-${testId}-droparea`} px={2}>
        <CustomDropZone
          id={`c-${testId}-dropzone`}
          maxFiles={1}
          text={`Drop ${cardFor} file here...`}
          onDrop={onDrop}
        />

        <Typography variant="caption">
          {fileDetails?.file?.name
            ? `File: ${fileDetails.file.name} - ${bytesToSize(fileDetails.file?.size)}`
            : 'No file selected'}
        </Typography>
      </Box>
      {isLoading ? <Spinner /> : null}
    </BaseCard>
  );
};
