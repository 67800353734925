import { ILocationDataST, ILocationLabelST } from 'codegen/warehouse_status';
import { labelTypeEnumToText } from 'components/ModalsAndPopups/LocationModal/features/labels-card/models/labelTypeEnumToText.model';

export const labelValueGetter = (row: ILocationDataST): string => {
  const label = row.location_label as ILocationLabelST;
  if (label?.status !== 'ACTIVE') {
    return '';
  }
  const labelType = label?.label_type;
  return labelType ? labelTypeEnumToText[labelType] : '';
};
