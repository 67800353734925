import { createSvgIcon } from '@mui/material/utils';

export const NoAccessIcon = () => {
  const Icon = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M12.64 7.52c.704 0 1.28-.576 1.28-1.28s-.576-1.28-1.28-1.28-1.28.576-1.28 1.28.576 1.28 1.28 1.28zm-2.368 2.176L8.48 18.72h1.344l1.152-5.12 1.344 1.28v3.84h1.28v-4.8l-1.344-1.28.384-1.92c.832.96 2.112 1.6 3.52 1.6v-1.28c-1.216 0-2.24-.64-2.752-1.536l-.64-1.024c-.256-.384-.64-.64-1.088-.64-.192 0-.32.064-.512.064L7.84 9.312v3.008h1.28v-2.176l1.152-.448" />
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM4 12c0-4.42 3.58-8 8-8 1.85 0 3.55.63 4.9 1.69L5.69 16.9A7.902 7.902 0 0 1 4 12zm8 8c-1.85 0-3.55-.63-4.9-1.69L18.31 7.1A7.902 7.902 0 0 1 20 12c0 4.42-3.58 8-8 8z" />
    </svg>,
    'NoAccessIcon',
  );

  return <Icon />;
};
