import { useFacilityLevelStore } from 'store/FacilityLevelStore/facilityLevelStore';
import { IFacilitySettingsSTMeasurementSystemEnum } from 'codegen/facility_settings';

export const useMeasurementSystem = (): {
  isMetricSystem: boolean;
  unit: string;
} => {
  const {
    stateFacilityLevel: {
      facilitySettings: { measurement_system: measurementSystem },
    },
  } = useFacilityLevelStore();

  const isMetricSystem = measurementSystem === IFacilitySettingsSTMeasurementSystemEnum.Metric;
  const unit = isMetricSystem ? 'm' : 'ft';

  return {
    unit,
    isMetricSystem,
  };
};
