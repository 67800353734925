import { ILocationDataSTStateEnum, IVeritySlotStatus1ST } from 'codegen/report';
import {
  IWMSSlotStatusSTStateEnum,
  IVeritySlotStatusStateST,
  IWMSSlotStatusST,
} from 'codegen/warehouse_status';
import { getMatchArray } from 'common/functions/arrayFunctions';
import { DISPLAY_WMS_STATES, DISPLAY_VERITY_STATES } from 'common/slotStates';
import { isArray } from 'lodash';
import { getContentFoundFromSlotStatus } from './getContentFoundFromSlotStatus';
import {
  getWMSArticleNosFromSlotStatus,
  getWMSQtyFromSlotStatus,
  getCustomersFromSlotStatus,
  getWMSValueFromSlotStatus,
} from './wmsSlotFunctions';

const getWmsData = (wms_status: IWMSSlotStatusST | string | null) => {
  const { contentExpectedState, contentExpectedValues } = getWMSValueFromSlotStatus(wms_status);
  // Expanding the contents of an array into a new array
  // in order to guarantee that we operate on a copy
  const paddedContentExpected = [...contentExpectedValues];
  const wmsArticle = getWMSArticleNosFromSlotStatus(wms_status);
  const paddedArticleNumber = isArray(wmsArticle) ? [...wmsArticle] : [];
  const wmsQty = getWMSQtyFromSlotStatus(wms_status);
  const paddedQuantity = isArray(wmsQty) ? [...wmsQty] : [];
  const customers = getCustomersFromSlotStatus(wms_status);
  const paddedCustomer = isArray(customers) ? [...customers] : [];

  return {
    contentExpectedState,
    paddedContentExpected,
    paddedArticleNumber,
    paddedQuantity,
    paddedCustomer,
  };
};

const getVerityData = (
  verity_status: IVeritySlotStatus1ST | string | null,
  scanState?: ILocationDataSTStateEnum,
) => {
  const contentFoundObject = getContentFoundFromSlotStatus(verity_status, scanState);
  // Expanding the contents of an array into a new array
  // in order to guarantee that we operate on a copy
  const contentFound = [...contentFoundObject.contentValue];
  const {
    contentDate: contentFoundDate,
    contentFoundState,
    contentFoundChangedByTheClient,
  } = contentFoundObject;

  return { contentFound, contentFoundDate, contentFoundState, contentFoundChangedByTheClient };
};

/**
 * This function takes care of padding the data with "No match" for easier reading
 * @slotStatus Slot (Location) status
 */
export const getPaddedSlotContent = (
  wms_status: IWMSSlotStatusST | string | null,
  verity_status: IVeritySlotStatus1ST | string | null,
  scanState?: ILocationDataSTStateEnum,
) => {
  const wmsData = getWmsData(wms_status);
  const { contentExpectedState, paddedArticleNumber, paddedQuantity, paddedCustomer } = wmsData;
  let { paddedContentExpected } = wmsData;

  const { contentFound, contentFoundDate, contentFoundState, contentFoundChangedByTheClient } =
    getVerityData(verity_status, scanState);

  let paddedContentFound: string[] = [];

  // Get padded arrays. We only get padded arrays if either the wms data or verity data contain barcodes
  if (
    contentExpectedState === IWMSSlotStatusSTStateEnum.Barcode ||
    contentFoundState === IVeritySlotStatusStateST.Barcode
  ) {
    paddedContentExpected.forEach((el) => {
      // Check if content expected item exists in content found array
      const ind = contentFound.indexOf(el);
      if (el !== null && ind > -1) {
        // Add content found item to content found padded array
        paddedContentFound.push(contentFound[ind]);

        // Remove content found item from content found array
        contentFound.splice(ind, 1);
      } else {
        // Add no match to content found padded array
        paddedContentFound.push('No match');
      }
    });

    // Add the items that are left on the content found array to the padded content found array
    // add "No match" to the content expected padded array
    contentFound.forEach((el) => {
      paddedContentFound.push(el);

      // Pad the wms data only if it contains barcodes
      if (contentExpectedState === IWMSSlotStatusSTStateEnum.Barcode) {
        paddedContentExpected.push('No match');
        paddedArticleNumber.push('-');
        paddedQuantity.push('-');
        paddedCustomer.push('-');
      }
    });
  }

  // If any of the result sets is not a barcode set - add the corresponding user facing string
  if (contentExpectedState !== IWMSSlotStatusSTStateEnum.Barcode) {
    paddedContentExpected = [
      contentExpectedState !== null
        ? DISPLAY_WMS_STATES[contentExpectedState as 'EMPTY' | 'INVALID']
        : '-',
    ];
  }

  if (contentFoundState !== IVeritySlotStatusStateST.Barcode) {
    paddedContentFound = [
      contentFoundState !== null
        ? DISPLAY_VERITY_STATES[contentFoundState as IVeritySlotStatusStateST]
        : '-',
    ];
  }

  const matchArray = getMatchArray(paddedContentExpected, paddedContentFound);

  let colorizeContentFound = true;
  let colorizeContentExpected = true;

  // If there is no verity data, we should not color it
  if (contentFoundState === null && contentExpectedState !== IWMSSlotStatusSTStateEnum.Invalid) {
    colorizeContentFound = false;
  }

  if (['UNREACHABLE', 'ABORTED', 'EXCLUDED'].includes(contentFoundState ?? '')) {
    colorizeContentExpected = false;
    colorizeContentFound = false;
  }

  // If there is no wms data, we should not color it
  // similarly, if wms data is available and is not "Invalid" and content found is not available, we should not color content expected
  if (
    contentExpectedState === null ||
    (contentExpectedState !== null &&
      contentExpectedState !== IWMSSlotStatusSTStateEnum.Invalid &&
      contentFoundState === null)
  ) {
    colorizeContentExpected = false;
  }

  return {
    paddedContentExpected,
    paddedArticleNumber,
    paddedQuantity,
    paddedCustomer,
    colorizeContentExpected,

    paddedContentFound,
    contentFoundDate,
    contentFoundChangedByTheClient,
    colorizeContentFound,
    matchArray,
  };
};
