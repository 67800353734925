import { IVeritySlotStatusStateST } from 'codegen/warehouse_status';
import { IVeritySlotStatus1ST } from 'codegen/report';
import { DISPLAY_VERITY_STATES } from '../../../slotStates';
import { getLatestOverwriteFromSlotStatus } from './getLatestOverwriteFromSlotStatus';

/**
 * Get the user name from location data (to render on the screen)
 * The user name should only be displayed when the user value was created by a client user
 * @param slotStatus
 * @returns the client user name, if it exists or "-", in case there is no client user overwrite
 */
export const getUserNameFromSlotStatus = (verity_status: IVeritySlotStatus1ST | string | null) => {
  const clientUserOverwrite = getLatestOverwriteFromSlotStatus(verity_status, 'client');
  return clientUserOverwrite ? clientUserOverwrite.user_name : '-';
};

/**
 * Returns verity user overwrite state, if available
 * @param slotStatus Slot Status
 * @returns overwrite state or "-"
 */
export const getVerityStateFromSlotStatus = (
  verity_status: IVeritySlotStatus1ST | string | null,
) => {
  const verityUserOverwrite = getLatestOverwriteFromSlotStatus(verity_status, 'verity');

  if (verityUserOverwrite) {
    return verityUserOverwrite.state;
  }

  return !verity_status || typeof verity_status === 'string' ? undefined : verity_status.state;
};

/**
 * Returns the VerityDate from location data
 * @param slotStatus Slot Status
 * @returns VerityDate or "-"
 */
export const getVerityDateFromSlotStatus = (verity_status: IVeritySlotStatus1ST | string | null) =>
  !verity_status || typeof verity_status === 'string' ? '-' : verity_status.collected_at;

/**
 * Returns the verity slot status version from location data
 * @param slotStatus Slot Status
 * @returns verity status or "-"
 */
export const getVeritySlotStatusVersionFromSlotStatus = (
  verity_status: IVeritySlotStatus1ST | string | null,
) => (!verity_status || typeof verity_status === 'string' ? undefined : verity_status.version);

/**
 * Returns the image ids from location data
 * @param slotStatus slot status
 * @returns array of IDs (or [])
 */
export const getImageIdsFromSlotStatus = (verity_status: IVeritySlotStatus1ST | string | null) =>
  !verity_status || typeof verity_status === 'string' ? [] : verity_status.image_ids;

export const getOriginalVerityValue = (verity_status: IVeritySlotStatus1ST | string | null) => {
  let processedVerityValue: string[] = [];

  if (verity_status && typeof verity_status !== 'string') {
    if (verity_status.state === IVeritySlotStatusStateST.Barcode) {
      processedVerityValue = verity_status.barcodes;
    } else {
      processedVerityValue = [DISPLAY_VERITY_STATES[verity_status.state]];
    }
  }

  return processedVerityValue;
};

// Get the user value from location data (to render on the screen)
// Process user value
// The user value should only be displayed when it was created by a client user
// Returns a string with:
//    the client user overwrite, if it exists
//    - , in case there is no client user overwrite
export const getUserValueFromSlotStatus = (verity_status: IVeritySlotStatus1ST | string | null) => {
  const clientUserOverwrite = getLatestOverwriteFromSlotStatus(verity_status, 'client');
  let processedUserValue: string[] = [];

  if (verity_status && typeof verity_status !== 'string' && clientUserOverwrite) {
    if (clientUserOverwrite.state === IVeritySlotStatusStateST.Barcode) {
      processedUserValue = clientUserOverwrite.barcodes;
    } else {
      processedUserValue = [DISPLAY_VERITY_STATES[clientUserOverwrite.state]];
    }
  }

  return processedUserValue;
};

export const getOriginalVerityState = (
  verity_status: IVeritySlotStatus1ST | string | null,
): IVeritySlotStatusStateST | undefined =>
  !verity_status || typeof verity_status === 'string' ? undefined : verity_status.state;
