import { Tooltip, Typography } from '@mui/material';
import { isArray, isEmpty } from 'lodash';
import { replaceArrayItems } from 'common/functions/arrayFunctions';
import { ILocationDataST } from 'codegen/warehouse_status';
import { multipleBarcode } from './multipleBarcode.style';

export const WmsQuantityCell = (props: { row: ILocationDataST }) => {
  const { classes } = multipleBarcode();

  const values: string[] =
    typeof props.row.wms_status !== 'string' &&
    props.row.wms_status?.state !== 'EMPTY' &&
    props.row.wms_status &&
    isArray(props.row.wms_status.qtys) &&
    !isEmpty(props.row.wms_status.qtys)
      ? (replaceArrayItems(
          props.row.wms_status.qtys as (number | string | null)[],
          null,
          '-',
        ) as string[])
      : ['-'];

  const tooltip = values.join(', ');

  const list = (
    <>
      {values.map((v, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Typography key={v + index} variant="body2" className="c-data-grid-cell-line">
          {v}
        </Typography>
      ))}
    </>
  );

  return (
    <Tooltip title={tooltip} className="c-data-grid-cell c-data-grid-cell-wms-quantity">
      <span className={classes.verticalCenter}>{list}</span>
    </Tooltip>
  );
};
