import {
  IFacilitySettingsST,
  IFacilitySettingsSTMeasurementSystemEnum,
} from 'codegen/facility_settings';
import { SettingsItem } from '../models/settingsCard.model';

export const getMeasurementSystemOptions = (
  facilitySettings: IFacilitySettingsST,
  handleValuesChange: (prop: string, value: number | boolean | string) => void,
): SettingsItem[] => [
  {
    type: 'select',
    label: 'Measurement system',
    testId: 'c-measurement-system-options',
    description: 'Switch between Imperial and Metric measurement systems for distances',
    value: facilitySettings.measurement_system ?? IFacilitySettingsSTMeasurementSystemEnum.Metric,
    selectOptions: [
      { value: IFacilitySettingsSTMeasurementSystemEnum.Metric, label: 'Metric' },
      { value: IFacilitySettingsSTMeasurementSystemEnum.Imperial, label: 'Imperial' },
    ],
    action: (e: React.ChangeEvent<HTMLInputElement>) =>
      handleValuesChange('measurement_system', e.target.value),
  },
];
