/* tslint:disable */
/* eslint-disable */
/**
 * User Override
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface IErrorResponseErrorsInnerST
 */
export interface IErrorResponseErrorsInnerST {
  /**
   * Error code
   * @type {string}
   * @memberof IErrorResponseErrorsInnerST
   */
  code: string;
}
/**
 *
 * @export
 * @interface IErrorResponseST
 */
export interface IErrorResponseST {
  /**
   * Error description
   * @type {string}
   * @memberof IErrorResponseST
   */
  message: string;
  /**
   * Error timestamp
   * @type {string}
   * @memberof IErrorResponseST
   */
  timestamp: string;
  /**
   * User triggering the request
   * @type {string}
   * @memberof IErrorResponseST
   */
  user_name: string;
  /**
   * The request trace id
   * @type {string}
   * @memberof IErrorResponseST
   */
  trace_id: string;
  /**
   * The URL that can be used to report the error
   * @type {string}
   * @memberof IErrorResponseST
   */
  report_url: string | null;
  /**
   * List of Error objects.
   * @type {Array<IErrorResponseErrorsInnerST>}
   * @memberof IErrorResponseST
   */
  errors: Array<IErrorResponseErrorsInnerST>;
}
/**
 *
 * @export
 * @interface IUserOverridePostRequestST
 */
export interface IUserOverridePostRequestST {
  /**
   * List of barcodes
   * @type {Array<string>}
   * @memberof IUserOverridePostRequestST
   */
  barcodes: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof IUserOverridePostRequestST
   */
  set_to_expected: boolean;
  /**
   *
   * @type {IVeritySlotStatusStateST}
   * @memberof IUserOverridePostRequestST
   */
  state: IVeritySlotStatusStateST;
  /**
   *
   * @type {boolean}
   * @memberof IUserOverridePostRequestST
   */
  verity_correct: boolean;
  /**
   * User comment
   * @type {string}
   * @memberof IUserOverridePostRequestST
   */
  comment?: string | null;
}

/**
 * State of the slot
 * @export
 * @enum {string}
 */

export const IVeritySlotStatusStateST = {
  Empty: 'EMPTY',
  Barcode: 'BARCODE',
  NotEmpty: 'NOT_EMPTY',
  Unreachable: 'UNREACHABLE',
  NoResult: 'NO_RESULT',
} as const;

export type IVeritySlotStatusStateST =
  typeof IVeritySlotStatusStateST[keyof typeof IVeritySlotStatusStateST];

/**
 * UserOverrideApi - axios parameter creator
 * @export
 */
export const UserOverrideApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Override verity slot status.
     * @summary Override verity slot status.
     * @param {string} systemIdStr
     * @param {string} slotLabel
     * @param {string} version
     * @param {IUserOverridePostRequestST} iUserOverridePostRequestST
     * @param {'true' | 'false'} [review]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postUserOverride: async (
      systemIdStr: string,
      slotLabel: string,
      version: string,
      iUserOverridePostRequestST: IUserOverridePostRequestST,
      review?: 'true' | 'false',
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('postUserOverride', 'systemIdStr', systemIdStr);
      // verify required parameter 'slotLabel' is not null or undefined
      assertParamExists('postUserOverride', 'slotLabel', slotLabel);
      // verify required parameter 'version' is not null or undefined
      assertParamExists('postUserOverride', 'version', version);
      // verify required parameter 'iUserOverridePostRequestST' is not null or undefined
      assertParamExists(
        'postUserOverride',
        'iUserOverridePostRequestST',
        iUserOverridePostRequestST,
      );
      const localVarPath = `/{system_id_str}/user-override/{slot_label}/{version}`
        .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
        .replace(`{${'slot_label'}}`, encodeURIComponent(String(slotLabel)))
        .replace(`{${'version'}}`, encodeURIComponent(String(version)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (review !== undefined) {
        localVarQueryParameter['review'] = review;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iUserOverridePostRequestST,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UserOverrideApi - functional programming interface
 * @export
 */
export const UserOverrideApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UserOverrideApiAxiosParamCreator(configuration);
  return {
    /**
     * Override verity slot status.
     * @summary Override verity slot status.
     * @param {string} systemIdStr
     * @param {string} slotLabel
     * @param {string} version
     * @param {IUserOverridePostRequestST} iUserOverridePostRequestST
     * @param {'true' | 'false'} [review]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postUserOverride(
      systemIdStr: string,
      slotLabel: string,
      version: string,
      iUserOverridePostRequestST: IUserOverridePostRequestST,
      review?: 'true' | 'false',
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postUserOverride(
        systemIdStr,
        slotLabel,
        version,
        iUserOverridePostRequestST,
        review,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * UserOverrideApi - factory interface
 * @export
 */
export const UserOverrideApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = UserOverrideApiFp(configuration);
  return {
    /**
     * Override verity slot status.
     * @summary Override verity slot status.
     * @param {string} systemIdStr
     * @param {string} slotLabel
     * @param {string} version
     * @param {IUserOverridePostRequestST} iUserOverridePostRequestST
     * @param {'true' | 'false'} [review]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postUserOverride(
      systemIdStr: string,
      slotLabel: string,
      version: string,
      iUserOverridePostRequestST: IUserOverridePostRequestST,
      review?: 'true' | 'false',
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .postUserOverride(
          systemIdStr,
          slotLabel,
          version,
          iUserOverridePostRequestST,
          review,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * UserOverrideApi - object-oriented interface
 * @export
 * @class UserOverrideApi
 * @extends {BaseAPI}
 */
export class UserOverrideApi extends BaseAPI {
  /**
   * Override verity slot status.
   * @summary Override verity slot status.
   * @param {string} systemIdStr
   * @param {string} slotLabel
   * @param {string} version
   * @param {IUserOverridePostRequestST} iUserOverridePostRequestST
   * @param {'true' | 'false'} [review]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserOverrideApi
   */
  public postUserOverride(
    systemIdStr: string,
    slotLabel: string,
    version: string,
    iUserOverridePostRequestST: IUserOverridePostRequestST,
    review?: 'true' | 'false',
    options?: AxiosRequestConfig,
  ) {
    return UserOverrideApiFp(this.configuration)
      .postUserOverride(
        systemIdStr,
        slotLabel,
        version,
        iUserOverridePostRequestST,
        review,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
