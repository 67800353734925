import {
  Country,
  CountryCode,
  getAllCountries as ctGetAllCountries,
  TimezoneName,
} from 'countries-and-timezones';

export const getAllCountries = () => {
  const countries: Record<CountryCode, Country> = ctGetAllCountries();

  return Object.values(countries);
};

export const getTimezones = (country: string): TimezoneName[] => {
  const countries = getAllCountries();
  const filteredCountry = countries.find((ct) => ct.name === country);
  return filteredCountry?.timezones || [];
};
