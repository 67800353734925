import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

export const useWarehouseStatus3DStyle = makeStyles()((theme: Theme) => ({
  pageWrapper: {
    display: 'grid',
    gridTemplateColumns: `minmax(16px, 1fr) minmax(100px, ${theme.breakpoints.values.xl}px) minmax(16px, 1fr)`,
    maxHeight: '75vh',
    height: '100%',
  },
  card: {
    height: '100%',
    gridColumn: '2 / -2',
    position: 'relative',
    padding: theme.spacing(3),
  },
  canvas: {
    minHeight: '30vh',
    borderRadius: 2 * theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
  },
  aisleSummary: {
    position: 'absolute',
    left: theme.spacing(3),
    bottom: theme.spacing(3),
    maxWidth: '80%',
    [theme.breakpoints.down('lg')]: {
      flexWrap: 'wrap',
    },
  },
  wrapper: {
    position: 'relative',
    height: '100%',
  },
}));
