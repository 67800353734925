import moment from 'moment';
import { IControlledZoneStatus1ST, IIntervalST } from 'codegen/controlled_zone';
import { ControlledZone } from '../controlled-zone.model';
import { DroneZoneTypes } from '../drone-zones.model';
import { geometryToBottomLeftDimensions } from './geometry-to-bottom-left-dimensions';

export const controlledZoneFromDTO = (
  zone: IControlledZoneStatus1ST & { id: string },
): ControlledZone => {
  const now = moment().utc();
  const { w, l, h, minX, minY, minZ, maxZ } = geometryToBottomLeftDimensions(zone.geometry);

  const isActive = !!zone?.enabled_interval_list?.some((interval: IIntervalST) => {
    const { start_time, end_time } = interval;
    if (!start_time && !end_time) return false;

    if (start_time && !end_time) return moment(start_time).isBefore(now);

    return now.isBetween(moment(start_time), moment(end_time));
  });

  return {
    id: zone.id,
    type: DroneZoneTypes.controlledZone,
    description: zone.description,
    waitingForDronesToComeHome: !!zone?.waiting_for_drones_to_come_home,
    name: zone.name,
    isActive,
    sizeAndPosition: { w, l, h, minX, minY, minZ, maxZ },
    isSelected: false,
  };
};

export const controlledZonesFromDTO: (
  controlledZones: Record<string, IControlledZoneStatus1ST>,
) => Record<string, ControlledZone> = (controlledZone) =>
  Object.keys(controlledZone).reduce(
    (acc, zoneId) => ({
      ...acc,
      [zoneId]: controlledZoneFromDTO({ id: zoneId, ...controlledZone[zoneId] }),
    }),
    {},
  );
